import styled, { css } from 'styled-components';
import { down, up } from 'styled-breakpoints';

export const ZoomWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background[100]};
    border-radius: 2rem;
    position: relative;
    padding-bottom: 70%;
    min-height: 500px;
    padding-top: 0;
    height: 0;
    overflow: hidden;

    ${down('xl')} {
      padding-bottom: 100%;
    }

    ${up('xxl')} {
      padding-bottom: 72%;
    }

    ${down('xxl')} {
      padding-bottom: 78%;
    }

    ${down('xl')} {
      padding-bottom: 88%;
    }

    ${down('md')} {
      padding-bottom: 88%;
    }

    ${down('sm')} {
      padding-bottom: 130%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `};
`;
