import { memo } from 'react';

import WhatsAppWidget from 'react-whatsapp-widget';

import { useEventfy } from '@hooks';
import { IntegrationLocation } from '@common/types/api';

import * as S from './styles';

type WhatsAppButtonProps = {
  location: IntegrationLocation;
};

const WhatsAppButton = ({ location }: WhatsAppButtonProps) => {
  const { name, whatsapp_button, whatsapp_button_local } = useEventfy();

  if (whatsapp_button_local !== location || !whatsapp_button) {
    return null;
  }

  return (
    <S.Container>
      <WhatsAppWidget
        sendButton="Enviar"
        phoneNumber={whatsapp_button}
        placeholder="Envie sua mensagem"
        companyName={`Suporte ${name}`}
        message={`Olá! 👋🏼 \n\nO que podemos fazer por você?`}
        textReplyTime="Responde tipicamente dentro de 24 horas"
      />
    </S.Container>
  );
};

export default memo(WhatsAppButton);
