import { useEffect, useMemo, useRef } from 'react';

import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';

import { EmojiNames } from '@common/types/api';
import {
  useBroadcastQuery,
  useEmojiMutation,
  useEmojiQuery,
  useEventfy,
} from '@hooks';
import { debounce } from '@utils';
import { selectEmojiSettings } from '@hooks/useEventfy/selectors';

import * as S from './styles';
import { EMOJI_NAMES } from './constants';

const Emoji = () => {
  const queryClient = useQueryClient();
  const shouldShowEmojiName = useBreakpoint(up('md'));
  const { t } = useTranslation();

  const emojiSettings = useEventfy(selectEmojiSettings);

  const { data: settings } = useBroadcastQuery({
    select: (room) => ({
      roomId: room.id,
      isEmojiEnabled: !!room.settings.data.emoji,
    }),
    notifyOnChangeProps: ['data'],
  });

  const { data: emojiData } = useEmojiQuery(settings?.roomId);

  const { mutateAsync } = useEmojiMutation(settings?.roomId);

  const handleEmojiInteraction = useRef(
    debounce(async (emojiName: EmojiNames) => {
      await mutateAsync(emojiName);
    }, 1500),
  ).current;

  const emojis = useMemo(
    () =>
      Object.entries(emojiSettings).filter(
        ([name]) => !['id', 'setting_id'].includes(name),
      ) as unknown as Array<[EmojiNames, string]>,
    [emojiSettings],
  );

  useEffect(() => {
    if (settings?.isEmojiEnabled) {
      queryClient.invalidateQueries(['emoji', settings?.roomId]);
    }
  }, [settings?.isEmojiEnabled, settings?.roomId, queryClient]);

  return (
    <S.Container $disabled={!settings?.isEmojiEnabled}>
      {emojis.map(([emoji, src]) => (
        <li key={emoji}>
          <button type="button" onClick={() => handleEmojiInteraction(emoji)}>
            <img src={src} alt={emoji} />
            <strong>{emojiData?.[emoji] || 0}</strong>

            {shouldShowEmojiName && <span>{t(EMOJI_NAMES[emoji])}</span>}
          </button>
        </li>
      ))}
    </S.Container>
  );
};

export default Emoji;
