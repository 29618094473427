import { memo, useMemo, useState } from 'react';

import getVideoId from 'get-video-id';

import { VideoData } from '@common/types/api';

import * as S from './styles';

type SponsorVideosProps = {
  videos: VideoData[];
};

const SponsorVideos = ({ videos }: SponsorVideosProps) => {
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);

  const currentVideo = useMemo(() => {
    const video = videos[selectedVideoIndex];

    const { id, service } = getVideoId(video.url_video);

    let src = video.url_video;

    if (service === 'youtube') {
      src = `https://www.youtube.com/embed/${id}`;
    } else if (service === 'vimeo') {
      src = `https://player.vimeo.com/video/${id}`;
    }

    return { src, description: video.description };
  }, [selectedVideoIndex, videos]);

  const handleSelectVideo = (index: number) => () =>
    setSelectedVideoIndex(index);

  return (
    <S.Container>
      {videos.length > 1 && (
        <S.VideoList component="ul">
          {videos.map((video, index) => {
            const { id, service } = getVideoId(video.url_video);

            const thumbnail =
              service === 'youtube'
                ? `https://i.ytimg.com/vi/${id}/hqdefault.jpg`
                : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAS1BMVEXN7CSE3Ob+UiJBNjLp8fT1/IoOrNO9LQh92+XX7PLo9fjxvLT/RwP/QBX284X7/oWi18IAqdQclLNDLCE3NzO0LQ29IgbDhRXO8iWCnLkgAAABEklEQVR4nO3QRQGAABAAMNzd+yelwfHks0VYksaatgh1/ZCFxqkMzctahbY9Dx1nHbruJ/ngwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwIEDBw4cOHDgwMHPBy+NyEugMEx2EAAAAABJRU5ErkJggg==';

            return (
              <li key={`${video.url_video}-${video.description}`}>
                <S.SelectVideoButton
                  type="button"
                  onClick={handleSelectVideo(index)}
                >
                  <img src={thumbnail} alt={video.description} />
                </S.SelectVideoButton>
              </li>
            );
          })}
        </S.VideoList>
      )}
      <S.WidescreenWrapper>
        <iframe
          title={currentVideo.description}
          src={currentVideo.src}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </S.WidescreenWrapper>
    </S.Container>
  );
};

export default memo(SponsorVideos);
