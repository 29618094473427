import { useQuery } from 'react-query';

import { api } from '@services';
import { SurveyQuestion } from '@common/types/pusher/events';

const fetchSatisfactionSurvey = async () => {
  const response = await api.get<{ data: SurveyQuestion[] }>('/survey');

  return response.data.data;
};

export const useSatisfactionSurveyQuery = () =>
  useQuery('satisfaction-survey', fetchSatisfactionSurvey);
