/* eslint-disable react/no-danger */
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import { FiCast } from 'react-icons/fi';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Variants, HTMLMotionProps } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { ScheduleSession } from '@components';
import { DEFAULT_TRANSITION } from '@constants';
import { useSpeakerProfileQuery } from '@hooks';

import * as S from './styles';

const variants: Variants = {
  hidden: {
    x: '30rem',
  },
  shown: {
    x: 0,
  },
};

type SpeakerModalBaseProps = HTMLMotionProps<'dialog'> & {
  speakerId: number;
};

type SpeakerModalProps = PropsWithChildren<SpeakerModalBaseProps>;

const SpeakerModal = (
  { speakerId, children, ...attrs }: SpeakerModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const { data } = useSpeakerProfileQuery(speakerId);
  const { t } = useTranslation();

  return (
    <S.Container
      open
      ref={ref}
      variants={variants}
      transition={DEFAULT_TRANSITION}
      {...attrs}
    >
      <S.Wrapper>
        {children}

        <S.SpeakerInfoHeader>
          <img src={data?.photo} alt={data?.name} />

          <S.SpeakerProfile>
            <h2>{data?.name}</h2>
            <strong>{data?.title}</strong>

            <div>
              {data?.facebook && (
                <a
                  href={data?.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook size="1.25rem" />
                </a>
              )}

              {data?.twitter && (
                <a
                  href={data?.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter size="1.25rem" />
                </a>
              )}

              {data?.instagram && (
                <a
                  href={data?.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size="1.25rem" />
                </a>
              )}

              {data?.linkedin && (
                <a
                  href={data?.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size="1.25rem" />
                </a>
              )}
            </div>
          </S.SpeakerProfile>
        </S.SpeakerInfoHeader>

        <S.SpeakerInfoSection>
          <S.SubTitle>{t('modal.speaker.detail')}</S.SubTitle>

          {data?.description && !data.description.includes('null') ? (
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          ) : (
            <p>{t('modal.speaker.empty')}</p>
          )}
        </S.SpeakerInfoSection>

        {!!data?.rooms.data?.length && (
          <S.SpeakerInfoSection>
            <S.SubTitle as="h4">{t('pages.lobby.rooms')}</S.SubTitle>
            {data?.rooms.data?.map((room) => (
              <S.SpeakerInfoRoomLink
                key={`navigate-to-${room.id}`}
                to={`/broadcast/${room.slug}`}
              >
                <FiCast size="0.875rem" />
                {room.name}
              </S.SpeakerInfoRoomLink>
            ))}
          </S.SpeakerInfoSection>
        )}

        {!!data?.schedules.data?.length && (
          <S.SpeakerInfoSection>
            <S.SubTitle as="h4">{t('pages.lobby.schedule')}</S.SubTitle>
            <ul>
              {data?.schedules.data?.map((session) => (
                <ScheduleSession key={session.id} data={session} />
              ))}
            </ul>
          </S.SpeakerInfoSection>
        )}
      </S.Wrapper>
    </S.Container>
  );
};

export default forwardRef(SpeakerModal);
