import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { api } from '@services';
import {
  TimelineResponse,
  NewsfeedPostWithoutComments,
} from '@common/types/api';

import { useAuth } from '../useAuth';
import { selectUser } from '../useAuth/selectors';

type PostData = {
  message: string;
  picture?: File;
};

export const useCreatePostMutation = () => {
  const queryClient = useQueryClient();

  const user = useAuth(selectUser);

  const queryKey = ['newsfeed'] as const;

  return useMutation(
    (variables: PostData) => {
      const data = new FormData();
      data.append('message', variables.message);

      if (variables.picture) {
        data.append('picture', variables.picture);
      }

      return api.post('/timeline', data);
    },
    {
      onMutate: (data: PostData) => {
        const date = new Date().toISOString();

        const previousPosts =
          queryClient.getQueryData<InfiniteData<TimelineResponse>>(queryKey);

        if (previousPosts) {
          queryClient.setQueryData<InfiniteData<TimelineResponse>>(queryKey, {
            ...previousPosts,
            pages: previousPosts.pages.map((page, index) => {
              if (index > 0) {
                return page;
              }

              return {
                ...page,
                data: [
                  {
                    id: Date.now(),
                    message: data.message,
                    created_at: date,
                    updated_at: date,
                    user_id: user.id,
                    picture: data.picture
                      ? URL.createObjectURL(data.picture)
                      : null,
                    likes: {
                      data: [],
                    },
                    likes_total: 0,
                    comments_total: 0,
                    aspect_ratio: '1:1',
                    user: {
                      data: {
                        id: user.id,
                        avatar: user.avatar,
                        name: user.name,
                      },
                    },
                  } as unknown as NewsfeedPostWithoutComments,
                  ...page.data,
                ],
              };
            }),
          });
        }

        return { previousPosts };
      },
      onError: (_, __, ctx) => {
        if (ctx?.previousPosts) {
          queryClient.setQueryData<InfiniteData<TimelineResponse>>(
            queryKey,
            ctx.previousPosts,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    },
  );
};
