import { useQueries, useQueryClient, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import {
  FullScheduleData,
  FullScheduleResult,
  FullSpeakersResult,
  FullSpeakerData,
  RoomsWithSpeakersResult,
  SponsorsResult,
  VideosResult,
} from '@common/types/api';
import { ONE_HOUR, TWENTY_FOUR_HOURS } from '@constants';

import {
  fetchRooms,
  fetchSchedule,
  fetchSpeakers,
  fetchSponsors,
  fetchVideos,
} from './queries';

type LobbyQuery<T> = UseQueryResult<T, AxiosError>;

export const useLobbyQueries = () => {
  const queryClient = useQueryClient();

  return useQueries([
    {
      queryKey: ['rooms', 1],
      queryFn: fetchRooms,
      staleTime: ONE_HOUR,
    },
    {
      queryKey: ['speakers', 1],
      queryFn: fetchSpeakers,
      staleTime: TWENTY_FOUR_HOURS,
      onSuccess: (data) => {
        const { speakers } = data as FullSpeakersResult;

        speakers.map((speaker) =>
          queryClient.setQueryData<FullSpeakerData>(
            ['speaker', speaker.id],
            speaker,
          ),
        );
      },
    },
    {
      queryKey: ['schedule', 1],
      queryFn: fetchSchedule,
      staleTime: TWENTY_FOUR_HOURS,
      onSuccess: (data) => {
        const { schedule } = data as FullScheduleResult;

        schedule.map((session) =>
          queryClient.setQueryData<FullScheduleData>(
            ['session', session.id],
            session,
          ),
        );
      },
    },
    {
      queryKey: ['sponsors', 1],
      queryFn: fetchSponsors,
      staleTime: TWENTY_FOUR_HOURS,
    },
    {
      queryKey: ['videos', 1],
      queryFn: fetchVideos,
      staleTime: TWENTY_FOUR_HOURS,
    },
  ]) as [
    LobbyQuery<RoomsWithSpeakersResult>,
    LobbyQuery<FullSpeakersResult>,
    LobbyQuery<FullScheduleResult>,
    LobbyQuery<SponsorsResult>,
    LobbyQuery<VideosResult>,
  ];
};
