import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { TransientProps } from '@common/types';

type WithLoading = TransientProps<{ loading?: boolean }>;

export const Container = styled.li<WithLoading>`
  ${({ theme, $loading: isLoading }) => css`
    width: 100%;
    /* max-width: 35.5rem; */
    border-radius: 1rem;
    background: ${theme.colors.background[100]};
    border-left: 0.25rem solid ${theme.colors.brand.secondary.default};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);
    transition: 180ms 80ms ease-in-out;

    ${isLoading &&
    css`
      pointer-events: none;
    `}

    > button {
      display: flex;
      width: 100%;
      max-height: 100%;
      justify-content: flex-start;
      align-items: center;
      padding: 1.25rem 2.5rem 1.25rem 1rem;
      font: inherit;
      background: none;
      color: ${theme.colors.foreground[100]};
    }

    :hover {
      transform: translate3d(0, -0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023), 0 2px 2px rgba(0, 0, 0, 0.023),
        0 4px 4px rgba(0, 0, 0, 0.023), 0 8px 8px rgba(0, 0, 0, 0.023),
        0 16px 16px rgba(0, 0, 0, 0.023), 0 32px 32px rgba(0, 0, 0, 0.023);
    }

    :active {
      transform: translate3d(0, 0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047), 0 2px 2px rgba(0, 0, 0, 0.047),
        0 4px 4px rgba(0, 0, 0, 0.047);
    }
  `}
`;

export const SessionDate = styled.div`
  ${({ theme }) => css`
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background: ${theme.colors.background[80]};
    color: ${theme.colors.foreground[60]};
    margin-right: 1rem;

    > span {
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 0.5rem;
      font-weight: 400;
    }

    > strong {
      font-size: 1.5rem;
    }
  `}
`;

export const SessionDateSkeleton = styled(SessionDate).attrs({
  as: Skeleton,
  width: '4rem',
  height: '4rem',
  style: {
    borderRadius: '1rem',
  },
})``;

export const SessionDataWrapper = styled.div`
  flex: 1;
  text-align: left;

  > strong {
    display: block;
    margin-bottom: 0.5rem;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.5rem;
  }
`;

export const Calendar = styled.div`
  margin: 0.95rem 0;

  .react-add-to-calendar__button {
    border-radius: 0.75rem;
    padding: 6px;
    color: #fff;
    font-size: 0.75rem;
    background: #2c3f5e;
  }
  .react-add-to-calendar__dropdown ul li a {
    color: #333;
    font-size: 0.75rem;
    text-decoration: none;
  }
`;

export const ScheduleSessionData = styled.div`
  ${({ theme }) => css`
    flex: 1;
    width: 100%;
    max-width: 16.5rem;
    min-width: 8.75rem;
    display: block;
    overflow: hidden;

    > span {
      font-size: 0.75rem;
      font-weight: 400;
      color: ${theme.colors.foreground[80]};
      max-height: 2.25rem;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      text-overflow: ellipsis;
    }

    > time {
      margin-top: 0.5rem;
      font-size: 0.875rem;
      font-weight: 400;
      color: ${theme.colors.brand.primary.default};
    }
  `}
`;

export const SessionSpeakersContainer = styled.div<WithLoading>`
  ${({ theme, $loading: isLoading }) => css`
    width: 7rem;

    > span {
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 0.75rem;
    }

    > ul {
      display: flex;
      align-items: center;

      img,
      div {
        width: 2.25rem;
        height: 2.25rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: ${isLoading ? 0 : 0.875}rem;
        object-fit: cover;
        border-radius: 50%;
        background: ${isLoading
          ? 'transparent'
          : theme.colors.brand.primary.default};
        color: ${theme.colors.background[100]};
        border: 0.25rem solid ${theme.colors.background[100]};

        :first-child {
          margin-left: -0.25rem;
        }

        + img,
        + div {
          margin-left: -1rem;
        }
      }
    }
  `}
`;
