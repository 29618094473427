import { memo, useCallback, useEffect, useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useTheme } from 'styled-components';
import { GooSpinner } from 'react-spinners-kit';
import { AxiosError } from 'axios';
import { CgPoll } from 'react-icons/cg';
import Slider from 'react-slick';

import { useBroadcastQuery, usePollQuery } from '@hooks';
import { api } from '@services';

import * as S from './styles';
import { BaseInteractionProps } from '../../types';

type MutationFnVariables = Record<'pollId' | 'optionId', number>;

const Poll = ({ active: isActive }: BaseInteractionProps) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: room } = useBroadcastQuery({
    select: ({ id, settings }) => ({
      id,
      isPollEnabled: !!settings.data.poll,
    }),
    notifyOnChangeProps: ['data'],
  });

  const pollsQuery = usePollQuery(room?.id);
  const refetchPolls = pollsQuery.refetch;

  const [, setCurrentAnswerId] = useState<number | null>(null);

  const { mutateAsync, isLoading: isMutating } = useMutation(
    ({ pollId, optionId }: MutationFnVariables) =>
      api.post(`/rooms/${room?.id}/polls`, {
        poll_id: pollId,
        option: optionId,
      }),
    {
      onMutate: ({ optionId }: MutationFnVariables) =>
        setCurrentAnswerId(optionId),
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (err) => {
        toast.error(
          (err as AxiosError).response?.data.message ||
            t('pages.broadcast.fail'),
        );
      },
      onSettled: async () => {
        setTimeout(() => setCurrentAnswerId(null), 1500);

        await queryClient.invalidateQueries(['poll', room?.id]);
      },
    },
  );

  const hasNoQuizzes =
    !pollsQuery.data?.polls.length && !pollsQuery.data?.totalAnsweredPolls;

  const hasAllQuizzesBeenAnswered =
    !!pollsQuery.data?.totalAnsweredPolls && !pollsQuery.data?.polls.length;

  const handleQuizAnswer = useCallback(
    (pollId, optionId) => async () => {
      await mutateAsync({ pollId, optionId });
    },
    [mutateAsync],
  );

  useEffect(() => {
    isActive && refetchPolls();
  }, [isActive, refetchPolls]);

  if (hasNoQuizzes) {
    return (
      <S.Container $hide={!room?.isPollEnabled} $disabled={!isActive}>
        <S.MessageContainer>
          <CgPoll size="3rem" />
          <p>{t('pages.broadcast.poll.noPoll')}</p>
        </S.MessageContainer>
      </S.Container>
    );
  }

  if (hasAllQuizzesBeenAnswered) {
    return pollsQuery.data?.answeredPolls.length ? (
      <S.ResultsContainer $hide={!room?.isPollEnabled} $disabled={!isActive}>
        <Slider
          dots
          swipe
          arrows
          centerMode
          accessibility
          infinite={false}
          autoplay={false}
          slidesToShow={1}
          slidesToScroll={1}
          centerPadding="16px"
          className="polls-carousel"
        >
          {pollsQuery.data?.answeredPolls.map((poll) => (
            <S.PollWrapper key={poll.id}>
              <h3>{poll.description}</h3>

              <S.PollOptionsContainer>
                {poll.results.map(({ id, option, porcentagem }) => (
                  <S.PollResult key={id}>
                    <div>
                      <div style={{ width: `${porcentagem}%` }} />

                      {`${option}`}
                      <strong>{`${porcentagem}%`}</strong>
                    </div>
                  </S.PollResult>
                ))}
              </S.PollOptionsContainer>
            </S.PollWrapper>
          ))}
        </Slider>
      </S.ResultsContainer>
    ) : (
      <S.Container $hide={!room?.isPollEnabled} $disabled={!isActive}>
        <S.MessageContainer>
          <CgPoll size="3rem" />
          <p>{t('pages.broadcast.poll.noPoll')}</p>
        </S.MessageContainer>
      </S.Container>
    );
  }

  return (
    <S.Container $hide={!room?.isPollEnabled} $disabled={!isActive}>
      {pollsQuery.data?.polls.slice(0, 1).map((poll) => (
        <S.PollWrapper key={poll.id}>
          <h3>
            {poll.description}

            {isMutating && (
              <GooSpinner
                size={1.5}
                sizeUnit="rem"
                color={theme.colors.brand.primary.default}
              />
            )}
          </h3>

          <S.PollOptionsContainer>
            {poll.options.data.map(({ id, option, picture }) => (
              <S.PollOption key={id}>
                <button type="button" onClick={handleQuizAnswer(poll.id, id)}>
                  {picture && <img src={picture} alt={option} />}

                  {option}
                </button>
              </S.PollOption>
            ))}
          </S.PollOptionsContainer>
        </S.PollWrapper>
      ))}
    </S.Container>
  );
};

export default memo(Poll);
