import { ForwardedRef, forwardRef, PropsWithChildren, useMemo } from 'react';

import { FiCalendar, FiCast } from 'react-icons/fi';
import { HTMLMotionProps, Variants } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import AddToCalendar from 'react-add-to-calendar';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';

import { formatScheduleDisplayData } from '@utils';
import { Speaker } from '@components';
import { DEFAULT_TRANSITION } from '@constants';
import { useScheduleSessionQuery } from '@hooks/useScheduleSessionQuery';

import * as S from './styles';

const variants: Variants = {
  hidden: {
    x: '30rem',
  },
  shown: {
    x: 0,
  },
};

type ScheduleModalBaseProps = HTMLMotionProps<'dialog'> & {
  sessionId: number;
  langId: number;
};

type ScheduleModalProps = PropsWithChildren<ScheduleModalBaseProps>;

const ScheduleModal = (
  { sessionId, langId, children, ...attrs }: ScheduleModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const { data } = useScheduleSessionQuery(sessionId);
  const { t } = useTranslation();

  const { day, month, year, timetable, weekDay } = useMemo(
    () =>
      data
        ? formatScheduleDisplayData(data, langId)
        : ({} as ReturnType<typeof formatScheduleDisplayData>),
    [data, langId],
  );

  const weekTrans = t(weekDay);
  const startTime = `${data?.schedule_date}T${data?.langs.data[langId].start_time}-03:00`;
  const endTime = `${data?.schedule_date}T${data?.langs.data[langId].end_time}-03:00`;
  const eventCalandar = {
    title: data?.langs.data[langId].title,
    description: data?.langs.data[langId].description,
    location: `${window.location.origin}/broadcast/${data?.room.data.slug}`,
    startTime,
    endTime,
  };
  const icon = { 'calendar-plus-o': 'left' };
  return (
    <S.Container
      open
      ref={ref}
      variants={variants}
      transition={DEFAULT_TRANSITION}
      {...attrs}
    >
      <S.Wrapper>
        {children}

        <S.ScheduleInfoHeader>
          <div>
            <FiCalendar size="1.5rem" />
            <span>{t('modal.schedule.info')}</span>
          </div>
          <h2>{data?.langs.data[langId].title}</h2>

          <time>
            {`${weekTrans}, ${day} ${t(`date.month.${month}`)} ${year}`} |
            {timetable}
          </time>
        </S.ScheduleInfoHeader>
        <S.Calendar>
          <AddToCalendar
            event={eventCalandar}
            buttonLabel={t('pages.schedule.reminder')}
            buttonTemplate={icon}
          />
        </S.Calendar>
        <S.ScheduleInfoSection>
          <S.SubTitle>{t('modal.schedule.detail')}</S.SubTitle>
          <p>{data?.langs.data[langId].description}</p>
        </S.ScheduleInfoSection>

        <S.ScheduleInfoSection>
          <S.SubTitle as="h4">{t('pages.lobby.rooms')}</S.SubTitle>
          <S.ScheduleInfoRoomLink to={`/broadcast/${data?.room.data.slug}`}>
            <FiCast size="0.875rem" />
            {data?.room.data.name}
          </S.ScheduleInfoRoomLink>
        </S.ScheduleInfoSection>

        <S.ScheduleInfoSection>
          <S.SubTitle as="h4">{t('pages.lobby.speakers')}</S.SubTitle>
          <ul>
            {data?.participants.data?.map((speaker) => (
              <Speaker key={speaker.id} data={speaker} flat negativeMargin />
            ))}
          </ul>
        </S.ScheduleInfoSection>
      </S.Wrapper>
    </S.Container>
  );
};

export default forwardRef(ScheduleModal);
