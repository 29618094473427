import { memo, useCallback, useEffect, useRef } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiSend } from 'react-icons/fi';
import { FaQuestionCircle } from 'react-icons/fa';
import { useVirtual } from 'react-virtual';
import { useTheme } from 'styled-components';
import { GooSpinner } from 'react-spinners-kit';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  useBroadcastQuery,
  useAskingQuery,
  useAskingAnswersChannel,
  useAskingMutation,
} from '@hooks';
import { Nullable } from '@common/types';

import * as S from './styles';
import * as I from '../styles';
import { ANSWERS_STATUS } from './constants';
import { BaseInteractionProps } from '../../types';

type FormInputs = {
  question: string;
};

const Asking = ({ active: isActive }: BaseInteractionProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const formMethods = useForm<FormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(
      Yup.object().shape({
        question: Yup.string().required('valid.asking.req'),
      }),
    ),
  });
  const { handleSubmit, reset } = formMethods;

  const { data: room } = useBroadcastQuery({
    select: ({ id, settings }) => ({
      id,
      isAskingEnabled: !!settings.data.asking,
    }),
    notifyOnChangeProps: ['data'],
  });

  const askingQuery = useAskingQuery(room?.id);

  const { mutateAsync, isLoading: isMutating } = useAskingMutation(room?.id);

  const parentRef = useRef<Nullable<HTMLElement>>(null);

  const rowVirtualizer = useVirtual({
    size: askingQuery.data?.length || 0,
    parentRef,
    estimateSize: useCallback(() => 48, []),
  });
  const { virtualItems, scrollToOffset, totalSize } = rowVirtualizer;

  const handleQuestionSubmit = useCallback(
    async ({ question }: FormInputs) => {
      reset();
      await mutateAsync(question);
    },
    [mutateAsync, reset],
  );

  useAskingAnswersChannel(room?.id);

  useEffect(() => {
    if (askingQuery.data) {
      scrollToOffset(totalSize, {
        align: 'end',
      });
    }
  }, [scrollToOffset, askingQuery.data, totalSize]);

  return (
    <S.Container
      autoComplete="off"
      onSubmit={handleSubmit(handleQuestionSubmit)}
      $hide={!room?.isAskingEnabled}
      $disabled={!isActive}
    >
      <FormProvider {...formMethods}>
        <I.InteractionWrapper>
          <h3>{t('pages.broadcast.asking.title')}</h3>

          <I.ContentWrapper
            containerRef={(ref) => {
              parentRef.current = ref;
            }}
          >
            <I.VirtualizedList $height={totalSize}>
              {virtualItems.map((virtualRow) => {
                const question = askingQuery.data?.[virtualRow.index];
                const isLastIndex =
                  virtualRow.index === (askingQuery.data?.length || 0) - 1;

                return (
                  <S.QuestionWrapper
                    key={virtualRow.index}
                    ref={virtualRow.measureRef}
                    $translateY={virtualRow.start}
                  >
                    <S.Question $status={question?.status || 0}>
                      <FaQuestionCircle />

                      <S.QuestionContent>
                        <div>
                          <p>{question?.asking}</p>

                          <span>
                            {t(ANSWERS_STATUS[question?.status || 0])}
                          </span>

                          {isLastIndex && isMutating && (
                            <GooSpinner
                              size={1.5}
                              sizeUnit="rem"
                              color={theme.colors.brand.primary.default}
                            />
                          )}
                        </div>

                        {question?.answer && (
                          <p>
                            <strong>R:</strong> {question.answer}
                          </p>
                        )}
                      </S.QuestionContent>
                    </S.Question>
                  </S.QuestionWrapper>
                );
              })}
            </I.VirtualizedList>
          </I.ContentWrapper>
        </I.InteractionWrapper>

        <S.QuestionInput
          required
          name="question"
          type="text"
          autoFocus={isActive}
          disabled={!room?.isAskingEnabled}
          placeholder={t('input.asking')}
          buttonIcon={FiSend}
        />
      </FormProvider>
    </S.Container>
  );
};

export default memo(Asking);
