import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { motion } from 'framer-motion';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled(motion.dialog)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-height: 96%;
    border-radius: 1rem;
    position: relative;
    background: ${theme.colors.background[100]};
    color: ${theme.colors.foreground[100]};
    box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0px 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0px 12.5px 0.625rem rgba(0, 0, 0, 0.035),
      0px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0px 41.8px 33.4px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.07);
    > p {
      text-align: center;
      margin: 1rem 0 2rem;
      word-break: break-all;
      + button {
        margin-top: auto;
      }
    }
    span {
      display: block;
      font-size: 0.875rem;
    }
    ${down('md')} {
      width: 100%;
      align-items: initial;
    }
  `}
`;

export const ScrollBar = styled(PerfectScrollbar)`
  padding: 3rem 1.875rem;
  ${down('md')} {
    padding: 2rem 1.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(196, 196, 196, 0.27);
  padding: 2.5rem 0 0.625rem 1.875rem;
  width: 100%;
  .indications-header {
    background: #e3fff3;
    border-radius: 1.875rem 0px 0px 1.875rem;
    padding: 0.375rem 1.563rem;
    strong {
      color: #4bc490;
      font-weight: 500;
    }
  }
  ${down('md')} {
    display: flex;
    flex-wrap: wrap;
    border: 0;
    padding: 0;
    .indications-header {
      width: 100%;
      border-radius: 0;
      padding: 0.375rem 0;
      text-align: center;
      > strong {
        font-size: 0.875rem;
      }
    }
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  > svg {
    width: 3.375rem;
    height: 3.375rem;
    margin-right: 0.625rem;
  }
  ${down('md')} {
    border-bottom: 1px solid rgba(196, 196, 196, 0.27);
    padding: 2rem 0 0.5rem 1rem;
    margin-bottom: 0.625rem;
    > div {
      display: grid;
      > h2 {
        font-size: 1rem;
      }
      > span {
        font-size: 12px;
      }
    }
  }
`;

export const Content = styled.div`
  padding: 0 1rem 3.125rem 1rem;
  text-align: center;
  ${down('md')} {
    padding: 0 1rem 1.5rem 1rem;
  }
`;

export const IndicationLink = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.375rem 0;
  padding: 0 6.25rem;
  height: 100%;
  > input {
    display: flex;
    background: #f1f4f9;
    border-radius: 0.313rem;
    font-size: 0.875rem;
    resize: none;
    padding: 1rem 0 1rem 1rem;
    width: 100%;
    color: rgba(97, 110, 132, 0.81);
  }
  > button {
    background: #616e84;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  ${down('md')} {
    padding: 0;
    > input {
      font-size: 0.625rem;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 2rem;
  > button {
    font-size: 1.125rem;
    color: #616e84;
    background: none;
    padding: 0;
  }
`;
