import styled, { css } from 'styled-components';

import { PerfectScrollbar } from '@components';
import { TransientProps } from '@common/types';

export const InteractionWrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;
    max-height: 100%;
    overflow: hidden;

    h3 {
      padding: 0 1rem;
      font-size: 1rem;
      font-weight: 500;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid ${theme.colors.background[80]};
    }

    @media (max-height: 768px) {
      max-height: 88%;
    }
  `}
`;

export const ContentWrapper = styled(PerfectScrollbar)`
  margin-bottom: 1rem;
  height: 100%;
  max-height: calc(100% - 3.5rem);
  padding: 0 1rem;
`;

type VirtualizedListProps = TransientProps<{ height: number }>;

export const VirtualizedList = styled.ul.attrs<VirtualizedListProps>(
  ({ $height: height }) => ({
    style: {
      height,
    },
  }),
)<VirtualizedListProps>`
  ${() => css`
    width: 100%;
    padding-top: 1rem;
    position: relative;
    margin-top: auto;
  `}
`;

type VirtualizedItemWrapperProps = TransientProps<{ translateY: number }>;

export const VirtualizedItemWrapper = styled.li.attrs<VirtualizedItemWrapperProps>(
  ({ $translateY: translateY }) => ({
    style: {
      transform: `translateY(${translateY}px)`,
    },
  }),
)<VirtualizedItemWrapperProps>`
  ${() => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  `}
`;
