import { memo } from 'react';

import { FaCrown } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';

import { GamificationRankingData } from '@common/types/api';
import { Avatar } from '@components';
import { generateArray } from '@utils';

import * as S from './styles';

type RankingTableProps = {
  data?: GamificationRankingData[];
};

const RankingTable = (props: RankingTableProps) => {
  const { data } = props;

  return (
    <S.Container>
      <S.Wrapper component="ul">
        {data
          ? data.map(({ info_user, total, user, user_id }, index) => {
              const position = index + 1;

              return (
                <S.RankedContainer key={`ranking-${user_id}-${position}`}>
                  <S.RankPosition $position={index <= 2 ? position : undefined}>
                    <strong>{`${position}`.padStart(2, '0')}.</strong>

                    {index <= 2 && <FaCrown size="1.75rem" />}
                  </S.RankPosition>

                  <S.UserData>
                    <Avatar src={user.data.avatar} alt={user.data.name} />

                    <div>
                      <strong>{user.data.name}</strong>
                      <span>{info_user}</span>
                    </div>
                  </S.UserData>

                  <span>{`${total} pts`}</span>
                </S.RankedContainer>
              );
            })
          : generateArray(10).map((key, index) => (
              <S.RankedContainer key={key}>
                <S.RankPosition $position={index <= 2 ? index + 1 : undefined}>
                  <strong>
                    <Skeleton width="1rem" />
                  </strong>

                  {index <= 2 && <FaCrown size="1.75rem" />}
                </S.RankPosition>

                <S.UserData>
                  <Skeleton width="2.5rem" height="2.5rem" />

                  <div>
                    <strong>
                      <Skeleton width="1rem" />
                    </strong>
                    <span>
                      <Skeleton width="2rem" />
                    </span>
                  </div>
                </S.UserData>

                <span>
                  <Skeleton width="12.5rem" />
                </span>
              </S.RankedContainer>
            ))}
      </S.Wrapper>
    </S.Container>
  );
};

export default memo(RankingTable);
