import { MASKS, MaskTypes } from './constants';

export const maskValue = (value: string, type: MaskTypes) => {
  const notNumberRegex = /\D/g;

  const pattern = MASKS[type];
  const sanitizedValue = value.toString().replace(notNumberRegex, '');

  if (sanitizedValue.length === 0) {
    return '';
  }

  let i = -1;

  const maskedValue = pattern.replace(/#/g, () => {
    i += 1;

    return sanitizedValue[i] || '';
  });

  const maskedValueLength = maskedValue.length;

  const lastChar = maskedValue.substr(maskedValueLength - 1, 1);

  if (notNumberRegex.test(lastChar)) {
    const charBeforeLastChar = maskedValue.substr(maskedValueLength - 2, 1);

    const numberOfCharsToRemove = charBeforeLastChar === ' ' ? 3 : 1;

    return maskedValue.substr(0, maskedValue.length - numberOfCharsToRemove);
  }

  return maskedValue;
};
