import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    grid-area: Player;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    /* max-height: 30.5rem; */
    background: ${theme.colors.background[100]};
    border-radius: 2rem;
    /* margin-bottom: 3rem; */
    box-shadow: 1rem 1rem 5.75rem #0000000d;
  `};
`;
