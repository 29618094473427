import styled, { css, keyframes, DefaultTheme } from 'styled-components';
import { down } from 'styled-breakpoints';

import { TransientProps } from '@common/types';

export const Container = styled.article`
  width: 100%;
  display: flex;
  justify-self: center;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  > p {
    a {
      color: ${({ theme }) => theme.colors.brand.primary.default};

      :hover {
        text-decoration: underline;
      }
    }
  }
`;
export const Lang = styled.div`
  display: flex;
  justify-content: right;
  margin: 0.25rem 2.2rem 0.25rem 0rem;
`;
export const Header = styled.header`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  img {
    width: 100%;
    max-height: 3rem;
    height: 100%;
    object-fit: contain;
    object-position: left center;
  }
`;

export const Form = styled.form`
  margin: 3.75rem 0;

  > h2 {
    font-size: 1.75rem;
    margin-bottom: 2rem;
  }

  > p {
    margin-bottom: 3rem;
  }

  > label + div {
    margin-top: 1rem;
  }

  > button {
    margin-top: 2.5rem;
  }
`;

export const MagicWrapper = styled.section`
  margin: 3.75rem 0;
  height: 72.5vh;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormSettings = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
`;

export const LGPDWarning = styled.p`
  margin-top: 2.5rem;
  font-size: 0.875rem;
  text-align: justify;
`;

type FlexWrapperProps = TransientProps<{ maxWidth: number; break: boolean }>;

export const FlexWrapper = styled.div<Partial<FlexWrapperProps>>`
  ${({ $maxWidth: maxWidth = 50, $break: willBreak = false }) => css`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;

    label {
      max-width: calc(${maxWidth}% - 0.5rem);
      margin: 0;
    }

    ${down('sm')} {
      flex-direction: ${willBreak ? 'column' : 'row'};

      label {
        max-width: ${willBreak ? '100%' : 'calc(60% - 0.5rem)'};
      }
    }
  `}
`;

export const TermsWrapper = styled.section`
  ${({ theme }) => css`
    margin-top: 2rem;

    > p {
      margin-bottom: 1rem;

      button {
        background: 0;
        display: inline-block;
        padding: 0;
        text-align: left;
        color: ${theme.colors.brand.secondary.default};

        :hover {
          text-decoration: underline;
        }
      }
    }
  `}
`;

export const TermsAgreement = styled.label`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 2rem;
    user-select: none;
    margin-top: 1rem;

    input {
      display: none;

      &:checked + span {
        animation: ${checkAnimation(theme)} 0.5s
          cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;

        svg {
          opacity: 1;
          transform: scale(0);
          color: white;
          animation: ${iconAnimation} 0.3s cubic-bezier(1, 0.008, 0.565, 1.65)
            0.1s 1 forwards;
        }
      }
    }

    span {
      display: inline-block;
      border-radius: 50%;
      border: 5px solid rgba(0, 0, 0, 0.1);
      background: white;
      vertical-align: middle;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: border 0.3s ease;
      position: absolute;
      left: 0;
      transform-origin: center;

      svg {
        opacity: 0.2;
        color: transparent;
        transition: opacity 0.3s 0.1s ease;
      }

      &:hover {
        border: 5px solid rgba(0, 0, 0, 0.2);
      }
    }
  `}
`;

const checkAnimation = ({ colors }: DefaultTheme) => keyframes`
  0% {
    transform: scale(0.75);
    border-width: 5px;
  }

  10% {
    transform: scale(0.75);
    opacity: 0.1;
    background: rgba(0, 0, 0, 0.2);
    border-width: 15px;
  }

  12% {
    transform: scale(0.75);
    opacity: 0.4;
    background: rgba(0, 0, 0, 0.1);
    border-width: 0;
  }

  50% {
    transform: scale(1);
    background: ${colors.success[100]};
    border: 0;
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
    background: ${colors.success[100]};
    border: 0;
    opacity: 1;
  }
`;

const iconAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;
