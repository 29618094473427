/* eslint-disable react/no-danger */
import { memo } from 'react';

import ExpandCollapse from 'react-expand-collapse';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

type EventDescriptionProps = {
  description: string;
};

const EventDescription = ({ description }: EventDescriptionProps) => {
  const { t } = useTranslation();
  const more = t('pages.lobby.aboutMore');
  return (
    <ExpandCollapse
      previewHeight="16rem"
      ellipsis={false}
      expandText={more}
      collapse={false}
    >
      <S.Wrapper dangerouslySetInnerHTML={{ __html: description }} />
    </ExpandCollapse>
  );
};

export default memo(EventDescription);
