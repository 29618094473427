import styled, { css } from 'styled-components';

import { PerfectScrollbar } from '@components';
import { TransientProps } from '@common/types';

type ContainerProps = TransientProps<{ disabled: boolean; hide: boolean }>;

export const Container = styled.div<ContainerProps>`
  ${({ $disabled: isDisabled, $hide: shouldHide }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    transition: 180ms ease-in-out;

    ${isDisabled &&
    css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
    `}

    ${shouldHide &&
    css`
      display: none;
    `}
  `}
`;

export const ResultsContainer = styled(PerfectScrollbar)<ContainerProps>`
  ${({ theme, $disabled: isDisabled, $hide: shouldHide }) => css`
    height: 100%;
    transition: 180ms ease-in-out;

    .polls-carousel {
      height: 92.5%;

      .slick-next::before,
      .slick-prev::before {
        color: ${theme.colors.brand.secondary.default};
      }

      .slick-prev {
        z-index: 4;
        left: 0.5rem;
      }

      .slick-next {
        z-index: 4;
        right: 0.5rem;
      }

      .slick-dots li button:before {
        font-size: 0.625rem;
      }
    }

    ${isDisabled &&
    css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
    `}

    ${shouldHide &&
    css`
      display: none;
    `}
  `}
`;

type MessageContainerProps = Partial<TransientProps<{ success: boolean }>>;

export const MessageContainer = styled.div<MessageContainerProps>`
  ${({ theme, $success: isSuccess }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    height: calc(100% - 5.875rem);

    svg {
      color: ${isSuccess
        ? theme.colors.success[100]
        : theme.colors.brand.primary.default};
    }
  `}
`;

export const PollWrapper = styled(PerfectScrollbar)`
  ${({ theme }) => css`
    width: 100%;

    h3 {
      display: flex;
      gap: 0.5rem;
      padding: 1rem;
      font-size: 1rem;
      font-weight: 500;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid ${theme.colors.background[80]};

      > div {
        overflow: hidden;
      }
    }
  `}
`;

export const PollOptionsContainer = styled.ul`
  ${() => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 0.5rem;
    padding: 0 1rem;
  `}
`;

export const PollOption = styled.li`
  ${({ theme }) => css`
    flex: 1;

    > button {
      width: 100%;
      border-radius: 0.5rem;
      justify-content: flex-start;
      gap: 1rem;
      background: ${theme.colors.background[80]};
      border: 2px solid ${theme.colors.background[80]};
      transition: 180ms 80ms ease-in-out;

      > img {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 1rem;
        object-fit: cover;
      }

      :hover {
        border-color: ${theme.colors.brand.primary.default};
      }
    }
  `}
`;

export const PollResult = styled.li`
  ${({ theme }) => css`
    flex: 1;

    > div {
      width: 100%;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.5rem;
      position: relative;
      background: ${theme.colors.background[80]};
      border: 2px solid ${theme.colors.brand.primary.opaque};
      color: ${theme.colors.brand.primary.default};
      transition: 180ms 80ms ease-in-out;

      > div {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: ${theme.colors.brand.primary.opaque};
      }
    }
  `}
`;
