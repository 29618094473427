import { useQuery } from 'react-query';

import { api } from '@services';
import { useEventfy } from '@hooks/useEventfy';
import { selectIsGamificationRankingEnabled } from '@hooks/useEventfy/selectors';
import { GamificationRankingResponse } from '@common/types/api';
import { THIRTY_MINUTES } from '@constants';

const fetchGamification = async () => {
  const response = await api.get<GamificationRankingResponse>(
    `/gamification?limit=100`,
  );

  return response.data.data;
};

export const useGamificationQuery = () => {
  const isGamificationEnabled = useEventfy(selectIsGamificationRankingEnabled);

  return useQuery('gamification', fetchGamification, {
    enabled: isGamificationEnabled,
    staleTime: THIRTY_MINUTES - 2000,
    refetchInterval: THIRTY_MINUTES,
  });
};
