/* eslint-disable radar/cognitive-complexity */
import { useState } from 'react';

import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory } from 'react-router-dom';
import { FiLock, FiMail } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button, Input, MetaTags, TawkTo, WhatsAppButton } from '@components';
import { useAuth, useEventfy } from '@hooks';
import { ResetPassRequest, IntegrationLocation } from '@common/types/api';
import { HistoryState } from '@services/history';

import { resetPassSchemas } from './schemas';
import * as S from '../styles';

const ResetPassword = () => {
  const event = useEventfy();
  const { resetPassword } = useAuth();
  const history = useHistory<HistoryState>();
  const tokenEmail = new URLSearchParams(history.location.search).get('token');
  const email = new URLSearchParams(history.location.search).get('email');
  const { t } = useTranslation();

  if (!email) {
    const message = t('resetPassword.invalid');
    toast.error(message);
    history.push(history.location.state?.from || '/sign-in');
  }

  const formMethods = useForm<ResetPassRequest>({
    resolver: yupResolver(resetPassSchemas[1]),
    defaultValues: {
      email: email || undefined,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const hasChatOnPage =
    event.whatsapp_button_local === IntegrationLocation.LOGIN ||
    event.tag_tawk_local === IntegrationLocation.LOGIN;

  const handleResetPassword = async (fields: ResetPassRequest) => {
    const { ...credentials } = fields;

    try {
      setIsLoading(true);
      if (tokenEmail) {
        credentials.token = tokenEmail;
      }

      if (email) {
        credentials.email = email;
      }
      await resetPassword(credentials);
      const message = t('resetPassword.success');
      toast.success(message);

      history.push(history.location.state?.from || '/sign-in');
    } catch (err) {
      const fallbackMessage = t('resetPassword.fail');

      if (err?.isAxiosError) {
        const message =
          (err as AxiosError<{ message: string }>).response?.data.message ??
          fallbackMessage;
        toast.error(message);
      } else {
        toast.error(fallbackMessage);
      }
      setIsLoading(false);
    }
  };

  return (
    <S.Container>
      <MetaTags title="Login" />

      <S.Header>
        <h1>
          <Link to="/sign-in">
            <img src={event.logo} alt={event.name} />
          </Link>
        </h1>

        {!!event.session_login && (
          <Button title="Login" variant="outline" as={Link} to="/sign-in" />
        )}
      </S.Header>

      {tokenEmail ? (
        <FormProvider {...formMethods}>
          <S.Form onSubmit={formMethods.handleSubmit(handleResetPassword)}>
            <h2>{t('resetPassword.title')}</h2>

            <p>{t('message')}</p>
            <div style={{ display: 'none' }}>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="E-mail"
                icon={FiMail}
                required
                readOnly
              />
            </div>
            <Input
              id="new-password"
              name="password"
              type="password"
              placeholder={t('input.newPassword')}
              autoComplete="new-password"
              icon={FiLock}
              required
            />

            <Input
              name="password_confirmation"
              type="password"
              placeholder={t('input.password.retry')}
              autoComplete="off"
              icon={FiLock}
              required
            />

            <Button
              title={
                event.session_login
                  ? t('button.resetPassword') || 'Resetar'
                  : t('button.disabled') || 'Ação desabilitada'
              }
              type="submit"
              disabled={!event.session_login}
              loading={isLoading}
              fullWidth
              pill
            />
          </S.Form>
        </FormProvider>
      ) : (
        <></>
      )}

      {hasChatOnPage && <p>{t('sign_in.support')}</p>}

      <TawkTo location={IntegrationLocation.LOGIN} />
      <WhatsAppButton location={IntegrationLocation.LOGIN} />
    </S.Container>
  );
};

export default ResetPassword;
