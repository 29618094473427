import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { ONE_HOUR } from '@constants';
import { RoomsWithSpeakersResult } from '@common/types/api';

import { fetchRooms } from '../useLobbyQueries/queries';

export const useRoomsQuery = (page = 1) =>
  useQuery<RoomsWithSpeakersResult, AxiosError>(['rooms', page], fetchRooms, {
    staleTime: ONE_HOUR,
  });
