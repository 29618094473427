import { memo } from 'react';

import { FiDownload } from 'react-icons/fi';

import { useBroadcastQuery } from '@hooks';

import * as S from './styles';

const RoomFiles = () => {
  const { data: roomArchives } = useBroadcastQuery({
    select: ({ archives }) => archives.data,
    notifyOnChangeProps: 'tracked',
  });

  return (
    <S.Container component="ul">
      <>
        {roomArchives?.map((file) => (
          <li key={file.id}>
            <S.DownloadLink
              download
              href={file.url_download}
              target="__blank"
              rel="noopener noreferer"
            >
              <span>
                <FiDownload size="1.25rem" />
              </span>

              {file.description}
            </S.DownloadLink>
          </li>
        ))}
      </>
    </S.Container>
  );
};

export default memo(RoomFiles);
