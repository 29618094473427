/* eslint-disable radar/cognitive-complexity */
import { useState } from 'react';

import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory } from 'react-router-dom';
import { FiMail } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button, Input, MetaTags, TawkTo, WhatsAppButton } from '@components';
import { useAuth, useEventfy } from '@hooks';
import { ForgotPassRequest, IntegrationLocation } from '@common/types/api';
import { HistoryState } from '@services/history';

import { forgotPassSchemas } from './schemas';
import * as S from '../styles';

const ForgotPassword = () => {
  const event = useEventfy();
  const { forgotPassword } = useAuth();
  const history = useHistory<HistoryState>();
  const { t } = useTranslation();

  const formMethods = useForm<ForgotPassRequest>({
    resolver: yupResolver(forgotPassSchemas[1]),
  });

  const [isLoading, setIsLoading] = useState(false);

  const hasChatOnPage =
    event.whatsapp_button_local === IntegrationLocation.LOGIN ||
    event.tag_tawk_local === IntegrationLocation.LOGIN;
  const magicToken = new URLSearchParams(history.location.search).get('string');

  const handleResetPassword = async (credentials: ForgotPassRequest) => {
    try {
      setIsLoading(true);
      // eslint-disable-next-line no-param-reassign
      credentials.route = `${window.location.origin}/reset-password/?token={token}`;
      await forgotPassword(credentials);
      const message = t('forgot.success');
      toast.success(message);

      history.push(history.location.state?.from || '/sign-in');
    } catch (err) {
      const fallbackMessage = t('forgot.fail');

      if (err?.isAxiosError) {
        const message =
          (err as AxiosError<{ message: string }>).response?.data.message ??
          fallbackMessage;
        toast.error(message);
      } else {
        toast.error(fallbackMessage);
      }
      setIsLoading(false);
    }
  };

  return (
    <S.Container>
      <MetaTags title="Login" />

      <S.Header>
        <h1>
          <Link to="/sign-in">
            <img src={event.logo} alt={event.name} />
          </Link>
        </h1>

        {!!event.session_login && (
          <Button title="Login" variant="outline" as={Link} to="/sign-in" />
        )}
      </S.Header>

      {!magicToken ? (
        <FormProvider {...formMethods}>
          <S.Form onSubmit={formMethods.handleSubmit(handleResetPassword)}>
            <h2>{t('forgot.title')}</h2>

            <p>{t('forgot.message')}</p>

            <Input
              required
              autoFocus
              name="email"
              type="email"
              placeholder="E-mail"
              autoComplete="email"
              inputMode="email"
              icon={FiMail}
              disabled={!event.session_login}
            />

            <Button
              title={
                event.session_login
                  ? t('button.recover') || 'Recuperar'
                  : t('button.disabled') || 'Desativado'
              }
              type="submit"
              disabled={!event.session_login}
              loading={isLoading}
              fullWidth
              pill
            />
          </S.Form>
        </FormProvider>
      ) : (
        <></>
      )}

      {hasChatOnPage && <p>{t('sign_in.support')}</p>}

      <TawkTo location={IntegrationLocation.LOGIN} />
      <WhatsAppButton location={IntegrationLocation.LOGIN} />
    </S.Container>
  );
};

export default ForgotPassword;
