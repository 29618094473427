import styled, { css } from 'styled-components';

import { TransientProps } from '@common/types';

type ContainerProps = TransientProps<{
  defaultSize: boolean;
}>;

export const Container = styled.section<ContainerProps>`
  ${({ $defaultSize: isDefaultSize }) => css`
    margin-top: ${isDefaultSize ? 6 : 0}rem !important;

    ${!isDefaultSize &&
    css`
      grid-area: Sponsors;
      max-width: 100%;
    `};

    > h3 {
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 0.75rem;
      margin-bottom: ${isDefaultSize ? 2 : 1}rem;
    }

    .sponsors-carousel {
      img {
        width: ${isDefaultSize ? 13.5 : 8.5}em;
        height: ${isDefaultSize ? 5 : 4.5}rem;
        object-fit: contain;
        transition: 180ms 80ms ease-in-out;

        :hover {
          transform: scale(1.05);
        }
      }
    }
  `}
`;
