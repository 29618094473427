import { ChangeEventHandler, useState } from 'react';
import { useCallback } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button } from '@components';
import { useCreatePostMutation } from '@hooks';

import { PICTURE_INITIAL_STATE } from './constants';
import Editor, { PostFields } from './Editor';
import PictureInput from './PictureInput';
import * as S from './styles';

const NewPost = () => {
  const formMethods = useForm<PostFields>();

  const [picture, setPicture] = useState(PICTURE_INITIAL_STATE);

  const { mutate, isLoading: isMutating } = useCreatePostMutation();
  const { t } = useTranslation();

  const handleResetForm = () => {
    formMethods.reset();
    handleResetPicture();
  };

  const handleResetPicture = useCallback(
    () => setPicture(PICTURE_INITIAL_STATE),
    [],
  );

  const handlePicture: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const image = e.target.files?.[0];

      if (image) {
        const fileSize = image.size / 1024 / 1024; // in MiB

        if (fileSize > 4) {
          const message = t('pages.newsfeed.sizeImage');
          toast.error(message);
        } else {
          setPicture({
            file: image,
            previewURL: URL.createObjectURL(image),
          });
        }
      }
    },
    [t],
  );

  const handleCreatePost = ({ message }: PostFields) => {
    mutate(
      {
        message,
        picture: picture.file,
      },
      {
        onSuccess: () => handleResetForm(),
      },
    );
  };

  return (
    <S.Container onSubmit={formMethods.handleSubmit(handleCreatePost)}>
      <FormProvider {...formMethods}>
        <Editor />

        <S.Footer>
          <PictureInput
            src={picture.previewURL}
            reset={handleResetPicture}
            handleChange={handlePicture}
            t={t}
          />

          <div>
            <Button
              type="button"
              variant="outline"
              title={t('button.discard') || 'Descartar'}
              size="small"
              onClick={handleResetForm}
            />

            <Button
              type="submit"
              size="small"
              title={t('button.newPost') || 'Criar Post'}
              disabled={isMutating}
              loading={isMutating}
            />
          </div>
        </S.Footer>
      </FormProvider>
    </S.Container>
  );
};

export default NewPost;
