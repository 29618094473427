import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { motion } from 'framer-motion';

import { TransientProps } from '@common/types';

export const TabsContainer = styled.div`
  ${() => css`
    grid-area: About;
    max-width: 100%;
    min-height: 20rem;

    ${down('md')} {
      margin-top: 1.5rem;
    }
  `};
`;

export const TabList = styled(motion.ul)`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    border-bottom: 1px solid ${theme.colors.base.border};
  `};
`;

type TabItemProps = TransientProps<{
  active: boolean;
}>;

export const TabItem = styled(motion.li)<TabItemProps>`
  ${({ theme, $active: isActive }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    text-align: center;
    transition: 180ms ease-in-out;
    font-size: 0.875rem;

    ${isActive &&
    css`
      color: ${theme.colors.brand.secondary.default};
    `}
  `};
`;

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  max-width: 100%;
  height: calc(100% - 5.875rem);
`;

export const AnimatedTab = styled(motion.div)`
  ${() => css`
    height: 100%;
    display: flex;
    flex-direction: row;
    will-change: transform, height;
  `};
`;

export const TabContent = styled.section`
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: stretch;
  justify-content: flex-start;
  flex-basis: 100%;
  flex-shrink: 0;
  height: 100%;
  outline: none;
`;
