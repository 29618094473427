import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import { CgBlock } from 'react-icons/cg';
import { FaUsers } from 'react-icons/fa';
import { HTMLMotionProps } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Button } from '@components';
import { useAuth } from '@hooks';
import { DEFAULT_TRANSITION, DEFAULT_MODAL_ANIMATION } from '@constants';

import * as S from './styles';

type BaseLogoutEventModalProps = HTMLMotionProps<'dialog'> & {
  type: string;
  message: string;
};

type LogoutEventModalProps = PropsWithChildren<BaseLogoutEventModalProps>;

const LogoutEventModal = (
  props: LogoutEventModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const { signOut } = useAuth();
  const { t } = useTranslation();

  const { type, message, children, ...attrs } = props;

  const isMultipleLoginEvent = type === 'login_multiple';

  const modalTitle = isMultipleLoginEvent
    ? t('modal.logoutEvent.multiple')
    : t('modal.logoutEvent.blocked');

  const Icon = isMultipleLoginEvent ? FaUsers : CgBlock;

  const handleSignOut = () => signOut();

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      {...attrs}
    >
      {children}

      <Icon size="5rem" />

      <h2>{modalTitle}</h2>
      <p>{message}</p>

      <Button onClick={handleSignOut} title={t('button.exitEvent') || 'Sair'} />
    </S.Container>
  );
};

export default forwardRef(LogoutEventModal);
