import styled, { css } from 'styled-components';

import { TransientProps } from '@common/types';

type withActive = TransientProps<{ active: boolean }>;
type withLoading = TransientProps<{ loading: boolean }>;
type RoomContainerProps = withLoading &
  withActive & {
    $deleting: boolean;
  };

export const Container = styled.li<RoomContainerProps>`
  ${({ $active: isActive, $deleting: isDeleting }) => css`
    display: flex;
    align-items: flex-start;

    > img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0;
    }

    & + & {
      margin-top: 0.5rem;
    }
    ${!isActive &&
    css`
      pointer-events: none;
    `}

    ${isDeleting &&
    css`
      opacity: 0.4;
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0.75rem;
    background: ${theme.colors.background[80]};

    > div {
      display: flex;
      gap: 0.2rem;
      strong {
        font-size: 0.875rem;
      }

      time {
        font-size: 0.825rem;
      }
    }

    p {
      margin-top: 0.25rem;
      word-break: break-word !important;
    }
    button {
      padding: 0 !important;
    }
  `}
`;

export const ModeratorOptionsContainer = styled.div`
  ${() => css`
    margin: 0 -0.75rem 0 auto;

    .tippy-content {
      padding: 0;
    }
  `}
`;

export const ModeratorOptionsButton = styled.button`
  ${({ theme }) => css`
    background: none;
    white-space: nowrap;
    width: 100%;
    border-radius: 0;
    justify-content: flex-start;
    transition: 180ms ease-in-out;
    padding: 1rem;

    :hover {
      background: ${theme.colors.brand.primary.default};
      color: ${theme.colors.base.text};
    }

    :first-child {
      border-radius: 0.25rem 0.25rem 0 0;
    }
    :last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }
  `}
`;
