import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { PerfectScrollbar } from '@components';

export const AuthContainer = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

export const FormsWrapper = styled(PerfectScrollbar)`
  flex: 1;
  padding: 2.5rem;
  overflow-y: auto;
  overflow-x: hidden;

  ${down('sm')} {
    padding: 1.5rem;
  }
`;

export const HeroImageWrapper = styled.div`
  max-width: 52%;
  width: 100%;
  min-height: 100vh;
  position: relative;

  ${down('xl')} {
    max-width: 44%;
  }

  ${down('lg')} {
    max-width: 38%;
  }

  ${down('md')} {
    display: none;
  }
`;

export const HeroImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center center;
`;
