import { ComponentProps } from 'react';

import { DefaultTheme as Theme } from 'styled-components';

type Toaster = typeof import('react-hot-toast').Toaster;
type ToastOptions = ComponentProps<Toaster>['toastOptions'];

export const generateToastOptions = ({ colors }: Theme): ToastOptions => ({
  style: {
    borderRadius: '0.25rem',
  },
  success: {
    style: {
      background: colors.success[100],
      color: colors.background[100],
    },
    iconTheme: {
      primary: colors.background[100],
      secondary: colors.success[100],
    },
  },
  error: {
    style: {
      background: colors.error[100],
      color: colors.background[100],
    },
    iconTheme: {
      primary: colors.background[100],
      secondary: colors.error[100],
    },
  },
});
