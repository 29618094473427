import { useHistory, useLocation } from 'react-router';
import { ReactPaginateProps } from 'react-paginate';
import { useTranslation } from 'react-i18next';

import { MetaTags, Pagination } from '@components';
import { usePagination, useRoomsQuery } from '@hooks';
import { generateArray } from '@utils';
import { RoomsIllustration } from '@assets';

import * as S from './styles';
import RoomCard from './RoomCard';

type HandlePagination = ReactPaginateProps['onPageChange'];

const Rooms = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(search);
  const currentPage = queryParams.get('page') || '1';

  const roomsQuery = useRoomsQuery(Number(currentPage));

  const pagination = usePagination(
    roomsQuery.data?.meta.pagination.total_pages,
  );

  const handlePageNavigation: HandlePagination = ({ selected }) => {
    queryParams.set('page', String(selected + 1));

    history.push({
      pathname,
      search: `?${queryParams.toString()}`,
    });
  };

  return (
    <S.Container>
      <MetaTags title={t('pages.rooms.title')} />

      <S.RoomsHeader>
        <div>
          <span>{t('pages.rooms.subTitle')}</span>
          <h1>{t('pages.rooms.title')}</h1>
        </div>

        <RoomsIllustration />
      </S.RoomsHeader>

      <S.ListWrapper>
        {roomsQuery.isLoading
          ? generateArray(6).map((key) => (
              <RoomCard key={`room-${key}`} loading />
            ))
          : roomsQuery.data?.rooms.map((room) => (
              <RoomCard key={`room-${room.id}`} data={room} />
            ))}
      </S.ListWrapper>

      <Pagination pageCount={pagination} onPageChange={handlePageNavigation} />
    </S.Container>
  );
};

export default Rooms;
