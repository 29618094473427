import * as Yup from 'yup';

const emailShape = {
  email: Yup.string().required('valid.email.req').email('valid.email.invalid'),
  route: Yup.string(),
};

export const forgotPassSchemas = {
  1: Yup.object().shape(emailShape),
};
