import { QueryFunctionContext, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { api } from '@services';
import { RoomAskingsData, RoomAskingsResponse } from '@common/types/api';
import { THIRTY_MINUTES } from '@constants';
import { PromiseWithCancel } from '@common/types';
import { cancelToken } from '@services/api';

const fetchQuestions = (ctx: QueryFunctionContext<[string, number]>) => {
  const [, roomId] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<RoomAskingsResponse>(`/rooms/${roomId}/askings`, {
      cancelToken: source.token,
    })
    .then(({ data }) => data.data.reverse()) as PromiseWithCancel<
    RoomAskingsData[]
  >;

  promise.cancel = () => source.cancel('useAskingQuery Cancelled');

  return promise;
};

export const useAskingQuery = (roomId?: number) =>
  useQuery<RoomAskingsData[], AxiosError>(['askings', roomId], fetchQuestions, {
    enabled: !!roomId,
    staleTime: THIRTY_MINUTES,
  });
