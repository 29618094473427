import { memo } from 'react';

import { MdOndemandVideo } from 'react-icons/md';
import { Link } from 'react-router-dom';
import getVideoId from 'get-video-id';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { SectionHeader } from '@components';
import { useEventfy, useVideosQuery } from '@hooks';
import { selectHasVideos } from '@hooks/useEventfy/selectors';

import * as S from './styles';

const VideosCarousel = () => {
  const eventHasVideos = useEventfy(selectHasVideos);
  const { t } = useTranslation();

  const { isLoading, data } = useVideosQuery();

  if (!eventHasVideos || isLoading || !data?.videos.length) {
    return null;
  }
  return (
    <S.Container>
      <S.Section>
        <SectionHeader title={t('pages.videos.title')} icon={MdOndemandVideo}>
          {data?.videos && data?.videos.length > 3 && (
            <Link to="videos/1">{t('pages.videos.all')}</Link>
          )}
        </SectionHeader>

        <S.VideoCardsContainer>
          {data.videos.slice(0, 3).map((video) => {
            const { id, service } = getVideoId(video.url_video);

            const thumbnail =
              service === 'youtube'
                ? `https://i.ytimg.com/vi/${id}/hqdefault.jpg`
                : video.url_image;
            return (
              <S.VideoCard key={video.id}>
                <Link to={`/videos/${video.id}`}>
                  <img src={thumbnail} alt={video.title} />
                  <S.VideoCardDataWrapper>
                    <S.VideoCardInfo>
                      <strong>{video.title}</strong>
                    </S.VideoCardInfo>
                  </S.VideoCardDataWrapper>
                </Link>
              </S.VideoCard>
            );
          })}
        </S.VideoCardsContainer>
      </S.Section>
    </S.Container>
  );
};

export default memo(VideosCarousel);
