import styled, { css } from 'styled-components';

import { Input, PerfectScrollbar } from '@components';
import { TransientProps } from '@common/types';

type ContainerProps = TransientProps<{ disabled: boolean; hide: boolean }>;

export const Container = styled.form<ContainerProps>`
  ${({ $disabled: isDisabled, $hide: shouldHide }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    transition: 180ms ease-in-out;

    .tag-cloud {
      text-align: center;
    }

    ${isDisabled &&
    css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
    `}

    ${shouldHide &&
    css`
      display: none;
    `}
  `}
`;

export const WordCloudWrapper = styled(PerfectScrollbar)`
  ${({ theme }) => css`
    width: 100%;
    overflow: hidden;
    max-height: 100%;

    h3 {
      display: flex;
      gap: 0.5rem;
      padding: 1rem;
      font-size: 1rem;
      font-weight: 500;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid ${theme.colors.background[80]};

      > div {
        overflow: hidden;
      }
    }

    .tag-cloud {
      padding: 0 1rem;
      overflow: hidden;
    }
  `}
`;

export const WordCloudInput = styled(Input)`
  height: 3.25rem;
  max-width: calc(100% - 2rem);
  margin: 0 1rem 1rem;

  > div svg {
    margin: 0 0 0 0.5rem;
  }

  input {
    font-size: 0.875rem;
  }
`;
