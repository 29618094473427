import { useState, useEffect } from 'react';

export const usePagination = (totalPages?: number) => {
  const [pagination, setPagination] = useState(0);

  useEffect(() => {
    if (totalPages) {
      setPagination(totalPages);
    }
  }, [pagination, totalPages]);

  return pagination;
};
