import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { AxiosError } from 'axios';

import { api } from '@services';
import { TimelineResponse } from '@common/types/api';
import { PromiseWithCancel } from '@common/types';
import { cancelToken } from '@services/api';
import { ONE_MINUTE } from '@constants';

export const fetchNewsfeed = (ctx: QueryFunctionContext<[string, number]>) => {
  const { pageParam = 1 } = ctx;

  const source = cancelToken();

  const promise = api
    .get<TimelineResponse>(
      `/timeline?limit=10&page=${pageParam}&include=user,likes.user`,
      {
        cancelToken: source.token,
      },
    )
    .then((res) => res.data) as PromiseWithCancel<TimelineResponse>;

  promise.cancel = () => source.cancel('useNewsfeedQuery Cancelled');

  return promise;
};

export const useNewsFeedQuery = (enabled: boolean) =>
  useInfiniteQuery<TimelineResponse, AxiosError>(['newsfeed'], fetchNewsfeed, {
    enabled,
    staleTime: ONE_MINUTE * 5,
    getNextPageParam: (lastPage) => {
      const { total_pages, current_page } = lastPage.meta.pagination;

      if (current_page === total_pages) {
        return undefined;
      }

      return current_page + 1;
    },
  });
