import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${() => css`
    margin: 4rem 0;

    > header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      a {
        font-weight: normal;
      }
    }
  `}
`;

export const PostPreviewList = styled.ul`
  ${() => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;

    ${down('lg')} {
      grid-template-columns: 1fr 1fr;

      li:last-child {
        display: none;
      }
    }

    ${down('md')} {
      display: flex;
      flex-direction: column;

      li:last-child {
        display: block;
      }
    }
  `}
`;
