import { ForwardedRef, forwardRef, PropsWithChildren, useState } from 'react';

import { HTMLMotionProps } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { AvatarInput, Button } from '@components';
import { useAuth } from '@hooks';
import { Nullable } from '@common/types';
import { UserProfile } from '@common/types/api';
import { api } from '@services';
import { DEFAULT_TRANSITION, DEFAULT_MODAL_ANIMATION } from '@constants';

import * as S from './styles';

type BaseCompleteProfileAvatarModalProps = HTMLMotionProps<'dialog'>;

type CompleteProfileAvatarModalProps =
  PropsWithChildren<BaseCompleteProfileAvatarModalProps>;

const CompleteProfileAvatarModal = (
  props: CompleteProfileAvatarModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const { user, updateProfileState } = useAuth();

  const [avatar, setAvatar] = useState<Nullable<string>>(
    user.avatar === 'undefined' ? null : user.avatar,
  );
  const { t } = useTranslation();

  const { children, ...attrs } = props;

  const handleNoAvatar = async () => {
    await api.post<UserProfile>(`/user/${user.id}/avatar`, {
      avatar: null,
    });

    return updateProfileState({ avatar: null });
  };

  const handleUpdateAvatar = () => updateProfileState({ avatar });

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      {...attrs}
    >
      {children}

      <h2>{t('modal.avatar.title')}</h2>
      <p>{t('modal.avatar.subTitle')}</p>

      <AvatarInput src={avatar} updateSrc={setAvatar} />

      <p>{t('modal.avatar.instruction')}</p>

      <S.ButtonsWrapper>
        <Button
          variant="outline"
          title={t('button.skip') || 'Pular'}
          onClick={handleNoAvatar}
        />
        <Button
          title={t('button.complete') || 'Completar'}
          onClick={handleUpdateAvatar}
          disabled={!avatar}
        />
      </S.ButtonsWrapper>
    </S.Container>
  );
};

export default forwardRef(CompleteProfileAvatarModal);
