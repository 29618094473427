import { ScheduleData } from '../../schedule';
import { SpeakerData } from '../../speakers';
import { TrueOrFalse } from '../../_common';

export interface RoomBroadcastResponse {
  data: RoomBroadcastData;
}

export interface RoomBroadcastData {
  id: number;
  group_id: number;
  group_key: string;
  sequence: number;
  name: string;
  description: string;
  slug: string;
  max_users: number;
  allow_users: number;
  image: string;
  thumb_image: string;
  timer: number;
  start_date: string;
  end_date: string;
  archives_total: number;
  status: number;
  created_at: string;
  updated_at: string;
  settings: RoomSettings;
  archives: RoomArchives;
  streamings: Streamings;
  participants: RoomSpeakers;
  schedules: RoomSchedule;
  callAction: CallToAction;
}

export interface RoomArchives {
  data: RoomArchiveData[];
}

export interface RoomArchiveData {
  id: number;
  room_id: number;
  description: string;
  url_download: string;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface RoomSettings {
  data: RoomSettingsData;
}

export interface RoomSettingsData extends RoomInteractions {
  id: number;
  room_id: number;
  limit_word_cloud: TrueOrFalse;
}

export interface RoomInteractions {
  chat: TrueOrFalse;
  quiz: TrueOrFalse;
  poll: TrueOrFalse;
  asking: TrueOrFalse;
  call_action: TrueOrFalse;
  emoji: TrueOrFalse;
  word_cloud: TrueOrFalse;
}

export interface Streamings {
  data: StreamingData[];
}

export interface StreamingData {
  id: number;
  room_id: number;
  streaming_lang: string;
  streaming_main: TrueOrFalse;
  streaming_method: StreamingOrigin;
  meeting_id: string;
  meeting_host_user_id: number;
  meeting_api_key: string;
  meeting_pass: string;
  url_video: string;
  status: TrueOrFalse;
  archived: TrueOrFalse;
}

export enum StreamingOrigin {
  VIMEO = 1,
  YOUTUBE = 2,
  IFRAME = 2,
  ZOOM = 4,
}

interface RoomSpeakers {
  data: SpeakerData[];
}

interface RoomSchedule {
  data: ScheduleData[];
}

interface CallToAction {
  data: CallToActionData;
}

interface CallToActionData {
  message: string;
  url_action: string;
  cta: string;
}
