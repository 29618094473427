import styled, { css, DefaultTheme } from 'styled-components';

import { TransientProps } from '@common/types';

import { BaseButtonProps } from './types';

type ButtonContainerProps = TransientProps<
  Required<Pick<BaseButtonProps, 'variant' | 'size' | 'fullWidth' | 'pill'>>
>;

const modifiers = ({ colors }: DefaultTheme) => ({
  variants: {
    primary: css`
      color: ${colors.brand.primary.text};
      background: ${colors.brand.primary.default};

      :not(:disabled) {
        :hover {
          background: ${colors.brand.primary.hover};
        }

        :active {
          background: ${colors.brand.primary.active};
        }
      }
    `,
    secondary: css`
      background: ${colors.brand.secondary.default};
      background: ${colors.brand.secondary.text};

      :not(:disabled) {
        :hover {
          background: ${colors.brand.secondary.hover};
        }

        :active {
          background: ${colors.brand.secondary.active};
        }
      }
    `,
    outline: css`
      background: none;
      color: ${colors.brand.primary.default};
      border: 2px solid ${colors.brand.primary.default};

      :not(:disabled) {
        :hover {
          background: ${colors.brand.primary.default};
          color: ${colors.brand.primary.text};
        }
      }

      :disabled {
        background: none;
        color: ${colors.background[40]};
        border-color: ${colors.background[40]};
      }
    `,
    danger: css`
      background: ${colors.error[100]};
    `,
    transparent: css`
      background: none;
      color: ${colors.foreground[100]};
    `,
  },
  sizes: {
    default: css`
      padding: 1rem 1.5rem;
      font-size: 1rem;
      border-radius: 0.5rem;
    `,
    small: css`
      padding: 0.75rem 1.25rem;
      font-size: 1rem;
      border-radius: 0.5rem;
    `,
    large: css`
      padding: 1.25rem;
      font-size: 1.125rem;
      border-radius: 0.75rem;
    `,
    even: css`
      padding: 1rem;
      font-size: 1rem;
      border-radius: 0.5rem;
    `,
  },
});

export const Container = styled.button<ButtonContainerProps>`
  ${({ theme, $variant, $size, $fullWidth, $pill }) => {
    const { variants, sizes } = modifiers(theme);

    return css`
      color: ${theme.colors.base.text};
      width: ${$fullWidth ? '100%' : 'auto'};
      transition: ${theme.transition.default};

      > svg + span {
        margin-left: 0.75rem;
      }

      :disabled {
        background: ${theme.colors.background[40]};
      }

      ${variants[$variant]};
      ${sizes[$size]};

      ${$pill &&
      css`
        border-radius: 1000px;
      `};
    `;
  }}
`;
