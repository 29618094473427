import styled, { css } from 'styled-components';

import { TransientProps } from '@common/types';

type HasCurrentUserProps = TransientProps<{
  currentUser: boolean;
}>;

type ContainerProps = HasCurrentUserProps &
  TransientProps<{
    removedMessage: boolean;
  }>;

export const Container = styled.div<ContainerProps>`
  ${({
    theme,
    $removedMessage: isRemovedMessage,
    $currentUser: isCurrentUser,
  }) => css`
    display: flex;
    align-items: flex-start;
    opacity: ${isRemovedMessage ? 0.6 : 1};
    padding: 0.75rem 0;
    background: ${theme.colors.background[100]};
    position: relative;
    z-index: 0;

    > img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.5rem;
    }

    ${isCurrentUser &&
    css`
      flex-direction: row-reverse;

      > img {
        margin: 0 0 0 1rem;
      }
    `};
  `}
`;

export const MessageContent = styled.div<HasCurrentUserProps>`
  flex: 1;
  display: flex;
  flex-direction: column;

  > p {
    font-size: 0.75rem;
  }

  ${({ $currentUser: isCurrentUser }) =>
    isCurrentUser &&
    css`
      > p,
      div {
        margin-left: auto;
        text-align: right;
      }
    `};
`;

export const MessageInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
  width: 100%;
  position: relative;
`;

export const MessageHeader = styled.div<HasCurrentUserProps>`
  ${({ theme, $currentUser: isCurrentUser }) => css`
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      flex: 1;
      flex-direction: ${isCurrentUser ? 'row-reverse' : 'row'};
      align-items: center;
      gap: 1.5rem;

      > h4 {
        display: flex;
        flex-direction: ${isCurrentUser ? 'row-reverse' : 'row'};
        align-items: center;
        font-size: 0.875rem;
        gap: 0.75rem;

        > time {
          color: ${theme.colors.foreground[60]};
          display: inline-block;
          align-self: flex-end;
          font-size: 0.625rem;
        }
      }

      > span {
        display: block;
        font-size: 0.5125rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        background: ${theme.colors.brand.secondary.default};
        color: ${theme.colors.base.text};
        padding: 0.125rem 0.625rem;
        border-radius: 1000px;
      }
    }
  `}
`;

export const ModeratorOptionsContainer = styled.div<HasCurrentUserProps>`
  ${({ $currentUser: isCurrentUser }) => css`
    position: absolute;
    top: -0.875rem;
    opacity: 0.15;
    transition: 180ms ease-in-out;

    :hover {
      opacity: 1;
    }

    .tippy-content {
      padding: 0;
    }

    ${isCurrentUser
      ? css`
          left: -1.5rem;
        `
      : css`
          right: -1.5rem;
        `}
  `}
`;

export const ModeratorOptionsButton = styled.button`
  ${({ theme }) => css`
    background: none;
    font-size: 0.75rem;
    white-space: nowrap;
    width: 100%;
    border-radius: 0;
    justify-content: flex-start;
    transition: 180ms ease-in-out;

    :hover {
      background: ${theme.colors.brand.primary.default};
      color: ${theme.colors.base.text};
    }

    :first-child {
      border-radius: 0.25rem 0.25rem 0 0;
    }
    :last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }
  `}
`;
