/* eslint-disable @typescript-eslint/no-explicit-any */
import { ForwardedRef, forwardRef, useState } from 'react';

import ReactCrop, { Crop } from 'react-image-crop';
import toast from 'react-hot-toast';

import { DEFAULT_TRANSITION, DEFAULT_MODAL_ANIMATION } from '@constants';
// eslint-disable-next-line import/no-cycle
import { Button } from '@components';

import * as S from './styles';

type ImageState = {
  src: string;
  filename: string;
};

type CropModalProps = {
  image: ImageState;
  handleClose: () => void;
  handleChange: (e: any) => void;
};

const CropModal = (
  { image, handleChange, handleClose }: CropModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 60,
    height: 60,
    x: 25,
    y: 25,
  });

  const [imageRef, setImageRef] = useState<HTMLImageElement>();

  async function cropImage(cropConfig: Crop) {
    if (imageRef && cropConfig.width && cropConfig.height) {
      const cropped = await getCroppedImage(imageRef, cropConfig);

      handleChange(cropped);
    }
  }

  function getCroppedImage(sourceImage: HTMLImageElement, cropConfig: Crop) {
    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return toast.error(
        'Não foi possível recortar sua foto de perfil. Por favor, tente novamente.',
      );
    }

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        // eslint-disable-next-line no-param-reassign
        // blob.name = 'fileName';

        resolve(blob);
      }, 'image/jpeg');
    }).catch(() =>
      toast.error(
        'Não foi possível recortar sua foto de perfil. Por favor, tente novamente.',
      ),
    );
  }

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
    >
      <div className="header">
        <h2>Ajustar Foto</h2>
      </div>

      <S.Content>
        <div className="reactCrop">
          <div>
            <ReactCrop
              src={image.src}
              crop={crop}
              onChange={(newCrop: Crop) => setCrop(newCrop)}
              onImageLoaded={(value: HTMLImageElement) => setImageRef(value)}
              locked
            />
          </div>
        </div>

        <div className="btnsContent">
          <Button
            title="Cancelar"
            variant="outline"
            onClick={() => handleClose()}
          />

          <Button title="Recortar" onClick={() => cropImage(crop)} />
        </div>
      </S.Content>
    </S.Container>
  );
};

export default forwardRef(CropModal);
