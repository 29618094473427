import { Route, Redirect, useHistory } from 'react-router-dom';

import { useAuth } from '@hooks';
import { AuthLayout, PrivateLayout } from '@pages/_layouts';
import { isValidObject } from '@utils';
import { HistoryState } from '@services/history';

type RouteProps = import('react-router-dom').RouteProps & {
  private?: boolean;
  component: import('react').ComponentType;
};

const AppRoute = (props: RouteProps) => {
  const { user } = useAuth();

  const history = useHistory<HistoryState>();
  const referral = new URLSearchParams(history.location.search).get('referral');
  const currentPath = history.location.pathname;

  const { private: isPrivate = false, component: Component, ...rest } = props;

  const isAuthenticated = isValidObject(user);

  const Layout = isPrivate ? PrivateLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (isPrivate === !isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : '/lobby',
                state: { from: routeProps.location.pathname },
              }}
            />
          );
        }

        if (
          !isAuthenticated &&
          currentPath !== '/sign-up' &&
          referral !== null
        ) {
          return (
            <Redirect
              to={{ pathname: '/sign-up', search: `?referral=${referral}` }}
            />
          );
        }

        return (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        );
      }}
    />
  );
};

AppRoute.defaultProps = {
  private: false,
};

export default AppRoute;
