import axios from 'axios';

import { BASE_URL } from '@constants';

export const cancelToken = axios.CancelToken.source;

export const api = axios.create({
  baseURL: BASE_URL,
});

export const chatApi = axios.create({
  baseURL: process.env.REACT_APP_CHAT_BASE_URL,
});
