import {
  ChangeEventHandler,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import { Trans } from 'react-i18next';
import { FieldError, useFormContext } from 'react-hook-form';
import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import Button from '@components/Button';
import { MaskTypes } from '@utils/mask-value/constants';
import { maskValue } from '@utils';

import * as S from './styles';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  mask?: MaskTypes;
  icon:
    | import('react').ElementType<import('react-icons').IconBaseProps>
    | JSX.Element;
  buttonIcon?:
    | import('react').ElementType<import('react-icons').IconBaseProps>
    | JSX.Element;
};

const Input = (props: InputProps) => {
  const { colors } = useTheme();
  const { register, formState, watch } = useFormContext();

  const [isFilled, setIsFilled] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    name,
    id,
    icon: Icon,
    type = 'text',
    required,
    disabled,
    className,
    buttonIcon: ButtonIcon,
    mask,
    ...attrs
  } = props;
  delete attrs.value;

  const observedInput = watch(name, '');
  const inputError = formState.errors[name] as FieldError;

  const PasswordIcon = isPasswordVisible ? FiEyeOff : FiEye;

  const { onChange, ...registerRef } = register(name, {
    required,
  });

  const handleMask: ChangeEventHandler<HTMLInputElement> = (e) => {
    const event = e;

    if (mask) {
      event.target.value = maskValue(e.target.value, mask);
    }

    onChange(event);
  };

  const handlePasswordVisibility = () =>
    setIsPasswordVisible((prevState) => !prevState);

  useEffect(() => {
    setIsFilled(!!observedInput);
  }, [observedInput]);

  return (
    <S.Container
      htmlFor={id || name}
      $error={!!inputError}
      $filled={isFilled}
      $disabled={disabled}
      className={className}
      {...attrs.tabIndex}
    >
      {Icon && typeof Icon === 'function' ? <Icon size="1.25rem" /> : Icon}

      <input
        id={id || name}
        type={isPasswordVisible ? 'text' : type}
        disabled={disabled}
        readOnly={disabled}
        {...attrs}
        {...registerRef}
        onChange={handleMask}
      />

      {type === 'password' && (
        <S.PasswordButton
          size="even"
          variant="transparent"
          onClick={handlePasswordVisibility}
          icon={<PasswordIcon size="1.25rem" />}
        />
      )}

      {inputError && (
        <S.ErrorWrapper>
          <FiAlertCircle color={colors.error[100]} size="0.875rem" />
          <Trans>{inputError.message}</Trans>
        </S.ErrorWrapper>
      )}

      {ButtonIcon && (
        <Button
          type="submit"
          size="even"
          icon={ButtonIcon}
          variant="transparent"
          disabled={disabled}
        />
      )}
    </S.Container>
  );
};

Input.defaultProps = {
  type: 'text',
  value: '',
  placeholder: '',
  required: false,
  onChange: () => null,
} as Partial<InputProps>;

export default Input;
