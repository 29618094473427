import Cookie from 'js-cookie';

import { STORAGE_KEY } from '@constants';

import { DEFAULT_COOKIE_OPTIONS } from './constants';

class PowerfulLocalStorage {
  storage: Storage;

  cookies: typeof Cookie;

  constructor(private storageKey: string) {
    this.storage = localStorage;
    this.cookies = Cookie;
  }

  composeStorageKey(key: string) {
    return `${this.storageKey}:${key}`;
  }

  getItem<T extends unknown = string>(key: string) {
    const storedValue = this.storage.getItem(this.composeStorageKey(key));

    return storedValue ? (JSON.parse(storedValue) as T) : undefined;
  }

  setItem(key: string, value: unknown) {
    this.storage.setItem(this.composeStorageKey(key), JSON.stringify(value));
  }

  setItems(...items: Array<[key: string, value: unknown]>) {
    items.forEach(([key, value]) =>
      this.storage.setItem(this.composeStorageKey(key), JSON.stringify(value)),
    );
  }

  removeItem(key: string) {
    this.storage.removeItem(this.composeStorageKey(key));
  }

  removeItems(keys: string[]) {
    keys.forEach((key) => this.storage.removeItem(this.composeStorageKey(key)));
  }

  setCookie(key: string, value: unknown, options = DEFAULT_COOKIE_OPTIONS) {
    this.cookies.set(
      this.composeStorageKey(key),
      JSON.stringify(value),
      options,
    );
  }

  getCookie<T extends unknown = string>(key: string) {
    const storedValue = this.cookies.get(this.composeStorageKey(key));

    return storedValue ? (JSON.parse(storedValue) as T) : undefined;
  }

  removeCookie(key: string) {
    this.cookies.remove(this.composeStorageKey(key));
  }

  clearStorage() {
    this.storage.clear();
  }
}

export const eventfyStorage = new PowerfulLocalStorage(STORAGE_KEY);
