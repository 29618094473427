/* eslint-disable no-console */
import { useEffect } from 'react';

import useLibHotjar from 'react-use-hotjar';

import { useEventfy } from '@hooks/useEventfy';
import { selectHotjarTag } from '@hooks/useEventfy/selectors';

export const useHotjar = () => {
  const hotjarTag = useEventfy(selectHotjarTag);
  const { initHotjar } = useLibHotjar();

  useEffect(() => {
    if (hotjarTag) {
      initHotjar(+hotjarTag, 6, false, console.info);
    }
  }, [initHotjar, hotjarTag]);
};
