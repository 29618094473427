import {
  DispatchWithoutAction,
  ForwardedRef,
  forwardRef,
  useMemo,
} from 'react';

import { HTMLMotionProps, Transition, Variants } from 'framer-motion';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';

import { useEventfy, useIsCertificateEnabled, useRoomsQuery } from '@hooks';
import { HistoryState } from '@services/history';
import { Button } from '@components';

import * as S from './styles';
import { NAVIGATION_MENU } from '../constants';

const variants: Variants = {
  hidden: {
    opacity: 0,
    x: '-100%',
  },
  shown: {
    opacity: 1,
    x: 0,
  },
};

const transition: Transition = {
  type: 'spring',
  duration: 0.5,
};

type MobileMenuProps = HTMLMotionProps<'dialog'> & {
  handleClose: DispatchWithoutAction;
  handleSurvey: DispatchWithoutAction;
  handleCertificate: DispatchWithoutAction;
};

const MobileMenu = (
  props: MobileMenuProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const event = useEventfy();
  const location = useLocation<HistoryState>();
  const { t } = useTranslation();

  const isCertificateEnabled = useIsCertificateEnabled();
  const isNewsFeedEnabled = !!event.timeline;

  const roomsQuery = useRoomsQuery();

  const { handleClose, handleSurvey, handleCertificate, ...attrs } = props;

  const navigation = useMemo(() => NAVIGATION_MENU(event), [event]);

  const handleSurveyClick = () => {
    handleClose();
    handleSurvey();
  };

  const handleCertificateClick = () => {
    handleClose();
    handleCertificate();
  };

  return (
    <S.Container
      open
      ref={ref}
      variants={variants}
      transition={transition}
      initial="hidden"
      animate="shown"
      exit="hidden"
      {...attrs}
    >
      <Button
        size="even"
        variant="transparent"
        icon={<FiX size="1.625rem" />}
        onClick={handleClose}
      />

      {Object.entries(navigation).map(
        ([title, { path, icon: Icon, enabled }]) => {
          if (!enabled) {
            return null;
          }
          const iTitle = t(title);

          if (path === '/satisfaction-survey') {
            return (
              <S.NavButton key={iTitle} as="button" onClick={handleSurveyClick}>
                <Icon size="1.5rem" />
                {iTitle}
              </S.NavButton>
            );
          }

          if (path === '/certificate') {
            if (!isCertificateEnabled) {
              return null;
            }

            return (
              <S.NavButton
                key={iTitle}
                as="button"
                onClick={handleCertificateClick}
              >
                <Icon size="1.5rem" />
                {iTitle}
              </S.NavButton>
            );
          }

          if (path === '/newsfeed') {
            if (!isNewsFeedEnabled) {
              return null;
            }

            return (
              <S.NavLink
                key={iTitle}
                to={path}
                $active={location.pathname.includes(path)}
                onClick={handleClose}
              >
                <Icon size="1.5rem" />
                {iTitle}
              </S.NavLink>
            );
          }

          let verifiedPath = path;

          const isRoomsPath = path === '/rooms';
          const hasOnlyOneEventRoom = event.rooms === 1;
          const hasLoadedRooms = !!roomsQuery.data?.rooms.length;

          if (isRoomsPath && hasOnlyOneEventRoom && hasLoadedRooms) {
            verifiedPath = `/broadcast/${roomsQuery.data?.rooms[0].slug}`;
          }

          return (
            <S.NavLink
              key={iTitle}
              to={verifiedPath}
              $active={location.pathname.includes(verifiedPath)}
              onClick={handleClose}
            >
              <Icon size="1.5rem" />
              {iTitle}
            </S.NavLink>
          );
        },
      )}
    </S.Container>
  );
};

export default forwardRef(MobileMenu);
