import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useAuth } from '@hooks';
import {
  ReceivedChatMessage,
  RemoveMessageEvent,
} from '@common/types/chat-socket';
import { chatApi } from '@services';

import { useBroadcastQuery } from '../useBroadcastQuery';
import { useWebSocket } from '../useWebSocket';

export const useChatMessages = () => {
  const { signChat } = useAuth();
  const socket = useWebSocket();
  const { t } = useTranslation();

  const { data: room } = useBroadcastQuery({
    select: ({ id, group_id, group_key, settings }) => ({
      id,
      groupId: group_id,
      groupKey: group_key,
      isChatEnabled: !!settings.data.chat,
    }),
    notifyOnChangeProps: ['data'],
  });

  const [messages, setMessages] = useState<ReceivedChatMessage[]>([]);

  useEffect(() => {
    (async () => {
      if (!room?.groupId) {
        return;
      }

      try {
        await signChat();
        const response = await chatApi.get(`/messages/${room?.groupId}`);

        setMessages(response.data.data.reverse());
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    })();
  }, [room?.groupId, signChat]);

  useEffect(() => {
    const listenToMessagesEvent = `${room?.groupKey}.message.${room?.groupId}`;
    const listenToRemoveMessagesEvent = `${room?.groupKey}.remove.message.${room?.groupId}`;

    const handleNewMessage = (data: ReceivedChatMessage) => {
      setMessages((prevState) => prevState.concat(data));
    };

    const handleRemovedMessage = (data: RemoveMessageEvent) => {
      const { status, message_id } = data;

      if (+status === 200) {
        setMessages((prevState) =>
          prevState.map((message) =>
            message.id !== message_id
              ? message
              : {
                  ...message,
                  content: t('pages.broadcast.chat.messageRemoved'),
                },
          ),
        );
      }
    };

    if (room?.isChatEnabled) {
      socket?.on(listenToMessagesEvent, handleNewMessage);
      socket?.on(listenToRemoveMessagesEvent, handleRemovedMessage);
    } else {
      socket?.off(listenToMessagesEvent, handleNewMessage);
      socket?.off(listenToRemoveMessagesEvent, handleRemovedMessage);
    }

    return () => {
      socket?.off(listenToMessagesEvent, handleNewMessage);
      socket?.off(listenToRemoveMessagesEvent, handleRemovedMessage);
    };
  }, [socket, room?.isChatEnabled, room?.groupKey, room?.groupId, t]);

  return useMemo(
    () => ({
      messages,
      groupId: room?.groupId,
      groupKey: room?.groupKey,
      socket,
    }),
    [messages, socket, room?.groupId, room?.groupKey],
  );
};
