import styled from 'styled-components';

import { PerfectScrollbar } from '@components';

export const Container = styled(PerfectScrollbar)`
  > li {
    max-width: 100%;

    + li {
      margin-top: 1rem;
    }
  }
`;
