import { HtmlHTMLAttributes } from 'react';

import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

import { RoomDataWithSpeakers } from '@common/types/api';
import { formatRoomDisplayData } from '@utils';
import { ComponentPropsWithLoading } from '@common/types';
import { SpeakersBubbles } from '@components';

import * as S from './styles';

type RoomCardProps = ComponentPropsWithLoading<RoomDataWithSpeakers> &
  HtmlHTMLAttributes<HTMLLIElement>;

const RoomCard = (props: RoomCardProps) => {
  const { data, loading: isLoading = false, ...attrs } = props;
  const { t } = useTranslation();

  if (!data) {
    return (
      <S.Container $active={false} $loading={isLoading} {...attrs}>
        <S.RoomCardLink as="div">
          <S.RoomCardThumbnailSkeleton />

          <S.RoomCardBottomWrapper>
            <S.RoomCardDataWrapper>
              <S.RoomCardDate />

              <S.RoomCardData $loading={isLoading}>
                <Skeleton width="80%" />

                <SpeakersBubbles loading innerKey={Date.now()} />
              </S.RoomCardData>
            </S.RoomCardDataWrapper>

            <S.RoomCardCTA $active={false} as={Skeleton} height="2rem" />
          </S.RoomCardBottomWrapper>
        </S.RoomCardLink>
      </S.Container>
    );
  }

  const { day, month } = formatRoomDisplayData(data);
  const callToActionText = data.status
    ? t('pages.rooms.enter')
    : 'Indisponível';

  return (
    <S.Container $active={!!data.status} $loading={isLoading} {...attrs}>
      <S.RoomCardLink to={`/broadcast/${data.slug}`}>
        <img src={data.thumb_image} alt={data.name} />

        <S.RoomCardBottomWrapper>
          <S.RoomCardDataWrapper>
            <S.RoomCardDate>
              <strong>{day}</strong>
              <span>{month}</span>
            </S.RoomCardDate>

            <S.RoomCardData $loading={isLoading}>
              <strong>{data.name}</strong>

              <SpeakersBubbles
                data={data.participants.data}
                innerKey={data.id}
              />
            </S.RoomCardData>
          </S.RoomCardDataWrapper>

          <S.RoomCardCTA $active={!!data.status}>
            {callToActionText}
          </S.RoomCardCTA>
        </S.RoomCardBottomWrapper>
      </S.RoomCardLink>
    </S.Container>
  );
};

export default RoomCard;
