import { FiCloud, FiMessageSquare } from 'react-icons/fi';
import { FaQuestion } from 'react-icons/fa';
import { CgDice3, CgPoll } from 'react-icons/cg';

import { Chat, Asking, WordCloud, Quiz, Poll } from '../tabs';

export const TABS = [
  {
    id: 'chat' as const,
    title: 'pages.broadcast.tabs.chat',
    icon: FiMessageSquare,
    content: Chat,
  },
  {
    id: 'asking' as const,
    title: 'pages.broadcast.tabs.askings',
    icon: FaQuestion,
    content: Asking,
  },
  {
    id: 'quiz' as const,
    title: 'pages.broadcast.tabs.quiz',
    icon: CgDice3,
    content: Quiz,
  },
  {
    id: 'poll' as const,
    title: 'pages.broadcast.tabs.poll',
    icon: CgPoll,
    content: Poll,
  },
  {
    id: 'word_cloud' as const,
    title: 'pages.broadcast.tabs.cloud',
    icon: FiCloud,
    content: WordCloud,
  },
];
