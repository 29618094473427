import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.dialog)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    margin: auto;
    width: 100%;
    max-width: 25rem;
    max-height: 96%;
    border-radius: 1rem;
    position: relative;
    background: ${theme.colors.background[100]};
    color: ${theme.colors.foreground[100]};
    box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0px 6.7px 5.3px rgba(0, 0, 0, 0.028), 0px 12.5px 10px rgba(0, 0, 0, 0.035),
      0px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0px 41.8px 33.4px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.07);
    > div {
      position: absolute;
      top: 1rem;
      right: 1rem;
      > button {
        font-size: 1.125rem;
        color: #616e84;
        background: none;
        padding: 0;
      }
    }
    p {
      margin: 1rem;
      text-align: center;
    }
    strong {
      margin: 1rem;
      text-align: center;
    }
  `}
`;
