import { memo } from 'react';

import getVideoId from 'get-video-id';

import { useEventfy, useVideosQuery } from '@hooks';
import { selectHasVideos } from '@hooks/useEventfy/selectors';

import * as S from './styles';

const VideosSidebar = () => {
  const eventHasVideos = useEventfy(selectHasVideos);
  const { isLoading, data } = useVideosQuery();

  if (!eventHasVideos || isLoading || !data?.videos.length) {
    return null;
  }

  return (
    <S.Container>
      {data?.videos && data?.videos.length > 1 && (
        <S.VideoList component="ul">
          {data.videos.map((video) => {
            const { id, service } = getVideoId(video.url_video);

            const thumbnail =
              service === 'youtube'
                ? `https://i.ytimg.com/vi/${id}/hqdefault.jpg`
                : video.url_image;

            return (
              <li key={`${video.url_video}-${video.title}`}>
                <S.SelectVideoButton type="button" to={`/videos/${video.id}`}>
                  <img src={thumbnail} alt={video.title} />
                  <section>
                    <h3>{video.title}</h3>
                  </section>
                </S.SelectVideoButton>
              </li>
            );
          })}
        </S.VideoList>
      )}
    </S.Container>
  );
};

export default memo(VideosSidebar);
