import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const Container = styled.div`
  ${() => css`
    display: flex;
    align-items: flex-start;

    > img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.75rem;
    }

    & + & {
      margin-top: 0.5rem;
    }
  `}
`;

export const AutosizeInput = styled(TextareaAutosize)`
  ${({ theme }) => css`
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.75rem;
    min-height: 2.5rem;
    resize: none;
    background: ${theme.colors.background[80]};

    ::placeholder {
      font-weight: 300;
      color: ${theme.colors.background[40]};
    }
  `}
`;
