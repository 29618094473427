import { HtmlHTMLAttributes } from 'react';

import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import * as S from './styles';

export type PaginationProps = Pick<
  ReactPaginateProps,
  'forcePage' | 'pageCount' | 'onPageChange'
> &
  HtmlHTMLAttributes<HTMLDivElement>;

const Pagination = ({
  forcePage,
  pageCount,
  onPageChange,
  ...attrs
}: PaginationProps) => {
  if (pageCount <= 1) {
    return null;
  }

  return (
    <S.Container {...attrs}>
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        forcePage={forcePage}
        pageCount={pageCount}
        activeClassName="active"
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={onPageChange}
      />
    </S.Container>
  );
};

export default Pagination;
