import React, { useCallback, useEffect, useState } from 'react';

import { AnimatePresence } from 'framer-motion';

import { PropsWithRequiredChildren } from '@common/types';
import {
  useAuth,
  useEventfy,
  useFloatingPlayer,
  useNotifyUserIsOnlineEffect,
  useSurveyChannel,
  useTimelineChannel,
  useToggle,
} from '@hooks';
import { FloatingPlayer, Modal, TawkTo, WhatsAppButton } from '@components';
import { selectIsSatisfactionSurveyEnabled } from '@hooks/useEventfy/selectors';
import { selectUser } from '@hooks/useAuth/selectors';
import { IntegrationLocation } from '@common/types/api';
import { selectIsFloatingPlayerVisible } from '@hooks/useFloatingPlayer/selectors';

import Navigation from './Navigation';
import * as S from './styles';
import SurveyModal from './SurveyModal';
import SurveyNotification from './SurveyNotification';
import GlobalModals from './GlobalModals';
import CertificateModal from './CertificateModal';

const PrivateLayout = ({ children }: PropsWithRequiredChildren) => {
  const user = useAuth(selectUser);
  const isSurveyEnabled = useEventfy(selectIsSatisfactionSurveyEnabled);
  const isFloatingPlayerVisible = useFloatingPlayer(
    selectIsFloatingPlayerVisible,
  );

  const [isSurveyModalVisible, toggleIsSurveyModalVisible] = useToggle(false);
  const [isCertificateModalVisible, toggleIsCertificateModalVisible] =
    useToggle(false);
  const [isSurveyNoticeVisible, setIsSurveyNoticeVisible] = useState(
    !user.survey_replied && isSurveyEnabled,
  );

  const shouldDisplaySurveyModal = isSurveyEnabled && isSurveyModalVisible;

  const closeSurveyNotice = useCallback(
    () => setIsSurveyNoticeVisible(false),
    [],
  );

  useSurveyChannel();
  useTimelineChannel();

  useNotifyUserIsOnlineEffect();

  useEffect(() => {
    if (isSurveyModalVisible && !isSurveyEnabled) {
      toggleIsSurveyModalVisible();
    }
  }, [isSurveyModalVisible, isSurveyEnabled, toggleIsSurveyModalVisible]);

  useEffect(() => {
    setIsSurveyNoticeVisible(isSurveyEnabled);
  }, [isSurveyEnabled]);

  useEffect(() => {
    if (isSurveyNoticeVisible && !!user.survey_replied) {
      closeSurveyNotice();
    }
  }, [isSurveyNoticeVisible, user.survey_replied, closeSurveyNotice]);

  return (
    <>
      <S.Container>
        <S.Wrapper>
          <Navigation
            handleSurvey={toggleIsSurveyModalVisible}
            handleCertificate={toggleIsCertificateModalVisible}
          />

          <AnimatePresence>
            {isSurveyNoticeVisible && (
              <SurveyNotification
                close={closeSurveyNotice}
                handleSurvey={toggleIsSurveyModalVisible}
              />
            )}
          </AnimatePresence>

          {children}
        </S.Wrapper>

        <TawkTo location={IntegrationLocation.AUTH_LAYOUT} />
        <WhatsAppButton location={IntegrationLocation.AUTH_LAYOUT} />
      </S.Container>

      <AnimatePresence>
        {shouldDisplaySurveyModal && (
          <Modal handleClose={toggleIsSurveyModalVisible}>
            <SurveyModal
              handleClose={toggleIsSurveyModalVisible}
              handleCertificate={toggleIsSurveyModalVisible}
            />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isCertificateModalVisible && (
          <Modal handleClose={toggleIsCertificateModalVisible}>
            <CertificateModal />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isFloatingPlayerVisible && <FloatingPlayer />}
      </AnimatePresence>

      <GlobalModals />
    </>
  );
};

export default PrivateLayout;
