/* eslint-disable react/no-danger */
import { memo } from 'react';

import { useBroadcastQuery } from '@hooks';

import * as S from './styles';

const Description = () => {
  const { data: roomDescription } = useBroadcastQuery({
    select: ({ description }) => description,
    notifyOnChangeProps: 'tracked',
  });

  return (
    <S.Container>
      <div
        dangerouslySetInnerHTML={{
          __html: roomDescription || '',
        }}
      />
    </S.Container>
  );
};

export default memo(Description);
