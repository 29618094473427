import { useRef, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence, Transition, Variants } from 'framer-motion';

import { useToggleOutsideClick } from '@hooks';
import { FlagUS, FlagBR } from '@assets';

import * as S from './styles';

const variants: Variants = {
  hidden: {
    opacity: 0,
    y: -25,
  },
  shown: {
    opacity: 1,
    y: 0,
  },
};

const transition: Transition = {
  type: 'spring',
  duration: 0.5,
};

const DropdownLang = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('br');

  const [isDropdownVisible, toggleDropdownVisibility] = useToggleOutsideClick(
    dropdownRef,
    false,
    { handleInsideClick: true },
  );

  const changeLanguage = (lang: string) => {
    setSelectedLang(lang);
    i18n.changeLanguage(lang);
  };

  const handleProfileClick = isDropdownVisible
    ? undefined
    : toggleDropdownVisibility;

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')) {
      const lng = localStorage.getItem('i18nextLng') || 'br';
      setSelectedLang(lng);
    }
  }, [setSelectedLang]);

  return (
    <S.Container ref={dropdownRef} $open={isDropdownVisible}>
      <button type="button" onClick={handleProfileClick}>
        <p>{t('langs.title')} </p>
        <S.IconFlag>
          {selectedLang === 'en' ? <FlagUS /> : <FlagBR />}
        </S.IconFlag>
      </button>

      <AnimatePresence>
        {isDropdownVisible && (
          <S.Dropdown
            variants={variants}
            transition={transition}
            initial="hidden"
            animate="shown"
            exit="hidden"
          >
            <li>
              <button type="button" onClick={() => changeLanguage('en')}>
                <S.IconFlag>
                  <FlagUS /> {t('langs.english')}
                </S.IconFlag>
              </button>
            </li>
            <li>
              <button type="button" onClick={() => changeLanguage('br')}>
                <S.IconFlag>
                  <FlagBR /> {t('langs.portuguese')}
                </S.IconFlag>
              </button>
            </li>
          </S.Dropdown>
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default DropdownLang;
