import { memo, useCallback, useEffect, useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useTheme } from 'styled-components';
import { GooSpinner } from 'react-spinners-kit';
import { AxiosError } from 'axios';
import { FiCheckCircle } from 'react-icons/fi';
import { CgDice3 } from 'react-icons/cg';

import { useBroadcastQuery, useQuizQuery } from '@hooks';
import { api } from '@services';

import * as S from './styles';
import { BaseInteractionProps } from '../../types';

type MutationFnVariables = Record<'quizId' | 'answerId', number>;

const Quiz = ({ active: isActive }: BaseInteractionProps) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: room } = useBroadcastQuery({
    select: ({ id, settings }) => ({
      id,
      isQuizEnabled: !!settings.data.quiz,
    }),
    notifyOnChangeProps: ['data'],
  });

  const quizQuery = useQuizQuery(room?.id);
  const refetchQuiz = quizQuery.refetch;

  const [currentAnswerId, setCurrentAnswerId] = useState<number | null>(null);

  const { mutateAsync, isLoading: isMutating } = useMutation(
    ({ quizId, answerId }: MutationFnVariables) =>
      api.post(`/rooms/${room?.id}/quiz`, {
        quiz_id: quizId,
        answer: answerId,
      }),
    {
      onMutate: ({ answerId }: MutationFnVariables) =>
        setCurrentAnswerId(answerId),
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (err) => {
        toast.error(
          (err as AxiosError).response?.data.message ||
            t('pages.broadcast.fail'),
        );
      },
      onSettled: async () => {
        setTimeout(() => setCurrentAnswerId(null), 1500);

        await queryClient.invalidateQueries(['quiz', room?.id]);
      },
    },
  );

  const hasNoQuizzes =
    !quizQuery.data?.quizzes.length && !quizQuery.data?.totalAnsweredQuizzes;

  const hasAllQuizzesBeenAnswered =
    !!quizQuery.data?.totalAnsweredQuizzes && !quizQuery.data?.quizzes.length;

  const handleQuizAnswer = useCallback(
    (quizId, answerId) => async () => {
      await mutateAsync({ quizId, answerId });
    },
    [mutateAsync],
  );

  useEffect(() => {
    isActive && refetchQuiz();
  }, [isActive, refetchQuiz]);

  if (hasNoQuizzes) {
    return (
      <S.Container $hide={!room?.isQuizEnabled} $disabled={!isActive}>
        <S.MessageContainer>
          <CgDice3 size="3rem" />
          <p>{t('pages.broadcast.quiz.noQuiz')}</p>
        </S.MessageContainer>
      </S.Container>
    );
  }

  return (
    <S.Container $hide={!room?.isQuizEnabled} $disabled={!isActive}>
      {hasAllQuizzesBeenAnswered ? (
        <S.MessageContainer $success>
          <FiCheckCircle size="3rem" />
          <p>{t('pages.broadcast.quiz.answeredAll')}</p>
        </S.MessageContainer>
      ) : (
        <>
          {quizQuery.data?.quizzes.slice(0, 1).map((quiz) => (
            <S.QuizWrapper key={quiz.id}>
              <h3>
                {quiz.asking}

                {isMutating && (
                  <GooSpinner
                    size={1.5}
                    sizeUnit="rem"
                    color={theme.colors.brand.primary.default}
                  />
                )}
              </h3>

              <S.QuizAnswersContainer>
                {quiz.answers.data.map(({ id, answer, correct }, index) => {
                  const displayCorrectAnswer =
                    !!quiz.show_answer &&
                    (currentAnswerId === id ||
                      (!!correct && !!currentAnswerId));

                  return (
                    <S.QuizAnswer
                      key={id}
                      $correct={!!correct}
                      $displayCorrect={displayCorrectAnswer}
                    >
                      <button
                        type="button"
                        onClick={handleQuizAnswer(quiz.id, id)}
                      >
                        <strong>
                          <span>{index + 1}.</span>
                        </strong>
                        {answer}
                      </button>
                    </S.QuizAnswer>
                  );
                })}
              </S.QuizAnswersContainer>
            </S.QuizWrapper>
          ))}
        </>
      )}
    </S.Container>
  );
};

export default memo(Quiz);
