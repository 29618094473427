import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { AxiosError, AxiosResponse } from 'axios';

import { api } from '@services';
import { RoomWordCloudResult } from '@common/types/api';

type WordCloudMutationOptions = UseMutationOptions<
  AxiosResponse<{ message: string }>,
  unknown,
  string,
  { previousWordCloud: RoomWordCloudResult | undefined }
>;

export const useWordCloudMutation = (
  roomId?: number,
  wordCloudId?: number,
  options?: WordCloudMutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (word: string) =>
      api.post(`/rooms/word_cloud`, {
        room_id: roomId,
        word_cloud_id: wordCloudId,
        word,
      }),
    {
      onMutate: async (word: string) => {
        await queryClient.cancelQueries(['word_cloud', roomId]);

        const previousWordCloud = queryClient.getQueryData<RoomWordCloudResult>(
          ['word_cloud', roomId],
        );

        if (previousWordCloud) {
          const updatedCloud = [...previousWordCloud.cloud];

          const wordIndexInCloud = updatedCloud.findIndex(
            ({ value }) => value.toLowerCase() === word,
          );

          if (wordIndexInCloud === -1) {
            updatedCloud.push({ value: word, count: 1 });
          } else {
            updatedCloud[wordIndexInCloud].count += 1;
          }

          queryClient.setQueryData<RoomWordCloudResult>(
            ['word_cloud', roomId],
            {
              ...previousWordCloud,
              cloud: updatedCloud,
            },
          );
        }

        return { previousWordCloud };
      },
      onError: (err, _variables, context) => {
        toast.error(
          (err as AxiosError).response?.data.message ||
            'Não foi possível popular a Nuvem de Palavras.',
        );

        if (context?.previousWordCloud) {
          queryClient.setQueryData<RoomWordCloudResult>(
            ['word_cloud', roomId],
            context.previousWordCloud,
          );
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(['word_cloud', roomId]);
      },
      ...(options || {}),
    },
  );
};
