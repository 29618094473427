import { useEffect } from 'react';

import { Redirect, Switch, useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import { SignIn, SignUp, ForgotPassword, ResetPassword } from '@pages/Auth';
import Lobby from '@pages/Lobby';
import Schedule from '@pages/Schedule';
import Speakers from '@pages/Speakers';
import Rooms from '@pages/Rooms';
import Sponsors from '@pages/Sponsors';
import SponsorProfile from '@pages/SponsorProfile';
import VideoGallery from '@pages/VideoGallery';
import Broadcast from '@pages/Broadcast';
import Gamification from '@pages/Gamification';
import NewsFeed from '@pages/NewsFeed';
import { useAuth, useEventfy } from '@hooks';
import {
  selectGTM,
  selectGoogleAnalyticsTag,
  selectIsRegistrationEnabled,
  selectPixelId,
} from '@hooks/useEventfy/selectors';
import { selectIsAuthenticated } from '@hooks/useAuth/selectors';
import { useHotjar } from '@hooks';
import { IS_DEV } from '@constants';

import Route from './Route';

const Routes = () => {
  const { pathname, search } = useLocation();
  const isAuthenticated = useAuth(selectIsAuthenticated);
  const pixelId = useEventfy(selectPixelId);
  const googleAnalyticsTag = useEventfy(selectGoogleAnalyticsTag);
  const googleGTM = useEventfy(selectGTM);
  const isRegistrationEnabled = useEventfy(selectIsRegistrationEnabled);

  const fallbackPath = isAuthenticated ? '/lobby' : '/sign-in';

  useHotjar();

  useEffect(() => {
    if (pixelId) {
      ReactPixel.init(pixelId, undefined, {
        autoConfig: true,
        debug: IS_DEV,
      });
    }
  }, [pixelId]);

  useEffect(() => {
    if (googleAnalyticsTag) {
      ReactGA.initialize(googleAnalyticsTag);
    }
  }, [googleAnalyticsTag]);

  useEffect(() => {
    if (googleGTM) {
      const tagManagerArgs = {
        gtmId: googleGTM,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [googleGTM]);

  useEffect(() => {
    googleAnalyticsTag &&
      ReactGA.send({
        hitType: 'pageview',
        page: `${pathname}${search}`,
      });
  }, [googleAnalyticsTag, pathname, search]);

  useEffect(() => {
    googleGTM &&
      TagManager.dataLayer({
        dataLayer: {
          page: `${pathname}${search}`,
        },
        dataLayerName: 'Page',
      });
  }, [googleGTM, pathname, search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <Route component={SignIn} path="/" exact />
      {isRegistrationEnabled && (
        <Route component={SignUp} path="/sign-up" exact />
      )}
      <Route component={SignIn} path="/sign-in" exact />
      <Route component={ForgotPassword} path="/forgot-password" exact />
      <Route component={ResetPassword} path="/reset-password" exact />
      <Route component={Lobby} path="/lobby" private />
      <Route component={NewsFeed} path="/newsfeed" private />
      <Route component={Schedule} path="/schedule" private />
      <Route component={Speakers} path="/speakers" private />
      <Route component={Rooms} path="/rooms" private />
      <Route component={Gamification} path="/ranking" private />
      <Route component={Sponsors} path="/sponsors" exact private />
      <Route component={SponsorProfile} path="/sponsors/:sponsorId" private />
      <Route component={Broadcast} path="/broadcast/:roomSlug" private />
      <Route component={VideoGallery} path="/videos" exact private />
      <Route component={VideoGallery} path="/videos/:videoId" private />
      <Redirect from="*" to={fallbackPath} push={false} />
    </Switch>
  );
};

export default Routes;
