import { QueryFunctionContext } from 'react-query';

import {
  FullScheduleResponse,
  FullScheduleResult,
  FullSpeakersResponse,
  FullSpeakersResult,
  RoomsResponse,
  RoomsWithSpeakersResult,
  SponsorsResponse,
  SponsorsResult,
  VideosResponse,
  VideosResult,
} from '@common/types/api';
import {
  ROOMS_ENDPOINT,
  SCHEDULE_ENDPOINT,
  SPEAKERS_ENDPOINT,
  SPONSORS_ENDPOINT,
  VIDEOS_ENDPOINT,
} from '@constants/endpoints';
import { api } from '@services';
import { PromiseWithCancel } from '@common/types';
import { cancelToken } from '@services/api';

type PaginatedContext = QueryFunctionContext<[string, number]>;

type PaginatedContextWithFilter = QueryFunctionContext<
  [string, number, { roomId?: number; date?: string }]
>;

export const fetchRooms = (ctx: PaginatedContext) => {
  const [, page] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<RoomsResponse>(
      `${ROOMS_ENDPOINT}?include=participants&limit=12&page=${page}`,
      {
        cancelToken: source.token,
      },
    )
    .then((response) => {
      const { data: rooms, meta } = response.data;

      return { rooms, meta } as RoomsWithSpeakersResult;
    }) as PromiseWithCancel<RoomsWithSpeakersResult>;

  promise.cancel = () => source.cancel('useRoomsQuery Cancelled');

  return promise;
};

export const fetchSpeakers = (ctx: PaginatedContextWithFilter) => {
  const [, page, filters] = ctx.queryKey;
  const { roomId } = filters || {};

  const source = cancelToken();

  const promise = api
    .get<FullSpeakersResponse>(
      `${SPEAKERS_ENDPOINT}?include=schedules,rooms&limit=25&page=${page}${
        roomId ? `&room_id=${roomId}` : ''
      }`,
      {
        cancelToken: source.token,
      },
    )
    .then((response) => {
      const { data: speakers, meta } = response.data;

      return { speakers, meta } as FullSpeakersResult;
    }) as PromiseWithCancel<FullSpeakersResult>;

  promise.cancel = () => source.cancel('useSpeakersQuery Cancelled');

  return promise;
};

export const fetchSchedule = (ctx: PaginatedContextWithFilter) => {
  const [, page, filters] = ctx.queryKey;
  const { roomId, date } = filters || {};

  const source = cancelToken();

  const promise = api
    .get<FullScheduleResponse>(
      `${SCHEDULE_ENDPOINT}?include=participants,room&limit=10&display=1&page=${page}${
        roomId ? `&room_id=${roomId}` : ''
      }${date ? `&schedule_date=${date}` : ''}`,
      {
        cancelToken: source.token,
      },
    )
    .then((response) => {
      const { data: schedule, meta } = response.data;

      return { schedule, meta } as FullScheduleResult;
    }) as PromiseWithCancel<FullScheduleResult>;

  promise.cancel = () => source.cancel('useScheduleQuery Cancelled');

  return promise;
};

export const fetchSponsors = (ctx: PaginatedContext) => {
  const [, page] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<SponsorsResponse>(`${SPONSORS_ENDPOINT}?limit=15&page=${page}`, {
      cancelToken: source.token,
    })
    .then((response) => {
      const { data: sponsors, meta } = response.data;

      return { sponsors, meta } as SponsorsResult;
    }) as PromiseWithCancel<SponsorsResult>;

  promise.cancel = () => source.cancel('useSponsorsQuery Cancelled');

  return promise;
};

export const fetchVideos = (ctx: PaginatedContext) => {
  const [, page] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<VideosResponse>(`${VIDEOS_ENDPOINT}?limit=15&page=${page}`, {
      cancelToken: source.token,
    })
    .then((response) => {
      const { data: videos, meta } = response.data;

      return { videos, meta } as VideosResult;
    }) as PromiseWithCancel<VideosResult>;

  promise.cancel = () => source.cancel('useVideosQuery Cancelled');

  return promise;
};
