import { useCallback } from 'react';

import { useChannel, useEvent } from '@harelpls/use-pusher';
import { useQueryClient } from 'react-query';

import { SurveyEvent, SurveyQuestion } from '@common/types/pusher/events';
import { SettingsData } from '@common/types/api';

export const useSurveyChannel = () => {
  const queryClient = useQueryClient();
  const surveyChannel = useChannel('survey');

  const handleSurveyEvent = useCallback(
    (response?: SurveyEvent) => {
      if (!response) {
        return;
      }

      const previousEventSettings =
        queryClient.getQueryData<SettingsData>('event-settings');

      if (previousEventSettings) {
        queryClient.setQueryData<SettingsData>('event-settings', {
          ...previousEventSettings,
          satisfaction_survey: response.survey,
        });
      }

      queryClient.setQueryData<SurveyQuestion[]>(
        'satisfaction-survey',
        response.data.data,
      );
    },
    [queryClient],
  );

  useEvent(surveyChannel, 'SurveyEvent', handleSurveyEvent);
};
