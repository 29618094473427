import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { TransientProps } from '@common/types';

type SpeakerContainerProps = TransientProps<{
  loading?: boolean;
  flat?: boolean;
  negativeMargin?: boolean;
}>;

export const Container = styled.li<SpeakerContainerProps>`
  ${({ theme, $loading: isLoading, $flat: isFlat, $negativeMargin }) => css`
    background: ${theme.colors.background[100]};
    border-radius: ${theme.radii.default};
    transition: 180ms ease-in-out;

    ${!isFlat &&
    css`
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
        0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.005);
    `}

    ${isLoading &&
    css`
      pointer-events: none;
    `}

    ${$negativeMargin &&
    css`
      margin: 0 -1rem;
    `}

    > button {
      display: flex;
      padding: 1rem;
      width: 100%;
      background: none;
      text-align: left;
      color: ${theme.colors.foreground[100]};

      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    :hover {
      transform: translate3d(0, -0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023), 0 2px 2px rgba(0, 0, 0, 0.023),
        0 4px 4px rgba(0, 0, 0, 0.023), 0 8px 8px rgba(0, 0, 0, 0.023),
        0 16px 16px rgba(0, 0, 0, 0.023), 0 32px 32px rgba(0, 0, 0, 0.023);
    }

    :active {
      transform: translate3d(0, 0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047), 0 2px 2px rgba(0, 0, 0, 0.047),
        0 4px 4px rgba(0, 0, 0, 0.047);
    }

    & + li {
      margin-top: ${$negativeMargin ? 0.25 : 1}rem;
    }
  `}
`;

export const Data = styled.div`
  ${({ theme }) => css`
    strong {
      display: block;
    }

    span {
      display: block;
      font-size: 0.85rem;
      font-weight: 400;
      color: ${theme.colors.foreground[60]};
    }
  `}
`;

export const AvatarSkeleton = styled(Skeleton).attrs({
  width: '2.8rem',
  height: '2.8rem',
})`
  margin-right: 1rem;
  border-radius: 0.5rem;
`;
