import styled, { css } from 'styled-components';

import { PerfectScrollbar } from '@components';
import { TransientProps } from '@common/types';

type ContainerProps = TransientProps<{ disabled: boolean; hide: boolean }>;

export const Container = styled.div<ContainerProps>`
  ${({ $disabled: isDisabled, $hide: shouldHide }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    transition: 500ms ease-in-out;

    ${isDisabled &&
    css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
    `}

    ${shouldHide &&
    css`
      display: none;
    `}
  `}
`;

type MessageContainerProps = Partial<TransientProps<{ success: boolean }>>;

export const MessageContainer = styled.div<MessageContainerProps>`
  ${({ theme, $success: isSuccess }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    height: calc(100% - 5.875rem);

    svg {
      color: ${isSuccess
        ? theme.colors.success[100]
        : theme.colors.brand.primary.default};
    }
  `}
`;

export const QuizWrapper = styled(PerfectScrollbar)`
  ${({ theme }) => css`
    width: 100%;

    h3 {
      display: flex;
      gap: 0.5rem;
      padding: 1rem;
      font-size: 1rem;
      font-weight: 500;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid ${theme.colors.background[80]};

      > div {
        overflow: hidden;
      }
    }
  `}
`;

export const QuizAnswersContainer = styled.ul`
  ${() => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 0.5rem;
    padding: 0 1rem;
  `}
`;
type QuizAnswerProps = TransientProps<
  Record<'correct' | 'displayCorrect', boolean>
>;

export const QuizAnswer = styled.li<QuizAnswerProps>`
  ${({ theme, $correct: isCorrect, $displayCorrect: displayCorrect }) => css`
    flex: 1;

    > button {
      width: 100%;
      height: 100%;
      text-align: left;
      justify-content: flex-start;
      gap: 0.5rem;
      border-radius: 0.5rem;
      background: ${theme.colors.background[80]};
      border: 2px solid ${theme.colors.background[80]};
      transition: 180ms 80ms ease-in-out;

      strong {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: ${theme.colors.brand.primary.default};
        color: ${theme.colors.brand.primary.text};
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        font-size: 0.75rem;

        span {
          margin-left: 3px;
        }
      }

      :hover {
        border-color: ${theme.colors.brand.primary.default};
      }

      ${displayCorrect &&
      css`
        color: ${isCorrect ? 'inherit' : theme.colors.base.text};
        background: ${isCorrect
          ? theme.colors.success[100]
          : theme.colors.error[100]};
        border-color: ${isCorrect
          ? theme.colors.success[100]
          : theme.colors.error[100]};

        :hover {
          border-color: ${isCorrect
            ? theme.colors.success[100]
            : theme.colors.error[100]};
        }
      `}
    }
  `}
`;
