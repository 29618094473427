import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { motion } from 'framer-motion';

export const Container = styled(motion.aside)`
  ${({ theme }) => css`
    position: fixed;
    z-index: 4;
    top: 4.25rem;
    left: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 5rem);
    padding: 0.25rem 1rem;
    background: ${theme.colors.success[100]};
    cursor: pointer;

    box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0px 6.7px 5.3px rgba(0, 0, 0, 0.028), 0px 12.5px 10px rgba(0, 0, 0, 0.035),
      0px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0px 41.8px 33.4px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.07);

    * {
      color: ${theme.colors.base.text};
    }

    ${down('md')} {
      left: 0;
      width: 100%;
    }
  `};
`;

export const Notification = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
