import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const VideoDescription = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 52.5rem;

  > section {
    padding: 1.5rem 1rem;
    > h3 {
      padding-top: 0rem;
      margin-bottom: 0.25rem;
      position: relative;
    }
  }
`;

export const WidescreenWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 0;
    border-radius: 1rem;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    background: ${theme.colors.background[100]};
  `};
`;
