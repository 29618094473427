import styled from 'styled-components';

export const Container = styled.div`
  .plyr {
    border-radius: 20px;
  }
  .plyr__video-embed,
  .plyr__video-wrapper--fixed-ratio {
  }
  /* to disable right click on youtube video [disableContextMenu] */
  .plyr .plyr__video-embed iframe,
  .plyr__tooltip {
    pointer-events: none;
  }
`;
