import { useState } from 'react';

import Slider from 'react-slick';

import { useEventfy } from '@hooks';
import {
  selectLeftNewsFeedBanners,
  selectRightNewsFeedBanners,
} from '@hooks/useEventfy/selectors';

import * as S from './styles';

type BannerCarouselProps = {
  side: 'left' | 'right';
};

const BannerCarousel = ({ side }: BannerCarouselProps) => {
  const bannersSelector =
    side === 'left' ? selectLeftNewsFeedBanners : selectRightNewsFeedBanners;

  const banners = useEventfy(bannersSelector);

  const [imageDuration, setImageDuration] = useState(
    banners[0]?.limit_time || 0,
  );

  const hasMoreThanOneBanner = banners.length > 1;

  const handleImageDuration = (currentIndex: number) =>
    setImageDuration(banners[currentIndex].limit_time);

  if (!banners.length) {
    return null;
  }

  return (
    <S.Container $padding={false}>
      <Slider
        infinite={hasMoreThanOneBanner}
        arrows={false}
        autoplay={hasMoreThanOneBanner}
        autoplaySpeed={imageDuration * 1000}
        swipe={hasMoreThanOneBanner}
        accessibility
        pauseOnHover
        className="banners-carousel"
        dots={false}
        slidesToShow={1}
        afterChange={handleImageDuration}
      >
        {banners.map((banner) => {
          const image = (
            <img
              key={banner.url_image}
              src={banner.url_image}
              alt={banner.action || ''}
            />
          );

          if (banner.action) {
            const target =
              typeof banner.target === 'object' ? undefined : banner.target;

            return (
              <a
                key={banner.url_image}
                href={banner.action}
                target={target}
                rel="noopener noreferrer"
              >
                {image}
              </a>
            );
          }

          return image;
        })}
      </Slider>
    </S.Container>
  );
};

export default BannerCarousel;
