import { GooSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';
import Tippy from '@tippyjs/react';
import { FiInfo } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button, MetaTags } from '@components';
import { useGamificationQuery, useRankingTimer } from '@hooks';
import { HistoryState } from '@services/history';

import RankingTable from './RankingTable';
import * as S from './styles';

const Gamification = () => {
  const theme = useTheme();
  const history = useHistory();
  const { search } = useLocation<HistoryState>();
  const timeToUpdate = useRankingTimer();
  const { t } = useTranslation();

  const gamificationQuery = useGamificationQuery();

  const queryParams = new URLSearchParams(search);

  const handleDisplayRulesModal = () => {
    queryParams.set('gamification_rules', `true`);

    history.replace({
      search: `?${queryParams.toString()}`,
    });
  };

  return (
    <S.Container>
      <MetaTags title="Ranking Top 100" />

      <S.Wrapper>
        <div>
          <span>
            Ranking
            <Tippy
              theme="light-border"
              content={<S.Message>{t('pages.lobby.rankingTerms')}</S.Message>}
              animation="shift-away-subtle"
              placement="right"
              interactiveBorder={12}
              zIndex={4}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              appendTo={document.getElementById('tippy-portal')!}
            >
              <Button
                size="even"
                variant="transparent"
                icon={<FiInfo size="1rem" />}
                onClick={handleDisplayRulesModal}
              />
            </Tippy>
          </span>
          <h1>Top 100</h1>
        </div>

        <S.TimerWrapper>
          {gamificationQuery.isFetching ? (
            <GooSpinner
              size={2}
              sizeUnit="rem"
              color={theme.colors.brand.primary.default}
            />
          ) : (
            <div>
              {t('pages.lobby.rankingUpdate')}
              <strong>{timeToUpdate}</strong>
            </div>
          )}
        </S.TimerWrapper>
        <RankingTable data={gamificationQuery.data} />
      </S.Wrapper>
    </S.Container>
  );
};

export default Gamification;
