import { PusherProviderProps } from '@harelpls/use-pusher';

export const STORAGE_KEY = '@eventfy';

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:8000/api';

export const PUSHER_CONFIG: PusherProviderProps = {
  clientKey: process.env.REACT_APP_PUSHER_APP_KEY,
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
};

export const CHAT_CLIENT_ID = Number(process.env.REACT_APP_CHAT_CLIENT_ID);
