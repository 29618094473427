import {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import toast from 'react-hot-toast';

import { api } from '@services';
import { useAuth, useEventfy, useToggle } from '@hooks';
import { selectUser } from '@hooks/useAuth/selectors';
import { getAvatarFallback } from '@utils';
import { UserProfileResponse } from '@common/types/api';
import { Nullable } from '@common/types';
import { selectPrimaryColor } from '@hooks/useEventfy/selectors';
// eslint-disable-next-line import/no-cycle
import { Modal } from '@components';

import CropModal from './CropModal';
import * as S from './styles';

type AvatarInputProps = {
  src: Nullable<string>;
  updateSrc: Dispatch<SetStateAction<Nullable<string>>>;
};

type ImageState = {
  src: string;
  filename: string;
};

const AvatarInput = ({ src, updateSrc }: AvatarInputProps) => {
  const eventPrimaryColor = useEventfy(selectPrimaryColor);
  const user = useAuth(selectUser);

  const [shouldDisplayCrop, toggleShouldDisplayCrop] = useToggle(false);
  const [image, setImage] = useState<ImageState | null>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [avatarSrc, setAvatarSrc] = useState<any>(null);

  useEffect(() => {
    const getAvatar = () => {
      const avatar = getAvatarFallback(src, user.name, eventPrimaryColor);
      setAvatarSrc(avatar);
    };

    getAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const readFile = (file: File) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });

  const blobToFile = (blob: Blob, fileName: string) =>
    new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type: blob.type,
    });

  const handleChange = async (croppedImage: Blob) => {
    setAvatarSrc(URL.createObjectURL(croppedImage));
    toggleShouldDisplayCrop();

    let loadingToastId = '';

    try {
      const selectedFile = await blobToFile(croppedImage, 'name');

      if (selectedFile) {
        const data = new FormData();
        data.append('avatar', selectedFile);

        loadingToastId = toast.loading('Enviando foto de perfil...');

        const response = await api.post<UserProfileResponse>(
          `/user/${user.id}/avatar`,
          data,
        );

        const { avatar } = response.data.data;

        updateSrc(avatar as string);
      }
    } catch {
      toast.error(
        'Não foi possível atualizar sua foto de perfil. Por favor, tente novamente.',
      );
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  const handleCropFile: ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);

      if (imageDataUrl && typeof imageDataUrl === 'string') {
        setImage({ src: imageDataUrl, filename: file.name });
      }
    }

    toggleShouldDisplayCrop();
  };

  return (
    <>
      <S.Container>
        <label htmlFor="avatar">
          <img src={avatarSrc} alt={user.name} />

          <input
            id="avatar"
            type="file"
            accept="image/*"
            onChange={handleCropFile}
          />
        </label>
      </S.Container>

      {shouldDisplayCrop && image?.src && (
        <Modal hideCloseButton>
          <CropModal
            image={image}
            handleChange={handleChange}
            handleClose={toggleShouldDisplayCrop}
          />
        </Modal>
      )}
    </>
  );
};

export default AvatarInput;
