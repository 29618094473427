import styled, { css } from 'styled-components';

import { TransientProps } from '@common/types';

type withLoading = TransientProps<{ loading?: boolean }>;

export const Container = styled.ul<withLoading>`
  ${({ theme, $loading: isLoading }) => css`
    display: flex;
    align-items: center;

    img,
    li {
      width: 2.25rem;
      height: 2.25rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: ${isLoading ? 0 : 0.875}rem;
      object-fit: cover;
      border-radius: 50%;
      background: ${isLoading ? 'transparent' : theme.colors.success[100]};
      color: ${theme.colors.background[100]};
      border: 0.25rem solid ${theme.colors.background[100]};

      :first-child {
        margin-left: -0.25rem;
      }

      + img,
      + li {
        margin-left: -1rem;
      }
    }
  `}
`;
