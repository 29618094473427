import { HtmlHTMLAttributes } from 'react';

import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

import { SponsorsData } from '@common/types/api';
import { ComponentPropsWithLoading } from '@common/types';

import * as S from './styles';

type RoomCardProps = ComponentPropsWithLoading<SponsorsData> &
  HtmlHTMLAttributes<HTMLLIElement>;

const RoomCard = (props: RoomCardProps) => {
  const { data, loading: isLoading = false, ...attrs } = props;
  const { t } = useTranslation();

  if (!data) {
    return (
      <S.Container $loading={isLoading} {...attrs}>
        <S.SponsorCardLink as="div">
          <S.SponsorCardThumbnailSkeleton />

          <S.SponsorCardCTA as={Skeleton} height="2rem" />
        </S.SponsorCardLink>
      </S.Container>
    );
  }

  return (
    <S.Container $loading={isLoading} {...attrs}>
      <S.SponsorCardLink to={`/sponsors/${data.id}`}>
        <img src={data.logo} alt={data.name} />

        <S.SponsorCardCTA>{t('pages.sponsors.more')}</S.SponsorCardCTA>
      </S.SponsorCardLink>
    </S.Container>
  );
};

export default RoomCard;
