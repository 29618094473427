import { useQuery } from 'react-query';

import { api } from '@services';
import { useEventfy } from '@hooks/useEventfy';
import { selectIsGamificationRankingEnabled } from '@hooks/useEventfy/selectors';

const fetchGamificationRules = async () => {
  const response = await api.get<{ rules: string }>(`/gamification/rules`);

  return response.data.rules;
};

export const useGamificationRulesQuery = () => {
  const isGamificationEnabled = useEventfy(selectIsGamificationRankingEnabled);

  return useQuery('gamification-rules', fetchGamificationRules, {
    enabled: isGamificationEnabled,
    staleTime: Infinity,
  });
};
