import { memo } from 'react';

import Tippy from '@tippyjs/react';

import { useBroadcastQuery } from '@hooks';
import { Button } from '@components';

import * as S from './styles';

const CallToAction = () => {
  const { data } = useBroadcastQuery();

  if (!data?.settings.data.call_action) {
    return null;
  }

  return (
    <S.Container>
      <Tippy
        arrow={!!data.callAction.data.cta}
        visible
        placement="left"
        theme="light-border"
        maxWidth="20.5rem"
        zIndex={3}
        content={<S.Message>{data.callAction.data.message}</S.Message>}
      >
        {data.callAction.data.cta ? (
          <Button
            as="a"
            href={data.callAction.data.url_action}
            target="__blank"
            rel="noopener noreferer"
            title={data.callAction.data.cta}
          />
        ) : (
          <div />
        )}
      </Tippy>
    </S.Container>
  );
};

export default memo(CallToAction);
