import { memo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { selectSecondaryColorRgbValues } from '@hooks/useEventfy/selectors';
import { useBroadcastQuery, useEventfy } from '@hooks';

import * as S from './styles';
import { TABS } from './constants';

const RoomInformation = () => {
  const secondaryColor = useEventfy(selectSecondaryColorRgbValues);
  const { t } = useTranslation();

  const { data: totalArchives } = useBroadcastQuery({
    select: ({ archives_total }) => archives_total,
    notifyOnChangeProps: 'tracked',
  });

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const setActiveTab = (index: number) => () => setActiveTabIndex(index);

  return (
    <S.TabsContainer>
      <S.TabList role="tablist">
        {TABS.map((tab, index) => {
          const title =
            tab.title === 'pages.broadcast.info.files'
              ? `${t(tab.title)} (${totalArchives || 0})`
              : t(tab.title);

          return (
            <S.TabItem
              key={tab.id}
              role="presentation"
              $active={activeTabIndex === index}
              onClick={setActiveTab(index)}
              initial={{ borderBottomColor: `rgba(${secondaryColor}, 0)` }}
              animate={{
                borderBottomColor: `rgba(${secondaryColor}, ${
                  activeTabIndex === index ? 1 : 0
                })`,
              }}
            >
              <span>{title}</span>
            </S.TabItem>
          );
        })}
      </S.TabList>

      <S.TabContentWrapper>
        <S.AnimatedTab
          initial={false}
          animate={{ x: `${activeTabIndex * -100}%` }}
          transition={{
            type: 'spring',
            stiffness: 30,
          }}
        >
          {TABS.map((tab) => (
            <S.TabContent key={tab.id} role="tabpanel">
              <tab.content />
            </S.TabContent>
          ))}
        </S.AnimatedTab>
      </S.TabContentWrapper>
    </S.TabsContainer>
  );
};

export default memo(RoomInformation);
