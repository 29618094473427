/* eslint-disable react/no-danger */
import { useCallback, useState } from 'react';

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaLink,
  FaLinkedin,
  FaYoutube,
} from 'react-icons/fa';
import { BsBuilding } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { MetaTags, Modal, Button } from '@components';
import { useSponsorProfileQuery, useToggle } from '@hooks';
import { ProductData } from '@common/types/api';
import { generateArray } from '@utils';

import * as S from './styles';
import ContactUsModal from './ContactModal';
import ProductModal from './ProductModal';
import SponsorVideos from './SponsorVideos';

type SponsorProfileParams = { sponsorId: string };

const SponsorProfile = () => {
  const { sponsorId } = useParams<SponsorProfileParams>();
  const { t } = useTranslation();

  const [activeProduct, setActiveProduct] = useState<ProductData | undefined>();

  const sponsorProfileQuery = useSponsorProfileQuery(sponsorId);

  const handleSelectProduct = (product: ProductData) => () =>
    setActiveProduct(product);

  const [shouldDisplayContactUs, toggleShouldDisplayContactUs] =
    useToggle(false);
  const handleCloseModal = useCallback(() => setActiveProduct(undefined), []);

  return (
    <S.Container>
      <MetaTags
        title={sponsorProfileQuery.data?.name || t('pages.sponsors.title')}
      />

      <S.SponsorProfileHeader
        title={t('pages.sponsors.title')}
        icon={BsBuilding}
      />

      {!sponsorProfileQuery.isSuccess ? (
        <S.ProfileWrapper>
          <S.SponsorResume>
            <Skeleton
              width="100%"
              height="7rem"
              style={{ maxWidth: '12rem', marginBottom: '1.25rem' }}
            />

            <h1>
              <Skeleton width="60%" />
            </h1>

            <S.SocialMediaList>
              {generateArray(4).map((key) => (
                <Skeleton key={key} circle width="1.5rem" height="1.5rem" />
              ))}
            </S.SocialMediaList>

            <S.Website>
              <Skeleton circle width="1rem" height="1rem" />

              <span>
                <Skeleton width="8rem" />
              </span>
            </S.Website>
          </S.SponsorResume>

          <S.SponsorProfileData>
            <S.HeroImageContainer>
              <Skeleton
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: 0, left: 0 }}
              />
            </S.HeroImageContainer>

            {generateArray(3).map((key) => (
              <section key={key}>
                <h3>
                  <Skeleton width={`${Math.floor(Math.random() * 8) + 20}%`} />
                </h3>

                <p>
                  <Skeleton
                    width="100%"
                    count={Math.floor(Math.random() * 4) + 2}
                  />
                  <Skeleton width={`${Math.floor(Math.random() * 40) + 28}%`} />
                </p>
              </section>
            ))}
          </S.SponsorProfileData>
        </S.ProfileWrapper>
      ) : (
        <S.ProfileWrapper>
          <S.SponsorResume>
            <a
              href={sponsorProfileQuery.data.website || ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={sponsorProfileQuery.data.logo}
                alt={sponsorProfileQuery.data.name}
              />
            </a>

            <div>
              <h1>{sponsorProfileQuery.data.name}</h1>

              <S.SocialMediaList>
                {sponsorProfileQuery.data.facebook && (
                  <li>
                    <a
                      href={sponsorProfileQuery.data.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook size="1.5rem" />
                    </a>
                  </li>
                )}

                {sponsorProfileQuery.data.instagram && (
                  <li>
                    <a
                      href={sponsorProfileQuery.data.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram size="1.5rem" />
                    </a>
                  </li>
                )}

                {sponsorProfileQuery.data.linkedin && (
                  <li>
                    <a
                      href={sponsorProfileQuery.data.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin size="1.5rem" />
                    </a>
                  </li>
                )}

                {sponsorProfileQuery.data.youtube && (
                  <li>
                    <a
                      href={sponsorProfileQuery.data.youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube size="1.5rem" />
                    </a>
                  </li>
                )}
              </S.SocialMediaList>

              {!!sponsorProfileQuery.data.website && (
                <S.Website
                  href={sponsorProfileQuery.data.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLink />
                  <span>{t('pages.sponsors.website')}</span>
                </S.Website>
              )}
              <S.Contact>
                <Button
                  title="Fale conosco"
                  variant="outline"
                  as={Link}
                  onClick={toggleShouldDisplayContactUs}
                />
              </S.Contact>
            </div>
          </S.SponsorResume>

          <S.SponsorProfileData>
            <S.HeroImageContainer>
              <img
                src={sponsorProfileQuery.data.banner || ''}
                alt={sponsorProfileQuery.data.name}
              />
            </S.HeroImageContainer>

            <section>
              <h3>{t('pages.sponsors.about')}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: sponsorProfileQuery.data.about,
                }}
              />
            </section>

            {!!sponsorProfileQuery.data.videos?.data.length && (
              <section>
                <h3>{t('pages.sponsors.videos')}</h3>

                <SponsorVideos
                  videos={sponsorProfileQuery.data.videos.data.filter(
                    (video) => !!video.status,
                  )}
                />
              </section>
            )}

            {!!sponsorProfileQuery.data.products.data.length && (
              <section>
                <h3>{t('pages.sponsors.products')}</h3>

                <S.ProductList>
                  {sponsorProfileQuery.data.products.data.map((product) => (
                    <li key={product.id}>
                      <button
                        type="button"
                        onClick={handleSelectProduct(product)}
                      >
                        <img src={product.photo} alt={product.name} />
                      </button>
                    </li>
                  ))}
                </S.ProductList>
              </section>
            )}

            {!!sponsorProfileQuery.data.archives.data.length && (
              <section>
                <h3>
                  {t('pages.sponsors.files')} (
                  {sponsorProfileQuery.data.archives_total})
                </h3>

                <ul>
                  {sponsorProfileQuery.data.archives.data.map((archive) => (
                    <li key={archive.id}>
                      <a
                        href={archive.url_download}
                        target="__blank"
                        rel="noopener noreferer"
                        download
                      >
                        {archive.description}
                      </a>
                    </li>
                  ))}
                </ul>
              </section>
            )}
          </S.SponsorProfileData>
        </S.ProfileWrapper>
      )}

      <AnimatePresence>
        {activeProduct && (
          <Modal handleClose={handleCloseModal}>
            <ProductModal data={activeProduct} />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {shouldDisplayContactUs && (
          <Modal handleClose={toggleShouldDisplayContactUs}>
            <ContactUsModal
              handleClose={toggleShouldDisplayContactUs}
              company={sponsorProfileQuery.data}
            />
          </Modal>
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default SponsorProfile;
