import { Fragment, useRef } from 'react';

import { GooSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { generateArray } from '@utils';
import { useNewsFeedQuery, useIntersectionObserver } from '@hooks';

import Post from './Post';
import PostLoading from './Post/Loading';
import * as S from './styles';
import NewPost from './NewPost';

const Feed = () => {
  const theme = useTheme();

  const newsfeedQuery = useNewsFeedQuery(true);
  const { t } = useTranslation();

  const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: newsfeedQuery.fetchNextPage,
    enabled: newsfeedQuery.hasNextPage,
  });

  return (
    <S.Container>
      <NewPost />

      {newsfeedQuery.isLoading
        ? generateArray(3).map((key) => (
            <PostLoading key={`newsfeed-post-${key}`} />
          ))
        : newsfeedQuery.data?.pages.map(({ meta, data }) => {
            const key = `current-page-${meta.pagination.current_page}`;

            return (
              <Fragment key={key}>
                {data.map((post) => (
                  <Post key={`newsfeed-post-${post.id}`} data={post} />
                ))}
              </Fragment>
            );
          })}

      {newsfeedQuery.isFetchingNextPage ? (
        <S.SpinnerWrapper>
          <GooSpinner
            size={2.5}
            sizeUnit="rem"
            color={theme.colors.brand.primary.default}
          />
        </S.SpinnerWrapper>
      ) : (
        newsfeedQuery.hasNextPage && (
          <S.LoadMoreButton
            type="button"
            ref={loadMoreButtonRef}
            onClick={() => newsfeedQuery.fetchNextPage()}
          >
            {t('pages.newsfeed.load')}
          </S.LoadMoreButton>
        )
      )}
    </S.Container>
  );
};

export default Feed;
