import styled from 'styled-components';

export const Container = styled.div`
  grid-area: CTA;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 1;

  a {
    font-size: 0.875rem;
    padding: 0.75rem 1.25rem;
  }
`;

export const Message = styled.div`
  padding: 0.25rem 0.5rem;
`;
