import { QueryFunctionContext, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { api } from '@services';
import {
  CloudData,
  RoomWordCloudResponse,
  RoomWordCloudResult,
} from '@common/types/api';
import { ONE_MINUTE } from '@constants';
import { PromiseWithCancel } from '@common/types';
import { cancelToken } from '@services/api';

const fetchWordCloud = (ctx: QueryFunctionContext<[string, number]>) => {
  const [, roomId] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<RoomWordCloudResponse>(`/rooms/${roomId}/word_cloud`, {
      cancelToken: source.token,
    })
    .then(({ data }) => {
      const { words_group = [], ...settings } = data.data;

      return {
        ...settings,
        cloud: words_group.map<CloudData>(({ word, total }) => ({
          value: word,
          count: total,
        })),
      };
    }) as PromiseWithCancel<RoomWordCloudResult>;

  promise.cancel = () => source.cancel('useWordCloudQuery Cancelled');

  return promise;
};

export const useWordCloudQuery = (roomId?: number) =>
  useQuery<RoomWordCloudResult, AxiosError>(
    ['word_cloud', roomId],
    fetchWordCloud,
    {
      enabled: !!roomId,
      staleTime: ONE_MINUTE * 8,
      refetchInterval: ONE_MINUTE * 5,
    },
  );
