import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { PerfectScrollbar } from '@components';

export const Container = styled(motion.dialog)`
  ${({ theme }) => css`
    margin-left: auto;
    width: 100%;
    max-width: 30rem;
    height: 100%;
    background: ${theme.colors.background[100]};
    box-shadow: 0 1px 1px rgba(193, 48, 48, 0.075),
      0 2px 2px rgba(193, 48, 48, 0.075), 0 4px 4px rgba(193, 48, 48, 0.075),
      0 8px 8px rgba(193, 48, 48, 0.075), 0 16px 16px rgba(193, 48, 48, 0.075),
      0 32px 32px rgba(193, 48, 48, 0.075);
  `}
`;

export const Wrapper = styled(PerfectScrollbar)`
  ${() => css`
    padding: 3rem 2rem;
    width: 100%
    height: 100%;
    position: relative;
  `}
`;

export const SpeakerInfoHeader = styled.header`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-bottom: 2rem;

    > img {
      width: 8rem;
      height: 8rem;
      border-radius: 1rem;
    }
  `}
`;

export const SpeakerProfile = styled.section`
  text-align: center;

  > h2 {
    margin-bottom: 0rem;
  }

  > strong {
    font-weight: 400;
    font-size: 0.75rem;
  }

  > div {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
`;

export const SubTitle = styled.h3`
  ${() => css`
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin-bottom: 1rem;
  `}
`;

export const SpeakerInfoSection = styled.section`
  p {
    margin: 0.75rem 0;
    font-size: 0.875rem;
  }

  + section {
    margin-top: 2rem;
  }

  > ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const SpeakerInfoRoomLink = styled(Link)`
  ${() => css`
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0.25rem 0.75rem;
    border: 2px solid #30c161;
    border-radius: 1000px;
    font-size: 0.75rem;
    color: #30c161;

    > svg {
      margin-right: 0.5rem;
    }
  `}
`;
