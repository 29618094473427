import { TextareaHTMLAttributes, useEffect, useState } from 'react';

import { FieldError, useFormContext } from 'react-hook-form';
import { FiAlertCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import Button from '@components/Button';

import * as S from './styles';

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string;
  icon:
    | import('react').ElementType<import('react-icons').IconBaseProps>
    | JSX.Element;
  buttonIcon?:
    | import('react').ElementType<import('react-icons').IconBaseProps>
    | JSX.Element;
};

const Textarea = (props: TextareaProps) => {
  const { colors } = useTheme();
  const { register, formState, watch } = useFormContext();

  const [isFilled, setIsFilled] = useState(false);

  const {
    name,
    id,
    icon: Icon,
    required,
    disabled,
    className,
    buttonIcon: ButtonIcon,
    ...attrs
  } = props;
  delete attrs.value;

  const observedTextarea = watch(name, '');
  const inputError = formState.errors[name] as FieldError;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, ...registerRef } = register(name, {
    required,
  });

  useEffect(() => {
    setIsFilled(!!observedTextarea);
  }, [observedTextarea]);

  return (
    <S.Container
      htmlFor={id || name}
      $error={!!inputError}
      $filled={isFilled}
      $disabled={disabled}
      className={className}
      {...attrs.tabIndex}
    >
      {Icon && typeof Icon === 'function' ? <Icon size="1.25rem" /> : Icon}

      <textarea
        id={id || name}
        rows={5}
        cols={33}
        {...attrs}
        {...registerRef}
      />

      {inputError && (
        <S.ErrorWrapper>
          <FiAlertCircle color={colors.error[100]} size="0.875rem" />
          {inputError.message}
        </S.ErrorWrapper>
      )}

      {ButtonIcon && (
        <Button
          type="submit"
          size="even"
          icon={ButtonIcon}
          variant="transparent"
          disabled={disabled}
        />
      )}
    </S.Container>
  );
};

Textarea.defaultProps = {
  type: 'text',
  value: '',
  placeholder: '',
  required: false,
  onChange: () => null,
} as Partial<TextareaProps>;

export default Textarea;
