import { Description, RoomSpeakers, RoomSchedule, RoomFiles } from '../tabs';

export const TABS = [
  {
    id: 'description' as const,
    title: 'pages.broadcast.info.about' as const,
    content: Description,
  },
  {
    id: 'speakers' as const,
    title: 'pages.broadcast.info.speakers' as const,
    content: RoomSpeakers,
  },
  {
    id: 'schedule' as const,
    title: 'pages.broadcast.info.schedule' as const,
    content: RoomSchedule,
  },
  {
    id: 'files' as const,
    title: 'pages.broadcast.info.files' as const,
    content: RoomFiles,
  },
];
