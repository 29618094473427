import styled, { css } from 'styled-components';

export const Container = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 39rem;
    margin-bottom: 1.25rem;

    > h2 {
      display: flex;
      align-items: center;

      > div {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 2.25rem;
        width: 2.25rem;
        border-radius: 0.25rem;
        color: ${theme.colors.brand.secondary.default};
        background: ${theme.colors.brand.secondary.opaque};
        margin-right: 1rem;
      }
    }
  `}
`;
