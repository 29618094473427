import format from 'date-fns/format';
import { enUS } from 'date-fns/locale';

import { FullScheduleData, ScheduleData } from '@common/types/api';
import { WEEK_DAYS } from '@constants';

export const formatScheduleDisplayData = (
  props: FullScheduleData | ScheduleData,
  lang: number,
) => {
  const { schedule_date, start_time, end_time, langs } = props;
  const scheduleDate = new Date(`${schedule_date}T00:00`);

  const formattedDate = format(scheduleDate, 'dd MMMM y', {
    locale: enUS,
  });

  const [day, month, year] = formattedDate.split(' ');

  const weekDay = WEEK_DAYS[scheduleDate.getDay()];

  const timetable = `${langs.data[lang].start_time.slice(0, 5)} - ${langs.data[
    lang
  ].end_time.slice(0, 5)}`;

  return {
    day: scheduleDate.getDate(),
    weekDay,
    timetable,
    formattedDate,
    month,
    year,
  };
};
