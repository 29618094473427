import { DispatchWithoutAction, memo, MouseEventHandler } from 'react';

import { CgPoll } from 'react-icons/cg';
import { FiXCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { Button } from '@components';
import { DEFAULT_MODAL_ANIMATION, DEFAULT_TRANSITION } from '@constants';

import * as S from './styles';

type SurveyNotificationProps = {
  close: DispatchWithoutAction;
  handleSurvey: DispatchWithoutAction;
};

const SurveyNotification = (props: SurveyNotificationProps) => {
  const { close, handleSurvey } = props;
  const { t } = useTranslation();

  const handleGoToSurvey = () => {
    close();
    handleSurvey();
  };

  const handleClose: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    close();
  };

  return (
    <S.Container
      onClick={handleGoToSurvey}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      initial="hidden"
      animate="shown"
      exit="hidden"
    >
      <S.Notification>
        <CgPoll size="1.25rem" />
        {t('modal.survey.call')}
      </S.Notification>

      <Button
        size="even"
        variant="transparent"
        icon={FiXCircle}
        onClick={handleClose}
      />
    </S.Container>
  );
};

export default memo(SurveyNotification);
