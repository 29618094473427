import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { PerfectScrollbar } from '@components';

export const Container = styled(motion.dialog)`
  ${({ theme }) => css`
    padding: 2.5rem 2rem;
    margin: auto;
    width: 100%;
    max-width: 60rem;
    height: 100%;
    max-height: 30rem;
    border-radius: 1rem;
    position: relative;
    background: ${theme.colors.background[100]};
    color: ${theme.colors.foreground[100]};

    box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0px 6.7px 5.3px rgba(0, 0, 0, 0.028), 0px 12.5px 10px rgba(0, 0, 0, 0.035),
      0px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0px 41.8px 33.4px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.07);

    > h2 {
      padding-bottom: 0.5rem;
      border-bottom: 2px solid ${theme.colors.background[80]};
    }
  `}
`;

export const ProductInfo = styled(PerfectScrollbar)`
  ${() => css`
    padding-top: 1rem;
    height: 100%;
    max-height: calc(100% - 2.5rem);
    p + p {
      margin-top: 1rem;
    }
    > div {
      margin-top: 1rem;
      display: flex;
      gap: 1rem;
      text-align: justify;
      font-size: 0.875rem;
    }
    > img {
      max-width: 250px;
      max-height: 250px;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      float: left;
      margin: 1rem;
    }
  `}
`;

export const SpeakerProfile = styled.section`
  text-align: center;

  > h2 {
    margin-bottom: 0rem;
  }

  > strong {
    font-weight: 400;
    font-size: 0.75rem;
  }

  > div {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
`;

export const SubTitle = styled.h3`
  ${() => css`
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin-bottom: 1rem;
  `}
`;

export const SpeakerInfoSection = styled.section`
  > p {
    font-size: 0.875rem;
  }

  + section {
    margin-top: 2rem;
  }

  > ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const SpeakerInfoRoomLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0.25rem 0.75rem;
    border: 2px solid ${theme.colors.brand.primary.default};
    border-radius: 1000px;
    font-size: 0.75rem;
    color: ${theme.colors.brand.primary.default};

    > svg {
      margin-right: 0.5rem;
    }
  `}
`;
