import { HtmlHTMLAttributes, memo } from 'react';

import Skeleton from 'react-loading-skeleton';
import { FiMessageCircle, FiThumbsUp } from 'react-icons/fi';

import * as S from '../styles';

const generateRandomBoolean = () => Boolean(Math.round(Math.random()));

const PostLoading = (attrs: HtmlHTMLAttributes<HTMLLIElement>) => (
  <S.Container $active={false} $loading $deleting={false} {...attrs}>
    <S.PostHeader>
      <Skeleton width="3rem" height="3rem" style={{ marginRight: '1rem' }} />

      <div>
        <h3>
          <Skeleton width="20rem" />
        </h3>
        <time>
          <Skeleton width="7rem" />
        </time>
      </div>
    </S.PostHeader>

    <S.PostMain>
      {generateRandomBoolean() && <Skeleton width="100%" count={2} />}
      <Skeleton width="60%" />

      {generateRandomBoolean() && (
        <S.PostImageWrapper>
          <Skeleton width="100%" height="25rem" />
        </S.PostImageWrapper>
      )}
    </S.PostMain>

    <S.PostFooter>
      <section>
        <span>
          <Skeleton width="7.5rem" />
        </span>

        <span>
          <Skeleton width="7.5rem" />
        </span>
      </section>

      <S.PostFooterActions $commentsVisible={false} $voted={false}>
        <button type="button" disabled>
          <FiThumbsUp size="1.25rem" />
          Like
        </button>

        <button type="button" disabled>
          <FiMessageCircle size="1.25rem" />
          Comment
        </button>
      </S.PostFooterActions>
    </S.PostFooter>
  </S.Container>
);

export default memo(PostLoading);
