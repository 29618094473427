import { PropsWithChildren } from 'react';

import { HTMLMotionProps } from 'framer-motion';

import * as S from './styles';

type RewardItemProps = PropsWithChildren<HTMLMotionProps<'dialog'>> & {
  typeReward: number;
  isBlocked: boolean;
  urlReward?: string;
  openModal: () => void;
};

const RewardItem = (props: RewardItemProps) => {
  const { typeReward, isBlocked, urlReward, openModal, children } = props;

  const getComponent = () => {
    if (isBlocked) {
      return <div className="reward-item">{children} </div>;
    }

    if (typeReward === 1) {
      return (
        <a
          className="reward-item"
          href={urlReward || '#'}
          target="_blank"
          rel="noreferrer"
          download
        >
          {children}
        </a>
      );
    }

    return (
      <button type="button" className="reward-item" onClick={openModal}>
        {children}
      </button>
    );
  };

  return <S.Container isBlocked={isBlocked}>{getComponent()}</S.Container>;
};

export default RewardItem;
