import { memo } from 'react';

import { useEventfy, useGamificationQuery } from '@hooks';
import { selectIsGamificationRankingEnabled } from '@hooks/useEventfy/selectors';

import RankingCard from './RankingCard';
import BannerCarousel from '../BannerCarousel';
import * as S from '../styles';

const Sidebar = () => {
  const isGamificationEnabled = useEventfy(selectIsGamificationRankingEnabled);

  const gamificationQuery = useGamificationQuery();

  return (
    <S.Container $side="right">
      {isGamificationEnabled && (
        <S.SidebarItem>
          <h4>Top 5 Usuários</h4>

          <ul>
            {gamificationQuery.data?.slice(0, 5).map((ranking, index) => (
              <RankingCard
                key={`ranking-${ranking.user_id}`}
                position={index + 1}
                {...ranking}
              />
            ))}
          </ul>
        </S.SidebarItem>
      )}

      <BannerCarousel side="right" />
    </S.Container>
  );
};

export default memo(Sidebar);
