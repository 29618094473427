import { DispatchWithoutAction, useRef } from 'react';

import { FiChevronDown, FiUser, FiXCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, Transition, Variants } from 'framer-motion';

import { Avatar } from '@components';
import { useAuth, useToggleOutsideClick } from '@hooks';

import * as S from './styles';

const variants: Variants = {
  hidden: {
    opacity: 0,
    y: -25,
  },
  shown: {
    opacity: 1,
    y: 0,
  },
};

const transition: Transition = {
  type: 'spring',
  duration: 0.5,
};

type DropdownMenuProps = {
  toggleProfileModal: DispatchWithoutAction;
};

const DropdownMenu = ({ toggleProfileModal }: DropdownMenuProps) => {
  const { user, signOut } = useAuth();
  const { t } = useTranslation();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isDropdownVisible, toggleDropdownVisibility] = useToggleOutsideClick(
    dropdownRef,
    false,
    { handleInsideClick: true },
  );

  const handleProfileClick = isDropdownVisible
    ? undefined
    : toggleDropdownVisibility;

  return (
    <S.Container ref={dropdownRef} $open={isDropdownVisible}>
      <button type="button" onClick={handleProfileClick}>
        <Avatar src={user.avatar} alt={user.name} />

        <span>
          {user.name}
          <FiChevronDown size="1.125rem" />
        </span>
      </button>

      <AnimatePresence>
        {isDropdownVisible && (
          <S.Dropdown
            variants={variants}
            transition={transition}
            initial="hidden"
            animate="shown"
            exit="hidden"
          >
            <li>
              <button type="button" onClick={toggleProfileModal}>
                <FiUser /> {t('menu.editProfile')}
              </button>
            </li>
            <li>
              <button type="button" onClick={() => signOut()}>
                <FiXCircle /> {t('menu.exitEvent')}
              </button>
            </li>
          </S.Dropdown>
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default DropdownMenu;
