import { HtmlHTMLAttributes, memo } from 'react';

import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { FullSpeakerData } from '@common/types/api';
import { ComponentPropsWithLoading } from '@common/types';

import * as S from './styles';

type SpeakerCardProps = ComponentPropsWithLoading<FullSpeakerData> &
  HtmlHTMLAttributes<HTMLLIElement>;

const SpeakerCard = ({ data, loading = false, ...attrs }: SpeakerCardProps) => {
  const history = useHistory();
  const { search, ...location } = useLocation();
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(search);

  const handleSpeaker = () => {
    queryParams.set('speaker', `${data?.id}`);

    history.replace({
      ...location,
      search: `?${queryParams.toString()}`,
    });
  };

  if (!data) {
    return (
      <S.Container $loading={loading} {...attrs}>
        <S.SpeakerCardLink as="div">
          <S.SpeakerCardDate>
            <S.SpeakerAvatarSkeleton />
          </S.SpeakerCardDate>

          <Skeleton width="80%" />
          <Skeleton width="40%" />

          <S.SpeakerCardCTA as={Skeleton} height="2rem" />
        </S.SpeakerCardLink>
      </S.Container>
    );
  }

  return (
    <S.Container $loading={loading} {...attrs}>
      <S.SpeakerCardLink onClick={handleSpeaker}>
        <S.SpeakerCardDate>
          <img src={data.photo} alt={data.name} />
        </S.SpeakerCardDate>

        <strong>{data.name}</strong>
        <span>{data.title}</span>

        <S.SpeakerCardCTA>{t('pages.speakers.view')}</S.SpeakerCardCTA>
      </S.SpeakerCardLink>
    </S.Container>
  );
};

export default memo(SpeakerCard);
