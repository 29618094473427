import * as Yup from 'yup';

import { BRAZILIAN_STATES } from '@constants';

const registerShape = {
  name: Yup.string().required('valid.name.req'),
  email: Yup.string().required('valid.email.req').email('valid.email.invalid'),
  phone: Yup.string(),
  city: Yup.string(),
  district: Yup.string()
    .required('valid.state.req')
    .test('district', 'valid.state.invalid', (district) => {
      if (!district) {
        return true;
      }
      const schema = Yup.string().oneOf(Object.keys(BRAZILIAN_STATES));
      return schema.isValidSync(district);
    }),
};

export const registerSchemas = {
  1: Yup.object().shape({
    ...registerShape,
    password: Yup.string().min(6, 'valid.password.req'),
    password_confirmation: Yup.string()
      .min(6, 'valid.password.retry')
      .oneOf([Yup.ref('password'), null], 'valid.password.invalid'),
  }),
  2: Yup.object().shape(registerShape),
};
