import { HtmlHTMLAttributes, PropsWithChildren } from 'react';

import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

export type SectionHeaderProps = {
  title?: string;
  icon:
    | import('react').ElementType<import('react-icons').IconBaseProps>
    | JSX.Element;
  as?: import('react').ElementType;
} & HtmlHTMLAttributes<HTMLDivElement>;

const SectionHeader = (props: PropsWithChildren<SectionHeaderProps>) => {
  const { title = '', icon: Icon, children, ...attrs } = props;

  return (
    <S.Container {...attrs}>
      <h2>
        <div>{typeof Icon === 'function' ? <Icon size="1.25rem" /> : Icon}</div>

        {title || <Skeleton width="16rem" height="1.5rem" />}
      </h2>

      {children}
    </S.Container>
  );
};

export default SectionHeader;
