import styled, { css } from 'styled-components';

import { TransientProps } from '@common/types';
import Button from '@components/Button';

type InputContainerProps = TransientProps<{
  error: boolean;
  filled: boolean;
  disabled?: boolean;
}>;

export const Container = styled.label<InputContainerProps>`
  ${({
    theme,
    $error: hasError,
    $filled: isFilled,
    $disabled: isDisabled,
  }) => css`
    padding: 1rem;
    width: 100%;
    height: 4rem;
    border-radius: ${theme.radii.default};
    background: none;
    color: ${theme.colors.background[40]};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border: 2px solid
      ${isFilled
        ? theme.colors.brand.primary.default
        : theme.colors.base.border};

    > svg {
      margin-right: 1rem;
    }

    svg {
      flex-shrink: 0;
      transition: ${theme.transition.default};
      color: ${isFilled
        ? theme.colors.brand.primary.default
        : theme.colors.foreground[40]};
    }

    > input {
      flex: 1;
      min-width: 0;
      background: none;
      border: none;
      color: ${theme.colors.foreground[100]};

      ::placeholder {
        color: ${theme.colors.foreground[40]};
      }
    }

    > button {
      margin-right: -1rem;

      + div {
        margin-left: 1rem;
      }

      :disabled {
        background: none;
        cursor: auto;
      }

      &[type='submit'] {
        transition: 180ms ease-in-out;

        :hover:not(:disabled) {
          transform: scale(1.1);
        }
      }
    }

    :focus-within {
      border-color: ${theme.colors.brand.primary.default};

      svg {
        color: ${theme.colors.brand.primary.default};
      }
    }

    ${isDisabled &&
    css`
      background: ${theme.colors.background[80]};
    `}

    ${hasError &&
    css`
      border-color: ${theme.colors.error[100]} !important;

      > svg {
        color: ${theme.colors.error[100]} !important;
      }

      button[type='submit'] {
        svg {
          color: ${theme.colors.error[100]} !important;
        }
      }
    `}

    + label {
      margin-top: 1rem;
    }
  `}
`;

export const PasswordButton = styled(Button)`
  position: absolute;
  right: 1rem;
`;

export const ErrorWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: -0.625rem;
    left: calc(0.75rem + 1px);
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    color: ${theme.colors.error[100]};
    background: ${theme.colors.background[100]};
  `}
`;
