/* eslint-disable react-redux/no-unused-prop-types */
/* eslint-disable react/no-unused-prop-types */
import { useAuth } from '@hooks';
import { selectUser } from '@hooks/useAuth/selectors';

import * as S from './styles';

type PlayerProps = {
  meetingNumber: string;
  passWord: string;
  apiKey: string;
  hostUserId: number;
};

const Zoom = ({ meetingNumber, passWord, hostUserId }: PlayerProps) => {
  const uri = window.location.hostname;
  const user = useAuth(selectUser);
  const isRole = user.id === hostUserId ? 1 : 0;
  const source = `https://zeventfy.eventfy.live/?name=${user.name}&uri=${uri}&meeting=${meetingNumber}&role=${isRole}&pwdroom=${passWord}`;
  return (
    <S.ZoomWrapper>
      <iframe
        title="Streaming"
        src={source}
        frameBorder="0"
        allow="camera; microphone; fullscreen; sharescreen; display-capture"
        style={{
          height: '570px',
          width: '100%',
        }}
        allowFullScreen
      />
    </S.ZoomWrapper>
  );
};
export default Zoom;
