import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

import { TransientProps } from '@common/types';

type ContainerProps = TransientProps<{ menuVisible: boolean }>;

export const Container = styled.div<ContainerProps>`
  ${({ $menuVisible: isMenuVisible }) => css`
    grid-area: Language;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    > button {
      padding: 0;
      background: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.125rem;
      font-weight: 500;
      white-space: nowrap;

      svg {
        transition: 180ms ease-in-out;
        transform: rotate(${isMenuVisible ? -180 : 0}deg);
      }
    }

    .tippy-box {
      left: 24.5px;
    }

    .tippy-content {
      padding: 0;
    }

    ${down('md')} {
      align-items: flex-start;

      .tippy-box {
        left: -8px;
      }
    }
  `};
`;

type TippyButtonProps = TransientProps<{ active: boolean }>;

export const TippyButton = styled.button<TippyButtonProps>`
  ${({ theme, $active: isActive }) => css`
    border-radius: 0;
    width: 100%;
    padding: 0.75rem 1rem;
    text-align: right;
    font-size: 0.875rem;
    color: ${theme.colors[isActive ? 'background' : 'foreground'][100]};
    background: ${isActive
      ? theme.colors.brand.primary.default
      : theme.colors.background[100]};
    transition: 180ms 80ms ease-in-out;

    :hover {
      color: ${theme.colors.brand.primary.text};
      background: ${theme.colors.brand.primary.default};
    }

    :first-child {
      border-radius: 0.25rem 0.25rem 0 0;
    }

    :last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }

    :only-child {
      border-radius: 0.25rem;
    }
  `}
`;
