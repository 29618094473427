import { parseToRgb } from 'polished';

import { SettingsData } from '@common/types/api';

export const selectEventName = (state: SettingsData) => state.name;

export const selectEmojiSettings = (state: SettingsData) => state.custom.data;

export const selectPrimaryColor = (state: SettingsData) => state.primary_color;

export const selectSecondaryColorRgbValues = (state: SettingsData) =>
  Object.values(parseToRgb(state.secondary_color)).join(', ');

export const selectIsSatisfactionSurveyEnabled = (state: SettingsData) =>
  !!state.satisfaction_survey;

export const selectIsCertificateEnabled = (state: SettingsData) =>
  !!state.certificate;

export const selectCertificateType = (state: SettingsData) =>
  state.type_certificate;

export const selectIsRegistrationEnabled = (state: SettingsData) =>
  !!state.session_register;

export const selectIsGamificationRankingEnabled = (state: SettingsData) =>
  !!state.gamification_ranking;

export const selectIsNewsFeedEnable = (state: SettingsData) => !!state.timeline;

export const selectLeftNewsFeedBanners = (state: SettingsData) =>
  state.timeline_l_banners;

export const selectRightNewsFeedBanners = (state: SettingsData) =>
  state.timeline_r_banners;

export const selectHasSponsors = (state: SettingsData) => state.partners > 0;

export const selectHasMoreThanOneRoom = (state: SettingsData) =>
  state.rooms > 1;

export const selectHotjarTag = (state: SettingsData) => state.tag_hotjar;

export const selectPixelId = (state: SettingsData) => state.pixel_facebook;

export const selectGoogleAnalyticsTag = (state: SettingsData) =>
  state.tag_google_analytics;

export const selectGTM = (state: SettingsData) => state.tag_google_gtm;

export const selectHasVideos = (state: SettingsData) => state.videos > 0;
