import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { motion } from 'framer-motion';

import { TransientProps } from '@common/types';

import Button from '../Button';

type ModalOverlayProps = TransientProps<{ noPadding?: boolean }>;

export const ModalOverlay = styled(motion.div)<ModalOverlayProps>`
  ${({ $noPadding: hasNoPadding }) => css`
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: ${hasNoPadding ? 0 : 2}rem;
    display: flex;
    align-items: center;
    backdrop-filter: blur(0.375rem);
    background: rgba(0, 0, 0, 0.19);

    ${down('md')} {
      padding: ${hasNoPadding ? 0 : 1.25}rem;
    }
  `}
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;
