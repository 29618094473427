/* eslint-disable radar/cognitive-complexity */
import { useMutation, useQueryClient, InfiniteData } from 'react-query';

import { api } from '@services';
import { TimelineResponse, CommentData } from '@common/types/api';

export const useDeleteCommentMutation = (postId: number, commentId: number) => {
  const queryClient = useQueryClient();
  const queryKey = ['post-comments', postId] as const;
  const postsQueryKey = ['newsfeed'] as const;

  return useMutation(() => api.delete(`/timeline/comment/${commentId}`), {
    onMutate: () => {
      const previousComments =
        queryClient.getQueryData<CommentData[]>(queryKey);
      const previousPosts =
        queryClient.getQueryData<InfiniteData<TimelineResponse>>(postsQueryKey);

      if (previousComments) {
        const commentIndex = previousComments.findIndex(
          (comment) => comment.id === commentId,
        );
        previousComments.splice(commentIndex, 1);
        queryClient.setQueryData<CommentData[]>(queryKey, previousComments);
      }

      if (previousPosts) {
        queryClient.setQueryData<InfiniteData<TimelineResponse>>(
          postsQueryKey,
          {
            ...previousPosts,
            pages: previousPosts.pages.map((page) => {
              const postIndex = page.data.findIndex(
                (post) => post.id === postId,
              );

              if (postIndex < 0) {
                return page;
              }
              return {
                ...page,
                data: page.data.map((post, index) => {
                  if (postIndex !== index) {
                    return post;
                  }

                  return {
                    ...post,
                    comments_total: post.comments_total - 1,
                  };
                }),
              };
            }),
          },
        );
      }
      return { previousComments, previousPosts };
    },
    onSuccess: (_, __, ctx) => {
      if (ctx) {
        const { previousComments } = ctx;

        if (previousComments) {
          queryClient.setQueryData<CommentData[]>(queryKey, previousComments);
        }
      }
    },
    onError: (_, __, ctx) => {
      if (ctx?.previousPosts) {
        queryClient.setQueryData<InfiniteData<TimelineResponse>>(
          queryKey,
          ctx.previousPosts,
        );
      }
    },
  });
};
