import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react';

import { ThemeProvider } from 'styled-components';

import { PropsWithRequiredChildren } from '@common/types';
import { GlobalStyles, theme } from '@styles';
import { eventfyStorage } from '@services';

export type ThemeModes = 'light' | 'dark';

export type AppThemeContext = {
  currentTheme: ThemeModes;
  toggleTheme: () => void;
};

export const ThemeContext = createContext({} as AppThemeContext);
ThemeContext.displayName = 'EventfyTheme';

export const EventfyThemeProvider = (props: PropsWithRequiredChildren) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeModes>(() => {
    const storedTheme = eventfyStorage.getItem<ThemeModes>('theme');

    if (storedTheme) {
      return storedTheme;
    }

    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // eslint-disable-next-line no-console
      console.log('Dark Theme enabled by the system preferences');
      return 'dark';
    }

    return 'light';
  });

  const { children } = props;

  const toggleTheme = useCallback(
    () =>
      setCurrentTheme((prevTheme) => {
        const newTheme = prevTheme === 'light' ? 'dark' : 'light';

        eventfyStorage.setItem('theme', newTheme);
        return newTheme;
      }),
    [],
  );

  const value = useMemo(
    () => ({
      currentTheme,
      toggleTheme,
    }),
    [currentTheme, toggleTheme],
  );

  useEffect(() => {
    const handleSystemThemeChange = (e: MediaQueryListEvent) => {
      const systemColorScheme: ThemeModes = e.matches ? 'dark' : 'light';

      setCurrentTheme(systemColorScheme);
    };

    const matchMediaPrefDark = window.matchMedia(
      '(prefers-color-scheme: dark)',
    );

    matchMediaPrefDark?.addEventListener('change', handleSystemThemeChange);

    return () => {
      matchMediaPrefDark?.removeEventListener(
        'change',
        handleSystemThemeChange,
      );
    };
  }, []);

  useEffect(() => {
    document.body.dataset.theme = currentTheme;

    eventfyStorage.setItem('theme', currentTheme);
  }, [currentTheme]);

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
      <GlobalStyles />
    </ThemeProvider>
  );
};
