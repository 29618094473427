import { TypeCertificate } from '@common/types/api';
import { useAuth } from '@hooks/useAuth';
import { selectUserHasRepliedSurvey } from '@hooks/useAuth/selectors';
import { useEventfy } from '@hooks/useEventfy';
import {
  selectIsCertificateEnabled,
  selectCertificateType,
} from '@hooks/useEventfy/selectors';

export const useIsCertificateEnabled = () => {
  const certificateType = useEventfy(selectCertificateType);
  const isCertificateEnabled = useEventfy(selectIsCertificateEnabled);

  const userHasRepliedSurvey = useAuth(selectUserHasRepliedSurvey);

  const userMustAnswerSurveyToGetCertificate =
    certificateType === TypeCertificate.MUST_ANSWER_SURVEY;

  if (userMustAnswerSurveyToGetCertificate && !userHasRepliedSurvey) {
    return false;
  }

  return isCertificateEnabled;
};
