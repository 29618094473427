import { memo, useEffect, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@components';
import { useAuth, useCreateCommentMutation } from '@hooks';
import { selectUser } from '@hooks/useAuth/selectors';

import * as S from './styles';

type FormField = {
  comment: string;
};

type NewCommentInputProps = {
  postId: number;
};

const NewCommentInput = ({ postId }: NewCommentInputProps) => {
  const user = useAuth(selectUser);

  const { handleSubmit, reset, register } = useForm<FormField>();

  const { mutate } = useCreateCommentMutation(postId);

  const inputRef = useRef<HTMLTextAreaElement>();

  const { ref, ...inputRegister } = register('comment', {
    required: true,
  });
  const { t } = useTranslation();

  const handleRef = (node: HTMLTextAreaElement | null) => {
    if (node) {
      ref(node);
      inputRef.current = node;
    }
  };

  useEffect(() => {
    const handleEnterKeyPress = (e: HTMLElementEventMap['keydown']) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(({ comment }) => {
          mutate(comment);

          reset({
            comment: '',
          });
        })();
      }
    };

    inputRef.current?.addEventListener('keydown', handleEnterKeyPress);

    return () => {
      inputRef.current?.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [handleSubmit, mutate, reset]);

  return (
    <S.Container>
      <Avatar src={user.avatar} alt={user.name} />

      <S.AutosizeInput
        placeholder={t('pages.newsfeed.newComment')}
        {...inputRegister}
        ref={handleRef}
      />
    </S.Container>
  );
};

export default memo(NewCommentInput);
