import { QueryFunctionContext, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import toast from 'react-hot-toast';

import { api } from '@services';
import { FullSpeakerData } from '@common/types/api';

const fetchSpeakerProfile = async (
  ctx: QueryFunctionContext<[string, number]>,
) => {
  const [, speakerId] = ctx.queryKey;

  const response = await api.get<{ data: FullSpeakerData }>(
    `/participants/${speakerId}?include=schedules,rooms`,
  );

  return response.data.data;
};

export const useSpeakerProfileQuery = (id: number) => {
  const history = useHistory();
  const { search, ...location } = useLocation();

  const queryParams = new URLSearchParams(search);

  return useQuery(['speaker', id], fetchSpeakerProfile, {
    onError: () => {
      queryParams.delete('speaker');

      history.replace({
        ...location,
        search: `?${queryParams.toString()}`,
      });

      toast.error('Falha ao buscar o perfil do palestrante');
    },
  });
};
