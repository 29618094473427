import {
  cloneElement,
  MouseEventHandler,
  useCallback,
  useEffect,
  ReactElement,
  useRef,
} from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { FiX } from 'react-icons/fi';
import { Variants } from 'framer-motion';

import { PropsWithRequiredChildren } from '@common/types';

import Portal from '../Portal';
import * as S from './styles';

const variants: Variants = {
  hidden: {
    backdropFilter: 'blur(0rem)',
    background: 'rgba(0, 0, 0, 0)',
  },
  shown: {
    backdropFilter: 'blur(0.375rem)',
    background: 'rgba(0, 0, 0, 0.19)',
    transition: {
      duration: 0.18,
    },
  },
};

type BaseModalPropsWithClose = {
  rootId?: string;
  noPadding?: boolean;
  handleClose: () => void;
  hideCloseButton?: never;
};

type BaseModalPropsWithoutClose = {
  rootId?: string;
  noPadding?: boolean;
  handleClose?: never;
  hideCloseButton?: boolean;
};

export type ModalProps = PropsWithRequiredChildren<
  BaseModalPropsWithClose | BaseModalPropsWithoutClose
>;

const Modal = (props: ModalProps) => {
  const {
    rootId,
    children,
    handleClose = () => null,
    noPadding = false,
    hideCloseButton = false,
  } = props;

  const modalContentRef = useRef<HTMLDialogElement>();

  const handleEscapeKey = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        handleClose();
      }
    },
    [handleClose],
  );

  const handleContainerClick: MouseEventHandler<HTMLDialogElement> = (e) =>
    e.stopPropagation();

  useEffect(() => {
    const targetElement = modalContentRef.current;

    if (targetElement) {
      disableBodyScroll(targetElement, {
        reserveScrollBarGap: true,
      });
    }

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      if (targetElement) {
        enableBodyScroll(targetElement);
      }

      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleEscapeKey]);

  return (
    <Portal rootId={rootId}>
      <S.ModalOverlay
        variants={variants}
        initial="hidden"
        animate="shown"
        exit="hidden"
        $noPadding={noPadding}
        onClick={handleClose}
      >
        {cloneElement(children as ReactElement, {
          onClick: handleContainerClick,
          ref: (node: HTMLDialogElement) => {
            if (node) {
              modalContentRef.current = node;
            }
          },
          children: hideCloseButton ? null : (
            <S.CloseButton
              variant="transparent"
              size="even"
              onClick={handleClose}
              icon={FiX}
            />
          ),
        })}
      </S.ModalOverlay>
    </Portal>
  );
};

export default Modal;
