/* eslint-disable radar/cognitive-complexity */
import { useMutation, useQueryClient, InfiniteData } from 'react-query';

import { api } from '@services';
import { TimelineResponse } from '@common/types/api';

import { useAuth } from '../useAuth';
import { selectUserId } from '../useAuth/selectors';

export const usePostLikeMutation = (postId: number, userHasVoted: boolean) => {
  const queryClient = useQueryClient();
  const queryKey = ['newsfeed'] as const;

  const userId = useAuth(selectUserId);

  return useMutation(
    () =>
      api.post('/timeline/like', {
        post_id: postId,
      }),
    {
      onMutate: () => {
        const previousData =
          queryClient.getQueryData<InfiniteData<TimelineResponse>>(queryKey);

        if (previousData) {
          queryClient.setQueryData<InfiniteData<TimelineResponse>>(queryKey, {
            ...previousData,
            pages: previousData.pages.map((page) => {
              const postIndex = page.data.findIndex(
                (post) => post.id === postId,
              );

              if (postIndex < 0) {
                return page;
              }

              const voteToCompute = userHasVoted ? -1 : 1;

              return {
                ...page,
                data: page.data.map((post, index) => {
                  if (postIndex !== index) {
                    return post;
                  }

                  if (userHasVoted) {
                    post.likes.data.pop();
                  } else {
                    post.likes.data.push({
                      ...post.likes.data[0],
                      id: Date.now(),
                      user_id: userId,
                    });
                  }

                  return {
                    ...post,
                    likes_total: post.likes_total + voteToCompute,
                  };
                }),
              };
            }),
          });
        }

        return { previousData };
      },
      onError: (_, __, ctx) => {
        if (ctx?.previousData) {
          queryClient.setQueryData<InfiniteData<TimelineResponse>>(
            queryKey,
            ctx.previousData,
          );
        }
      },
    },
  );
};
