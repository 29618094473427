/* eslint-disable react/no-danger */
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import { FaProductHunt } from 'react-icons/fa';
import { HTMLMotionProps } from 'framer-motion';

import { ProductData } from '@common/types/api';
import { SectionHeader } from '@components';
import { DEFAULT_MODAL_ANIMATION, DEFAULT_TRANSITION } from '@constants';

import * as S from './styles';

type SpeakerInfoBaseProps = HTMLMotionProps<'dialog'> & {
  data: ProductData;
};

type SpeakerInfoProps = PropsWithChildren<SpeakerInfoBaseProps>;

const ProductModal = (
  { data, children, ...attrs }: SpeakerInfoProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => (
  <S.Container
    open
    ref={ref}
    transition={DEFAULT_TRANSITION}
    variants={DEFAULT_MODAL_ANIMATION}
    {...attrs}
  >
    {children}

    <SectionHeader title={data.name} icon={FaProductHunt} />
    <S.ProductInfo>
      <img src={data.photo} alt={data.name} />
      <section>
        <div
          dangerouslySetInnerHTML={{
            __html: data.description,
          }}
        />
      </section>
    </S.ProductInfo>
  </S.Container>
);

export default forwardRef(ProductModal);
