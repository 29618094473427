import { DispatchWithoutAction, memo, useMemo } from 'react';

import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useEventfy, useIsCertificateEnabled, useRoomsQuery } from '@hooks';
import { HistoryState } from '@services/history';

import { NAVIGATION_MENU } from '../constants';
import * as S from './styles';

type SidebarProps = {
  handleSurvey: DispatchWithoutAction;
  handleCertificate: DispatchWithoutAction;
};

export const Sidebar = ({ handleSurvey, handleCertificate }: SidebarProps) => {
  const event = useEventfy();
  const location = useLocation<HistoryState>();
  const { t } = useTranslation();

  const isCertificateEnabled = useIsCertificateEnabled();
  const isNewsFeedEnabled = !!event.timeline;

  const roomsQuery = useRoomsQuery();

  const navigation = useMemo(() => NAVIGATION_MENU(event), [event]);

  return (
    <S.Container>
      {Object.entries(navigation).map(
        ([title, { path, icon: Icon, enabled }]) => {
          if (!enabled) {
            return null;
          }
          const iTitle = t(title);
          if (path === '/satisfaction-survey') {
            return (
              <S.NavButton key={iTitle} as="button" onClick={handleSurvey}>
                <Icon size="1.5rem" />
                {iTitle}
              </S.NavButton>
            );
          }

          if (path === '/certificate') {
            if (!isCertificateEnabled) {
              return null;
            }

            return (
              <S.NavButton key={iTitle} as="button" onClick={handleCertificate}>
                <Icon size="1.5rem" />
                {iTitle}
              </S.NavButton>
            );
          }

          if (path === '/newsfeed') {
            if (!isNewsFeedEnabled) {
              return null;
            }

            return (
              <S.NavLink
                key={iTitle}
                to={path}
                $active={location.pathname.includes(path)}
              >
                <Icon size="1.5rem" />
                {iTitle}
              </S.NavLink>
            );
          }

          let verifiedPath = path;

          const isRoomsPath = path === '/rooms';
          const hasOnlyOneEventRoom = event.rooms === 1;
          const hasLoadedRooms = !!roomsQuery.data?.rooms.length;

          if (isRoomsPath && hasOnlyOneEventRoom && hasLoadedRooms) {
            verifiedPath = `/broadcast/${roomsQuery.data?.rooms[0].slug}`;
          }

          return (
            <S.NavLink
              key={iTitle}
              to={verifiedPath}
              $active={location.pathname.includes(verifiedPath)}
            >
              <Icon size="1.5rem" />
              {iTitle}
            </S.NavLink>
          );
        },
      )}
    </S.Container>
  );
};

export default memo(Sidebar);
