import { useState, useEffect } from 'react';

import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import addMinutes from 'date-fns/addMinutes';
import lightFormat from 'date-fns/lightFormat';

import { useGamificationQuery } from '@hooks/useGamificationQuery';

export const useRankingTimer = () => {
  const { dataUpdatedAt } = useGamificationQuery();

  const [{ lastDistance: stateDistance, formatted }, setTimer] = useState(
    () => {
      const lastDistance = differenceInMilliseconds(
        addMinutes(dataUpdatedAt, 30),
        Date.now(),
      );

      return {
        lastDistance,
        lastUpdatedAt: dataUpdatedAt,
        formatted: lightFormat(lastDistance, 'mm:ss'),
      };
    },
  );

  useEffect(() => {
    const timeout = setInterval(
      () =>
        setTimer((prevState) => {
          let lastDistance: number;

          const isSameDate = prevState.lastUpdatedAt === dataUpdatedAt;

          if (isSameDate) {
            const newDistance = prevState.lastDistance - 1000;

            lastDistance = newDistance <= 0 ? 0 : newDistance;

            return {
              ...prevState,
              lastDistance,
              formatted: lightFormat(lastDistance, 'mm:ss'),
            };
          }

          lastDistance = differenceInMilliseconds(
            addMinutes(dataUpdatedAt, 30),
            Date.now(),
          );

          return {
            lastDistance,
            lastUpdatedAt: dataUpdatedAt,
            formatted: lightFormat(lastDistance, 'mm:ss'),
          };
        }),
      1000,
    );

    return () => clearTimeout(timeout);
  }, [stateDistance, dataUpdatedAt]);

  return formatted;
};
