import { useMemo, createContext, Dispatch } from 'react';

import { PropsWithRequiredChildren } from '@common/types';
import { StreamingOrigin } from '@common/types/api';

import { useStateReducer } from '../../hooks/useStateReducer';
import { StateAction } from '../../hooks/useStateReducer/types';

type FloatingPlayerState = {
  src: string;
  roomName: string;
  roomSlug: string;
  streamingMethod: StreamingOrigin;
  isVisible: boolean;
};

export type FloatingPlayerContextData = FloatingPlayerState & {
  updateFloatingPlayer: Dispatch<StateAction<FloatingPlayerState>>;
};

export const FloatingPlayerContext =
  createContext<FloatingPlayerContextData | null>(null);

FloatingPlayerContext.displayName = 'FloatingPlayerContext';

export const FloatingPlayerProvider = (props: PropsWithRequiredChildren) => {
  const [state, updateFloatingPlayer] = useStateReducer<FloatingPlayerState>({
    src: '',
    roomName: '',
    streamingMethod: 1,
    roomSlug: '',
    isVisible: false,
  });

  const { src, isVisible, roomName, roomSlug, streamingMethod } = state;

  const { children } = props;

  const value = useMemo<FloatingPlayerContextData>(
    () => ({
      src,
      roomName,
      isVisible,
      roomSlug,
      streamingMethod,
      updateFloatingPlayer,
    }),
    [src, isVisible, roomName, roomSlug, streamingMethod, updateFloatingPlayer],
  );

  return (
    <FloatingPlayerContext.Provider value={value}>
      {children}
    </FloatingPlayerContext.Provider>
  );
};
