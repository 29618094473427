import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@hooks';
import { selectUserFirstName } from '@hooks/useAuth/selectors';

import * as S from './styles';

export type PostFields = {
  message: string;
};

const Editor = () => {
  const user = useAuth(selectUserFirstName);
  const { register, watch } = useFormContext<PostFields>();
  const { t } = useTranslation();

  const postLength = watch('message', '')?.length || 0;

  return (
    <S.Container>
      <S.AutosizeTextarea
        minRows={5}
        maxLength={1000}
        placeholder={t('pages.newsfeed.shared', { user })}
        {...register('message', {
          required: true,
        })}
      />
      {postLength}/1000
    </S.Container>
  );
};

export default Editor;
