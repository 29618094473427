import { useMemo } from 'react';

import { AxiosError } from 'axios';
import { useLocation } from 'react-router';

import { PropsWithRequiredChildren } from '@common/types';
import { useAuth } from '@hooks';
import { api } from '@services';

export const AxiosInterceptor = ({ children }: PropsWithRequiredChildren) => {
  const { signOut } = useAuth();
  const { pathname } = useLocation();

  useMemo(() => {
    api.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        config.headers[`X-localization`] = localStorage.getItem('i18nextLng');
        return config;
      },
      (error) => {
        // Do something with request error
        console.error(error);
        return Promise.reject(error);
      },
    );
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          (error as AxiosError).response?.status === 401 &&
          pathname !== '/'
        ) {
          signOut({ skipInvalidation: true });
        }

        return Promise.reject(error);
      },
    );
  }, [signOut, pathname]);

  return <>{children}</>;
};
