import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: ${theme.colors.brand.primary.default};
    background: linear-gradient(
      45deg,
      ${theme.colors.background[40]} 0%,
      ${theme.colors.background[100]} 75%
    );
    z-index: 9999999999999;
  `}
`;
