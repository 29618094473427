import { TrueOrFalse } from '../../_common';

export interface SettingsResponse {
  data: SettingsData;
}

export interface SettingsData {
  id: number;
  name: string;
  slug: string;
  description: string;
  access_type: AcessTypes; // 1 = auth with email and password; 2 = auth with only email;
  contact_email: string;
  whatsapp_button: string;
  whatsapp_button_local: IntegrationLocation;
  logo: string;
  banner: string;
  banner_app: string | null;
  banner_login: string;
  session_login: TrueOrFalse; // 0 = blocked auth; 1 = open auth;
  session_register: TrueOrFalse;
  message_login: string | null;
  pixel_facebook: string | null;
  rooms: number;
  partners: number;
  videos: number;
  tag_google_analytics: string | null;
  tag_google_gtm: string | null;
  tag_hotjar: string | null;
  gamification: TrueOrFalse;
  gamification_ranking: TrueOrFalse;
  certificate: TrueOrFalse;
  indication: TrueOrFalse;
  type_certificate: TypeCertificate;
  image_certificate: string | null;
  tag_tawk: string | null;
  tag_tawk_local: IntegrationLocation;
  satisfaction_survey: TrueOrFalse;
  primary_color: string;
  secondary_color: string;
  timeline: TrueOrFalse;
  timeline_l_banners: TimelineBanner[];
  timeline_r_banners: TimelineBanner[];
  custom: Emoji;
}

export enum IntegrationLocation {
  EVERY_PAGE = 1,
  LOGIN = 2,
  REGISTER = 3,
  LOGIN_AND_REGISTER = 4,
  AUTH_LAYOUT = 5,
}

export enum TypeCertificate {
  FREE = 0,
  MUST_ANSWER_SURVEY = 1,
}

export enum AcessTypes {
  EMAIL_AND_PASSWORD = 1,
  EMAIL_ONLY = 2,
}

export interface Emoji {
  data: EmojiData;
}

export interface EmojiData extends ApiEmojis {
  id: number;
  setting_id: number;
}

export interface ApiEmojis {
  likes: string;
  love: string;
  rocket: string;
  wow: string;
  hands: string;
  incredible: string;
}

export type EmojiNames = keyof ApiEmojis;

export type TimelineBanner = {
  id: number;
  url_image: string;
  action: string | null;
  target: string | null;
  limit_time: number;
};
