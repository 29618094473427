import styled, { css } from 'styled-components';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';

export const Container = styled.div``;

export const VideoList = styled(PerfectScrollbar)<ScrollBarProps>`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
`;

export const SelectVideoButton = styled.button`
  ${() => css`
    flex: 1;
    background: none;
    width: 19.5rem;
    height: 11rem;
    padding: 0;
    transition: 180ms ease-in-out;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    :hover {
      opacity: 0.85;
    }
  `}
`;

export const WidescreenWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    background: ${theme.colors.background[100]};
  `};
`;
