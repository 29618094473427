import { Transition, Variants } from 'framer-motion';

export const DEFAULT_TRANSITION: Transition = {
  type: 'spring',
  duration: 0.5,
};

export const DEFAULT_MODAL_ANIMATION: Variants = {
  hidden: {
    opacity: 0,
    y: -80,
  },
  shown: {
    opacity: 1,
    y: 0,
  },
};
