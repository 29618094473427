import { TrueOrFalse } from '@common/types/api/_common';

export interface SurveyEvent {
  type: 'survey';
  survey: TrueOrFalse;
  data: SurveyEventData;
}

export interface SurveyEventData {
  data: SurveyQuestion[];
}

export interface SurveyQuestion {
  id: number;
  sequence: number;
  asking: string;
  description: null | string;
  required_answer: TrueOrFalse;
  asking_type: SurveyQuestionType;
  status: TrueOrFalse;
  answers: SurveyAnswers;
  rateMin: number;
  rateMax: number;
  minRateDescription: string;
  maxRateDescription: string;
}

export interface SurveyAnswers {
  data: SurveyAnswersData[];
}

export interface SurveyAnswersData {
  id: number;
  survey_asking_id: number;
  answer: string;
}

export enum SurveyQuestionType {
  SINGLE_CHOICE = 1,
  MULTIPLE_CHOICE = 2,
  TEXT = 3,
  RATING = 4,
}
