import { useQuery } from 'react-query';

import { SelectRoomsResponse } from '@common/types/api';
import { api } from '@services';

const fetchSelectRooms = async () => {
  const response = await api.get<SelectRoomsResponse>('/utils/select-rooms');

  return response.data.data;
};

export const useSelectRoomsQuery = (enabled: boolean) =>
  useQuery('select-rooms', fetchSelectRooms, {
    enabled,
    staleTime: Infinity,
  });
