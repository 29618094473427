import { useCallback } from 'react';

import { useChannel, useEvent } from '@harelpls/use-pusher';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import {
  BlockChatEvent,
  BlockedUserEvent,
  ModeratorUserEvent,
  MultipleLoginEvent,
} from '@common/types/pusher/events';
import { useAuth } from '@hooks/useAuth';
import { useStateReducer } from '@hooks/useStateReducer';
import { SettingsData } from '@common/types/api';
import { GamificationRankingEvent } from '@common/types/pusher/events/gamification-ranking';

export const useGlobalEventChannels = () => {
  const queryClient = useQueryClient();
  const { user, updateProfileState } = useAuth();

  const [event, setEvent] = useStateReducer({ type: '', message: '' });

  const gamificationRankingChannel = useChannel('rankingGamification');

  const moderationPermissionChannel = useChannel(
    `auth.${user.uuid}.moderator_user`,
  );

  const blockUserFromChatChannel = useChannel(`auth.${user.uuid}.blocked_chat`);

  const multipleLoginChannel = useChannel(
    `auth.${user.key_auth}.login_multiple`,
  );
  const blockUserFromEventChannel = useChannel(`auth.${user.uuid}.block_user`);

  const handleGamificationRanking = useCallback(
    (response?: GamificationRankingEvent) => {
      if (!response) {
        return;
      }

      const eventSettings =
        queryClient.getQueryData<SettingsData>('event-settings');

      if (eventSettings) {
        queryClient.setQueryData<SettingsData>('event-settings', {
          ...eventSettings,
          gamification_ranking: response.gamificationRanking,
        });
      }
    },
    [queryClient],
  );

  const handleModerationPermission = useCallback(
    (response?: ModeratorUserEvent | BlockChatEvent) => {
      if (!response) {
        return;
      }

      if ('moderator_user' in response) {
        if (response.moderator_user) {
          toast.success(response.data.message, {
            duration: 15000,
          });
        } else {
          toast.error(response.data.message, {
            duration: 15000,
          });
        }

        updateProfileState({
          moderator: response.moderator_user,
        });
      } else {
        if (response.blocked_chat) {
          toast.error(response.data.message, {
            duration: 15000,
          });
        } else {
          toast.success(response.data.message, {
            duration: 15000,
          });
        }

        updateProfileState({
          blocked_chat: response.blocked_chat,
        });
      }
    },
    [updateProfileState],
  );

  const handleLogoutEvents = useCallback(
    (response?: MultipleLoginEvent | BlockedUserEvent) => {
      if (response?.user === user.uuid) {
        setEvent({
          type: response.type,
          message: response.data.message,
        });
      }
    },
    [setEvent, user.uuid],
  );

  useEvent<GamificationRankingEvent>(
    gamificationRankingChannel,
    'GamificationRankingEvent',
    handleGamificationRanking,
  );

  useEvent<ModeratorUserEvent>(
    moderationPermissionChannel,
    'ChatModeratorUserEvent',
    handleModerationPermission,
  );

  useEvent<BlockChatEvent>(
    blockUserFromChatChannel,
    'UserBlockedChatEvent',
    handleModerationPermission,
  );

  useEvent<MultipleLoginEvent>(
    multipleLoginChannel,
    'MultipleLoginEvent',
    handleLogoutEvents,
  );

  useEvent<BlockedUserEvent>(
    blockUserFromEventChannel,
    'UserBlockedEvent',
    handleLogoutEvents,
  );

  return event;
};
