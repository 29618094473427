import { useState } from 'react';

import { FiMaximize2, FiMove, FiXCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Variants } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Plyr } from '@components';
import { useFloatingPlayer } from '@hooks';
import { StreamingOrigin } from '@common/types/api';

import Portal from '../Portal';
import * as S from './styles';

const variants: Variants = {
  hidden: {
    transform: 'scale(0)',
    transition: {
      duration: 0.25,
    },
  },
  shown: {
    transform: 'scale(1)',
    transition: {
      duration: 0.35,
    },
  },
};

const FloatingPlayer = () => {
  const [tooltips, setTooltips] = useState({
    move: {
      visible: false,
      hasBeenVisible: false,
    },
    expand: {
      visible: false,
      hasBeenVisible: false,
    },
    close: {
      visible: false,
      hasBeenVisible: false,
    },
  });

  const { src, roomName, roomSlug, streamingMethod, updateFloatingPlayer } =
    useFloatingPlayer();
  const { t } = useTranslation();

  const handleClose = () => updateFloatingPlayer({ isVisible: false });

  const paramChar = src.includes('?') ? '&' : '?';
  const source = `${src}${paramChar}autoplay=1&modestbranding=1`;

  const handleTooltip = (tooltip: keyof typeof tooltips) => {
    const currentTooltip = tooltips[tooltip];
    const shouldNotUpdateState =
      currentTooltip.hasBeenVisible && !currentTooltip.visible;

    return () => {
      if (shouldNotUpdateState) {
        return;
      }

      setTooltips((prevState) => {
        const selectedTooltip = prevState[tooltip];

        return {
          ...prevState,
          [tooltip]: {
            visible: !selectedTooltip.hasBeenVisible,
            hasBeenVisible: true,
          },
        };
      });
    };
  };
  return (
    <Portal rootId="floating-player">
      <S.Container
        drag
        dragConstraints={{
          top: -window.innerHeight + 420,
          right: 20,
          bottom: 20,
          left: -window.innerWidth + 640,
        }}
        dragMomentum={false}
        variants={variants}
        initial="hidden"
        animate="shown"
        exit="hidden"
      >
        <S.ControlsWrapper>
          <h5>{roomName}</h5>

          <div onMouseEnter={handleTooltip('move')}>
            <FiMove size="2rem" />
            {t('button.move')}
          </div>

          <Link
            to={`/broadcast/${roomSlug}`}
            onMouseEnter={handleTooltip('expand')}
          >
            <FiMaximize2 size="2rem" />
            {t('button.expand')}
          </Link>

          <button
            type="button"
            onClick={handleClose}
            onMouseEnter={handleTooltip('close')}
          >
            <FiXCircle size="2rem" />
            {t('button.close')}
          </button>
        </S.ControlsWrapper>
        {streamingMethod === StreamingOrigin.YOUTUBE ? (
          <Plyr src={src} />
        ) : (
          <iframe
            id="floating-player"
            title="Streaming"
            src={source}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </S.Container>
    </Portal>
  );
};

export default FloatingPlayer;
