import {
  DispatchWithoutAction,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { HTMLMotionProps } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { FiUser, FiPhone, FiMail, FiLock } from 'react-icons/fi';

import { AvatarInput, Button, Input, SelectCustom } from '@components';
import { useAuth, useEventfy, useToggle } from '@hooks';
import { UserUpdateProfileRequest } from '@common/types/api';
import { SELECT_OPTIONS } from '@pages/Auth/SignUp/options';
import { DEFAULT_MODAL_ANIMATION, DEFAULT_TRANSITION } from '@constants';

import * as S from './styles';
import { updateSchemas } from './schemas';

type UpdateProfileFields = UserUpdateProfileRequest & {
  current_password: string;
  password_confirmation: string;
};

type UserProfileModalProps = PropsWithChildren<HTMLMotionProps<'dialog'>> & {
  handleClose: DispatchWithoutAction;
};

const UserProfileModal = (
  props: UserProfileModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
  // eslint-disable-next-line radar/cognitive-complexity
) => {
  const event = useEventfy();
  const { user, updateUser, deleteUser } = useAuth();
  const { t } = useTranslation();

  const formMethods = useForm<UserUpdateProfileRequest>({
    resolver: yupResolver(updateSchemas[event.access_type]),
    defaultValues: {
      name: user.name,
      phone: user.phone || undefined,
      district: user.district || undefined,
      city: user.city || undefined,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState(user.avatar);

  const [userWantsToDeleteAccount, toggleUserWantsToDeleteAccount] =
    useToggle(false);

  const { children, handleClose, ...attrs } = props;

  const handleUpdateUser = async (fields: UpdateProfileFields) => {
    const {
      password_confirmation: _,
      current_password: _Ignored,
      ...credentials
    } = fields;

    if (!credentials.password) {
      delete credentials.password;
    }

    try {
      setIsLoading(true);
      await updateUser(credentials);
      const message = t('modal.profile.success') || 'Atualizado com sucesso';
      toast.success(message);

      handleClose();
    } catch (err) {
      const message = t('modal.profile.fail') || 'Falha ao atualizar';
      toast.error(message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (userWantsToDeleteAccount) {
      timeout = setTimeout(toggleUserWantsToDeleteAccount, 3500);
    }

    return () => clearInterval(timeout);
  }, [userWantsToDeleteAccount, toggleUserWantsToDeleteAccount]);

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      {...attrs}
    >
      {children}

      <FormProvider {...formMethods}>
        <S.Form
          component="form"
          onSubmit={formMethods.handleSubmit(handleUpdateUser)}
        >
          <AvatarInput src={avatar} updateSrc={setAvatar} />
          <S.FlexWrapper $break>
            <Input
              name="name"
              placeholder={t('input.name')}
              autoComplete="off"
              icon={FiUser}
              required
            />

            <Input
              name="phone"
              type="tel"
              mask="phone"
              placeholder={t('input.phone')}
              inputMode="tel"
              icon={FiPhone}
              required
            />
          </S.FlexWrapper>
          <Input
            name="email"
            type="email"
            placeholder="E-mail"
            defaultValue={user.email}
            inputMode="email"
            icon={FiMail}
            disabled
            required
          />

          {+event.access_type === 1 && (
            <>
              <Input
                id="current_password"
                name="current_password"
                type="password"
                placeholder={t('input.currentPassword')}
                autoComplete="new-password"
                icon={FiLock}
              />

              <Input
                id="new-password"
                name="password"
                type="password"
                placeholder={t('input.newPassword')}
                icon={FiLock}
              />

              <Input
                name="password_confirmation"
                type="password"
                placeholder={t('input.passwordRetry')}
                icon={FiLock}
              />
            </>
          )}
          <S.FlexWrapper $maxWidth={80} $break>
            <Input name="city" placeholder={t('input.city')} />

            <SelectCustom
              name="district"
              placeholder={t('input.state')}
              options={SELECT_OPTIONS}
            />
          </S.FlexWrapper>

          <S.FlexWrapper>
            {userWantsToDeleteAccount ? (
              <Button
                type="button"
                variant="danger"
                title={
                  t('button.confirmDelete') || 'Confirmar Exclusão de Conta'
                }
                onClick={deleteUser}
              />
            ) : (
              <Button
                type="button"
                variant="outline"
                title={t('button.deleteAccount') || 'Excluir Conta'}
                onClick={toggleUserWantsToDeleteAccount}
              />
            )}

            <Button
              title={t('button.updateProfile') || 'Atualizar Perfil'}
              type="submit"
              loading={isLoading}
            />
          </S.FlexWrapper>
        </S.Form>
      </FormProvider>
    </S.Container>
  );
};

export default forwardRef(UserProfileModal);
