import { useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { ONE_HOUR } from '@constants';
import { FullScheduleData, FullScheduleResult } from '@common/types/api';
import { isValidObject } from '@utils';

import { fetchSchedule } from '../useLobbyQueries/queries';

type ScheduleFilters = {
  date?: string;
  roomId?: number;
};

export const useScheduleQuery = (page = 1, filters: ScheduleFilters) => {
  const queryClient = useQueryClient();

  const sanitizedFilters = isValidObject(filters) ? filters : undefined;

  return useQuery<FullScheduleResult, AxiosError>(
    ['schedule', page, sanitizedFilters],
    fetchSchedule,
    {
      staleTime: ONE_HOUR,
      onSuccess: ({ schedule }) => {
        schedule.map((session) =>
          queryClient.setQueryData<FullScheduleData>(
            ['session', session.id],
            session,
          ),
        );
      },
    },
  );
};
