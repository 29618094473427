import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { FiSend } from 'react-icons/fi';
import { useVirtual } from 'react-virtual';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Nullable } from '@common/types';
import { ChatMessage } from '@common/types/chat-socket';
import { useAuth, useBroadcastQuery, useChatMessages } from '@hooks';
import { selectUser } from '@hooks/useAuth/selectors';
import { CHAT_CLIENT_ID } from '@constants';

import { BaseInteractionProps } from '../../types';
import Message from './Message';
import * as S from './styles';
import * as I from '../styles';

type FormInputs = {
  message: string;
};

const Chat = ({ active: isActive }: BaseInteractionProps) => {
  const user = useAuth(selectUser);
  const { t } = useTranslation();

  const { data: isChatEnabled } = useBroadcastQuery({
    select: ({ settings }) => !!settings.data.chat,
    notifyOnChangeProps: ['data'],
  });

  const { messages, groupId, groupKey, socket } = useChatMessages();

  const formMethods = useForm<FormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        message: Yup.string().required('valid.message.req'),
      }),
    ),
  });
  const { handleSubmit, reset } = formMethods;

  const [hasUserScrolled, setHasUserScrolled] = useState(false);

  const inputPlaceholder = user.blocked_chat
    ? t('pages.broadcast.chat.blocked')
    : t('pages.broadcast.chat.send');

  const parentRef = useRef<Nullable<HTMLElement>>(null);

  const rowVirtualizer = useVirtual({
    size: messages.length,
    parentRef,
    estimateSize: useCallback(() => 65, []),
    keyExtractor: useCallback((index) => messages[index].id, [messages]),
  });
  const { virtualItems, scrollToOffset, totalSize } = rowVirtualizer;

  const handleSendNewMessage = ({ message }: FormInputs) => {
    reset();

    socket?.emit('chat.message', {
      client_id: CHAT_CLIENT_ID,
      group_id: groupId,
      group_key: groupKey,
      author_id: `${user.id}`,
      author_name: user.name,
      author_avatar: user.avatar,
      moderator: !!user.moderator,
      fixed: false,
      content: message,
    } as ChatMessage);

    scrollToOffset(totalSize, {
      align: 'end',
    });

    setHasUserScrolled(false);
  };

  const handleContainerRef = useCallback((node: HTMLElement) => {
    if (node) {
      parentRef.current = node;
    }
  }, []);

  const handleChatListScroll = useCallback(
    (hasScrolled: boolean) => () => setHasUserScrolled(hasScrolled),
    [],
  );

  useEffect(() => {
    if (hasUserScrolled) {
      return;
    }

    scrollToOffset(totalSize, {
      align: 'end',
    });
  }, [messages, hasUserScrolled, scrollToOffset, totalSize]);

  return (
    <S.Container
      autoComplete="off"
      onSubmit={handleSubmit(handleSendNewMessage)}
      $disabled={!isActive}
      $hide={!isChatEnabled}
    >
      <FormProvider {...formMethods}>
        <I.InteractionWrapper>
          <h3>{t('pages.broadcast.chat.title')}</h3>

          <S.ChatContentWrapper
            containerRef={handleContainerRef}
            onScroll={handleChatListScroll(true)}
            onYReachEnd={handleChatListScroll(false)}
          >
            <I.VirtualizedList $height={totalSize}>
              {virtualItems.map((virtualRow) => (
                <I.VirtualizedItemWrapper
                  key={messages[virtualRow.index].id}
                  ref={virtualRow.measureRef}
                  $translateY={virtualRow.start}
                >
                  <Message
                    socket={socket}
                    groupId={groupId}
                    groupKey={groupKey}
                    {...messages[virtualRow.index]}
                  />
                </I.VirtualizedItemWrapper>
              ))}
            </I.VirtualizedList>
          </S.ChatContentWrapper>
        </I.InteractionWrapper>

        <S.ChatInput
          required
          type="text"
          name="message"
          buttonIcon={FiSend}
          autoFocus={isActive}
          placeholder={inputPlaceholder}
          disabled={!!user.blocked_chat || !isChatEnabled}
        />
      </FormProvider>
    </S.Container>
  );
};

export default memo(Chat);
