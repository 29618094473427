import { useMemo } from 'react';

import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import parseISO from 'date-fns/parseISO';
import { enUS, ptBR } from 'date-fns/locale';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@components';
import { NewsfeedPostWithoutComments } from '@common/types/api';

import * as S from './styles';

type PostPreviewProps = NewsfeedPostWithoutComments;

const PostPreview = (props: PostPreviewProps) => {
  const { user: userData, created_at, picture } = props;
  const user = userData.data;
  const { t, i18n } = useTranslation();

  const [article, postType] = (
    picture ? t('pages.lobby.newsfeedPhoto') : t('pages.lobby.newsfeedPost')
  ).split(' ');

  const date = useMemo(
    () =>
      formatDistanceToNowStrict(parseISO(created_at), {
        locale: enUS,
        addSuffix: true,
      }),
    [created_at],
  );
  const dateBr = useMemo(
    () =>
      formatDistanceToNowStrict(parseISO(created_at), {
        locale: ptBR,
        addSuffix: true,
      }),
    [created_at],
  );
  return (
    <S.Container>
      <Link to="/newsfeed">
        <Avatar src={user.avatar} alt={user.name} />

        <div>
          <p>
            <strong>{user.name.split(' ')[0]}</strong>
            {t('pages.lobby.newsfeedPosted')} {`${article} `}
            <strong>{postType}</strong>
          </p>
          <time>{i18n.language === 'en' ? date : dateBr}</time>
        </div>

        <FiChevronRight size="1.5rem" />
      </Link>
    </S.Container>
  );
};

export default PostPreview;
