import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    align-self: center;
    position: relative;

    > button {
      position: absolute;
      z-index: 2;
      top: -25%;
      right: -25%;
      border-radius: 50%;
      padding: 0.25rem;
    }

    label {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 3rem;
      height: 3rem;
      border-radius: 0.5rem;
      overflow: hidden;
      cursor: pointer;
      transition: 180ms ease-in-out;

      img {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        transition: 180ms ease-in-out;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025),
          0 2px 2px rgba(0, 0, 0, 0.02), 0 4px 4px rgba(0, 0, 0, 0.015),
          0 8px 8px rgba(0, 0, 0, 0.01), 0 16px 16px rgba(0, 0, 0, 0.005);

        :hover {
          opacity: 0.85;
          transform: scale(1.025);
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023),
            0 2px 2px rgba(0, 0, 0, 0.023), 0 4px 4px rgba(0, 0, 0, 0.023),
            0 8px 8px rgba(0, 0, 0, 0.023), 0 16px 16px rgba(0, 0, 0, 0.023),
            0 32px 32px rgba(0, 0, 0, 0.023);
        }
      }

      input {
        display: none;
      }

      :active {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047),
          0 2px 2px rgba(0, 0, 0, 0.047), 0 4px 4px rgba(0, 0, 0, 0.047);
      }
    }
  `};
`;
