import { QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { PusherProvider } from '@harelpls/use-pusher';

import { queryClient } from '@services';
import { PropsWithRequiredChildren } from '@common/types';
import { PUSHER_CONFIG } from '@constants';

import { EventfyThemeProvider } from './theme';
import { AuthProvider } from './auth';
import { AxiosInterceptor } from './axios';
import { FloatingPlayerProvider } from './floating-player';

const AppProviders = ({ children }: PropsWithRequiredChildren) => (
  <AuthProvider>
    <AxiosInterceptor>
      <FloatingPlayerProvider>{children}</FloatingPlayerProvider>
    </AxiosInterceptor>
  </AuthProvider>
);

export const RootProviders = ({ children }: PropsWithRequiredChildren) => (
  <PusherProvider {...PUSHER_CONFIG}>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <EventfyThemeProvider>
          <FloatingPlayerProvider>{children}</FloatingPlayerProvider>
        </EventfyThemeProvider>
      </HelmetProvider>
    </QueryClientProvider>
  </PusherProvider>
);

export default AppProviders;
