import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { PerfectScrollbar } from '@components';

export const Container = styled(motion.dialog)`
  ${({ theme }) => css`
    margin-left: auto;
    width: 100%;
    max-width: 25rem;
    height: 100%;
    background: ${theme.colors.background[100]};

    box-shadow: 0 1px 1px rgba(5, 137, 94, 0.075),
      0 2px 2px rgba(5, 137, 94, 0.075), 0 4px 4px rgba(5, 137, 94, 0.075),
      0 8px 8px rgba(5, 137, 94, 0.075), 0 16px 16px rgba(5, 137, 94, 0.075),
      0 32px 32px rgba(5, 137, 94, 0.075);
  `}
`;

export const Wrapper = styled(PerfectScrollbar)`
  ${() => css`
    padding: 3rem 2rem;
    width: 100%
    height: 100%;
    position: relative;
  `}
`;

export const ScheduleInfoHeader = styled.header`
  ${() => css`
    > div {
      display: flex;
      align-items: center;

      svg {
        color: #30c161;
        margin-right: 1rem;
      }

      span {
        font-weight: 500;
      }
    }

    > h2 {
      margin: 1.5rem 0 0.25rem;
    }

    > time {
      font-size: 0.75rem;
    }

    + section {
      margin-top: 2rem;
    }
  `}
`;

export const Calendar = styled.div`
  margin: 0.95rem 0;

  .react-add-to-calendar__button {
    border-radius: 0.75rem;
    padding: 6px;
    color: #fff;
    font-size: 0.75rem;
    background: #2c3f5e;
  }
  .react-add-to-calendar__dropdown ul li a {
    color: #333;
    font-size: 0.75rem;
    text-decoration: none;
  }
`;

export const SubTitle = styled.h3`
  ${() => css`
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin-bottom: 1rem;
  `}
`;

export const ScheduleInfoSection = styled.section`
  p {
    margin: 0.75rem 0;
    font-size: 0.875rem;
  }

  + section {
    margin-top: 2rem;
  }
`;

export const ScheduleInfoRoomLink = styled(Link)`
  ${() => css`
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0.25rem 0.75rem;
    border: 2px solid #30c161;
    border-radius: 1000px;
    font-size: 0.75rem;
    color: #30c161;

    > svg {
      margin-right: 0.5rem;
    }
  `}
`;
