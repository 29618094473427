import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { SettingsData, SettingsResponse } from '@common/types/api';
import { SETTINGS_ENDPOINT } from '@constants/endpoints';
import { api } from '@services';
import { eventfyStorage } from '@services';
import { TWENTY_FOUR_HOURS } from '@constants';

export const fetchEventData = async () => {
  const { data } = await api.get<SettingsResponse>(
    `${SETTINGS_ENDPOINT}?include=custom`,
  );

  return data.data;
};

export const useEventQuery = () =>
  useQuery<SettingsData, AxiosError>('event-settings', fetchEventData, {
    cacheTime: TWENTY_FOUR_HOURS,
    staleTime: TWENTY_FOUR_HOURS,
    placeholderData: () => eventfyStorage.getItem<SettingsData>('event'),
    onSuccess: (data) => eventfyStorage.setItem('event', data),
  });
