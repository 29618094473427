import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 1rem;
    font-size: 0.875rem;
    color: ${theme.colors.background[60]};
    background: ${theme.colors.background[100]};
    border-radius: 1rem 1rem 0 0;
    border: 1px solid ${theme.colors.base.border};
  `}
`;

export const AutosizeTextarea = styled(TextareaAutosize)`
  ${({ theme }) => css`
    width: 100%;
    resize: none;
    margin-bottom: 1rem;
    background: ${theme.colors.background[100]};

    ::placeholder {
      font-weight: 300;
      color: ${theme.colors.background[40]};
    }
  `}
`;
