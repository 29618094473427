export interface RoomAskingsResponse {
  data: RoomAskingsData[];
}

export interface RoomAskingsData {
  id: number;
  room_id: number;
  user_id: number;
  asking: string;
  answer: null | string;
  status: RoomAskingStatus;
  created_at: string;
  updated_at: string;
}

export enum RoomAskingStatus {
  NOT_ANSWERED = 0,
  ANSWERED = 1,
  ANSWERED_LIVE = 2,
}
