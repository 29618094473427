import { useEffect } from 'react';

import {
  shade,
  desaturate,
  tint,
  readableColor,
  transparentize,
} from 'polished';

import { SettingsData } from '@common/types/api';

export const useEventColorsEffect = (eventSettings?: SettingsData) => {
  useEffect(() => {
    if (eventSettings) {
      const root = document.documentElement;
      const { primary_color, secondary_color } = eventSettings;

      root.style.setProperty('--colors-brand-primary', primary_color);
      root.style.setProperty(
        '--colors-brand-primary-hover',
        tint(0.05, primary_color),
      );
      root.style.setProperty(
        '--colors-brand-primary-active',
        shade(0.05, primary_color),
      );
      root.style.setProperty(
        '--colors-brand-primary-disabled',
        desaturate(0.7, primary_color),
      );
      root.style.setProperty(
        '--colors-brand-primary-opaque',
        transparentize(0.86, primary_color),
      );
      root.style.setProperty(
        '--colors-brand-primary-text',
        readableColor(primary_color),
      );

      root.style.setProperty('--colors-brand-secondary', secondary_color);
      root.style.setProperty(
        '--colors-brand-secondary-hover',
        tint(0.05, secondary_color),
      );
      root.style.setProperty(
        '--colors-brand-secondary-active',
        shade(0.05, secondary_color),
      );
      root.style.setProperty(
        '--colors-brand-secondary-disabled',
        desaturate(0.7, secondary_color),
      );
      root.style.setProperty(
        '--colors-brand-secondary-opaque',
        transparentize(0.86, secondary_color),
      );
      root.style.setProperty(
        '--colors-brand-secondary-text',
        readableColor(secondary_color),
      );
    }
  }, [eventSettings]);
};
