import { memo, useMemo } from 'react';

import getVideoId from 'get-video-id';

import { VideosData } from '@common/types/api';

import * as S from './styles';

type ListVideosProps = {
  video: VideosData;
};

const VideoPlayer = ({ video }: ListVideosProps) => {
  const currentVideo = useMemo(() => {
    const { id, service } = getVideoId(video.url_video);

    let src = video.url_video;

    if (service === 'youtube') {
      src = `https://www.youtube.com/embed/${id}`;
    }

    return { src, title: video.title, description: video.description };
  }, [video]);

  return (
    <S.Container>
      <S.WidescreenWrapper>
        <iframe
          title={currentVideo.title}
          src={currentVideo.src}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </S.WidescreenWrapper>
      <S.VideoDescription>
        <section>
          <h3>{currentVideo.title}</h3>
          <div>{currentVideo.description}</div>
        </section>
      </S.VideoDescription>
    </S.Container>
  );
};

export default memo(VideoPlayer);
