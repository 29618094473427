import { useEffect, RefObject } from 'react';

import { useToggle } from '../useToggle';

type ToggleOptions = { handleInsideClick: boolean };

export const useToggleOutsideClick = (
  el: RefObject<HTMLElement>,
  initialState: boolean,
  options?: ToggleOptions,
) => {
  const toggler = useToggle(initialState);
  const [isActive, toggleIsActive] = toggler;

  const { handleInsideClick = false } = options || {};

  useEffect(() => {
    const pageClickEvent = (e: MouseEvent) => {
      if (handleInsideClick || !el.current?.contains(e.target as Node)) {
        toggleIsActive();
      }
    };

    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [el, isActive, toggleIsActive, handleInsideClick]);

  return toggler;
};
