import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.article`
  height: 100%;
`;

export const SponsorsHeader = styled.header`
  ${({ theme }) => css`
    padding: 1.5rem;
    padding-left: 0;
    border-radius: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.75rem;
    background: ${theme.colors.brand.secondary.opaque};
    background: linear-gradient(
      95deg,
      rgba(193, 48, 48, 0) 15%,
      ${theme.colors.brand.secondary.opaque} 75%,
      ${theme.colors.brand.secondary.opaque} 100%
    );

    > div {
      span {
        color: ${theme.colors.brand.secondary.default};
      }

      h1 {
        font-size: 3rem;
        color: #2c3f5e;
      }
    }

    > svg {
      max-height: 10rem;
      color: ${theme.colors.brand.secondary.default};
    }

    ${down('md')} {
      > svg {
        display: none;
      }
    }
  `}
`;

export const ListWrapper = styled.ul`
  display: flex;
  row-gap: 2rem;
  column-gap: 1.5rem;
  flex-wrap: wrap;
`;
