import { FiChevronRight } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import * as S from '../styles';

const PostPreviewLoading = () => (
  <S.Container>
    <Link to="/newsfeed">
      <Skeleton width="3rem" height="3rem" />

      <div>
        <p>
          <Skeleton width="92%" height="1rem" />
        </p>
        <Skeleton width="32%" height="1rem" />
      </div>

      <FiChevronRight size="1.5rem" />
    </Link>
  </S.Container>
);

export default PostPreviewLoading;
