import { useQuery } from 'react-query';

import { api } from '@services';
import { ONE_HOUR } from '@constants';

const fetchScheduleDates = async () => {
  const response = await api.get<{ data: Record<'value' | 'label', string>[] }>(
    '/utils/select-dates-schedules',
  );

  return response.data.data;
};

export const useSelectScheduleDatesQuery = () =>
  useQuery('schedule-dates', fetchScheduleDates, {
    staleTime: ONE_HOUR,
  });
