import { memo } from 'react';

import { Speaker } from '@components';
import { useBroadcastQuery } from '@hooks';

import * as S from './styles';

const RoomSpeakers = () => {
  const { data: speakers } = useBroadcastQuery({
    select: ({ participants }) => participants.data,
    notifyOnChangeProps: 'tracked',
  });

  return (
    <S.Container component="ul">
      <>
        {speakers?.map((speaker) => (
          <Speaker key={speaker.id} data={speaker} />
        ))}
      </>
    </S.Container>
  );
};

export default memo(RoomSpeakers);
