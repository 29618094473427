import { ButtonHTMLAttributes, ForwardedRef, forwardRef, useMemo } from 'react';

import { LinkProps } from 'react-router-dom';
import { GooSpinner } from 'react-spinners-kit';

import * as S from './styles';
import { BaseButtonProps, ButtonWithIconProps } from './types';

type ButtonTypes = ButtonHTMLAttributes<HTMLButtonElement> | LinkProps;

export type ButtonProps = (BaseButtonProps | ButtonWithIconProps) & ButtonTypes;

const DEFAULT_CLICK_HANDLER = () => null;

const Button = (props: ButtonProps, ref: ForwardedRef<Element>) => {
  const {
    title,
    onClick,
    size = 'default',
    variant = 'primary',
    loading: isLoading = false,
    fullWidth = false,
    pill = false,
    icon: Icon,
    iconSize,
    ...attrs
  } = props;

  const onClickHandler = isLoading ? DEFAULT_CLICK_HANDLER : onClick;

  const buttonIconSize = size === 'default' ? '1.125rem' : '1.25rem';
  const resolvedIconSize = iconSize || buttonIconSize;

  attrs.type = attrs.type || 'button';
  attrs.as && delete attrs.type;

  const content = useMemo(() => {
    if (isLoading) {
      return <GooSpinner size={1.4375} sizeUnit="rem" color="white" />;
    }

    if (Icon) {
      return (
        <>
          {typeof Icon === 'function' ? <Icon size={resolvedIconSize} /> : Icon}
          {title && <span>{title}</span>}
        </>
      );
    }

    return title;
  }, [isLoading, Icon, title, resolvedIconSize]);

  return (
    <S.Container
      ref={ref}
      $variant={variant}
      $size={size}
      $pill={pill}
      $fullWidth={fullWidth}
      onClick={onClickHandler}
      {...attrs}
    >
      {content}
    </S.Container>
  );
};

export default forwardRef(Button);
