import { useQuery } from 'react-query';

import { api } from '@services';

type TermsResponse = { data: { terms: string | null } };

const fetchTerms = async () => {
  const response = await api.get<TermsResponse>('/settings/terms');

  return response.data.data.terms;
};

export const useEventTermsQuery = () =>
  useQuery('event-terms', fetchTerms, {
    staleTime: Infinity,
  });
