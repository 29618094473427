import { QueryFunctionContext, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { api } from '@services';
import {
  EmojiStatisticsData,
  EmojiStatisticsResponse,
} from '@common/types/api';
import { ONE_MINUTE } from '@constants';
import { PromiseWithCancel } from '@common/types';
import { cancelToken } from '@services/api';

const fetchEmojiData = (ctx: QueryFunctionContext<[string, number]>) => {
  const [, roomId] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<EmojiStatisticsResponse>(`/rooms/${roomId}/emojis`, {
      cancelToken: source.token,
    })
    .then(({ data }) => data.data) as PromiseWithCancel<EmojiStatisticsData>;

  promise.cancel = () => source.cancel('useEmojiQuery Cancelled');

  return promise;
};

export const useEmojiQuery = (roomId?: number) =>
  useQuery<EmojiStatisticsData, AxiosError>(['emoji', roomId], fetchEmojiData, {
    enabled: !!roomId,
    refetchInterval: ONE_MINUTE * 5,
  });
