import { memo } from 'react';

import { Plyr } from '@components';
import { StreamingOrigin } from '@common/types/api';

import * as S from './styles';

type PlayerProps = {
  src?: string | null;
  thumb?: string | null;
  streamingMethod: StreamingOrigin;
};

const Player = ({ src, thumb, streamingMethod }: PlayerProps) => {
  if (!src) {
    return thumb ? <img src={thumb} alt="Room Thumbnail" /> : null;
  }

  const paramChar = src.includes('?') ? '&' : '?';
  const source = `${src}${paramChar}autoplay=1&modestbranding=1`;

  return (
    <>
      {streamingMethod === StreamingOrigin.YOUTUBE ? (
        <Plyr src={src} />
      ) : (
        <S.PlayerWrapper>
          <iframe
            title="Streaming"
            src={source}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </S.PlayerWrapper>
      )}
    </>
  );
};

export default memo(Player);

Player.defaultProps = {
  src: '',
  thumb: '',
};
