import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { ONE_HOUR } from '@constants';
import { VideosResult } from '@common/types/api';

import { fetchVideos } from '../useLobbyQueries/queries';
import { useEventfy } from '../useEventfy';
import { selectHasVideos } from '../useEventfy/selectors';

export const useVideosQuery = (page = 1) => {
  const eventHasVideos = useEventfy(selectHasVideos);

  return useQuery<VideosResult, AxiosError>(['videos', page], fetchVideos, {
    enabled: eventHasVideos,
    staleTime: ONE_HOUR * 24,
  });
};
