import { memo } from 'react';

import { FaCrown } from 'react-icons/fa';

import { GamificationRankingData } from '@common/types/api';
import { Avatar } from '@components';

import * as S from './styles';

type RankingCardProps = GamificationRankingData & {
  position: number;
};

const RankingCard = (props: RankingCardProps) => {
  const { info_user, total, user, position } = props;
  const { avatar, name } = user.data;

  const isPodiumPosition = position <= 3;

  return (
    <S.Container>
      <S.ProfileData>
        <strong>{`${position}`.padStart(2, '0')}.</strong>

        <S.ImageWrapper $position={isPodiumPosition ? position : undefined}>
          <Avatar src={avatar} alt={name} />
          {isPodiumPosition && <FaCrown size="2rem" />}
        </S.ImageWrapper>

        <div>
          <strong>{name}</strong>
          <span>{info_user}</span>
        </div>
      </S.ProfileData>

      <span>{`${total} pts`}</span>
    </S.Container>
  );
};

export default memo<RankingCardProps>(
  RankingCard,
  (prevProps, nextProps) =>
    prevProps.user_id === nextProps.user_id &&
    prevProps.total === nextProps.total &&
    prevProps.position === nextProps.position,
);
