import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    padding-top: 1rem;

    > ul {
      margin-top: 1rem;
    }
  `}
`;

export const LoadingWrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    overflow: hidden;
  `}
`;
