import * as Yup from 'yup';

import { BRAZILIAN_STATES } from '@constants';

const updateShape = {
  name: Yup.string().required('Preencha com seu nome completo'),
  email: Yup.string(),
  phone: Yup.string(),
  city: Yup.string(),
  district: Yup.string()
    .notRequired()
    .test('district', 'Estado Inválido', (district) => {
      if (!district) {
        return true;
      }

      const schema = Yup.string().oneOf(Object.keys(BRAZILIAN_STATES));
      return schema.isValidSync(district);
    }),
};

export const updateSchemas = {
  1: Yup.object().shape({
    ...updateShape,
    current_password: Yup.string().test(
      'current_password',
      'Senha tem no mínimo 6 caracteres',
      (password) => {
        if (!password) {
          return true;
        }

        const schema = Yup.string().min(6);
        return schema.isValidSync(password);
      },
    ),
    password: Yup.string().when('current_password', {
      is: (current_password: string) => current_password.length >= 6,
      then: Yup.string().min(6, 'Preencha sua nova senha'),
      otherwise: Yup.string(),
    }),
    password_confirmation: Yup.string()
      .when('current_password', {
        is: (current_password: string) => current_password.length >= 6,
        then: Yup.string().min(6, 'Preencha a confirmação de sua nova senha'),
        otherwise: Yup.string(),
      })
      .oneOf(
        [Yup.ref('password'), null],
        'Confirmação de nova senha incorreta',
      ),
  }),
  2: Yup.object().shape(updateShape),
};
