/* eslint-disable react/no-danger */
import { useMemo, useState, HtmlHTMLAttributes } from 'react';

import { formatDistanceToNowStrict, parseISO } from 'date-fns/esm';
import { ptBR, enUS } from 'date-fns/locale';
import { GooSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';
import { FiMessageCircle, FiMoreVertical, FiThumbsUp } from 'react-icons/fi';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import { NewsfeedPostWithoutComments } from '@common/types/api';
import { Avatar, Button } from '@components';
import { useAuth, useDeletePostMutation, usePostLikeMutation } from '@hooks';
import { selectUser } from '@hooks/useAuth/selectors';

import CommentSection from './CommentSection';
import * as S from './styles';

type RoomCardProps = HtmlHTMLAttributes<HTMLLIElement> & {
  data: NewsfeedPostWithoutComments;
};

const Post = (props: RoomCardProps) => {
  const theme = useTheme();
  const currentUser = useAuth(selectUser);

  const { data, ...attrs } = props;

  const [isCommentSectionVisible, setIsCommentSectionVisible] = useState(false);

  const {
    id,
    message,
    picture,
    likes_total,
    likes,
    comments_total,
    created_at,
  } = data;
  const user = data.user.data;
  const { t } = useTranslation();

  const [article, postType] = (
    picture ? t('pages.lobby.newsfeedPhoto') : t('pages.lobby.newsfeedPost')
  ).split(' ');

  const userHasVoted = likes.data.some(
    ({ user_id }) => user_id === currentUser.id,
  );

  const { mutate, isLoading: isMutatingLikes } = usePostLikeMutation(
    id,
    !!userHasVoted,
  );

  const { mutate: deletePost, isLoading: isDeletingPost } =
    useDeletePostMutation(id);

  const shouldDisplayNewCommentInput =
    data.user_id !== currentUser.id && comments_total === 0 && !picture;

  const shouldDisplayModerationMenu =
    data.user_id === currentUser.id || !!currentUser.moderator;
  const date = useMemo(
    () =>
      formatDistanceToNowStrict(parseISO(created_at), {
        locale: enUS,
        addSuffix: true,
      }),
    [created_at],
  );

  const handleCommentSectionVisibility = () => setIsCommentSectionVisible(true);

  return (
    <S.Container
      $active
      $loading={false}
      $deleting={isDeletingPost}
      $moderator={!!user.moderator}
      {...attrs}
    >
      <S.PostHeader>
        <Avatar src={user.avatar} alt={user.name} />

        <div>
          <h3>
            <strong>{user.name}</strong> {t('pages.newsfeed.posted')}
            {`${article} `}
            <strong>{postType}</strong>
          </h3>
          <time>{date}</time>
          {!!user.moderator && <span>{t('pages.newsfeed.moderator')}</span>}
        </div>

        {shouldDisplayModerationMenu && (
          <S.ModeratorOptionsContainer>
            <Tippy
              trigger="click"
              inertia
              hideOnClick
              interactive
              theme="light-border"
              animation="shift-away-subtle"
              zIndex={4}
              placement="bottom-end"
              interactiveBorder={12}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              appendTo={document.getElementById('tippy-portal')!}
              content={
                <>
                  <S.ModeratorOptionsButton
                    type="button"
                    onClick={() => deletePost()}
                    disabled={isDeletingPost}
                  >
                    {t('pages.newsfeed.remove')} post
                  </S.ModeratorOptionsButton>
                </>
              }
            >
              <Button size="even" variant="transparent" icon={FiMoreVertical} />
            </Tippy>
          </S.ModeratorOptionsContainer>
        )}
      </S.PostHeader>

      <S.PostMain>
        <div>
          {message
            .split('\n')
            .filter((content) => !!content)
            .map((paragraph) => (
              <p key={`paragraph-post-${id}-${paragraph.substr(0, 15)}`}>
                {paragraph}
              </p>
            ))}
        </div>

        {picture && (
          <S.PostImageWrapper>
            <img src={picture} alt={message} />
          </S.PostImageWrapper>
        )}
      </S.PostMain>

      <S.PostFooter>
        <section>
          <span>
            {`${likes_total} ${t('pages.newsfeed.like')}${
              likes_total !== 1 ? 's' : ''
            }`}

            {isMutatingLikes && (
              <div>
                <GooSpinner
                  size={1.25}
                  sizeUnit="rem"
                  color={theme.colors.brand.primary.default}
                />
              </div>
            )}
          </span>

          <S.InlineButton
            type="button"
            onClick={handleCommentSectionVisibility}
          >
            {`${comments_total} ${t('pages.newsfeed.comment')}${
              comments_total !== 1 ? 's' : ''
            }`}
          </S.InlineButton>
        </section>

        <S.PostFooterActions
          $commentsVisible={
            shouldDisplayNewCommentInput || isCommentSectionVisible
          }
          $voted={userHasVoted}
        >
          <button
            type="button"
            onClick={() => mutate()}
            disabled={isMutatingLikes}
          >
            <FiThumbsUp size="1.25rem" rotate={45} />
            {userHasVoted
              ? t('pages.newsfeed.deslike')
              : t('pages.newsfeed.like')}
          </button>

          <button type="button" onClick={handleCommentSectionVisibility}>
            <FiMessageCircle size="1.25rem" />
            {t('pages.newsfeed.comment')}
          </button>
        </S.PostFooterActions>
      </S.PostFooter>

      <CommentSection
        postId={id}
        visible={isCommentSectionVisible}
        inputVisible={shouldDisplayNewCommentInput}
      />
    </S.Container>
  );
};

export default Post;
