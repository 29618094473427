import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { QueryFunctionContext } from 'react-query';

import {
  RoomQuizData,
  RoomQuizResponse,
  RoomQuizResult,
} from '@common/types/api';
import { api } from '@services';
import { cancelToken } from '@services/api';
import { PromiseWithCancel } from '@common/types';

const fetchQuiz = (ctx: QueryFunctionContext<[string, number]>) => {
  const [, roomId] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<RoomQuizResponse>(`/rooms/${roomId}/quiz`, {
      cancelToken: source.token,
    })
    .then(({ data }) => {
      const activeQuizzes = data.data.filter((quiz) => quiz.status);

      let totalAnsweredQuizzes = 0;
      const unansweredQuizzes = [] as RoomQuizData[];

      activeQuizzes.forEach((quiz) => {
        if (quiz.user_replied) {
          totalAnsweredQuizzes += 1;
        } else {
          unansweredQuizzes.push(quiz);
        }
      });

      return {
        totalAnsweredQuizzes,
        quizzes: unansweredQuizzes,
      };
    }) as PromiseWithCancel<RoomQuizResult>;

  promise.cancel = () => source.cancel('useQuizQuery Cancelled');

  return promise;
};

export const useQuizQuery = (roomId?: number) =>
  useQuery<RoomQuizResult, AxiosError>(['quiz', roomId], fetchQuiz, {
    enabled: !!roomId,
  });
