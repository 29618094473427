import { BiCertification } from 'react-icons/bi';
import { CgCrown, CgPoll } from 'react-icons/cg';
import { FiCalendar, FiCast, FiHome, FiTrello, FiUsers } from 'react-icons/fi';
import { RiHandHeartLine } from 'react-icons/ri';
import { MdOndemandVideo } from 'react-icons/md';

import { SettingsData } from '@common/types/api';

export const NAVIGATION_MENU = (data: SettingsData) => ({
  'menu.lobby': {
    path: '/lobby',
    icon: FiHome,
    enabled: true,
  },
  'menu.newsfeed': {
    path: '/newsfeed',
    icon: FiTrello,
    enabled: true,
  },
  'menu.transmission': {
    path: '/rooms',
    icon: FiCast,
    enabled: true,
  },
  'menu.videos': {
    path: '/videos',
    icon: MdOndemandVideo,
    enabled: data.videos > 0,
  },
  'menu.speakers': {
    path: '/speakers',
    icon: FiUsers,
    enabled: true,
  },
  'menu.schedules': {
    path: '/schedule',
    icon: FiCalendar,
    enabled: true,
  },
  'menu.ranking': {
    path: '/ranking',
    icon: CgCrown,
    enabled: !!data.gamification_ranking,
  },
  'menu.sponsors': {
    path: '/sponsors',
    icon: RiHandHeartLine,
    enabled: data.partners > 0,
  },
  'menu.certificate': {
    path: '/certificate',
    icon: BiCertification,
    enabled: !!data.certificate,
  },
  'menu.satisfactionSurvey': {
    path: '/satisfaction-survey',
    icon: CgPoll,
    enabled: !!data.satisfaction_survey,
  },
});
