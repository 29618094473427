import styled from 'styled-components';

import { PerfectScrollbar } from '@components';

export const Container = styled(PerfectScrollbar)`
  > div {
    > * {
      word-break: break-word;
    }
  }
`;
