import { DispatchWithoutAction, ForwardedRef, forwardRef } from 'react';

import { HTMLMotionProps, Transition, Variants } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FiUser, FiX, FiXCircle } from 'react-icons/fi';

import { useAuth } from '@hooks';
import { selectSignOut } from '@hooks/useAuth/selectors';
import { Button } from '@components';

import * as S from './styles';

const variants: Variants = {
  hidden: {
    opacity: 0,
    x: '100%',
  },
  shown: {
    opacity: 1,
    x: 0,
  },
};

const transition: Transition = {
  type: 'spring',
  duration: 0.5,
};

type UserMobileMenuProps = HTMLMotionProps<'dialog'> & {
  handleProfileModal: DispatchWithoutAction;
  handleClose: DispatchWithoutAction;
};

const UserMobileMenu = (
  props: UserMobileMenuProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const signOut = useAuth(selectSignOut);

  const { handleClose, handleProfileModal, ...attrs } = props;
  const { t } = useTranslation();

  const handleEditProfile = () => {
    handleClose();
    handleProfileModal();
  };

  return (
    <S.Container
      open
      ref={ref}
      variants={variants}
      transition={transition}
      initial="hidden"
      animate="shown"
      exit="hidden"
      {...attrs}
    >
      <Button
        size="even"
        variant="transparent"
        icon={<FiX size="1.625rem" />}
        onClick={handleClose}
      />

      <S.NavButton as="button" onClick={handleEditProfile}>
        <FiUser size="1.5rem" /> {t('menu.editProfile')}
      </S.NavButton>

      <S.NavButton as="button" onClick={() => signOut()}>
        <FiXCircle size="1.5rem" /> {t('menu.exitEvent')}
      </S.NavButton>
    </S.Container>
  );
};

export default forwardRef(UserMobileMenu);
