import {
  useMemo,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';

import { useQueryClient } from 'react-query';

import { PropsWithRequiredChildren } from '@common/types';
import { api, chatApi, eventfyStorage } from '@services';
import {
  AuthLoginRequest,
  AuthLoginResponse,
  RegisterUserRequest,
  RegisterUserResponse,
  ForgotPassRequest,
  ForgotPasswordResponse,
  ResetPassRequest,
  ResetPasswordResponse,
  UserProfile,
  UserProfileResponse,
  UserUpdateProfileRequest,
} from '@common/types/api';
import { AUTH_ENDPOINT, USER_ENDPOINT } from '@constants/endpoints';
import { useEventfy } from '@hooks/useEventfy';

import { handleTokenAndUserData, handleTokenChat, Token } from './helpers';

type SignOutOptions = {
  skipInvalidation: boolean;
};

export type AuthContextData = {
  user: UserProfile;
  magicLinkSignIn: (token: string) => Promise<void>;
  signChat: () => Promise<void>;
  signIn: (credentials: AuthLoginRequest) => Promise<void>;
  signUp: (credentials: RegisterUserRequest) => Promise<void>;
  forgotPassword: (credentials: ForgotPassRequest) => Promise<void>;
  resetPassword: (credentials: ResetPassRequest) => Promise<void>;
  signOut: (options?: SignOutOptions) => void;
  updateUser: (user: UserUpdateProfileRequest) => void;
  deleteUser: () => void;
  updateProfileState: (newData: Partial<UserProfile>) => void;
};

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children }: PropsWithRequiredChildren) => {
  const event = useEventfy();
  const queryClient = useQueryClient();

  const [profile, setProfile] = useState<UserProfile>(() => {
    const user = eventfyStorage.getItem<UserProfile>('user');
    const token = eventfyStorage.getCookie<Token>('token');
    const chatToken = eventfyStorage.getCookie<Token>('chat-token');

    if (token && chatToken && user) {
      api.defaults.headers.authorization = `${token.type} ${token.jwt}`;
      chatApi.defaults.headers.authorization = `Bearer ${chatToken}`;

      return user;
    }

    return {} as UserProfile;
  });

  const magicLinkSignIn = useCallback<AuthContextData['magicLinkSignIn']>(
    async (token) => {
      const response = await api.post(AUTH_ENDPOINT.TOKEN, {
        token,
      });

      const { user: _, ...tokenData } = response.data;

      const user = await handleTokenAndUserData(tokenData);

      setProfile(user);
    },
    [setProfile],
  );

  const signChat = useCallback<AuthContextData['signChat']>(async () => {
    await handleTokenChat();
  }, []);

  const signIn = useCallback<AuthContextData['signIn']>(
    async ({ email, password }) => {
      const response = await api.post<AuthLoginResponse>(AUTH_ENDPOINT.LOGIN, {
        email,
        password,
      });

      const { user: _, ...tokenData } = response.data;

      const user = await handleTokenAndUserData(tokenData);

      setProfile(user);
    },
    [setProfile],
  );

  const forgotPassword = useCallback<AuthContextData['forgotPassword']>(
    async ({ email, route }) => {
      const response = await api.post<ForgotPasswordResponse>(
        AUTH_ENDPOINT.FORGOTPASSWORD,
        {
          email,
          route,
        },
      );

      await response.data;
    },
    [],
  );

  const resetPassword = useCallback<AuthContextData['resetPassword']>(
    async ({ email, token, password, password_confirmation }) => {
      const response = await api.post<ResetPasswordResponse>(
        AUTH_ENDPOINT.RESETPASSWORD,
        {
          email,
          token,
          password,
          password_confirmation,
        },
      );

      await response.data;
    },
    [],
  );

  const signUp = useCallback<AuthContextData['signUp']>(
    async (credentials) => {
      const sanitizedCredentials: RegisterUserRequest = {
        ...credentials,
        phone: credentials.phone?.replace(/^\+55/, ''),
      };

      const response = await api.post<RegisterUserResponse>(
        USER_ENDPOINT.REGISTER,
        sanitizedCredentials,
      );

      if (event.session_login) {
        const { token: access_token, token_type } = response.data;

        const user = await handleTokenAndUserData({ access_token, token_type });

        setProfile(user);
      }
    },
    [event.session_login, setProfile],
  );

  const signOut = useCallback<AuthContextData['signOut']>(
    async (options) => {
      const { skipInvalidation = false } = options || {};

      try {
        eventfyStorage.removeItem('user');
        eventfyStorage.removeCookie('token');

        const tokenInvalidationPromise = !skipInvalidation
          ? api.post(AUTH_ENDPOINT.LOGOUT)
          : () => null;

        await Promise.allSettled([
          queryClient.cancelQueries,
          queryClient.resetQueries,
          tokenInvalidationPromise,
        ]);
      } finally {
        setProfile({} as UserProfile);

        await queryClient.invalidateQueries('event-settings');
      }
    },
    [setProfile, queryClient],
  );

  const updateProfileState = useCallback(
    (newData: Partial<UserProfile>) =>
      setProfile((prevState) => {
        const newUserData = {
          ...prevState,
          ...newData,
        };

        eventfyStorage.setItem('user', newUserData);

        return newUserData;
      }),
    [],
  );

  const updateUser = useCallback<AuthContextData['updateUser']>(
    async (updatedData) => {
      const response = await api.put<UserProfileResponse>(
        USER_ENDPOINT.EDIT_PROFILE(profile.id),
        updatedData,
      );

      const newData = response.data.data;

      updateProfileState(newData);
    },
    [profile.id, updateProfileState],
  );

  const deleteUser = useCallback<AuthContextData['deleteUser']>(async () => {
    await api.delete<UserProfileResponse>(
      USER_ENDPOINT.EDIT_PROFILE(profile.id),
    );

    await signOut({ skipInvalidation: true });
  }, [profile.id, signOut]);

  useEffect(() => {
    (async () => {
      try {
        const token = eventfyStorage.getCookie<Token>('token');

        if (token) {
          const user = await handleTokenAndUserData({
            access_token: token.jwt,
            token_type: token.type,
          });

          setProfile(user);
        }
        // eslint-disable-next-line no-empty
      } catch {}
    })();
  }, []);

  const value = useMemo<AuthContextData>(
    () => ({
      user: profile,
      magicLinkSignIn,
      signChat,
      signIn,
      signUp,
      forgotPassword,
      resetPassword,
      signOut,
      updateUser,
      deleteUser,
      updateProfileState,
    }),
    [
      profile,
      magicLinkSignIn,
      signChat,
      signIn,
      signUp,
      forgotPassword,
      resetPassword,
      signOut,
      updateUser,
      deleteUser,
      updateProfileState,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
