import * as Yup from 'yup';

const emailShape = {
  email: Yup.string().required('valid.email.req').email('valid.email.invalid'),
};

export const authSchemas = {
  1: Yup.object().shape({
    ...emailShape,
    password: Yup.string().min(6, 'valid.password.req'),
  }),
  2: Yup.object().shape(emailShape),
};
