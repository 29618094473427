import { memo, useEffect } from 'react';

import tawkTo from 'tawkto-react';

import { IntegrationLocation } from '@common/types/api';
import { useEventfy } from '@hooks';

type TawkToProps = {
  location: IntegrationLocation;
};

const TawkTo = ({ location }: TawkToProps) => {
  const { tag_tawk, tag_tawk_local } = useEventfy();

  useEffect(() => {
    if (tag_tawk_local === location && tag_tawk) {
      const [propertyId, key] = tag_tawk.split('.to/')[1].split('/');

      tawkTo(propertyId, key);
    }
  }, [location, tag_tawk_local, tag_tawk]);

  useEffect(() => {
    if (tag_tawk_local !== location) {
      document
        .querySelectorAll('script[src^="https://embed.tawk.to"]')
        ?.forEach((node) => node.remove());
      document
        .querySelector('iframe[src="about:blank"]')
        ?.parentElement?.remove();
    }
  }, [location, tag_tawk_local, tag_tawk]);

  return <></>;
};

export default memo(TawkTo);
