import { memo } from 'react';

import * as S from '../styles';
import BannerCarousel from '../BannerCarousel';

const SidebarLeft = () => (
  <S.Container $side="left">
    <BannerCarousel side="left" />
  </S.Container>
);

export default memo(SidebarLeft);
