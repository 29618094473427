import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

import { TransientProps } from '@common/types';

type SidebarContainerProps = TransientProps<{ side: 'left' | 'right' }>;

export const Container = styled.article<SidebarContainerProps>`
  ${({ $side: side }) => {
    const normalizedSide = `${side.charAt(0).toUpperCase()}${side.slice(1)}`;

    return css`
      grid-area: ${`FeedSidebar${normalizedSide}`};
      width: 100%;
      height: 100%;

      ${down('lg')} {
        display: none;
      }
    `;
  }}
`;

type SidebarItemProps = TransientProps<{ padding: boolean }>;

export const SidebarItem = styled.div<Partial<SidebarItemProps>>`
  ${({ theme, $padding: withPadding = true }) => css`
    width: 100%;
    padding: ${withPadding ? 1 : 0}rem;
    border-radius: 1rem;
    background: ${theme.colors.background[100]};
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);

    h4 {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }

    & + & {
      margin-top: 1.5rem;
    }
  `}
`;

export const ProfileItem = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    margin: 0 0 1rem 0;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
`;

export const Message = styled.div`
  padding: 0.25rem 0.5rem;
`;
