import { QueryFunctionContext, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import toast from 'react-hot-toast';

import { FullScheduleData } from '@common/types/api';
import { api } from '@services';

const fetchScheduleSession = async (
  ctx: QueryFunctionContext<[string, number]>,
) => {
  const [, speakerId] = ctx.queryKey;

  const response = await api.get<{ data: FullScheduleData }>(
    `/schedules/${speakerId}?include=participants,room`,
  );

  return response.data.data;
};

export const useScheduleSessionQuery = (id: number) => {
  const history = useHistory();
  const { search, ...location } = useLocation();

  const queryParams = new URLSearchParams(search);

  return useQuery(['session', id], fetchScheduleSession, {
    onError: () => {
      queryParams.delete('session');

      history.replace({
        ...location,
        search: `?${queryParams.toString()}`,
      });

      toast.error('Falha ao buscar os dados da sessão');
    },
  });
};
