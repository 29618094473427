/* eslint-disable radar/cognitive-complexity */
import { useState } from 'react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { FiLock, FiMail, FiXCircle } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { GooSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';

import { Button, Input, MetaTags, TawkTo, WhatsAppButton } from '@components';
import { useAuth, useEventfy, useStateReducer } from '@hooks';
import { AuthLoginRequest, IntegrationLocation } from '@common/types/api';
import { HistoryState } from '@services/history';
import DropdownLang from '@pages/_layouts/private/Navigation/DropdownLang';

import { authSchemas } from './schemas';
import * as S from '../styles';

type MagicLinkState = {
  status: 'authenticating' | 'error' | null;
  error: '';
};

const SignIn = () => {
  const theme = useTheme();
  const event = useEventfy();
  const { signIn, magicLinkSignIn } = useAuth();
  const history = useHistory<HistoryState>();
  const { t } = useTranslation();

  const formMethods = useForm<AuthLoginRequest>({
    resolver: yupResolver(authSchemas[event.access_type]),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [magicLinkState, setMagicLinkState] = useStateReducer<MagicLinkState>(
    {} as MagicLinkState,
  );

  const hasChatOnPage =
    event.whatsapp_button_local === IntegrationLocation.LOGIN ||
    event.tag_tawk_local === IntegrationLocation.LOGIN;
  const magicToken = new URLSearchParams(history.location.search).get('string');

  const handleSignIn = async (credentials: AuthLoginRequest) => {
    try {
      setIsLoading(true);
      await signIn(credentials);

      if (event.tag_google_gtm) {
        TagManager.dataLayer({ dataLayer: { event: 'eventfy_user_logged' } });
      }

      const message =
        t('sign_in.success') || 'Autenticação realizada com sucesso!';

      toast.success(message);

      history.push(history.location.state?.from || '/lobby');
    } catch (err) {
      const fallbackMessage = t('sign_in.fail');

      if (err?.isAxiosError) {
        const message =
          (err as AxiosError<{ message: string }>).response?.data.message ??
          fallbackMessage;
        toast.error(message);
      } else {
        toast.error(fallbackMessage);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (magicToken) {
          setMagicLinkState({ status: 'authenticating' });

          const parsedMagicToken = magicToken.replace(/\s/, '+');
          await magicLinkSignIn(parsedMagicToken);
        }
      } catch (err) {
        setMagicLinkState({
          status: 'error',
          error:
            err.response.data.message ||
            'Não foi possível autenticar sua conta',
        });
      }
    })();
  }, [magicToken, magicLinkSignIn, setMagicLinkState]);

  return (
    <S.Container>
      <MetaTags title={t('sign_in.title')} />

      <S.Header>
        <h1>
          <Link to="/sign-in">
            <img src={event.logo} alt={event.name} />
          </Link>
        </h1>

        {!!event.session_register && (
          <Button
            title={t('menu.register') || 'Register'}
            variant="outline"
            as={Link}
            to="/sign-up"
          />
        )}
      </S.Header>
      <S.Lang>
        <DropdownLang />
      </S.Lang>
      {!magicToken ? (
        <FormProvider {...formMethods}>
          <S.Form onSubmit={formMethods.handleSubmit(handleSignIn)}>
            <h2>{t('sign_in.title')}</h2>

            <p>{t('sign_in.message')}</p>

            <Input
              required
              autoFocus
              name="email"
              type="email"
              placeholder="E-mail"
              autoComplete="email"
              inputMode="email"
              icon={FiMail}
              disabled={!event.session_login}
            />
            {+event.access_type === 1 && (
              <Input
                required
                id="current-password"
                name="password"
                type="password"
                placeholder={t('input.password')}
                autoComplete="current-password"
                icon={FiLock}
                disabled={!event.session_login}
              />
            )}
            {+event.access_type === 1 && (
              <p style={{ marginTop: '1rem' }}>
                <Link to="/forgot-password">{t('sign_in.forgotPassword')}</Link>
              </p>
            )}
            <Button
              title={
                event.session_login
                  ? t('button.login') || 'Login'
                  : event.message_login || 'Login disabilitado'
              }
              type="submit"
              disabled={!event.session_login}
              loading={isLoading}
              fullWidth
              pill
            />
          </S.Form>
        </FormProvider>
      ) : (
        <S.MagicWrapper>
          {magicLinkState.status === 'authenticating' && (
            <>
              <GooSpinner
                size={5}
                sizeUnit="rem"
                color={theme.colors.brand.primary.default}
              />
              <h3>Autenticando...</h3>
            </>
          )}

          {magicLinkState.status === 'error' && (
            <>
              <FiXCircle size="5rem" color={theme.colors.error[100]} />
              <h3>{magicLinkState.error}.</h3>
            </>
          )}
        </S.MagicWrapper>
      )}

      {hasChatOnPage && <p>{t('sign_in.support')}</p>}

      <TawkTo location={IntegrationLocation.LOGIN} />
      <WhatsAppButton location={IntegrationLocation.LOGIN} />
    </S.Container>
  );
};

export default SignIn;
