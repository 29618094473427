/* eslint-disable radar/cognitive-complexity */
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import { HTMLMotionProps } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { DEFAULT_MODAL_ANIMATION, DEFAULT_TRANSITION } from '@constants';

import * as S from './styles';

type TermsModalBaseProps = HTMLMotionProps<'dialog'> & {
  data: string;
};

type TermsModalProps = PropsWithChildren<TermsModalBaseProps>;

const TermsModal = (
  props: TermsModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const { children, data, ...attrs } = props;
  const { t } = useTranslation();

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      {...attrs}
    >
      {children}

      <h2>{t('sign_up.terms')}</h2>

      <S.TermsContainer dangerouslySetInnerHTML={{ __html: data }} />
    </S.Container>
  );
};

export default forwardRef(TermsModal);
