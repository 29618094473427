import styled, { css, DefaultTheme } from 'styled-components';

import { Input } from '@components';
import { TransientProps } from '@common/types';
import { RoomAskingStatus } from '@common/types/api';

import { VirtualizedItemWrapper } from '../styles';

type ContainerProps = TransientProps<{ disabled: boolean; hide: boolean }>;

export const Container = styled.form<ContainerProps>`
  ${({ $disabled: isDisabled, $hide: shouldHide }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1rem 0;
    transition: 500ms ease-in-out;

    ${isDisabled &&
    css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
    `}

    ${shouldHide &&
    css`
      display: none;
    `}
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;
    max-height: 90%;

    h3 {
      font-size: 1rem;
      font-weight: 500;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid ${theme.colors.background[80]};
    }
  `}
`;

export const QuestionsListWrapper = styled.div`
  margin-bottom: 1rem;
  height: 100%;
  max-height: calc(100% - 3.5rem);
  overflow-y: auto;
`;

type QuestionsListProps = { height: number };

export const QuestionsList = styled.ul<QuestionsListProps>`
  ${({ height }) => css`
    width: 100%;
    height: ${height}px;
    padding-top: 1rem;
    position: relative;
  `}
`;

const statusColors = ({ colors }: DefaultTheme) => ({
  0: colors.background[60],
  1: colors.success[100],
  2: colors.brand.primary.default,
});

export const QuestionWrapper = styled(VirtualizedItemWrapper)`
  ${() => css`
    padding-top: 0.5rem;
  `}
`;

type QuestionProps = TransientProps<{ status: RoomAskingStatus }>;

export const Question = styled.div<QuestionProps>`
  ${({ theme, $status: status }) => css`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    background: ${theme.colors.background[80]};
    font-size: 0.875rem;

    svg {
      color: ${statusColors(theme)[status]};
    }

    span {
      display: inline-block;
      padding: 0.25rem 0.75rem;
      font-size: 0.625rem;
      font-weight: 500;
      color: ${status === RoomAskingStatus.ANSWERED_LIVE
        ? theme.colors.background[100]
        : 'inherit'};
      border-radius: 1000px;
      background: ${statusColors(theme)[status]};
    }
  `}
`;

export const QuestionContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      > p {
        margin-right: 0.5rem;
      }

      div {
        overflow: hidden;
      }
    }

    > p {
      font-size: 0.75rem;
      color: ${theme.colors.foreground[80]};
    }
  `}
`;

export const QuestionInput = styled(Input)`
  height: 3.25rem;
  max-width: calc(100% - 2rem);
  margin: 0 1rem;

  > svg {
    margin: 0 0 0 0.5rem;
  }

  input {
    font-size: 0.875rem;
  }
`;
