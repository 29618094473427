import { HtmlHTMLAttributes } from 'react';

import { FiChevronRight } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router';

import { SpeakerData } from '@common/types/api';

import Avatar from '../Avatar';
import * as S from './styles';

type SpeakerBaseProps = {
  flat?: boolean;
  negativeMargin?: boolean;
};

type SpeakerWithData = SpeakerBaseProps & {
  data: SpeakerData;
  loading?: never;
};

type SpeakerLoading = SpeakerBaseProps & {
  data?: never;
  loading: boolean;
};

type SpeakerProps = (SpeakerWithData | SpeakerLoading) &
  HtmlHTMLAttributes<HTMLLIElement>;

const Speaker = (props: SpeakerProps) => {
  const history = useHistory();
  const { search, ...location } = useLocation();

  const queryParams = new URLSearchParams(search);

  const {
    data,
    loading = false,
    flat: isFlat = false,
    negativeMargin = false,
    ...attrs
  } = props;

  const handleSpeaker = () => {
    queryParams.set('speaker', `${data?.id}`);

    history.push({
      ...location,
      search: `?${queryParams.toString()}`,
    });
  };

  if (!data) {
    return (
      <S.Container
        $loading={loading}
        $flat={isFlat}
        $negativeMargin={negativeMargin}
        {...attrs}
      >
        <div>
          <S.AvatarSkeleton />

          <div>
            <S.Data>
              <strong>
                <Skeleton width="200%" />
              </strong>
              <span>
                <Skeleton width={88} />
              </span>
            </S.Data>

            <Skeleton width="0.5rem" height="1.5rem" />
          </div>
        </div>
      </S.Container>
    );
  }

  return (
    <S.Container
      $loading={false}
      $flat={isFlat}
      $negativeMargin={negativeMargin}
      {...attrs}
    >
      <button type="button" onClick={handleSpeaker}>
        <Avatar src={data.photo} alt={data.name} />

        <div>
          <S.Data>
            <strong>{data.name}</strong>
            <span>{data.title}</span>
          </S.Data>

          <FiChevronRight size="1.5rem" />
        </div>
      </button>
    </S.Container>
  );
};

export default Speaker;
