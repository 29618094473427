import styled, { css } from 'styled-components';

import { TransientProps } from '@common/types';

export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

type TooltipContainerProps = TransientProps<{
  color?: string;
  position: TooltipPosition;
}>;

const modifiers = {
  top: (color: string) => css`
    ::before,
    ::after {
      left: 50%;
      transform: translateX(-50%);
    }

    ::before {
      bottom: calc(100% - 0.315rem);
    }

    ::after {
      content: '';
      border-style: solid;
      border-width: 0.315rem 0.315rem 0 0.315rem;
      border-color: ${color} transparent transparent transparent;
      bottom: calc(100% - 0.65rem);
    }
  `,
  right: (color: string) => css`
    ::before,
    ::after {
      top: 50%;
      transform: translate3d(0, calc(-50%), 0);
    }

    ::before {
      left: calc(100% + 1rem);
    }

    ::after {
      border-width: 0.315rem 0.315rem 0.315rem 0;
      border-color: transparent ${color} transparent transparent;
      left: calc(100% + 0.7rem);
    }
  `,
  bottom: (color: string) => css`
    ::before,
    ::after {
      left: 50%;
      transform: translateX(-50%);
    }

    ::before {
      top: calc(100% - 0.315rem);
    }

    ::after {
      top: calc(100% - 0.65rem);
      border-width: 0 0.315rem 0.315rem 0.315rem;
      border-color: transparent transparent ${color} transparent;
    }
  `,
  left: (color: string) => css`
    ::before,
    ::after {
      top: 50%;
      transform: translate3d(0, calc(-50% - 3px), 0);
    }

    ::before {
      right: calc(100% + 1rem);
    }

    ::after {
      border-width: 0.6rem 0 0.6rem 0.6rem;
      border-color: transparent transparent transparent ${color};
      right: calc(100% + 0.25rem);
    }
  `,
};

export const Container = styled.div<TooltipContainerProps>`
  ${({ theme, $color, $position }) => css`
    position: relative;
    padding: 1rem 0;
    margin: -1rem 0;
    cursor: help;

    > svg {
      flex-shrink: 0;
      vertical-align: -0.325rem;
    }

    ::before,
    ::after {
      z-index: 2;
      pointer-events: none;
      display: block;
      position: absolute;
      opacity: 0;
      transition: 250ms ease-in-out;
    }

    ::before {
      content: attr(data-tip);
      background: ${$color || theme.colors.error[100]};
      color: ${theme.colors.base.text};
      padding: 0.25rem 0.315rem;
      border-radius: 0.25rem;
      max-width: 14rem;
      width: max-content;
      font-size: 0.75rem;
      text-align: center;
    }

    ::after {
      content: '';
      border-style: solid;
    }

    ${modifiers[$position]($color || theme.colors.error[100])}

    :hover {
      ::before,
      ::after {
        pointer-events: all;
        opacity: 1;
      }
    }
  `}
`;
