import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

import { PerfectScrollbar } from '@components';

export const Container = styled(PerfectScrollbar)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > li {
    width: 100%;
  }

  ${down('md')} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const DownloadLink = styled.a`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: ${theme.colors.brand.primary.opaque};
    padding: 0.75rem 1rem;
    gap: 1rem;
    border-radius: 0.75rem;
    font-weight: normal;
    font-size: 0.75rem;
    transition: 180ms ease-in-out;

    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${theme.colors.base.text};
      border-radius: 1rem;
      flex-shrink: 0;
      width: 3rem;
      height: 3rem;
      color: ${theme.colors.brand.primary.default};
    }

    :hover {
      background: ${theme.colors.brand.primary.default};
      color: ${theme.colors.brand.primary.text};
    }
  `}
`;
