import { FiClock } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { RiCheckDoubleFill } from 'react-icons/ri';
import { GrLock, GrUnlock } from 'react-icons/gr';
// import { MdNotInterested } from 'react-icons/md';
import { AnimatePresence } from 'framer-motion';

import { Modal } from '@components';
import trophy from '@assets/trophy.svg';
import { useToggle } from '@hooks';

import * as S from './styles';
import RewardModal from './RewardModal';
import RewardItem from './RewardItem';

type Reward = {
  id: number;
  sequence: number;
  title: string;
  type_reward: number;
  qty_rewards: number;
  qty_available: number;
  target: number;
  url_reward: string;
  status: number;
};

export type RewardsProps = {
  rewards: Array<Reward>;
  nominess: number;
};

const Rewards = (props: RewardsProps) => {
  const { rewards, nominess } = props;
  const { t } = useTranslation();

  const [isRewardModalVisible, toggleIsRewardModalVisible] = useToggle(false);

  return (
    <S.Container>
      <S.Header>
        <img src={trophy} alt="Trophy" />
        <div>
          <h2>{t('modal.indication.rewardTitle')}</h2>
          <span>{t('modal.indication.rewardCall')}</span>
        </div>
      </S.Header>

      <S.RewardsList
        style={{ touchAction: 'none' }}
        options={{ suppressScrollX: false }}
      >
        <ul>
          {rewards.map((reward) => (
            <RewardItem
              key={reward.id}
              typeReward={reward.type_reward}
              urlReward={reward.type_reward === 1 ? reward.url_reward : ''}
              openModal={toggleIsRewardModalVisible}
              isBlocked={reward.target > nominess}
            >
              <div>
                <strong>{reward.sequence}</strong>
              </div>

              <div className="rewards-content">
                <div>
                  <h4>
                    {`${reward.target} ${
                      reward.target === 1
                        ? t('modal.indication.indicationTitle')
                        : t('modal.indication.indicationsTitle')
                    }`}
                  </h4>
                  <strong>{reward.title}</strong>
                </div>
                <span>
                  {reward.target > nominess ? (
                    <>
                      <FiClock /> {t('modal.indication.pending')}
                    </>
                  ) : (
                    <>
                      <RiCheckDoubleFill />
                      {t('modal.indication.rewardObtained')}
                    </>
                  )}
                </span>
                {/* {(!!reward.qty_available && reward.target > nominess) ?? (
                  <span className="soldOff">
                    <MdNotInterested /> Esgotado!
                  </span>
                )} */}
              </div>
              <S.Icon>
                {reward.target > nominess ? <GrLock /> : <GrUnlock />}
              </S.Icon>
            </RewardItem>
          ))}
        </ul>
      </S.RewardsList>

      <AnimatePresence>
        {isRewardModalVisible && (
          <Modal handleClose={toggleIsRewardModalVisible}>
            <RewardModal handleClose={toggleIsRewardModalVisible} />
          </Modal>
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default Rewards;
