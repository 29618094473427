import { memo } from 'react';

import { ScheduleSession } from '@components';
import { useBroadcastQuery } from '@hooks';

import * as S from './styles';

const RoomSchedule = () => {
  const { data: roomSchedule } = useBroadcastQuery({
    select: ({ schedules }) => schedules.data,
    notifyOnChangeProps: 'tracked',
  });

  return (
    <S.Container component="ul">
      <>
        {roomSchedule?.map((session) => (
          <ScheduleSession key={session.id} data={session} />
        ))}
      </>
    </S.Container>
  );
};

export default memo(RoomSchedule);
