import {
  DispatchWithoutAction,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useState,
} from 'react';

import { HTMLMotionProps } from 'framer-motion';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { FiUser, FiMail } from 'react-icons/fi';

import { Button, Input, Textarea } from '@components';
import { useAuth } from '@hooks';
import { ContactCreateRequest } from '@common/types/api';
import { DEFAULT_MODAL_ANIMATION, DEFAULT_TRANSITION } from '@constants';
import { api } from '@services';

import * as S from './styles';
import { createSchema } from './schemas';

type ContactUsModalProps = PropsWithChildren<HTMLMotionProps<'dialog'>> & {
  handleClose: DispatchWithoutAction;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  company: any;
};

const ContactUsModal = (
  props: ContactUsModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const { user } = useAuth();
  const { children, handleClose, company, ...attrs } = props;

  const formMethods = useForm<ContactCreateRequest>({
    resolver: yupResolver(createSchema),
    defaultValues: {
      user_id: user.id,
      name: user.name || '',
      email: user.email || '',
      sponsor_id: company.id,
      message: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data: ContactCreateRequest) => {
    try {
      setIsLoading(true);
      await api.post('/partners/contact', data);
      toast.success('Recebemos o seu pedido de contato, retornaremos em breve');
      handleClose();
    } catch (err) {
      toast.error('Falha ao enviar a mensagem');
      setIsLoading(false);
    }
  };

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      {...attrs}
    >
      {children}
      <h2>{company.name} - Fale Conosco </h2>

      <FormProvider {...formMethods}>
        <S.Form
          component="form"
          onSubmit={formMethods.handleSubmit(handleSubmit)}
        >
          <Input
            name="name"
            placeholder="Nome"
            defaultValue={user.name}
            autoComplete="off"
            icon={FiUser}
            readOnly
            required
          />

          <Input
            name="email"
            type="email"
            placeholder="E-mail"
            defaultValue={user.email}
            inputMode="email"
            icon={FiMail}
            readOnly
            required
          />

          <Textarea name="message" placeholder="Mensagem" required />

          <S.FlexWrapper>
            <Button title="Enviar mensagem" type="submit" loading={isLoading} />
          </S.FlexWrapper>
        </S.Form>
      </FormProvider>
    </S.Container>
  );
};

export default forwardRef(ContactUsModal);
