import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

import { SectionHeader } from '@components';

export const Container = styled.article``;

export const VideoProfileHeader = styled(SectionHeader)`
  display: flex;
  align-items: left;
  gap: 0.5rem;
  margin-bottom: 2rem !important;

  h2 {
    font-size: 1.75rem;
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.25rem;

  ${down('md')} {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const VideoResume = styled.section`
  width: 12.5rem;

  > a {
    :first-of-type {
      flex-shrink: 0;
      display: block;
      margin-bottom: 1.25rem;

      > img {
        width: 100%;
        max-width: 12rem;
        height: 7rem;
        object-fit: contain;
        object-position: left;
      }
    }
  }

  a {
    transition: 180ms ease-in-out;

    :hover {
      color: ${({ theme }) => theme.colors.brand.primary.default};
    }
  }

  ${down('md')} {
    display: flex;
    width: 100%;
    gap: 1rem;
  }
`;

export const VideoDetailData = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 52.5rem;

    > section {
      /* padding: 1.5rem 1rem; */
      border-radius: 1rem;
      background: ${theme.colors.background[100]};
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
        0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.005);

      > h3 {
        padding-top: 1rem;
        margin-bottom: 1rem;
        position: relative;

        :before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          width: 2.5rem;
          border-top: 0.25rem solid ${theme.colors.brand.secondary.default};
        }
      }
    }
  `}
`;
