import { QueryFunctionContext, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { api } from '@services';
import { CommentData, PostResponse } from '@common/types/api';
import { PromiseWithCancel } from '@common/types';
import { cancelToken } from '@services/api';

const fetchPostComments = (ctx: QueryFunctionContext<[string, number]>) => {
  const [, postId] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<PostResponse>(`/timeline/${postId}?include=comments.user`, {
      cancelToken: source.token,
    })
    .then((res) => res.data.data.comments.data) as PromiseWithCancel<
    CommentData[]
  >;

  promise.cancel = () => source.cancel('useNewsFeedPostQuery Cancelled');

  return promise;
};

export const useNewsFeedPostCommentsQuery = (
  postId: number,
  enabled: boolean,
) =>
  useQuery<CommentData[], AxiosError>(
    ['post-comments', postId],
    fetchPostComments,
    {
      enabled,
    },
  );
