import { ChangeEventHandler } from 'react';

import { FiCamera, FiXCircle } from 'react-icons/fi';
import Tippy from '@tippyjs/react';

import { Nullable } from '@common/types';
import { Button } from '@components';
import { Message } from '@pages/NewsFeed/Sidebar/styles';

import * as S from './styles';

type PictureInputProps = {
  src: Nullable<string>;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  reset: () => void;
  t: (str: string) => void;
};

const PictureInput = ({ src, handleChange, reset, t }: PictureInputProps) => (
  <S.Container>
    {src && (
      <Tippy
        arrow
        placement="top"
        theme="light-border"
        maxWidth="20.5rem"
        zIndex={3}
        offset={[0, 0]}
        content={<Message>{t('pages.newsfeed.removePhoto')} </Message>}
      >
        <Button size="even" icon={FiXCircle} onClick={reset} />
      </Tippy>
    )}

    <Tippy
      arrow
      placement="top"
      theme="light-border"
      maxWidth="20.5rem"
      zIndex={3}
      offset={[0, 0]}
      content={
        <Message>
          {src ? t('pages.newsfeed.alter') : t('pages.newsfeed.add')}{' '}
        </Message>
      }
    >
      <label htmlFor="picture">
        {src ? <img src={src} alt="Images Post" /> : <FiCamera size="1.5rem" />}

        <input
          id="picture"
          type="file"
          accept="image/*"
          onChange={handleChange}
        />
      </label>
    </Tippy>
  </S.Container>
);

export default PictureInput;
