import styled, { css } from 'styled-components';

export const Container = styled.li`
  ${({ theme }) => css`
    min-width: 0;

    > a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 0.75rem;
      border-radius: 0.8rem;
      font-weight: normal;
      background: ${theme.colors.background[100]};
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
        0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.005);

      img,
      > span {
        margin-right: 1rem;
      }

      div {
        flex: 1;
        min-width: 0;

        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        time {
          font-size: 0.875rem;
        }
      }

      svg {
        margin-left: auto;
      }

      :hover {
        transform: translate3d(0, -0.125rem, 0);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023),
          0 2px 2px rgba(0, 0, 0, 0.023), 0 4px 4px rgba(0, 0, 0, 0.023),
          0 8px 8px rgba(0, 0, 0, 0.023), 0 16px 16px rgba(0, 0, 0, 0.023),
          0 32px 32px rgba(0, 0, 0, 0.023);
      }

      :active {
        transform: translate3d(0, 0.125rem, 0);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047),
          0 2px 2px rgba(0, 0, 0, 0.047), 0 4px 4px rgba(0, 0, 0, 0.047);
      }
    }
  `}
`;
