import { HiOutlineMail } from 'react-icons/hi';
import { FaWhatsapp, FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { useEventfy } from '@hooks';

import * as S from './styles';

type SocialNetworksProps = {
  linkReferral: string;
};

const SocialNetworks = (props: SocialNetworksProps) => {
  const { linkReferral } = props;
  const event = useEventfy();
  const { t } = useTranslation();

  const body = t('modal.indication.body', { event: event.name, linkReferral });

  const socialNetworks = [
    {
      title: 'Whatsapp',
      icon: <FaWhatsapp />,
      background: '#21CD5B',
    },
    {
      title: 'Facebook',
      icon: <FaFacebook />,
      background: '#176CF0',
    },
    {
      title: 'Linkedin',
      icon: <FaLinkedin />,
      background: '#245CA9',
    },
    {
      title: 'Twitter',
      icon: <FaTwitter />,
      background: '#1B97F0',
    },
    {
      title: 'E-mail',
      icon: <HiOutlineMail />,
      background: '#4F5559',
    },
  ];

  const composeUrlToShare = (socialNetwork: string) => {
    const socialNetworkFormatted = socialNetwork
      .replace(/-/g, '')
      .toLowerCase();

    switch (socialNetworkFormatted) {
      case 'whatsapp':
        return `https://wa.me/?text=${body}`;
      case 'facebook':
        return `https://www.facebook.com/share.php?u=${linkReferral}&quote=${body}`;
      case 'linkedin':
        return `https://www.linkedin.com/sharing/share-offsite/?url=${linkReferral}`;
      case 'twitter':
        return `https://twitter.com/intent/tweet?text=${body}`;
      case 'email':
        return `mailto:?subject=Indicação%20subjsect&body=${body}`;
      default:
        return '';
    }
  };

  return (
    <S.Container>
      {socialNetworks.map((socialNetwork) => (
        <S.SocialNetworkLink
          key={socialNetwork.title}
          background={socialNetwork.background}
          target="_blank"
          href={composeUrlToShare(socialNetwork.title)}
          rel="noreferrer"
        >
          {socialNetwork.icon}
          {socialNetwork.title}
        </S.SocialNetworkLink>
      ))}
    </S.Container>
  );
};

export default SocialNetworks;
