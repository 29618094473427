/* eslint-disable no-console */
/* eslint-disable radar/no-duplicate-string */
/* eslint-disable radar/cognitive-complexity */
import { useCallback } from 'react';

import { useQueryClient } from 'react-query';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import { useParams } from 'react-router';

import {
  AskingEvent,
  CallToActionEvent,
  ChatEvent,
  EmojiEvent,
  PollEvent,
  QuizEvent,
  WordCloudEvent,
} from '@common/types/pusher/events';
import { ROOM_SETTING_EVENT } from '@constants';
import {
  EmojiStatisticsData,
  RoomBroadcastData,
  RoomInteractions,
} from '@common/types/api';
import { BroadcastPageParams } from '@pages/Broadcast/types';

type InteractionEvents =
  | AskingEvent
  | ChatEvent
  | EmojiEvent
  | PollEvent
  | QuizEvent
  | WordCloudEvent
  | CallToActionEvent;

const generateBroadcastChannel = (
  interaction: keyof RoomInteractions,
  roomId?: number,
) => `room.${roomId}.${interaction}`;

export const useBroadcastChannels = (roomId?: number) => {
  const queryClient = useQueryClient();
  const { roomSlug } = useParams<BroadcastPageParams>();

  const chatChannel = useChannel(generateBroadcastChannel('chat', roomId));
  const emojiChannel = useChannel(generateBroadcastChannel('emoji', roomId));
  const pollChannel = useChannel(generateBroadcastChannel('poll', roomId));
  const quizChannel = useChannel(generateBroadcastChannel('quiz', roomId));
  const askingChannel = useChannel(generateBroadcastChannel('asking', roomId));
  const ctaChannel = useChannel(
    generateBroadcastChannel('call_action', roomId),
  );
  const cloudChannel = useChannel(
    generateBroadcastChannel('word_cloud', roomId),
  );

  const handleEnableInteractionsEvents = useCallback(
    (response?: InteractionEvents) => {
      if (!response) {
        return;
      }

      const queryKey = ['broadcast', roomSlug];

      const previousCache =
        queryClient.getQueryData<RoomBroadcastData>(queryKey);

      if (previousCache) {
        const updatedSetting = {} as RoomInteractions;

        if ('asking' in response) {
          console.info(
            `Perguntas ${response.asking ? 'disponíveis' : 'desabilitadas'}`,
          );

          Object.assign(updatedSetting, { asking: response.asking });
        } else if ('chat' in response) {
          console.info(`Chat ${response.chat ? 'disponível' : 'desabilitado'}`);

          Object.assign(updatedSetting, { chat: response.chat });
        } else if ('emoji' in response) {
          console.info(
            `Emojis ${response.emoji ? 'disponíveis' : 'desabilitados'}`,
          );

          Object.assign(updatedSetting, { emoji: response.emoji });

          const emojiQueryKey = ['emoji', roomId];

          const previousEmojiCache =
            queryClient.getQueryData<EmojiStatisticsData>(emojiQueryKey);

          queryClient.setQueryData<EmojiStatisticsData>(emojiQueryKey, {
            ...(previousEmojiCache || {}),
            ...response.data,
          });
        } else if ('poll' in response) {
          console.info(
            `Enquetes ${response.poll ? 'disponíveis' : 'desabilitadas'}`,
          );

          Object.assign(updatedSetting, { poll: response.poll });

          queryClient.invalidateQueries(['poll', roomId]);
        } else if ('quiz' in response) {
          console.info(
            `Quiz ${response.quiz ? 'disponíveis' : 'desabilitados'}`,
          );

          Object.assign(updatedSetting, { quiz: response.quiz });

          queryClient.invalidateQueries(['quiz', roomId]);
        } else if ('word_cloud' in response) {
          console.info(
            `Nuvem de palavras ${
              response.word_cloud ? 'disponível' : 'desabilitada'
            }`,
          );

          Object.assign(updatedSetting, { word_cloud: response.word_cloud });

          queryClient.invalidateQueries(['word_cloud', roomId]);
        } else if ('call_action' in response) {
          console.info(
            `CTA ${response.call_action ? 'disponível' : 'desabilitado'}`,
          );

          Object.assign(updatedSetting, { call_action: response.call_action });
          Object.assign(previousCache, {
            callAction: {
              data: response.data,
            },
          });
        }

        queryClient.setQueryData<RoomBroadcastData>(queryKey, {
          ...previousCache,
          settings: {
            data: {
              ...previousCache.settings.data,
              ...updatedSetting,
            },
          },
        });
      }
    },
    [queryClient, roomId, roomSlug],
  );

  useEvent(chatChannel, ROOM_SETTING_EVENT, handleEnableInteractionsEvents);
  useEvent(emojiChannel, ROOM_SETTING_EVENT, handleEnableInteractionsEvents);
  useEvent(askingChannel, ROOM_SETTING_EVENT, handleEnableInteractionsEvents);
  useEvent(pollChannel, ROOM_SETTING_EVENT, handleEnableInteractionsEvents);
  useEvent(quizChannel, ROOM_SETTING_EVENT, handleEnableInteractionsEvents);
  useEvent(cloudChannel, ROOM_SETTING_EVENT, handleEnableInteractionsEvents);
  useEvent(ctaChannel, ROOM_SETTING_EVENT, handleEnableInteractionsEvents);
};
