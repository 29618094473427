type UIAvatarsParams = {
  name: string;
  size: '64' | '128' | '256' | '512';
  background: string;
  color: string;
  length?: '1' | '2';
  'font-size'?:
    | '0.1'
    | '0.2'
    | '0.3'
    | '0.4'
    | '0.5'
    | '0.6'
    | '0.7'
    | '0.8'
    | '0.9'
    | '1.0';
  rounded?: 'true' | 'false';
  uppercase?: 'true' | 'false';
  bold?: 'true' | 'false';
  format?: 'png' | 'svg';
};

export const getAvatarFallback = (
  src: string | null,
  name: string,
  background: string,
) => {
  const fallbackParams: UIAvatarsParams = {
    name: encodeURIComponent(name),
    size: '128',
    background,
    color: '#fff',
    format: 'svg',
  };

  const fallbackUrl = new URL('https://ui-avatars.com/api');
  fallbackUrl.search = new URLSearchParams(fallbackParams).toString();

  return src && src !== 'undefined' ? src : fallbackUrl.toString();
};
