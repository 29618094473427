import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

type SocialNetworkLinkProps = { background: string };

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.875rem;
  margin-top: 0.938rem;
  ${down('md')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const SocialNetworkLink = styled.a<SocialNetworkLinkProps>`
  ${({ background }) => css`
    display: flex;
    align-items: center;
    background: ${background};
    border-radius: 1.875rem;
    font-weight: 300;
    font-size: 0.875rem;
    color: #ffffff;
    padding: 0.625rem 1rem;
    > svg {
      margin-right: 0.5rem;
      font-size: 1.25rem;
    }
    ${down('md')} {
      font-size: 0.75rem;
      width: 8.4rem;
    }
  `}
`;
