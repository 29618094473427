import { useTranslation } from 'react-i18next';

import { MetaTags } from '@components';
import { FeedIllustration } from '@assets';

import Feed from './Feed';
import { LeftSidebar, RightSidebar } from './Sidebar';
import * as S from './styles';

const NewsFeed = () => {
  const { t } = useTranslation();
  const title = t('pages.newsfeed.subTitle');
  return (
    <S.Container>
      <MetaTags title="NewsFeed" />

      <S.NewsfeedHeader>
        <div>
          <span>{title}</span>
          <h1>NewsFeed</h1>
        </div>

        <FeedIllustration />
      </S.NewsfeedHeader>

      <S.Wrapper>
        <LeftSidebar />
        <Feed />
        <RightSidebar />
      </S.Wrapper>
    </S.Container>
  );
};

export default NewsFeed;
