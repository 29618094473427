import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.form`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background: ${theme.colors.background[100]};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);

    ${down('md')} {
      max-width: 100%;
    }
  `}
`;

export const Footer = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    > div {
      display: flex;
      gap: 1rem;
    }
  `}
`;
