/* eslint-disable react/no-danger */
import { useParams } from 'react-router';
import { MdOndemandVideo } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

import { MetaTags } from '@components';
import { useVideoDetailQuery, useVideosQuery } from '@hooks';

import * as S from './styles';
import VideoPlayer from './Video';
import VideosSidebar from './Sidebar';

type VideoDetailParams = { videoId: string };

const VideoGallery = () => {
  const { videoId } = useParams<VideoDetailParams>();
  const { t } = useTranslation();

  const { isLoading, data } = useVideosQuery(1);
  const videoDetailQuery = useVideoDetailQuery(videoId ?? data?.videos[0].id);

  if (isLoading || !data?.videos.length) {
    return null;
  }

  return (
    <S.Container>
      <MetaTags
        title={videoDetailQuery.data?.title || t('pages.videos.title')}
      />

      <S.VideoProfileHeader
        title={t('pages.videos.title')}
        icon={MdOndemandVideo}
      />
      {!videoDetailQuery.isSuccess ? (
        <S.ProfileWrapper>
          <S.VideoDetailData>
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: 'absolute', top: 0, left: 0 }}
            />
          </S.VideoDetailData>
        </S.ProfileWrapper>
      ) : (
        <S.ProfileWrapper>
          <S.VideoDetailData>
            <section>
              <VideoPlayer video={videoDetailQuery.data} />
            </section>
          </S.VideoDetailData>
          <VideosSidebar />
        </S.ProfileWrapper>
      )}
    </S.Container>
  );
};

export default VideoGallery;
