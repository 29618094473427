export const theme = {
  colors: {
    brand: {
      primary: {
        default: 'var(--colors-brand-primary)',
        hover: 'var(--colors-brand-primary-hover)',
        active: 'var(--colors-brand-primary-active)',
        disabled: 'var(--colors-brand-primary-disabled)',
        opaque: 'var(--colors-brand-primary-opaque)',
        text: 'var(--colors-brand-primary-text)',
      },
      secondary: {
        default: 'var(--colors-brand-secondary)',
        hover: 'var(--colors-brand-secondary-hover)',
        active: 'var(--colors-brand-secondary-active)',
        disabled: 'var(--colors-brand-secondary-disabled)',
        opaque: 'var(--colors-brand-secondary-opaque)',
        text: 'var(--colors-brand-secondary-text)',
      },
    },
    primary: {
      100: 'var(--colors-primary-100)',
      80: 'var(--colors-primary-80)',
      60: 'var(--colors-primary-60)',
      40: 'var(--colors-primary-40)',
      20: 'var(--colors-primary-20)',
    },
    secondary: {
      100: 'var(--colors-secondary-100)',
      80: 'var(--colors-secondary-80)',
      60: 'var(--colors-secondary-60)',
      40: 'var(--colors-secondary-40)',
    },
    background: {
      100: 'var(--colors-background-100)',
      80: 'var(--colors-background-80)',
      60: 'var(--colors-background-60)',
      40: 'var(--colors-background-40)',
    },
    foreground: {
      100: 'var(--colors-foreground-100)',
      80: 'var(--colors-foreground-80)',
      60: 'var(--colors-foreground-60)',
      40: 'var(--colors-foreground-40)',
    },
    error: {
      100: 'var(--colors-error-100)',
      80: 'var(--colors-error-80)',
      60: 'var(--colors-error-60)',
      40: 'var(--colors-error-40)',
    },
    success: {
      100: 'var(--colors-success-100)',
      80: 'var(--colors-success-80)',
      60: 'var(--colors-success-60)',
      40: 'var(--colors-success-40)',
    },
    warning: {
      100: 'var(--colors-warning-100)',
      80: 'var(--colors-warning-80)',
      60: 'var(--colors-warning-60)',
      40: 'var(--colors-warning-40)',
    },
    base: {
      text: 'var(--colors-text-base)',
      border: 'var(--colors-border-base)',
    },
    gradients: {
      primary: {
        0: 'var(--colors-gradient-primary-0)',
        100: 'var(--colors-gradient-primary-100)',
      },
      secondary: {
        0: 'var(--colors-gradient-secondary-0)',
        100: 'var(--colors-gradient-secondary-100)',
      },
    },
  },
  fontSizes: {
    default: '1.4rem',
    large: '1.6rem',
    small: '1.2rem',
    tiny: '1rem',
  },
  spacing: {
    default: '1.6rem',
    vertical: '1.6rem 0',
    horizontal: '0 1.6rem',
    large: '2.4rem',
  },
  transition: {
    default: '120ms ease-in-out',
  },
  radii: {
    default: '0.8rem',
    small: '0.4rem',
    smallTop: '0.4rem 0.4rem 0 0',
    smallBottom: '0 0 0.4rem 0.4rem',
    tiny: '0.2rem',
  },
  shadows: {
    default: '0 0.5rem 2rem rgba(0, 0, 0, 0.08)',
    flat: '0 0.2rem 0.2rem rgba(0, 0, 0, 0.08)',
  },
  breakpoints: {
    sm: '0px', // on breakpoint function `down` read as maxWidth: 576px
    md: '576px', // on breakpoint function `down` read as maxWidth: 768px
    lg: '768px', // on breakpoint function `down` read as maxWidth: 992px
    xl: '992px', // on breakpoint function `down` read as maxWidth: 1200px
    xxl: '1200px', // on breakpoint function `down` read as maxWidth: 1400px
    xxxl: '1400px',
  },
} as const;
