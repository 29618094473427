import { ComponentProps } from 'react';

import { QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AxiosError } from 'axios';

import { IS_PRD } from '@constants';

type ReactQueryDevtoolsProps = ComponentProps<typeof ReactQueryDevtools>;

type ToggleButtonProps = ReactQueryDevtoolsProps['toggleButtonProps'];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, err) => {
        if (!IS_PRD) {
          return false;
        }

        const error = err as AxiosError;

        if (error.isAxiosError && error.response) {
          const errStatus = error.response?.status;

          if (errStatus >= 400 && errStatus < 500) {
            return false;
          }
        }

        return failureCount <= 3;
      },
      refetchOnWindowFocus: IS_PRD,
    },
  },
});

export const DEVTOOLS_TOGGLE_BUTTON_PROPS: ToggleButtonProps = {
  style: { left: '0.75rem', bottom: '0.75rem' },
};
