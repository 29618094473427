import { useMemo } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { enUS } from 'date-fns/locale';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FiXCircle } from 'react-icons/fi';

import { MetaTags, ScheduleSession, Pagination } from '@components';
import {
  useEventfy,
  usePagination,
  useScheduleQuery,
  useSelectRoomsQuery,
  useSelectScheduleDatesQuery,
} from '@hooks';
import { selectHasMoreThanOneRoom } from '@hooks/useEventfy/selectors';
import { generateArray } from '@utils';
import { CalendarIllustration } from '@assets';
import { HistoryState } from '@services/history';
import { SelectRoomsData } from '@common/types/api';
import { ReactSelect } from '@components/Select/styles';

import * as S from './styles';

type HandlePagination = (selectedItem: { selected: number }) => void;

const Schedule = () => {
  const history = useHistory<HistoryState>();
  const { search } = useLocation<HistoryState>();
  const eventHasManyRooms = useEventfy(selectHasMoreThanOneRoom);

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const currentPage = Number(queryParams.get('page') || '1');
  const roomId = queryParams.get('roomId');
  const scheduleDate = queryParams.get('date');

  const selectRoomsQuery = useSelectRoomsQuery(eventHasManyRooms);
  const selectDatesQuery = useSelectScheduleDatesQuery();
  const { t } = useTranslation();

  const scheduleQuery = useScheduleQuery(currentPage, {
    date: scheduleDate || undefined,
    roomId: roomId ? +roomId : undefined,
  });

  const pagination = usePagination(
    scheduleQuery.data?.meta.pagination.total_pages,
  );

  const formattedDates = useMemo(
    () =>
      selectDatesQuery.data?.map(({ value }) => {
        const parsedDate = parseISO(value);

        const formattedDate = format(parsedDate, 'dd MMM', {
          locale: enUS,
        });

        const [day, month] = formattedDate.split(' ');

        return {
          day,
          month,
          value,
        };
      }),
    [selectDatesQuery.data],
  );

  const handleDateFilter = useCallback(
    (newDate: string) => () => {
      if (scheduleDate === newDate) {
        queryParams.delete('date');
      } else {
        queryParams.set('date', newDate);
      }

      history.push({
        search: `?${queryParams.toString()}`,
      });
    },
    [queryParams, scheduleDate, history],
  );

  const handleRoomFilter = useCallback(
    (e?: SelectRoomsData) => {
      if (!e) {
        queryParams.delete('roomId');
      } else {
        queryParams.set('roomId', String(e.value));
      }

      history.push({
        search: `?${queryParams.toString()}`,
      });
    },
    [queryParams, history],
  );

  const handlePageNavigation = useCallback<HandlePagination>(
    ({ selected }) => {
      queryParams.set('page', String(selected + 1));

      history.push({
        search: `?${queryParams.toString()}`,
      });
    },
    [history, queryParams],
  );

  useEffect(() => {
    handlePageNavigation({ selected: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, scheduleDate]);

  return (
    <S.Container>
      <MetaTags title={t('pages.schedule.title')} />
      <S.ScheduleHeader>
        <div>
          <span>{t('pages.schedule.subTitle')}</span>
          <h1>{t('pages.schedule.title')}</h1>
        </div>

        <CalendarIllustration />
      </S.ScheduleHeader>

      <S.FilterContainer>
        <S.DatesWrapper>
          {formattedDates?.map((session) => {
            const isSelected = scheduleDate === session.value;

            return (
              <S.DateButton
                type="button"
                key={session.value}
                $selected={isSelected}
                onClick={handleDateFilter(session.value)}
              >
                <strong>{session.day}</strong>
                <span>{t(`date.month.${session.month}`)}</span>

                {isSelected && <FiXCircle />}
              </S.DateButton>
            );
          })}
        </S.DatesWrapper>

        {eventHasManyRooms && (
          <ReactSelect
            isClearable
            placeholder={t('pages.schedule.filter')}
            options={selectRoomsQuery.data}
            onChange={handleRoomFilter}
            value={
              roomId && selectRoomsQuery.data?.find((e) => e.value === +roomId)
            }
          />
        )}
      </S.FilterContainer>

      <S.ListWrapper>
        {scheduleQuery.isLoading
          ? generateArray(4).map((key) => <ScheduleSession key={key} loading />)
          : scheduleQuery.data?.schedule.map((session) => (
              <ScheduleSession key={`session-${session.id}`} data={session} />
            ))}
      </S.ListWrapper>
      <Pagination
        pageCount={pagination}
        forcePage={currentPage - 1}
        onPageChange={handlePageNavigation}
      />
    </S.Container>
  );
};

export default Schedule;
