import { PropsWithRequiredChildren } from '@common/types';
import { IntegrationLocation } from '@common/types/api';
import { TawkTo, WhatsAppButton } from '@components';
import { useEventfy } from '@hooks';

import * as S from './styles';

const AuthLayout = ({ children }: PropsWithRequiredChildren) => {
  const { name, banner_login } = useEventfy();

  return (
    <S.AuthContainer>
      <S.HeroImageWrapper>
        <S.HeroImage src={banner_login} alt={name} />
      </S.HeroImageWrapper>

      <S.FormsWrapper>{children}</S.FormsWrapper>

      <TawkTo location={IntegrationLocation.LOGIN_AND_REGISTER} />
      <WhatsAppButton location={IntegrationLocation.LOGIN_AND_REGISTER} />
    </S.AuthContainer>
  );
};

export default AuthLayout;
