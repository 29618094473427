import { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
import { Socket } from 'socket.io-client';
import { FiMoreVertical } from 'react-icons/fi';
import parseISO from 'date-fns/parseISO';
import Tippy from '@tippyjs/react';

import { ReceivedChatMessage } from '@common/types/chat-socket';
import { Avatar, Button } from '@components';
import { selectUser } from '@hooks/useAuth/selectors';
import { useAuth } from '@hooks';
import { api } from '@services';

import * as S from './styles';

type MessageProps = ReceivedChatMessage & {
  groupId?: number;
  groupKey?: string;
  socket?: Socket;
};

const Message = ({ groupId, groupKey, socket, ...data }: MessageProps) => {
  const user = useAuth(selectUser);
  const { t } = useTranslation();

  const {
    id,
    author_id,
    author_avatar,
    author_name,
    content,
    createdAt,
    moderator: isMessageUserAModerator,
  } = data;

  const isCurrentUserAModerador = !!user.moderator;
  const isCurrentUser = Number(author_id) === user.id;

  const hasMessageBeenDeleted =
    content.toLowerCase() === t('pages.broadcast.chat.messageRemoved');

  const handleDeleteMessage = () => {
    socket?.emit('remove.message', {
      group_id: groupId,
      group_key: groupKey,
      message_id: id,
    });
  };

  const handleBlockUser = async () => {
    await api.put(`/user/${author_id}/blockChat`);
  };
  const at = t('pages.broadcast.chat.at');
  const formattedDate = useMemo(
    () =>
      format(parseISO(createdAt), `'${at} 'HH'h'mm`, {
        locale: ptBR,
      }),
    [createdAt, at],
  );

  return (
    <S.Container
      $currentUser={isCurrentUser}
      $removedMessage={hasMessageBeenDeleted}
    >
      <Avatar src={author_avatar} alt={author_name} />

      <S.MessageContent $currentUser={isCurrentUser}>
        <S.MessageInfo>
          <S.MessageHeader $currentUser={isCurrentUser}>
            <div>
              <h4>
                {author_name}
                <time>{formattedDate}</time>
              </h4>

              {isMessageUserAModerator && (
                <span>{t('pages.broadcast.chat.moderator')}</span>
              )}
            </div>

            {isCurrentUserAModerador && !hasMessageBeenDeleted && (
              <S.ModeratorOptionsContainer $currentUser={isCurrentUser}>
                <Tippy
                  trigger="click"
                  inertia
                  hideOnClick
                  interactive
                  theme="light-border"
                  animation="shift-away-subtle"
                  zIndex={4}
                  placement="bottom-start"
                  interactiveBorder={12}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  appendTo={document.getElementById('tippy-portal')!}
                  content={
                    <>
                      <S.ModeratorOptionsButton
                        type="button"
                        onClick={handleDeleteMessage}
                      >
                        {t('pages.broadcast.chat.remove')}
                      </S.ModeratorOptionsButton>
                      <S.ModeratorOptionsButton
                        type="button"
                        onClick={handleBlockUser}
                      >
                        {t('pages.broadcast.chat.blockChat')}
                      </S.ModeratorOptionsButton>
                    </>
                  }
                >
                  <Button
                    size="even"
                    variant="transparent"
                    icon={FiMoreVertical}
                  />
                </Tippy>
              </S.ModeratorOptionsContainer>
            )}
          </S.MessageHeader>
        </S.MessageInfo>

        <p>{content}</p>
      </S.MessageContent>
    </S.Container>
  );
};

export default memo(Message);
