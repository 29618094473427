import addHours from 'date-fns/addHours';
import { CookieAttributes } from 'js-cookie';

import { IS_PRD } from '@constants';

export const DEFAULT_COOKIE_OPTIONS: CookieAttributes = {
  expires: addHours(new Date(), 10),
  sameSite: 'Strict',
  secure: IS_PRD,
};
