import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    height: 4.25rem;
    padding: 0 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: all;
    background: ${theme.colors.background[100]};

    box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0px 6.7px 5.3px rgba(0, 0, 0, 0.028), 0px 12.5px 10px rgba(0, 0, 0, 0.035),
      0px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0px 41.8px 33.4px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.07);

    > a {
      img {
        max-height: 2.5rem;
      }
    }

    > div {
      display: flex;
    }

    ${down('md')} {
      padding: 0;
      position: relative;

      > a {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  `}
`;

export const UserMobileMenuButton = styled.button`
  background: none;
  padding: 0;
`;

export const IndicationsButton = styled.button`
  background: none;
  padding: 0;
  ${down('md')} {
    margin-right: 0.625rem;
  }
`;
