import { Helmet } from 'react-helmet-async';

import { useEventfy } from '@hooks';

export type MetaTagsProps = Partial<{
  title: string;
  description: string;
  canonical: string;
  image: string;
}>;

const MetaTags = (props: MetaTagsProps) => {
  const event = useEventfy();

  const {
    title,
    description = 'Eventos Digitais para sua Empresa.',
    canonical,
    image = 'https://xcode-eventfy.sfo3.digitaloceanspaces.com/eventfy/rooms/f9df7ed197ec7f8817909cc487658641e4e122a8-ti.jpg',
  } = props;

  const eventName = event.name || 'Eventfy';

  const pageTitle = title ? `${title} | ${eventName}` : eventName;
  const eventDescription = event.description || description;
  const eventImage = event.banner_app || image;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={eventDescription} />
      <meta name="theme-color" content={event.primary_color} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={pageTitle} />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta property="og:site_name" content={pageTitle} />
      <meta property="og:url" content={`${canonical}`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={eventDescription} />
      <meta name="twitter:site" content="Eventfy" />
      <meta name="twitter:creator" content="XCode Tecnologia" />
      <meta property="og:image" content={eventImage} />
      {eventImage && <meta name="twitter:image" content={eventImage} />}
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

export default MetaTags;
