import styled, { css } from 'styled-components';

export const Container = styled.div`
  grid-area: Feed;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;

  > div {
    overflow: hidden;
  }
`;

export const LoadMoreButton = styled.button`
  ${({ theme }) => css`
    border-radius: 1000rem;
    color: ${theme.colors.brand.primary.default};
    background: ${theme.colors.brand.primary.opaque};
    transition: 180ms ease-in-out;

    :hover {
      color: ${theme.colors.brand.primary.text};
      background: ${theme.colors.brand.primary.default};
    }
  `};
`;
