import styled from 'styled-components';

import { SidebarItem } from '../styles';

export const Container = styled(SidebarItem)`
  .banners-carousel {
    height: 100%;

    img,
    a {
      width: 100%;
      height: 100%;
      display: block !important;
      object-fit: cover;
    }
  }
`;
