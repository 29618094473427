import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { Link } from 'react-router-dom';

export const Container = styled.section`
  ${() => css`
    margin: 4rem 0;

    > header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  `}
`;

export const Message = styled.div`
  padding: 0.25rem 0.5rem;
`;

export const TimerWrapper = styled.div`
  div {
    overflow: hidden;
    text-align: right;
    margin-bottom: 1rem;
    font-size: 0.875rem;

    strong {
      font-size: 400;
      display: inline-block;
      width: 2.625rem;
      margin-left: 3px;
    }

    svg {
      margin-left: 1rem;
    }
  }
`;

export const RankingList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.5rem;
  max-height: 19.25rem;
  position: relative;

  ${down('md')} {
    max-height: initial;
  }
`;

export const EmptyPlaceholder = styled.div`
  ${({ theme }) => css`
    display: grid;
    place-content: center;
    height: 18rem;
    position: relative;
    z-index: 1;
    color: ${theme.colors.brand.secondary.default};

    :before {
      content: '';
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      opacity: 0.2;
      background: ${theme.colors.brand.secondary.opaque};
    }
  `}
`;

export const ViewMore = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    width: 100%;
    height: 6rem;
    right: 0;
    bottom: -1rem;
    left: 0;
    background: linear-gradient(
      0deg,
      ${theme.colors.background[80]} 15%,
      rgba(0, 0, 0, 0) 90%
    );

    span {
      position: absolute;
      left: 47.15%;
      bottom: -0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      color: ${theme.colors.brand.primary.default};
      background: ${theme.colors.background[100]};
      transition: 180ms 80ms ease-in-out;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
        0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.005);
    }

    :hover,
    :hover * {
      filter: none !important;

      span {
        transform: scale(1.05);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023),
          0 2px 2px rgba(0, 0, 0, 0.023), 0 4px 4px rgba(0, 0, 0, 0.023),
          0 8px 8px rgba(0, 0, 0, 0.023), 0 16px 16px rgba(0, 0, 0, 0.023),
          0 32px 32px rgba(0, 0, 0, 0.023);
      }
    }

    :active {
      span {
        transform: scale(0.95);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047),
          0 2px 2px rgba(0, 0, 0, 0.047), 0 4px 4px rgba(0, 0, 0, 0.047);
      }
    }
  `}
`;
