import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    align-self: center;
    margin-bottom: 1.5rem;

    label {
      margin: 0 auto;
      display: block;
      position: relative;
      width: 9rem;
      height: 9rem;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      transition: 180ms ease-in-out;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
        0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.005);

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 3px solid ${theme.colors.background[80]};
        background: rgba(0, 0, 0, 0.3);
      }

      input {
        display: none;
      }

      :hover {
        opacity: 0.85;
        transform: scale(1.025);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023),
          0 2px 2px rgba(0, 0, 0, 0.023), 0 4px 4px rgba(0, 0, 0, 0.023),
          0 8px 8px rgba(0, 0, 0, 0.023), 0 16px 16px rgba(0, 0, 0, 0.023),
          0 32px 32px rgba(0, 0, 0, 0.023);
      }

      :active {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047),
          0 2px 2px rgba(0, 0, 0, 0.047), 0 4px 4px rgba(0, 0, 0, 0.047);
      }

      :before {
        content: 'ENVIAR FOTO';
        position: absolute;
        bottom: 3px;
        left: 50%;
        transform: translateX(-50%);
        color: ${theme.colors.base.text};
        border-radius: 50%;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 80) 0%,
          rgba(0, 0, 0, 0) 35%
        );
        width: 9rem;
        height: 9rem;
        font-size: 0.625rem;
        line-height: 15.75rem;
        text-align: center;
        font-weight: 500;
      }
    }
  `};
`;
