import { ComponentProps } from 'react';

import styled, { css } from 'styled-components';
import Select from 'react-select';

type Styles = ComponentProps<typeof Select>['styles'];

const styles: Styles = {
  control: (provided, _state) => ({
    ...provided,
    height: '4rem',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  container: (provided, _state) => ({
    ...provided,
    flex: 1,
    minWidth: 100,
  }),
  input: (provided, _state) => ({
    ...provided,
    color: '#05162b',
  }),
  dropdownIndicator: (provided, _state) => ({
    ...provided,
    cursor: 'pointer',
  }),
  valueContainer: (provided, _state) => ({
    ...provided,
    cursor: 'text',
  }),
  option: (provided, _state) => ({
    ...provided,
    cursor: 'pointer',
    color: '#05162b',
    background: '#f1f4f9',
  }),
  menuList: (provided, _state) => ({
    ...provided,
    // kill the white space on first and last option
    padding: 0,
    color: '#05162b',
    background: '#f1f4f9',
    borderRadius: '0.3rem',
    // border: '1px solid #d9e1e7',
  }),
};
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

export const ReactSelect = styled(Select).attrs(() => ({
  className: 'react-select-container',
  classNamePrefix: 'react-select',
  blurInputOnSelect: true,
  styles,
}))`
  ${({ theme, $error: hasError }) => css`
    & .react-select {
      &__control {
        color: ${theme.colors.foreground[100]};
        background: ${theme.colors.background[100]};
        border-radius: ${theme.radii.default};
        border: 2px solid  ${
          hasError ? theme.colors.error[100] : theme.colors.base.border
        };
        ${
          hasError &&
          css`
            &:hover {
              border: 2px solid
                ${hasError ? theme.colors.error[100] : theme.colors.base.border};
            }
          `
        }
      }

      &__input {
        color: ${theme.colors.foreground[100]};
      }

      &__placeholder {
        color: {theme.colors.foreground[40]};
      }

      &__indicator-separator {
        background: ${theme.colors.base.border};
      }

      &__dropdown-indicator {
        color: ${theme.colors.foreground[40]};
      }

      &__single-value {
        color: ${theme.colors.foreground[100]};
      }

      &__option {
        color: ${theme.colors.foreground[100]};
        background: ${theme.colors.background[80]};
      }

      &__menu {
        background: ${theme.colors.background[80]};
      }
    }
  `}
`;

export const ErrorWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: -0.625rem;
    left: calc(0.75rem + 1px);
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    color: ${theme.colors.error[100]};
    background: ${theme.colors.background[100]};
  `}
`;
