import { useEffect, useState, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useBroadcastQuery, useEventfy } from '@hooks';
import { selectSecondaryColorRgbValues } from '@hooks/useEventfy/selectors';

import * as S from './styles';
import { TABS } from './constants';

const Interactions = () => {
  const history = useHistory();
  const { hash } = useLocation();
  const secondaryColor = useEventfy(selectSecondaryColorRgbValues);
  const { t } = useTranslation();

  const { data: settings } = useBroadcastQuery({
    select: (data) => data.settings.data,
    notifyOnChangeProps: ['data'],
  });

  const [firstActiveSettingId] = useMemo(
    () =>
      Object.entries(settings || {})
        .filter(([setting]) =>
          ['chat', 'asking', 'quiz', 'poll', 'word_cloud'].includes(setting),
        )
        .find(([_, value]) => !!value) || [],
    [settings],
  );

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const setActiveTab = (id: string) => () => history.push(`#${id}`);

  useEffect(() => {
    const tabIndexFromHash = TABS.findIndex(({ id }) => `#${id}` === hash);
    const firstActiveSettingIndex = TABS.findIndex(
      ({ id }) => id === (firstActiveSettingId || 'chat'),
    );

    if (settings) {
      if (settings[TABS[tabIndexFromHash]?.id]) {
        setActiveTabIndex(tabIndexFromHash);
      } else {
        history.push({
          hash: `#${TABS[firstActiveSettingIndex]?.id}`,
        });
      }
    }
  }, [hash, history, firstActiveSettingId, settings]);

  useEffect(() => {
    const handleTabKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleTabKeyDown);

    return () => {
      document.removeEventListener('keydown', handleTabKeyDown);
    };
  }, []);

  return (
    <S.TabsContainer>
      <S.TabList role="tablist">
        {TABS.map((tab, index) => (
          <S.TabItem
            key={tab.id}
            role="presentation"
            $active={activeTabIndex === index}
            $disabled={!settings?.[tab.id]}
            onClick={setActiveTab(tab.id)}
            initial={{ borderBottomColor: `rgba(${secondaryColor}, 0)` }}
            animate={{
              borderBottomColor: `rgba(${secondaryColor}, ${
                activeTabIndex === index ? 1 : 0
              })`,
            }}
          >
            <tab.icon size="1.25rem" />

            <span>{t(tab.title)}</span>
          </S.TabItem>
        ))}
      </S.TabList>

      <S.TabContentWrapper>
        <S.AnimatedTab
          initial={false}
          animate={{ x: `${activeTabIndex * -100}%` }}
          transition={{
            type: 'spring',
            stiffness: 30,
          }}
        >
          {TABS.map((tab, index) => (
            <S.TabContent key={tab.id} role="tabpanel">
              <tab.content active={activeTabIndex === index} />
            </S.TabContent>
          ))}
        </S.AnimatedTab>
      </S.TabContentWrapper>
    </S.TabsContainer>
  );
};

export default Interactions;
