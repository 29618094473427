import { AuthContextData } from '@contexts/auth';

export const selectUser = ({ user }: AuthContextData) => user;

export const selectUserName = ({ user }: AuthContextData) => user.name;

export const selectUserFirstName = ({ user }: AuthContextData) =>
  user.name.split(' ')[0];

export const selectUserId = ({ user }: AuthContextData) => user.id;

export const selectUserUUID = ({ user }: AuthContextData) => user.uuid;

export const selectUserHasRepliedSurvey = ({ user }: AuthContextData) =>
  !!user.survey_replied;

export const selectUpdateProfile = ({ updateProfileState }: AuthContextData) =>
  updateProfileState;

export const selectSignOut = ({ signOut }: AuthContextData) => signOut;

export const selectIsAuthenticated = ({ user }: AuthContextData) =>
  !!user.key_auth;
