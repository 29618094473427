import styled, { css } from 'styled-components';

import { TransientProps } from '@common/types';
import { Input } from '@components';

import { ContentWrapper } from '../styles';

type ContainerProps = TransientProps<{ disabled: boolean; hide: boolean }>;

export const Container = styled.form<ContainerProps>`
  ${({ $disabled: isDisabled, $hide: shouldHide }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1rem 0;
    transition: 500ms ease-in-out;

    ${isDisabled &&
    css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
    `}

    ${shouldHide &&
    css`
      display: none;
    `}
  `}
`;

export const ChatContentWrapper = styled(ContentWrapper)`
  display: flex;
`;

export const ChatInput = styled(Input)`
  height: 3.25rem;
  max-width: calc(100% - 2rem);
  margin: 0 1rem;

  > svg {
    margin: 0 0 0 0.5rem;
  }

  input {
    font-size: 0.875rem;
  }
`;
