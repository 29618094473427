import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.article`
  height: 100%;
`;

export const Wrapper = styled.section`
  ${({ theme }) => css`
    padding: 1.5rem;
    border-radius: 1.5rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    background: ${theme.colors.brand.secondary.opaque};
    background: linear-gradient(
      115deg,
      rgba(0, 0, 0, 0) 5%,
      ${theme.colors.brand.secondary.opaque} 25%,
      ${theme.colors.brand.secondary.default} 500%
    );

    > div:first-child {
      span {
        display: flex;
        align-items: center;
        color: ${theme.colors.brand.secondary.default};
      }

      h1 {
        font-size: 3rem;
        color: #2c3f5e;
      }
    }

    ${down('md')} {
      margin: 0 -1rem;
      width: calc(100% + 2rem);
    }
  `}
`;

export const Message = styled.div`
  padding: 0.25rem 0.5rem;
`;

export const TimerWrapper = styled.div`
  margin-top: 1.75rem;

  div {
    overflow: hidden;
    text-align: right;
    margin-bottom: 1rem;
    font-size: 0.875rem;

    strong {
      font-size: 400;
      display: inline-block;
      width: 2.625rem;
      margin-left: 3px;
    }
  }

  ${down('md')} {
    div {
      text-align: left;
    }
  }
`;
