import styled, { css } from 'styled-components';

import { TransientProps } from '@common/types';

export const Container = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: relative;

    > span {
      position: absolute;
      top: 0;
      right: 0;
      display: grid;
      place-content: center;
      padding: 0.25rem 0.625rem;
      border-radius: 1000px;
      font-size: 0.625rem;
      font-weight: 700;
      background: ${theme.colors.brand.secondary.opaque};
      color: ${theme.colors.brand.secondary.default};
    }

    & + & {
      margin-top: 1.5rem;
    }
  `}
`;

export const ProfileData = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 1rem;

    > strong {
      display: grid;
      place-content: center;
      width: 2.25rem;
      vertical-align: middle;
      font-size: 1.5rem;
      font-weight: 400;
    }

    > div:last-child {
      strong {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
      }
    }
  `}
`;

const medalVariations = {
  1: '#fdba14',
  2: '#bbc3c6',
  3: '#c7a276',
};

type ImageWrapperProps = TransientProps<{ position?: number }>;

export const ImageWrapper = styled.div<ImageWrapperProps>`
  ${({ $position: position }) => css`
    position: relative;

    img {
      display: block;
      margin: 0;
    }

    ${position &&
    css`
      svg {
        position: absolute;
        top: -1.175rem;
        left: -0.75rem;
        transform: rotate(-30deg);
        fill: ${medalVariations[position as keyof typeof medalVariations]};
      }
    `}
  `}
`;
