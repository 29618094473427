import format from 'date-fns/format';
import isPast from 'date-fns/isPast';
import isFuture from 'date-fns/isFuture';
import parseISO from 'date-fns/parseISO';
import { enUS } from 'date-fns/locale';

import { RoomData } from '@common/types/api';

export const formatRoomDisplayData = ({ start_date, end_date }: RoomData) => {
  const roomStartDate = parseISO(start_date);
  const roomEndDate = parseISO(end_date);
  const formattedDate = format(roomStartDate, 'dd MMM', {
    locale: enUS,
  });

  const [day, month] = formattedDate.split(' ');

  let isOnline = false;
  let status = '';

  const isFutureRoom = isFuture(roomStartDate);
  const isPastRoom = isPast(roomEndDate);

  if (!isFutureRoom && !isPastRoom) {
    status = 'pages.rooms.live';
    isOnline = true;
  } else if (isFutureRoom) {
    status = 'pages.rooms.soon';
  } else {
    status = 'pages.rooms.reprise';
  }

  return {
    day,
    month,
    isOnline,
    status,
  };
};
