import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { Link } from 'react-router-dom';

import { TransientProps } from '@common/types';

export const Container = styled.article`
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.75rem;
    margin-bottom: 3rem;

    ${down('md')} {
      height: 100%;
      margin-bottom: 2rem;
    }
  }
`;

export const Section = styled.section`
  min-width: 0;
  position: relative;

  > header {
    max-width: 100%;

    a {
      font-size: 0.875rem;
    }
  }

  & + &,
  & + div {
    margin-top: 4rem;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  font-size: 0.875rem;

  ${down('md')} {
    flex-direction: column;
  }
`;

export const DescriptionCollapser = styled.div`
  ${({ theme }) => css`
    .react-expand-collapse__content {
      position: relative;
      overflow: hidden;
    }

    .react-expand-collapse__body {
      display: inline;
    }

    /* expand-collapse button */
    .react-expand-collapse__button {
      color: ${theme.colors.brand.secondary.default};
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 7rem;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      background-color: ${theme.colors.background[80]};
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      font-weight: 600;
      background: linear-gradient(
        0deg,
        ${theme.colors.background[80]} 25%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    /* expanded state */
    .react-expand-collapse--expanded .react-expand-collapse__button {
      padding-left: 5px;
      position: relative;
      bottom: auto;
      right: auto;
    }
  `}
`;

type MainRoomContainerProps = TransientProps<{ loading: boolean }>;

export const MainRoomContainer = styled(Link)<MainRoomContainerProps>`
  ${({ $loading: isLoading }) => css`
    flex: 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    ${isLoading &&
    css`
      pointer-events: none;
    `}

    > img {
      width: 25rem;
      height: 12.375rem;
      object-fit: cover;
      border-radius: 1rem 1rem 0 0;
    }

    ${down('md')} {
      margin-top: 1rem;

      > img {
        width: calc(100% - 1.5rem);
      }
    }
  `}
`;

export const MainRoomPlayIcon = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: -1.85rem;
    right: 14%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 0.5rem;
    background: ${theme.colors.background[100]};
    box-shadow: -0.5rem 0.5rem 0 ${theme.colors.brand.primary.default};

    ${down('md')} {
      right: 8%;
    }
  `}
`;

export const MainRoomBadge = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 26rem;
    height: 3.5rem;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    color: ${theme.colors.base.text};
    background: ${theme.colors.brand.primary.default};

    > span {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      border-radius: 0.5rem;
      margin-right: 1rem;
      color: ${theme.colors.brand.primary.default};
      background: ${theme.colors.base.text};
    }

    > svg {
      margin-left: auto;
    }

    ${down('md')} {
      width: 100%;
    }
  `}
`;

export const RoomCardsContainer = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.25rem;

  ${down('md')} {
    flex-direction: column;
  }
`;

export const RoomCard = styled.li`
  ${({ theme }) => css`
    width: calc(33% - 1.25rem);
    height: 16rem;
    border-radius: 1rem;
    overflow: hidden;
    background: ${theme.colors.background[100]};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);
    transition: 180ms ease-in-out;

    > a {
      color: ${theme.colors.foreground[100]};

      > img {
        width: 100%;
        height: 11rem;
        object-fit: cover;
        border-radius: 1rem;
      }
    }

    :hover {
      transform: translate3d(0, -0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023), 0 2px 2px rgba(0, 0, 0, 0.023),
        0 4px 4px rgba(0, 0, 0, 0.023), 0 8px 8px rgba(0, 0, 0, 0.023),
        0 16px 16px rgba(0, 0, 0, 0.023), 0 32px 32px rgba(0, 0, 0, 0.023);
    }

    :active {
      transform: translate3d(0, 0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047), 0 2px 2px rgba(0, 0, 0, 0.047),
        0 4px 4px rgba(0, 0, 0, 0.047);
    }

    ${down('md')} {
      flex-grow: 1;
      width: 100%;
    }
  `}
`;

export const RoomCardDataWrapper = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const RoomCardDate = styled.div`
  ${({ theme }) => css`
    width: 3.125rem;
    height: 3.125rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background: ${theme.colors.background[80]};
    color: ${theme.colors.foreground[60]};

    > strong {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    > span {
      font-weight: 400;
      line-height: 1rem;
    }
  `}
`;

type RoomCardInfoProps = TransientProps<{
  online: boolean;
}>;

export const RoomCardInfo = styled.div<RoomCardInfoProps>`
  ${({ theme, $online }) => css`
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > strong {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > span {
      font-weight: 400;
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      > svg {
        vertical-align: -1px;
        margin: 0 0.125rem 0 0;
        color: ${$online ? theme.colors.success[100] : theme.colors.error[100]};
      }
    }
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  > section {
    flex-basis: 50%;
    min-width: 0;

    ul {
      position: relative;
    }
  }

  + ${Section}, + div {
    margin-top: 2rem !important;
  }

  ${down('md')} {
    flex-direction: column;
  }
`;

type ViewMoreProps = TransientProps<{ big: boolean }>;

export const ViewMore = styled(Link)<Partial<ViewMoreProps>>`
  ${({ theme, $big: isBig }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    width: 100%;
    height: ${isBig ? 8.5 : 6}rem;
    right: 0;
    bottom: -1rem;
    left: 0;
    background: linear-gradient(
      0deg,
      ${theme.colors.background[80]} 15%,
      rgba(0, 0, 0, 0) 90%
    );

    span {
      position: absolute;
      bottom: -0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      color: ${theme.colors.brand.primary.default};
      background: ${theme.colors.background[100]};
      transition: 180ms 80ms ease-in-out;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
        0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.005);
    }

    :hover,
    :hover * {
      filter: none !important;

      span {
        transform: scale(1.05);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023),
          0 2px 2px rgba(0, 0, 0, 0.023), 0 4px 4px rgba(0, 0, 0, 0.023),
          0 8px 8px rgba(0, 0, 0, 0.023), 0 16px 16px rgba(0, 0, 0, 0.023),
          0 32px 32px rgba(0, 0, 0, 0.023);
      }
    }

    :active {
      span {
        transform: scale(0.95);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047),
          0 2px 2px rgba(0, 0, 0, 0.047), 0 4px 4px rgba(0, 0, 0, 0.047);
      }
    }
  `}
`;

export const ScheduleContainer = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
`;
