import { ImgHTMLAttributes } from 'react';

import { getAvatarFallback } from '@utils';
import { useEventfy } from '@hooks';
import { selectPrimaryColor } from '@hooks/useEventfy/selectors';

import * as S from './styles';

type ImageAttrsWithoutSrcAndAlt = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
>;

export type AvatarProps = ImageAttrsWithoutSrcAndAlt & {
  src: string | null;
  alt: string;
};

const Avatar = ({ src, alt, ...attrs }: AvatarProps) => {
  const eventPrimaryColor = useEventfy(selectPrimaryColor);

  return (
    <S.Container
      src={getAvatarFallback(src, alt, eventPrimaryColor)}
      alt={alt}
      {...attrs}
    />
  );
};

export default Avatar;
