import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { TransientProps } from '@common/types';

export const Container = styled.nav`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 5rem;
    overflow: hidden;
    pointer-events: all;
    background: ${theme.colors.background[100]};
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    transition: width 250ms ease-in-out, color 120ms ease-in-out;

    :hover {
      width: 18rem;
    }
  `}
`;

type NavigateProps = TransientProps<{
  active: boolean;
}>;

export const NavLink = styled(Link)<NavigateProps>`
  ${({ theme, $active: isActive }) => css`
    display: flex;
    align-items: center;

    font-size: 1rem;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    color: ${isActive
      ? theme.colors.brand.primary.default
      : theme.colors.foreground[100]};
    border-right: 0.25rem solid
      ${isActive
        ? theme.colors.brand.primary.default
        : theme.colors.background[100]};

    > svg {
      margin: 0.75rem 1.75rem;
    }

    :hover {
      color: ${theme.colors.brand.primary.default};
      border-right-color: ${theme.colors.brand.primary.default};
    }
  `}
`;

export const NavButton = styled(NavLink).attrs(() => ({
  $active: false,
}))`
  ${() => css`
    background: none;
    justify-content: flex-start;
    padding: 0;
  `}
`;
