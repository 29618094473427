import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${() => css`
    position: fixed;
    bottom: 4rem;
    right: -2rem;

    > div {
      > div:first-child {
        border-radius: 0.5rem;

        > div:first-child {
          padding: 0.5rem;

          > div:last-child {
            padding-right: 0.5rem;
          }
        }
      }

      button {
        width: 100%;
        margin: 0;
        padding: 0.75rem;
        border-radius: 1000px;
      }
    }
  `}
`;
