import styled, { css } from 'styled-components';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';
import { down } from 'styled-breakpoints';
import { Link } from 'react-router-dom';

export const Container = styled(PerfectScrollbar)<ScrollBarProps>`
  display: flex;
  padding-right: 1rem;
  max-height: calc(100vh - 33vh);
  @media (max-height: 768px) {
    max-height: calc(100vh - 12vh);
    overflow-y: scroll;
  }
  ${down('md')} {
    max-height: 100vh;
  }
`;

export const VideoList = styled(PerfectScrollbar)<ScrollBarProps>`
  ${({ theme }) => css`
    flex-direction: column;
    width: 100%;
    max-width: 19.5rem;

    > li {
      gap: 1rem;
      padding-bottom: 1rem;
      border-radius: 1rem;
      margin-bottom: 1rem;
      background: ${theme.colors.background[100]};
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
        0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.005);
    }

    ${down('md')} {
      max-width: 50rem;
    }
  `}
`;

export const SelectVideoButton = styled(Link)`
  ${() => css`
    flex: 1;
    background: none;
    width: 19.5rem;
    height: 11rem;
    padding: 0;
    transition: 180ms ease-in-out;

    img {
      border-radius: 1rem;
      display: block;
      width: 100%;
      height: 140px;
      object-fit: cover;

      ${down('md')} {
        height: 180px;
        margin-bottom: 1rem;
      }
    }
    > section {
      padding: 0rem 0.5rem;
    > h3 {
      padding-top: 0.75rem;
      font-size: 0.85rem;
      position: relative;
    }

    :hover {
      opacity: 0.85;
    }
  `}
`;
