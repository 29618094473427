import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

import { SectionHeader } from '@components';

export const Container = styled.article``;

export const Wrapper = styled.div`
  p + p {
    margin-top: 1rem;
  }
`;

export const SponsorProfileHeader = styled(SectionHeader)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem !important;

  h2 {
    font-size: 1.75rem;
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 7rem;

  ${down('md')} {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const SponsorResume = styled.section`
  width: 12.5rem;

  > a {
    :first-of-type {
      flex-shrink: 0;
      display: block;
      margin-bottom: 1.25rem;

      > img {
        width: 100%;
        max-width: 12rem;
        height: 7rem;
        object-fit: contain;
        object-position: left;
      }
    }
  }

  a {
    transition: 180ms ease-in-out;

    :hover {
      color: ${({ theme }) => theme.colors.brand.primary.default};
    }
  }

  > div {
    margin-top: 1rem;
  }

  ${down('md')} {
    display: flex;
    width: 100%;
    gap: 1rem;
  }
`;
export const Contact = styled.a`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  gap: 0.5rem;
`;
export const SocialMediaList = styled.ul`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
`;

export const Website = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const SponsorProfileData = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 52.5rem;

    > section {
      padding: 1.5rem 1rem;
      border-radius: 0.5rem;
      background: ${theme.colors.background[100]};
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
        0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.005);

      > h3 {
        padding-top: 1rem;
        margin-bottom: 1rem;
        position: relative;

        :before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          width: 2.5rem;
          border-top: 0.25rem solid ${theme.colors.brand.secondary.default};
        }
      }
    }
  `}
`;

export const HeroImageContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 47.15%;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.05);

  > img {
    position: absolute;
    object-fit: contain;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ProductList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  > li {
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);
    transition: 180ms 80ms ease-in-out;

    button {
      all: initial;
      cursor: pointer;
      width: 7rem;
      height: 7rem;
      background: none;
      border: none;
      padding: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    :hover {
      transform: scale3d(1.05, 1.05, 1);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.035), 0 2px 2px rgba(0, 0, 0, 0.025),
        0 4px 4px rgba(0, 0, 0, 0.025), 0 8px 8px rgba(0, 0, 0, 0.02),
        0 16px 16px rgba(0, 0, 0, 0.05);
    }
  }
`;
