import * as Yup from 'yup';

const resetShape = {
  email: Yup.string().required('valid.email.req'),
  token: Yup.string(),
  password: Yup.string()
    .required('valid.password.new')
    .min(6, 'valid.password.req'),
  password_confirmation: Yup.string()
    .min(6, 'valid.password.retry')
    .oneOf([Yup.ref('password'), null], 'valid.password.invalid'),
};

export const resetPassSchemas = {
  1: Yup.object().shape(resetShape),
};
