import { useEffect } from 'react';

import { api } from '@services';
import { IS_PRD, ONE_MINUTE } from '@constants';

export const useNotifyUserIsOnlineEffect = () => {
  // hook to ping api to notify the user is online interacting with the website
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (IS_PRD) {
      interval = setInterval(() => api.get('/ping'), ONE_MINUTE * 4);
    }

    return () => clearInterval(interval);
  }, []);
};
