import {
  DispatchWithoutAction,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useRef,
  useCallback,
} from 'react';

import { useQuery } from 'react-query';
import { HTMLMotionProps } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FiCopy } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';

import { Button } from '@components';
import { IndicationsIllustration } from '@assets';
import { NomineesResponse, RewardsResponse } from '@common/types/api';
import { DEFAULT_MODAL_ANIMATION, DEFAULT_TRANSITION } from '@constants';
import { useAuth } from '@hooks';
import { api } from '@services';

import * as S from './styles';
import Rewards from './Rewards';
import SocialNetworks from './SocialNetworks';

type IndicationsModalProps = PropsWithChildren<HTMLMotionProps<'dialog'>> & {
  handleClose: DispatchWithoutAction;
};

const IndicationsModal = (
  props: IndicationsModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const linkReferral = user.link_referral;

  const inputRef = useRef<HTMLInputElement>(null);
  const { handleClose, ...attrs } = props;

  const fetchRewards = useCallback(async () => {
    const { data } = await api.get<RewardsResponse>('/rewards');

    return data.data;
  }, []);

  const fetchNominees = async () => {
    const { data } = await api.get<NomineesResponse>('/user/nominees');

    return data.data.total;
  };

  const { data: nominess } = useQuery('nominess', fetchNominees);
  const { data: rewards } = useQuery('rewards', fetchRewards);

  const copyToClipboard = () => {
    inputRef?.current?.select();
    document.execCommand('copy');
  };

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      {...attrs}
    >
      <S.CloseButton>
        <button type="button" onClick={handleClose}>
          <MdClose />
        </button>
      </S.CloseButton>
      <S.Header>
        <S.HeaderLeft>
          <IndicationsIllustration />
          <div>
            <h2>{t('modal.indication.title')}</h2>
            <span>{`${t('modal.indication.call')}${
              rewards && rewards.length > 0 ? t('modal.indication.rewards') : ''
            }.`}</span>
          </div>
        </S.HeaderLeft>

        <div className="indications-header">
          <strong>
            {t('modal.indication.youHave')}
            {nominess === 1
              ? ` ${nominess || 0} ${t('modal.indication.indication')}`
              : ` ${nominess || 0} ${t('modal.indication.indications')}`}
          </strong>
        </div>
      </S.Header>

      <S.Content>
        <span>{t('modal.indication.copy')}</span>
        <S.IndicationLink>
          <input ref={inputRef} value={linkReferral} readOnly type="textarea" />
          <Button
            type="submit"
            size="even"
            icon={FiCopy}
            variant="primary"
            title={t('button.copy') || 'Copiar'}
            onClick={() => copyToClipboard()}
          />
        </S.IndicationLink>
        <span>{t('modal.indication.share')}</span>

        <SocialNetworks linkReferral={linkReferral} />
      </S.Content>

      {rewards && rewards.length > 0 && (
        <Rewards rewards={rewards} nominess={nominess || 0} />
      )}
    </S.Container>
  );
};

export default forwardRef(IndicationsModal);
