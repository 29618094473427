import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

import { TransientProps } from '@common/types';

export const Container = styled.article`
  height: 100%;
`;

export const ScheduleHeader = styled.header`
  ${({ theme }) => css`
    padding: 1.5rem;
    padding-left: 0;
    border-radius: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    background: ${theme.colors.brand.secondary.opaque};
    background: linear-gradient(
      95deg,
      rgba(193, 48, 48, 0) 15%,
      ${theme.colors.brand.secondary.opaque} 75%,
      ${theme.colors.brand.secondary.opaque} 100%
    );

    > div {
      span {
        color: ${theme.colors.brand.secondary.default};
      }

      h1 {
        font-size: 3rem;
        color: #2c3f5e;
      }
    }

    > svg {
      color: ${theme.colors.brand.secondary.default};
    }

    ${down('md')} {
      > svg {
        display: none;
      }
    }
  `}
`;

export const FilterContainer = styled.aside`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .react-select-container {
      max-width: 20rem;

      .react-select__control {
        background: ${theme.colors.background[80]};
        border-radius: 0.5rem;
        height: 3rem;
      }

      ${down('md')} {
        max-width: 100%;
      }
    }

    ${down('md')} {
      flex-direction: column;
    }
  `}
`;

export const DatesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

type DateButtonProps = TransientProps<{ selected: boolean }>;

export const DateButton = styled.button<DateButtonProps>`
  ${({ theme, $selected: isSelected }) => css`
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background: ${theme.colors.brand.primary.opaque};
    color: ${theme.colors.brand.primary.default};
    margin-right: 1rem;
    position: relative;

    ${isSelected &&
    css`
      background: ${theme.colors.brand.primary.default};
      color: ${theme.colors.brand.primary.text};

      > svg {
        position: absolute;
        top: -0.25rem;
        right: -0.25rem;
        background: ${theme.colors.brand.primary.default};
        color: ${theme.colors.brand.primary.text};
        border-radius: 50%;
      }
    `}

    > span {
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 0.5rem;
      font-weight: 400;
    }

    > strong {
      font-size: 1.5rem;
    }
  `}
`;

export const ListWrapper = styled.ul`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2.75rem;

  > li {
    max-width: calc(50% - 2rem);
  }

  ${down('md')} {
    > li {
      max-width: 100%;
    }
  }
`;
