import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

import { TransientProps } from '@common/types';

type withActive = TransientProps<{ active: boolean }>;
type withLoading = TransientProps<{ loading: boolean }>;
type RoomContainerProps = withLoading &
  withActive & {
    $deleting: boolean;
    $moderator?: boolean;
  };

export const Container = styled.article<RoomContainerProps>`
  ${({
    theme,
    $active: isActive,
    $deleting: isDeleting,
    $moderator: isModerator,
  }) => css`
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    padding: 1rem;
    background: ${theme.colors.background[100]};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);
    transition: 180ms 80ms ease-in-out;

    ${!isActive &&
    css`
      pointer-events: none;
    `}

    ${isDeleting &&
    css`
      opacity: 0.4;
    `}

    ${isModerator &&
    css`
      box-shadow: ${theme.colors.brand.primary.default} 0px 1px 1px,
        ${theme.colors.brand.primary.default} 0px 2px 2px,
        ${theme.colors.brand.primary.default} 0px 4px 4px,
        rgb(0 0 0 / 1%) 0px 8px 8px, rgb(0 0 0 / 0%) 0px 16px 16px;
    `}

    ${down('md')} {
      max-width: 100%;
    }
  `}
`;

export const PostHeader = styled.header`
  ${({ theme }) => css`
    display: flex;

    h3 {
      font-weight: 300;

      strong {
        color: ${theme.colors.brand.primary.default};
      }
    }
    span {
      margin: 0 0.5rem;
      padding: 1px 10px;
      font-size: 0.75rem;
      color: ${theme.colors.base.text};
      font-weight: 600;
      border-radius: 0.75rem;
      background: ${theme.colors.brand.primary.default};
    }
  `};
`;

export const ModeratorOptionsContainer = styled.div`
  ${() => css`
    margin: -0.5rem -0.75rem 0 auto;

    .tippy-content {
      padding: 0;
    }
  `}
`;

export const ModeratorOptionsButton = styled.button`
  ${({ theme }) => css`
    background: none;
    white-space: nowrap;
    width: 100%;
    border-radius: 0;
    justify-content: flex-start;
    transition: 180ms ease-in-out;

    :hover {
      background: ${theme.colors.brand.primary.default};
      color: ${theme.colors.base.text};
    }

    :first-child {
      border-radius: 0.25rem 0.25rem 0 0;
    }
    :last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }
  `}
`;

export const PostMain = styled.main`
  ${() => css`
    margin: 1.5rem 0;

    p {
      margin: 1.25rem 0;
      word-break: break-word;
    }
  `};
`;

export const PostImageWrapper = styled.div`
  ${() => css`
    margin: 1rem -1rem 0;
    display: grid;
    grid-auto-columns: 1fr;
    place-content: center;
    width: calc(100% + 2rem);

    span {
      display: block;
      width: 100%;
    }

    > img {
      display: block;
      width: 100%;
      /* max-height: 45rem; */
      object-fit: cover;
    }
  `};
`;

export const PostFooter = styled.footer`
  ${() => css`
    > section {
      display: flex;
      justify-content: space-between;

      span {
        display: flex;
        align-items: center;

        > div {
          overflow: hidden;
          margin-left: 0.25rem;
        }
      }
    }
  `};
`;

export const InlineButton = styled.button`
  ${() => css`
    background: none;
    border: 0;
    padding: 0;

    :hover {
      text-decoration: underline;
    }
  `};
`;

type PostFooterActionsProps = TransientProps<{
  commentsVisible: boolean;
  voted: boolean;
}>;

export const PostFooterActions = styled.section<PostFooterActionsProps>`
  ${({ theme, $commentsVisible: areCommentsVisible, $voted: hasVoted }) => css`
    margin: 1rem -1rem ${areCommentsVisible ? 0 : -1}rem;
    max-width: calc(100% + 2rem);
    border-radius: ${areCommentsVisible ? '0' : '0 0 1rem 1rem'};
    border-top: 1px solid ${theme.colors.base.border};
    background: ${theme.colors.background[80]};
    overflow: hidden;

    ${areCommentsVisible &&
    css`
      border-bottom: 1px solid ${theme.colors.base.border};
    `}

    > button {
      width: 50%;
      background: none;
      border-radius: 0;
      transition: 180ms ease-in-out;
      gap: 0.5rem;

      :not(:disabled):hover {
        color: ${theme.colors.brand.secondary.text};
        background: ${theme.colors.brand.secondary.default};
      }

      ${hasVoted &&
      css`
        :first-child {
          svg {
            stroke: ${theme.colors.brand.primary.default};
            fill: ${theme.colors.brand.primary.opaque};
          }
        }
      `}
    }
  `};
`;
