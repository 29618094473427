import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    margin-top: 5rem;
    display: flex;
    justify-content: center;

    > ul {
      display: flex;
      gap: 1rem;

      > li {
        border-radius: 0.25rem;
        color: ${theme.colors.background[100]};
        background: ${theme.colors.background[60]};

        a {
          width: 1.75rem;
          height: 1.75rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.active,
        :hover {
          color: ${theme.colors.base.text};
          background: ${theme.colors.brand.primary.default};
        }
      }
    }
  `}
`;
