import { ResponsiveObject } from 'react-slick';

export const CAROUSEL_BREAKPOINTS: ResponsiveObject[] = [
  {
    breakpoint: 1080,
    settings: {
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 820,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 580,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
];
