import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { api } from '@services';
import { RoomAskingsData } from '@common/types/api';

export const useAskingMutation = (roomId?: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (question: string) =>
      api.post(`/rooms/asking`, {
        room_id: roomId,
        asking: question,
      }),
    {
      onMutate: async (question: string) => {
        await queryClient.cancelQueries(['askings', roomId]);

        const previousQuestions = queryClient.getQueryData<RoomAskingsData[]>([
          'askings',
          roomId,
        ]);

        const randomNumber = Date.now();

        queryClient.setQueryData<RoomAskingsData[]>(
          ['askings', roomId],
          [
            ...(previousQuestions || []),
            {
              id: randomNumber,
              user_id: 1,
              status: 0,
              room_id: roomId || 0,
              asking: question,
              answer: null,
              created_at: `${randomNumber}`,
              updated_at: `${randomNumber}`,
            },
          ],
        );

        return { previousQuestions };
      },
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (err, _variables, context) => {
        toast.error(
          (err as AxiosError).response?.data.message ||
            'Não foi possível enviar sua pergunta.',
        );

        queryClient.setQueryData<RoomAskingsData[]>(
          ['askings', roomId],
          context?.previousQuestions || [],
        );
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(['askings', roomId]);
      },
    },
  );
};
