import styled from 'styled-components';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';

export default styled(PerfectScrollbar).attrs<ScrollBarProps>((props) => ({
  ...props,
  options: {
    ...props.options,
    handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
    wheelPropagation: false,
    suppressScrollX: true,
    minScrollbarLength: 32,
  },
}))<ScrollBarProps>``;
