import styled, { css } from 'styled-components';

import { TransientProps } from '@common/types';

export { ViewMore } from '../styles';

export const Container = styled.li`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 calc(50% - 1.5rem);
    padding: 1rem 0.75rem;
    border-radius: 0.8rem;
    background: ${theme.colors.background[100]};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);

    > span {
      display: grid;
      place-content: center;
      padding: 0.25rem 1rem;
      border-radius: 1000px;
      font-size: 0.875rem;
      font-weight: 700;
      background: ${theme.colors.brand.secondary.opaque};
      color: ${theme.colors.brand.secondary.default};
    }
  `}
`;

export const ProfileData = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 1rem;

    > strong {
      display: grid;
      place-content: center;
      width: 2.25rem;
      vertical-align: middle;
      font-size: 1.5rem;
      font-weight: 400;
    }

    > div:last-child {
      strong {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
      }
    }
  `}
`;

const medalVariations = {
  1: '#fdba14',
  2: '#bbc3c6',
  3: '#c7a276',
};

type ImageWrapperProps = TransientProps<{ position?: number }>;

export const ImageWrapper = styled.div<ImageWrapperProps>`
  ${({ $position: position }) => css`
    position: relative;

    img {
      display: block;
      margin: 0;
    }

    ${position &&
    css`
      svg {
        position: absolute;
        top: -1.175rem;
        left: -0.75rem;
        transform: rotate(-30deg);
        fill: ${medalVariations[position as keyof typeof medalVariations]};
      }
    `}
  `}
`;
