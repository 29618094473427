import { useCallback } from 'react';

import { useChannel, useEvent } from '@harelpls/use-pusher';
import { useQueryClient } from 'react-query';

import { TimelineEvent } from '@common/types/pusher/events';
import { SettingsData } from '@common/types/api';
import { fetchNewsfeed } from '@hooks/useNewsfeedQuery';

export const useTimelineChannel = () => {
  const queryClient = useQueryClient();
  const timelineChannel = useChannel('timeline');

  const handleTimelineEvent = useCallback(
    (response?: TimelineEvent) => {
      if (!response) {
        return;
      }

      const previousEventSettings =
        queryClient.getQueryData<SettingsData>('event-settings');

      if (previousEventSettings) {
        queryClient.setQueryData<SettingsData>('event-settings', {
          ...previousEventSettings,
          timeline: response.timeline,
        });
      }

      if (response.timeline) {
        queryClient.prefetchInfiniteQuery(['newsfeed', fetchNewsfeed]);
      }
    },
    [queryClient],
  );

  useEvent(timelineChannel, 'TimelineEvent', handleTimelineEvent);
};
