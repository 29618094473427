import { memo } from 'react';

import { useHistory, useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';

import { Modal } from '@components';
import { useAuth, useGlobalEventChannels } from '@hooks';

import SpeakerModal from './SpeakerModal';
import ScheduleModal from './ScheduleModal';
import LogoutEventModal from './LogoutEventModal';
import CompleteProfileAvatarModal from './CompleteProfileAvatarModal';
import GamificationRulesModal from './GamificationRulesModal';

const GlobalModals = () => {
  const history = useHistory();
  const { user, signOut } = useAuth();
  const { search, ...location } = useLocation();

  const globalEvent = useGlobalEventChannels();

  const queryParams = new URLSearchParams(search);

  const speakerId = queryParams.get('speaker');
  const lang = queryParams.get('lang');
  const scheduleSessionId = queryParams.get('session');
  const gamificationRules = queryParams.get('gamification_rules');

  const handleCloseModal =
    (key: 'speaker' | 'session' | 'gamification_rules') => () => {
      queryParams.delete(key);

      history.replace({
        ...location,
        search: `?${queryParams.toString()}`,
      });
    };

  return (
    <>
      <AnimatePresence>
        {user.avatar === 'undefined' && (
          <Modal hideCloseButton>
            <CompleteProfileAvatarModal />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {speakerId && (
          <Modal noPadding handleClose={handleCloseModal('speaker')}>
            <SpeakerModal speakerId={+speakerId} />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {scheduleSessionId && lang && (
          <Modal noPadding handleClose={handleCloseModal('session')}>
            <ScheduleModal sessionId={+scheduleSessionId} langId={+lang} />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {gamificationRules && (
          <Modal noPadding handleClose={handleCloseModal('gamification_rules')}>
            <GamificationRulesModal />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {globalEvent.type && (
          <Modal handleClose={signOut}>
            <LogoutEventModal
              type={globalEvent.type}
              message={globalEvent.message}
            />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default memo(GlobalModals);
