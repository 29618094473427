import { useEffect } from 'react';

import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';

import { api, history } from '@services';
import { ROOMS_ENDPOINT } from '@constants/endpoints';
import { RoomBroadcastData, RoomBroadcastResponse } from '@common/types/api';
import { PromiseWithCancel } from '@common/types';
import { cancelToken } from '@services/api';
import { ONE_MINUTE } from '@constants';
import { BroadcastPageParams } from '@pages/Broadcast/types';

import { useFloatingPlayer } from '../useFloatingPlayer';

export const fetchBroadcast = (ctx: QueryFunctionContext<string[]>) => {
  const [, roomId] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<RoomBroadcastResponse>(
      `${ROOMS_ENDPOINT}/${roomId}?include=settings,streamings,archives,participants,schedules,callAction`,
      {
        cancelToken: source.token,
      },
    )
    .then(({ data }) => data.data)
    .catch((err) => {
      if (
        err.isAxiosError &&
        (err.response?.status >= 400 || err.response?.status <= 500)
      ) {
        toast.error(err.response.data.message);
        history.replace('/lobby');
      }
    }) as PromiseWithCancel<RoomBroadcastData>;

  promise.cancel = () => source.cancel('useBroadcastQuery Cancelled');

  return promise;
};

export const useBroadcastQuery = <T = RoomBroadcastData>(
  options?: UseQueryOptions<RoomBroadcastData, AxiosError, T>,
) => {
  const { roomSlug } = useParams<BroadcastPageParams>();
  const { updateFloatingPlayer } = useFloatingPlayer();

  if (!roomSlug) {
    throw new Error('useBroadcastQuery must be used within a Broadcast room');
  }

  useEffect(() => {
    updateFloatingPlayer({ roomSlug });
  }, [roomSlug, updateFloatingPlayer]);

  return useQuery<RoomBroadcastData, AxiosError, T>(
    ['broadcast', roomSlug],
    fetchBroadcast,
    {
      staleTime: ONE_MINUTE * 15,
      onError: (err) => {
        if (err.isAxiosError && err.response?.status === 404) {
          // toast.error('Sala não encontrada');
          history.replace('/lobby');
        }
      },
      ...(options || {}),
    },
  );
};
