import PlyrReact from 'plyr-react';
import 'plyr-react/plyr.css';

import { Container } from './styles';

type PlyrProps = {
  src: string;
};

const Plyr = ({ src }: PlyrProps) => (
  <Container>
    <PlyrReact
      options={{
        ratio: '16:9',
        autoplay: true,
        clickToPlay: true,
        hideControls: true,
        disableContextMenu: true,
        controls: [
          'play-large', // The large play button in the center
          'restart', // Restart playback
          'play', // Play/pause playback
          'progress',
          'volume',
          'mute', // Toggle mute
          'fullscreen', // Toggle fullscreen
        ],
      }}
      source={{
        type: 'video',
        sources: [
          {
            src,
            provider: 'youtube',
          },
        ],
      }}
    />
  </Container>
);

export default Plyr;
