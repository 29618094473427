import { LiHTMLAttributes, useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { AiFillHeart } from 'react-icons/ai';

import { FullScheduleData, ScheduleData } from '@common/types/api';
import { formatScheduleDisplayData, isValidObject } from '@utils';
import { HistoryState } from '@services/history';
import { ComponentPropsWithLoading } from '@common/types';

import SpeakersBubbles from '../SpeakersBubbles';
import * as S from './styles';

export type ScheduleSessionProps = ComponentPropsWithLoading<
  FullScheduleData | ScheduleData
> &
  LiHTMLAttributes<HTMLLIElement>;

const ScheduleSession = (props: ScheduleSessionProps) => {
  const history = useHistory();
  const { pathname, search } = useLocation<HistoryState>();
  const { t, i18n } = useTranslation();

  const queryParams = new URLSearchParams(search);

  const { data, loading = false, ...attrs } = props;
  const [lang, setLng] = useState(1);

  const handleSession = () => {
    queryParams.set('session', `${data?.id}`);
    queryParams.set('lang', `${lang}`);

    history.push({
      pathname,
      search: `?${queryParams.toString()}`,
    });
  };

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')) {
      const l = localStorage.getItem('i18nextLng') === 'en' ? 1 : 0;
      setLng(l);
    }
    i18n.on('languageChanged', function (lng) {
      setLng(lng === 'en' ? 1 : 0);
    });
  }, [i18n, setLng]);

  if (!data) {
    return (
      <S.Container $loading={loading} {...attrs}>
        <div>
          <S.SessionDateSkeleton />

          <S.SessionDataWrapper>
            <strong>
              <Skeleton width="68%" />
            </strong>

            <div>
              <S.ScheduleSessionData>
                <span>
                  <Skeleton />
                </span>
                <time>
                  <Skeleton width="32%" />
                </time>
              </S.ScheduleSessionData>

              <S.SessionSpeakersContainer $loading={loading}>
                <span>{t('pages.speakers.title')}</span>

                <SpeakersBubbles loading innerKey={Date.now()} />
              </S.SessionSpeakersContainer>
            </div>
          </S.SessionDataWrapper>
        </div>
      </S.Container>
    );
  }

  const { day, timetable, weekDay } = formatScheduleDisplayData(data, lang);
  const weekTrans = t(weekDay);

  const speakers = 'participants' in data ? data.participants : undefined;

  return (
    <S.Container $loading={false} {...attrs}>
      <button type="button" onClick={handleSession}>
        <S.SessionDate>
          <span>{weekTrans.split('-')[0]}</span>
          <strong>{day}</strong>
        </S.SessionDate>

        <S.SessionDataWrapper>
          <strong>{data && data.langs.data[lang].title}</strong>

          <div>
            <S.ScheduleSessionData>
              <span>{data.langs.data[lang].description}</span>
              <time>{timetable}</time>
              <span>
                <AiFillHeart /> {t('pages.schedule.reminder')}
              </span>
            </S.ScheduleSessionData>

            {speakers && isValidObject(speakers) && (
              <S.SessionSpeakersContainer $loading={false}>
                <span>{t('pages.speakers.title')}</span>

                <SpeakersBubbles
                  data={speakers.data}
                  innerKey={`session-${data.id}-room-${data.room_id}`}
                />
              </S.SessionSpeakersContainer>
            )}
          </div>
        </S.SessionDataWrapper>
      </button>
    </S.Container>
  );
};

export default ScheduleSession;
