import Skeleton from 'react-loading-skeleton';

import { StreamingData, StreamingOrigin } from '@common/types/api';

import Emoji from './Emoji';
import Player from './Player';
import Zoom from './Zoom';
import * as S from './styles';

type StreamingProps = {
  data?: StreamingData;
  thumb?: string;
};

const Streaming = ({ data, thumb }: StreamingProps) => (
  <S.Container>
    {data ? (
      <>
        {data?.streaming_method === StreamingOrigin.ZOOM ? (
          <Zoom
            meetingNumber={data.meeting_id}
            passWord={data.meeting_pass}
            apiKey={data.meeting_api_key}
            hostUserId={data.meeting_host_user_id}
          />
        ) : (
          <Player
            thumb={thumb}
            src={data.url_video}
            streamingMethod={data.streaming_method}
          />
        )}
      </>
    ) : (
      <Skeleton
        width="100%"
        height={0}
        style={{
          paddingBottom: '56.25%',
          borderRadius: '2rem',
          lineHeight: 2,
        }}
      />
    )}

    <Emoji />
  </S.Container>
);

export default Streaming;
