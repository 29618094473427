import { memo } from 'react';

import { CgCrown } from 'react-icons/cg';
import { GooSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';
import { FiChevronDown, FiInfo } from 'react-icons/fi';
import Tippy from '@tippyjs/react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useEventfy, useGamificationQuery, useRankingTimer } from '@hooks';
import { selectIsGamificationRankingEnabled } from '@hooks/useEventfy/selectors';
import { Button, SectionHeader } from '@components';
import { HistoryState } from '@services/history';

import * as S from './styles';
import RankingCard from './RankingCard';

const GamificationRanking = () => {
  const theme = useTheme();
  const history = useHistory();
  const { search } = useLocation<HistoryState>();

  const timeToUpdate = useRankingTimer();
  const isGamificationEnabled = useEventfy(selectIsGamificationRankingEnabled);

  const gamificationQuery = useGamificationQuery();

  const queryParams = new URLSearchParams(search);
  const { t } = useTranslation();

  const handleDisplayRulesModal = () => {
    queryParams.set('gamification_rules', `true`);

    history.replace({
      search: `?${queryParams.toString()}`,
    });
  };

  if (!isGamificationEnabled) {
    return null;
  }

  return (
    <>
      <S.Container>
        <header>
          <SectionHeader
            as="div"
            title={t('pages.lobby.ranking')}
            icon={CgCrown}
          >
            <Tippy
              theme="light-border"
              content={<S.Message>{t('pages.lobby.rankingTerms')}</S.Message>}
              animation="shift-away-subtle"
              interactiveBorder={12}
              zIndex={4}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              appendTo={document.getElementById('tippy-portal')!}
            >
              <Button
                size="even"
                variant="transparent"
                icon={<FiInfo size="1rem" />}
                onClick={handleDisplayRulesModal}
              />
            </Tippy>
          </SectionHeader>

          <S.TimerWrapper>
            {gamificationQuery.isFetching ? (
              <GooSpinner
                size={2}
                sizeUnit="rem"
                color={theme.colors.brand.primary.default}
              />
            ) : (
              <div>
                {t('pages.lobby.rankingUpdate')}
                <strong>{timeToUpdate}</strong>
              </div>
            )}
          </S.TimerWrapper>
        </header>

        {gamificationQuery.isSuccess &&
          (gamificationQuery.data.length ? (
            <S.RankingList>
              {gamificationQuery.data.slice(0, 6).map((ranking, index) => (
                <RankingCard
                  key={`ranking-${ranking.user_id}`}
                  position={index + 1}
                  {...ranking}
                />
              ))}

              <S.ViewMore to="/ranking">
                <span>
                  <FiChevronDown size="1.5rem" />
                </span>
              </S.ViewMore>
            </S.RankingList>
          ) : (
            <S.EmptyPlaceholder>
              {t('pages.lobby.rankingEmpty')}
            </S.EmptyPlaceholder>
          ))}
      </S.Container>
    </>
  );
};

export default memo(GamificationRanking);
