import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';

import { TransientProps } from '@common/types';

export const Container = styled.section`
  ${() => css`
    display: block;
  `};
`;

export const Wrapper = styled(PerfectScrollbar)<ScrollBarProps>`
  ${() => css`
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 1rem;
  `};
`;

export const RankedContainer = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0.5rem;
    border: thin solid ${theme.colors.brand.secondary.opaque};
    transition: 380ms ease-in-out;

    > span {
      display: grid;
      place-content: center;
      padding: 0.25rem 1rem;
      margin-left: auto;
      border-radius: 1000px;
      font-size: 0.875rem;
      font-weight: 700;
      white-space: nowrap;
      background: ${theme.colors.brand.secondary.opaque};
      color: ${theme.colors.brand.secondary.default};
    }

    :hover {
      transform: translateY(-0.25rem);
      background: ${theme.colors.background[100]};
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
        0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.005);
    }

    & + & {
      margin-top: 0.5rem;
    }
  `};
`;

const medalVariations = {
  1: '#fdba14',
  2: '#bbc3c6',
  3: '#c7a276',
};

type PositionCellProps = TransientProps<{ position?: number }>;

export const RankPosition = styled.div<Partial<PositionCellProps>>`
  ${({ $position: position }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 3.75rem;
    flex-shrink: 0;

    strong {
      display: block;
      width: 2rem;
    }

    ${position &&
    css`
      svg {
        fill: ${medalVariations[position as keyof typeof medalVariations]};
      }
    `}

    ${down('md')} {
      width: 2rem;

      svg {
        display: none;
      }
    }
  `}
`;

export const UserData = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    min-width: 9.5rem;

    > img {
      width: 2.5rem;
      height: 2.5rem;
    }

    * {
      display: block;
      white-space: nowrap;
    }

    strong {
      color: ${theme.colors.brand.primary.default};
    }
  `}
`;
