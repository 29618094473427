/* eslint-disable radar/cognitive-complexity */
import { useMutation, useQueryClient, InfiniteData } from 'react-query';

import { api } from '@services';
import { TimelineResponse } from '@common/types/api';

export const useDeletePostMutation = (postId: number) => {
  const queryClient = useQueryClient();
  const queryKey = ['newsfeed'] as const;

  return useMutation(() => api.delete(`/timeline/${postId}`), {
    onMutate: () => {
      const previousData =
        queryClient.getQueryData<InfiniteData<TimelineResponse>>(queryKey);

      return { previousData };
    },
    onSuccess: (_, __, ctx) => {
      if (ctx?.previousData) {
        const { previousData } = ctx;

        queryClient.setQueryData<InfiniteData<TimelineResponse>>(queryKey, {
          ...previousData,
          pages: previousData.pages.map((page) => {
            const postIndex = page.data.findIndex((post) => post.id === postId);

            if (postIndex < 0) {
              return page;
            }

            return {
              ...page,
              data: page.data.filter((post) => post.id !== postId),
            };
          }),
        });
      }
    },
    onError: (_, __, ctx) => {
      if (ctx?.previousData) {
        queryClient.setQueryData<InfiniteData<TimelineResponse>>(
          queryKey,
          ctx.previousData,
        );
      }
    },
  });
};
