import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { ONE_HOUR } from '@constants';
import { SponsorsResult } from '@common/types/api';

import { fetchSponsors } from '../useLobbyQueries/queries';
import { useEventfy } from '../useEventfy';
import { selectHasSponsors } from '../useEventfy/selectors';

export const useSponsorsQuery = (page = 1) => {
  const eventHasSponsors = useEventfy(selectHasSponsors);

  return useQuery<SponsorsResult, AxiosError>(
    ['sponsors', page],
    fetchSponsors,
    {
      enabled: eventHasSponsors,
      staleTime: ONE_HOUR,
    },
  );
};
