import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { TransientProps } from '@common/types';

type withActive = TransientProps<{ active: boolean }>;
type withLoading = TransientProps<{ loading: boolean }>;
type RoomContainerProps = withLoading & withActive;

export const Container = styled.li<RoomContainerProps>`
  ${({ theme, $active: isActive }) => css`
    width: 100%;
    max-width: 20.5rem;
    height: 100%;
    max-height: 18rem;
    border-radius: 1rem;
    background: ${theme.colors.background[100]};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);
    transition: 180ms 80ms ease-in-out;

    ${!isActive &&
    css`
      pointer-events: none;
    `}

    :hover {
      transform: translate3d(0, -0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023), 0 2px 2px rgba(0, 0, 0, 0.023),
        0 4px 4px rgba(0, 0, 0, 0.023), 0 8px 8px rgba(0, 0, 0, 0.023),
        0 16px 16px rgba(0, 0, 0, 0.023), 0 32px 32px rgba(0, 0, 0, 0.023);
    }

    :active {
      transform: translate3d(0, 0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047), 0 2px 2px rgba(0, 0, 0, 0.047),
        0 4px 4px rgba(0, 0, 0, 0.047);
    }

    ${down('md')} {
      max-width: 100%;
    }
  `}
`;

export const RoomCardLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > img {
      background: ${theme.colors.brand.primary.default};
      border-radius: 1rem;
      width: 100%;
      height: 9.5rem;
      object-fit: cover;
    }
  `}
`;

export const RoomCardThumbnailSkeleton = styled(Skeleton).attrs(() => ({
  width: '100%',
  height: '9.5rem',
  style: {
    display: 'block',
    borderRadius: '1rem',
  },
}))`
  background: red;
`;

export const RoomCardBottomWrapper = styled.div`
  padding: 1rem;
`;

export const RoomCardDataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const RoomCardDate = styled.div`
  ${({ theme }) => css`
    width: 3.125rem;
    height: 3.125rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background: ${theme.colors.background[80]};
    color: ${theme.colors.foreground[60]};

    > strong {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    > span {
      font-weight: 400;
      line-height: 1rem;
    }
  `}
`;

export const RoomCardData = styled.div<withLoading>`
  ${({ theme, $loading: isLoading }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    > * {
      display: block;
    }

    > strong {
      font-size: 0.875rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > ul {
      display: flex;
      align-items: center;

      img,
      div {
        width: 2.25rem;
        height: 2.25rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: ${isLoading ? 0 : 0.875}rem;
        object-fit: cover;
        border-radius: 50%;
        background: ${isLoading
          ? 'transparent'
          : theme.colors.brand.primary.default};
        color: ${theme.colors.background[100]};
        border: 0.25rem solid ${theme.colors.background[100]};

        :first-child {
          margin-left: -0.25rem;
        }

        + img,
        + div {
          margin-left: -1rem;
        }
      }
    }
  `}
`;

export const RoomCardCTA = styled.div<withActive>`
  ${({ theme, $active: isActive }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background: ${isActive
      ? theme.colors.brand.primary.default
      : theme.colors.foreground[40]};
    color: ${theme.colors.base.text};
  `}
`;
