import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 100%;
    flex: 1;
    padding: 5.5rem 1rem 6.5rem 6rem;
    display: flex;
    justify-content: center;
    background: ${theme.colors.background[80]};
    position: relative;

    @media (min-width: 1600px) {
      padding: 6.5rem 7rem;
    }

    ${down('md')} {
      padding-left: 1rem;
    }
  `}
`;

export const Wrapper = styled.main`
  width: 100%;
  max-width: 72.5rem;
  height: 100%;
  margin: 0 auto;

  ${down('md')} {
    max-width: 100%;
  }
`;
