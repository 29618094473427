import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

import { TransientProps } from '@common/types';

type ContainerProps = TransientProps<{ disabled: boolean | undefined }>;

export const Container = styled.ul<ContainerProps>`
  ${({ theme, $disabled: isDisabled }) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    margin: auto 0;
    transition: 180ms ease-in-out;

    ${isDisabled &&
    css`
      opacity: 0.2;
      filter: grayscale(1);
      pointer-events: none;
    `}

    > li {
      > button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.25rem;
        padding: 0;
        background: none;

        img {
          width: 3.5rem;
          height: 3.5rem;
          transition: 180ms ease-in-out;

          ${down('md')} {
            width: 2.75rem;
            height: 2.75rem;
          }
        }

        ${down('md')} {
          strong {
            font-size: 0.875rem;
          }
        }

        span {
          font-weight: normal;
          font-size: 0.75rem;
          color: ${theme.colors.foreground[60]};
        }

        :hover {
          img {
            transform: scale(1.25);
            filter: brightness(1.1)
              drop-shadow(0 0 4px rgba(243, 165, 45, 0.47));
          }
        }
      }
    }
  `};
`;
