import { memo, useMemo } from 'react';

import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import parseISO from 'date-fns/parseISO';
import { ptBR } from 'date-fns/locale';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { FiMoreVertical } from 'react-icons/fi';

import { useAuth, useDeleteCommentMutation } from '@hooks';
import { Avatar, Button } from '@components';
import { selectUser } from '@hooks/useAuth/selectors';
import { CommentData } from '@common/types/api';

import * as S from './styles';

type CommentProps = CommentData;

const Comment = (props: CommentProps) => {
  const { id, post_id, comment, updated_at, user: userData } = props;
  const currentUser = useAuth(selectUser);
  const user = userData.data;
  const { mutate: deleteComment, isLoading: isDeletingComment } =
    useDeleteCommentMutation(post_id, id);
  const shouldDisplayModerationMenu =
    user.id === currentUser.id || !!currentUser.moderator;
  const { t } = useTranslation();
  const date = useMemo(
    () =>
      formatDistanceToNowStrict(parseISO(updated_at), {
        locale: ptBR,
        addSuffix: true,
      }),
    [updated_at],
  );

  return (
    <S.Container $active $loading={false} $deleting={isDeletingComment}>
      <Avatar src={user.avatar} alt={user.name} />

      <S.Content>
        <div>
          <strong>{user.name}</strong>
          <time>{` ${date}`}</time>
          {shouldDisplayModerationMenu && (
            <S.ModeratorOptionsContainer>
              <Tippy
                trigger="click"
                inertia
                hideOnClick
                interactive
                theme="light-border"
                animation="shift-away-subtle"
                zIndex={4}
                placement="bottom-end"
                interactiveBorder={12}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                appendTo={document.getElementById('tippy-portal')!}
                content={
                  <>
                    <S.ModeratorOptionsButton
                      type="button"
                      onClick={() => deleteComment()}
                      disabled={isDeletingComment}
                    >
                      {t('pages.newsfeed.remove')}
                    </S.ModeratorOptionsButton>
                  </>
                }
              >
                <Button
                  size="even"
                  variant="transparent"
                  icon={FiMoreVertical}
                />
              </Tippy>
            </S.ModeratorOptionsContainer>
          )}
        </div>
        <p>{comment}</p>
      </S.Content>
    </S.Container>
  );
};

export default memo(Comment);
