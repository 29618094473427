/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */

import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';

import 'survey-react/survey.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'react-perfect-scrollbar/dist/css/styles.css';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away-subtle.css';
import 'tippy.js/themes/light-border.css';

import 'react-whatsapp-widget/dist/index.css';

import { RootProviders } from '@contexts';

// eslint-disable-next-line import/extensions
import './i18n';
import App from './App';

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<span>Loading...</span>}>
      <RootProviders>
        <App />
      </RootProviders>
    </Suspense>
  </StrictMode>,
  document.getElementById('root'),
);
