import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { motion } from 'framer-motion';

import { TransientProps } from '@common/types';

export const TabsContainer = styled.div`
  ${({ theme }) => css`
    grid-area: Interactions;
    max-width: 100%;
    background: ${theme.colors.background[100]};
    border-radius: 1rem;
    box-shadow: 1rem 1rem 5.75rem #0000000d;

    @media (max-height: 768px) {
      max-height: 78vh;
    }

    ${down('md')} {
      max-height: 32rem;
    }
  `};
`;

export const TabList = styled(motion.ul)`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    position: relative;
    padding-top: 1rem;
    height: 5.875rem;
    border-bottom: 1px solid ${theme.colors.base.border};

    ${down('md')} {
      height: 5rem;
    }
  `};
`;

type TabItemProps = TransientProps<{
  disabled: boolean;
  active: boolean;
}>;

export const TabItem = styled(motion.li)<TabItemProps>`
  ${({ theme, $disabled: isDisabled, $active: isActive }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    text-align: center;
    transition: 180ms ease-in-out;

    ${isActive &&
    css`
      color: ${theme.colors.brand.secondary.default};
    `}

    ${isDisabled &&
    css`
      opacity: 0.2;
      cursor: auto;
      pointer-events: none;
    `};

    ${down('md')} {
      gap: 0.75rem;
      padding-bottom: 0.75rem;
      font-size: 0.875rem;

      svg {
        height: 1rem;
      }
    }
  `};
`;

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% - 5.875rem);

  ${down('md')} {
    height: 27rem;
  }
`;

export const AnimatedTab = styled(motion.div)`
  ${() => css`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    will-change: transform, height;
  `};
`;

export const TabContent = styled.section`
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  flex: 1 0 100%;
  height: 100%;
  outline: none;

  ${down('md')} {
    overflow: hidden;
  }
`;
