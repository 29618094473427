import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

type ContainerProps = { isBlocked: boolean };

export const Container = styled.li<ContainerProps>`
  ${({ isBlocked }) => css`
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    .reward-item {
      cursor: ${!isBlocked ? 'pointer' : 'initial'};
      display: flex;
      position: relative;
      padding: 1.625rem 1.125rem;
      margin-right: 1.625rem;
      background: #ffffff;
      border: 1px solid rgba(233, 233, 233, 0.26);
      box-sizing: border-box;
      box-shadow: 0px 0.25rem 0.875rem rgba(0, 0, 0, 0.06);
      border-radius: 0.313rem;
      width: 100%;
      max-width: 18.75rem;
      text-align: left;
      > div:first-child {
        background: black;
        border-radius: 100%;
        width: 3.5rem;
        height: 3.5rem;
        background: #4bc490;
        font-size: 1.875rem;
        color: #ffffff;
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-items: center;
        flex-shrink: 0;
        strong {
          margin: 0 auto;
        }
      }
      > div:not(:first-child) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div {
          > h4 {
            display: block;
            font-size: 0.75rem;
            line-height: 1.125rem;
            letter-spacing: 0.175em;
            color: #4bc490;
            text-transform: uppercase;
          }
          > strong {
            display: block;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: #05162b;
            white-space: wrap;
            word-break: break-word;
            overflow: hidden;
          }
        }
        > span {
          display: flex;
          align-items: center;
          font-weight: 300;
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: ${!isBlocked ? '#2a9467' : '#616E84'};
          background: ${!isBlocked ? '#E0FFF2' : '#F1F4F9'};
          border-radius: 1.875rem;
          padding: 0.125rem 0.625rem;
          margin-top: 0.675rem;
          svg {
            margin-right: 0.375rem;
            font-size: 1rem;
          }
        }
        > .soldOff {
          background: #c73d3d;
          color: #fff;
        }
      }
    }
    ${down('md')} {
      margin-top: -1rem;
    }
  `}
`;
