import styled, { css, keyframes, DefaultTheme } from 'styled-components';
import { down } from 'styled-breakpoints';
import { motion } from 'framer-motion';

import { TransientProps } from '@common/types';
import { PerfectScrollbar } from '@components';

export const Container = styled(motion.dialog)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    margin: auto;
    width: 100%;
    max-width: 50rem;
    max-height: 96%;
    border-radius: 1rem;
    position: relative;
    background: ${theme.colors.background[100]};
    color: ${theme.colors.foreground[100]};

    box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0px 6.7px 5.3px rgba(0, 0, 0, 0.028), 0px 12.5px 10px rgba(0, 0, 0, 0.035),
      0px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0px 41.8px 33.4px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.07);

    > p {
      text-align: center;
      margin: 1rem 0 2rem;
      word-break: break-all;

      + button {
        margin-top: auto;
      }
    }
  `}
`;

export const Form = styled(PerfectScrollbar)`
  padding: 0 2rem;
  width: 95%;

  > label + div {
    margin-top: 1rem;
  }

  > button {
    margin-top: 1.5rem;
  }

  ${down('md')} {
    padding: 0 1rem;
    width: 100%;
  }
`;

export const FormSettings = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
`;

type FlexWrapperProps = TransientProps<{ maxWidth: number; break: boolean }>;

export const FlexWrapper = styled.div<Partial<FlexWrapperProps>>`
  ${({ $maxWidth: maxWidth = 50, $break: willBreak = false }) => css`
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;

    label {
      max-width: calc(${maxWidth}% - 0.5rem);
      margin: 0;
    }

    ${down('sm')} {
      flex-direction: ${willBreak ? 'column' : 'row'};

      label {
        max-width: ${willBreak ? '100%' : 'calc(60% - 0.5rem)'};
      }
    }
  `}
`;

export const TermsAgreement = styled.label`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 2rem;
    user-select: none;
    margin-top: 1rem;

    input {
      display: none;

      &:checked + span {
        animation: ${checkAnimation(theme)} 0.5s
          cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;

        svg {
          opacity: 1;
          transform: scale(0);
          color: white;
          animation: ${iconAnimation} 0.3s cubic-bezier(1, 0.008, 0.565, 1.65)
            0.1s 1 forwards;
        }
      }
    }

    span {
      display: inline-block;
      border-radius: 50%;
      border: 5px solid rgba(0, 0, 0, 0.1);
      background: white;
      vertical-align: middle;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: border 0.3s ease;
      position: absolute;
      left: 0;
      transform-origin: center;

      svg {
        opacity: 0.2;
        color: transparent;
        transition: opacity 0.3s 0.1s ease;
      }

      &:hover {
        border: 5px solid rgba(0, 0, 0, 0.2);
      }
    }
  `}
`;

const checkAnimation = ({ colors }: DefaultTheme) => keyframes`
  0% {
    transform: scale(0.75);
    border-width: 5px;
  }

  10% {
    transform: scale(0.75);
    opacity: 0.1;
    background: rgba(0, 0, 0, 0.2);
    border-width: 15px;
  }

  12% {
    transform: scale(0.75);
    opacity: 0.4;
    background: rgba(0, 0, 0, 0.1);
    border-width: 0;
  }

  50% {
    transform: scale(1);
    background: ${colors.success[100]};
    border: 0;
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
    background: ${colors.success[100]};
    border: 0;
    opacity: 1;
  }
`;

const iconAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;
