import { HtmlHTMLAttributes } from 'react';

import Skeleton from 'react-loading-skeleton';

import { ComponentPropsWithLoading } from '@common/types';
import { generateArray } from '@utils';
import { SpeakerData } from '@common/types/api';

import * as S from './styles';

export type SpeakersBubblesProps = ComponentPropsWithLoading<SpeakerData[]> & {
  innerKey: string | number;
} & HtmlHTMLAttributes<HTMLUListElement>;

const SpeakersBubbles = (props: SpeakersBubblesProps) => {
  const { data, loading: isLoading = false, innerKey, ...attrs } = props;

  if (!data) {
    return (
      <S.Container $loading={isLoading} {...attrs}>
        {generateArray(5).map((key, index) => (
          <li key={key}>
            <Skeleton
              circle
              width="1.75rem"
              height="1.75rem"
              duration={1.2 + index * 0.1}
            />
          </li>
        ))}
      </S.Container>
    );
  }

  const numberOfSpeakers = data.length;

  return (
    <S.Container $loading={isLoading} {...attrs}>
      {data.slice(0, 5).map((speaker, index) => {
        if (index === 4 && numberOfSpeakers > 5) {
          return (
            <div key={`speakers-${Date.now()}-overflow`}>
              {`+${numberOfSpeakers - index}`}
            </div>
          );
        }

        return (
          <img
            key={`speaker-${speaker.id}-${Date.now()}-${innerKey}`}
            src={speaker.photo}
            alt={speaker.name}
          />
        );
      })}
    </S.Container>
  );
};

export default SpeakersBubbles;
