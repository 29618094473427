import { memo } from 'react';

import { useTheme } from 'styled-components';
import { GooSpinner } from 'react-spinners-kit';

import { useNewsFeedPostCommentsQuery } from '@hooks';

import Comment from './Comment';
import * as S from './styles';
import NewCommentInput from './NewCommentInput';

type CommentSectionProps = {
  postId: number;
  visible: boolean;
  inputVisible: boolean;
};

const CommentSection = (props: CommentSectionProps) => {
  const theme = useTheme();

  const { postId, visible: isVisible, inputVisible: isInputVisible } = props;

  const postComments = useNewsFeedPostCommentsQuery(postId, isVisible);

  if (!isInputVisible && !isVisible) {
    return null;
  }

  if (!isInputVisible && postComments.isLoading) {
    return (
      <S.LoadingWrapper>
        <GooSpinner
          size={1.5}
          sizeUnit="rem"
          color={theme.colors.brand.primary.default}
        />
      </S.LoadingWrapper>
    );
  }

  return (
    <S.Container>
      <NewCommentInput postId={postId} />

      {isVisible && !!postComments.data?.length && (
        <ul>
          {postComments.data.map((comment) => (
            <Comment key={comment.id} {...comment} />
          ))}
        </ul>
      )}
    </S.Container>
  );
};

export default memo(CommentSection);
