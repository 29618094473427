import { QueryFunctionContext, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { ONE_HOUR } from '@constants';
import { SponsorProfileData, SponsorProfileResponse } from '@common/types/api';
import { api } from '@services';
import { PromiseWithCancel } from '@common/types';
import { cancelToken } from '@services/api';

const fetchSponsorProfile = (ctx: QueryFunctionContext<string[]>) => {
  const [, sponsorId] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<SponsorProfileResponse>(
      `partners/${sponsorId}?include=archives,products,category,videos`,
      { cancelToken: source.token },
    )
    .then(({ data }) => data.data) as PromiseWithCancel<SponsorProfileData>;

  promise.cancel = () => source.cancel('useSponsorProfileQuery Cancelled');

  return promise;
};

export const useSponsorProfileQuery = (id: string) =>
  useQuery<SponsorProfileData, AxiosError>(
    ['sponsor-profile', id],
    fetchSponsorProfile,
    {
      staleTime: ONE_HOUR,
    },
  );
