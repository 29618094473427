import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { TagCloud } from 'react-tagcloud';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiSend } from 'react-icons/fi';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { GooSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';

import {
  useBroadcastQuery,
  useWordCloudMutation,
  useWordCloudQuery,
} from '@hooks';
import { eventfyStorage } from '@services';

import * as S from './styles';
import { BaseInteractionProps } from '../../types';

type FormInputs = {
  word_cloud: string;
};

const WordCloud = ({ active: isActive }: BaseInteractionProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const formMethods = useForm<FormInputs>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(
      Yup.object().shape({
        word_cloud: Yup.string()
          .required('valid.word.req')
          .min(2, 'valid.word.invalid')
          .test(
            'string-length',
            'valid.word.onlyOne',
            (value) => (value?.trim().split(' ')?.length || 0) < 2,
          ),
      }),
    ),
  });
  const { handleSubmit, reset } = formMethods;

  const { data: room } = useBroadcastQuery({
    select: ({ id, settings }) => ({
      id,
      isWordCloudActive: !!settings.data.word_cloud,
    }),
    notifyOnChangeProps: ['data'],
  });

  const wordCloudQuery = useWordCloudQuery(room?.id);

  const [amountOfWordsSent, setAmountOfWordsSent] = useState(0);

  const maxWordsToBeSent = wordCloudQuery.data?.word_limit || Infinity;

  const canUserSendNewWord = amountOfWordsSent <= maxWordsToBeSent;

  const cloudTitle =
    wordCloudQuery.data?.description ?? t('pages.broadcast.word.title');

  const wordCloud = useMemo(
    () => (
      <TagCloud
        minSize={10}
        maxSize={52}
        tags={wordCloudQuery.data?.cloud || []}
        colorOptions={{
          luminosity: 'dark',
        }}
      />
    ),
    [wordCloudQuery.data],
  );

  const { mutateAsync, isLoading: isMutating } = useWordCloudMutation(
    room?.id,
    wordCloudQuery.data?.id,
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);

        setAmountOfWordsSent((prevState) => {
          const newAmount = prevState + 1;

          eventfyStorage.setItem(
            `${room?.id}:word_cloud:amount_of_words_sent`,
            newAmount,
          );

          return newAmount;
        });
      },
    },
  );

  const handleWordCloudSubmit = useCallback(
    async ({ word_cloud }: FormInputs) => {
      reset();
      await mutateAsync(word_cloud.toLowerCase());
    },
    [mutateAsync, reset],
  );

  useEffect(() => {
    if (room?.id) {
      const previousAmountOfWordsSent = eventfyStorage.getItem(
        `${room.id}:word_cloud:amount_of_words_sent`,
      );

      setAmountOfWordsSent(Number(previousAmountOfWordsSent || 0));
    }
  }, [room?.id]);

  return (
    <S.Container
      $hide={!room?.isWordCloudActive}
      $disabled={!isActive}
      onSubmit={handleSubmit(handleWordCloudSubmit)}
    >
      <FormProvider {...formMethods}>
        <S.WordCloudWrapper>
          <h3>
            {cloudTitle}

            {isMutating && (
              <GooSpinner
                size={1.5}
                sizeUnit="rem"
                color={theme.colors.brand.primary.default}
              />
            )}
          </h3>

          {wordCloud}
        </S.WordCloudWrapper>

        <S.WordCloudInput
          required
          name="word_cloud"
          type="text"
          autoFocus={isActive}
          readOnly={!room?.isWordCloudActive || !canUserSendNewWord}
          placeholder={
            canUserSendNewWord
              ? t('pages.broadcast.word.title')
              : t('pages.broadcast.word.maxWord')
          }
          buttonIcon={FiSend}
        />
      </FormProvider>
    </S.Container>
  );
};

export default memo(WordCloud);
