import styled, { css } from 'styled-components';
import { down, up, between } from 'styled-breakpoints';

import { TransientProps } from '@common/types';

type ContainerZoomProps = TransientProps<{
  zoom: boolean;
}>;

export const Container = styled.article<ContainerZoomProps>`
  ${({ $zoom: isZoom }) => css`
    ${isZoom &&
    css`
      ${up('xxl')} {
        margin: 0 -5rem;
      }
      ${down('xxl')} {
        margin: 0 -3rem;
      }
      ${between('lg', 'xl')} {
        margin: 0;
      }
      ${between('md', 'lg')} {
        margin: 0 1rem;
      }
      ${between('sm', 'md')} {
        margin: 0;
      }
    `}
  `};
`;

export const BroadcastHeader = styled.header`
  ${() => css`
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    gap: 2rem;
    grid-template-areas: 'Title Title Title Title Language CTA CTA CTA CTA';
    margin-bottom: 1.25rem;

    > div:first-child {
      grid-area: Title;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0;

      > div:first-child {
        margin-bottom: 0;
      }
    }

    ${down('md')} {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 0.5rem;
      grid-template-areas:
        'Title Title Title Title Title Title'
        'Language CTA CTA CTA CTA CTA';

      > div:first-child {
        flex-direction: column;
        align-items: flex-start;

        h2 {
          font-size: 1.25rem;
        }
      }
    }
  `};
`;

export const ContentGrid = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(5, minmax(5.25rem, 8.25rem)) 19rem;
    gap: 3rem 2rem;
    grid-template-areas:
      'Player Player Player Player Player Interactions Interactions Interactions Interactions'
      'Player Player Player Player Player Interactions Interactions Interactions Interactions'
      'Player Player Player Player Player Interactions Interactions Interactions Interactions'
      'About About About About About Interactions Interactions Interactions Interactions'
      'About About About About About Sponsors Sponsors Sponsors Sponsors'
      'About About About About About . . . .';
    overflow: hidden;

    ${down('md')} {
      display: flex;
      flex-direction: column;
    }
  `};
`;

export const ContentGridZoom = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, minmax(5.25rem, 7.25rem)) 19rem;
    gap: 3rem 2rem;
    grid-template-areas:
      'Player Player Player Player Player Player Player Player Interactions Interactions Interactions Interactions'
      'Player Player Player Player Player Player Player Player Interactions Interactions Interactions Interactions'
      'Player Player Player Player Player Player Player Player Interactions Interactions Interactions Interactions'
      'Player Player Player Player Player Player Player Player Interactions Interactions Interactions Interactions'
      'Player Player Player Player Player Player Player Player Interactions Interactions Interactions Interactions';
    overflow: hidden;

    ${down('md')} {
      display: flex;
      flex-direction: column;
    }
  `};
`;
