/* eslint-disable react/no-danger */
/* eslint-disable radar/cognitive-complexity */
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import { HTMLMotionProps } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { DEFAULT_MODAL_ANIMATION, DEFAULT_TRANSITION } from '@constants';
import { useGamificationRulesQuery } from '@hooks';

import * as S from './styles';

type TermsModalBaseProps = HTMLMotionProps<'dialog'>;

type TermsModalProps = PropsWithChildren<TermsModalBaseProps>;

const TermsModal = (
  props: TermsModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const gamificationRulesQuery = useGamificationRulesQuery();
  const { t } = useTranslation();

  const { children, ...attrs } = props;

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      {...attrs}
    >
      {children}

      <h2>{t('modal.regulation.title')}</h2>

      {gamificationRulesQuery.isSuccess && (
        <S.RulesWrapper>
          <div
            dangerouslySetInnerHTML={{ __html: gamificationRulesQuery.data }}
          />
        </S.RulesWrapper>
      )}
    </S.Container>
  );
};

export default forwardRef(TermsModal);
