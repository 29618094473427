import { useEffect } from 'react';
import { useRef } from 'react';

import { Variants } from 'framer-motion';
import { GooSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import * as S from './styles';

const variants: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      delay: 2,
      type: 'spring',
      duration: 0.5,
    },
  },
  shown: {
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 1.5,
    },
  },
};

const LoadingScreen = () => {
  const theme = useTheme();

  const loadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const targetElement = loadingRef.current;

    if (targetElement) {
      disableBodyScroll(targetElement);
    }

    return () => {
      if (targetElement) {
        enableBodyScroll(targetElement);
      }
    };
  }, []);

  return (
    <S.Container
      ref={loadingRef}
      variants={variants}
      initial="hidden"
      animate="shown"
      exit="hidden"
      data-testid="loading-screen"
    >
      <GooSpinner
        size={5}
        sizeUnit="rem"
        color={theme.colors.brand.primary.default}
      />
    </S.Container>
  );
};

export default LoadingScreen;
