import { useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { ONE_HOUR } from '@constants';
import { FullSpeakerData, FullSpeakersResult } from '@common/types/api';

import { fetchSpeakers } from '../useLobbyQueries/queries';

export const useSpeakersQuery = (page = 1, roomId?: number | null) => {
  const queryClient = useQueryClient();

  return useQuery<FullSpeakersResult, AxiosError>(
    ['speakers', page, roomId ? { roomId } : undefined],
    fetchSpeakers,
    {
      staleTime: ONE_HOUR,
      onSuccess: ({ speakers }) => {
        speakers.map((speaker) =>
          queryClient.setQueryData<FullSpeakerData>(
            ['speaker', speaker.id],
            speaker,
          ),
        );
      },
    },
  );
};
