import { useCallback } from 'react';

import { useQueryClient } from 'react-query';
import { useChannel, useEvent } from '@harelpls/use-pusher';

import { UserAskingAnsweredEvent } from '@common/types/pusher/events';
import { RoomAskingsData } from '@common/types/api';
import { useAuth } from '@hooks/useAuth';
import { selectUserUUID } from '@hooks/useAuth/selectors';

export const useAskingAnswersChannel = (roomId?: number) => {
  const queryClient = useQueryClient();
  const userUUID = useAuth(selectUserUUID);

  const askingAnswersChannel = useChannel(
    `room.${roomId}.${userUUID}.asking_reply`,
  );

  const handleAnsweredQuestion = useCallback(
    (response?: UserAskingAnsweredEvent) => {
      if (!response || !roomId) {
        return;
      }

      const queryKey = ['askings', roomId] as const;

      const previousAskingsCache =
        queryClient.getQueryData<RoomAskingsData[]>(queryKey);

      if (previousAskingsCache) {
        const answeredQuestion = response.data[0];

        queryClient.setQueryData<RoomAskingsData[]>(
          queryKey,
          previousAskingsCache.map((asking) =>
            asking.id !== answeredQuestion.id
              ? asking
              : {
                  ...asking,
                  status: answeredQuestion.status,
                  answer: answeredQuestion.answer,
                },
          ),
        );
      }
    },
    [queryClient, roomId],
  );

  useEvent(
    askingAnswersChannel,
    'UserAskingAnsweredEvent',
    handleAnsweredQuestion,
  );
};
