/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import Tippy from '@tippyjs/react';
import { FiChevronDown } from 'react-icons/fi';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { useTranslation } from 'react-i18next';

import { useBroadcastQuery, useToggle } from '@hooks';

import * as S from './styles';

type SelectPlayerLanguageProps = {
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
};

const SelectPlayerLanguage = (props: SelectPlayerLanguageProps) => {
  const shouldDisplayOnRight = useBreakpoint(up('lg'));

  const { data } = useBroadcastQuery({
    select: ({ streamings }) => streamings.data,
    notifyOnChangeProps: 'tracked',
  });
  const { t } = useTranslation();

  const [isMenuVisible, toggleIsMenuVisible] = useToggle(false);

  const { currentIndex, setCurrentIndex } = props;

  const currentStreaming = data![currentIndex];

  const handleSelectLanguage = useCallback(
    (index: number) => () => {
      setCurrentIndex(index);
    },
    [setCurrentIndex],
  );

  useEffect(() => {
    const mainStreamingIndex = data!.findIndex(
      (streaming) => !!streaming.streaming_main,
    );

    if (mainStreamingIndex >= 0) {
      setCurrentIndex(mainStreamingIndex);
    }
  }, [setCurrentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <S.Container $menuVisible={isMenuVisible}>
      {t('pages.broadcast.lang')}
      <Tippy
        arrow
        inertia
        interactive
        hideOnClick
        trigger="click"
        placement="bottom-end"
        offset={[shouldDisplayOnRight ? -16 : 6, 10]}
        theme="light-border"
        animation="shift-away-subtle"
        zIndex={1001}
        onShow={toggleIsMenuVisible}
        onHide={toggleIsMenuVisible}
        content={
          <>
            {data!.map((streaming, index) => (
              <S.TippyButton
                key={streaming.streaming_lang}
                $active={currentIndex === index}
                type="button"
                onClick={handleSelectLanguage(index)}
              >
                {streaming.streaming_lang}
              </S.TippyButton>
            ))}
          </>
        }
      >
        <button type="button">
          {currentStreaming.streaming_lang}
          <FiChevronDown />
        </button>
      </Tippy>
    </S.Container>
  );
};

export default SelectPlayerLanguage;
