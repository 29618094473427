import { memo } from 'react';

import { FiTrello } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useEventfy, useNewsFeedQuery } from '@hooks';
import { SectionHeader } from '@components';
import { generateArray } from '@utils';
import { selectIsNewsFeedEnable } from '@hooks/useEventfy/selectors';

import PostPreviewLoading from './PostPreview/Loading';
import PostPreview from './PostPreview';
import * as S from './styles';

const NewsFeedPreview = () => {
  const isNewsFeedEnabled = useEventfy(selectIsNewsFeedEnable);

  const newsfeedQuery = useNewsFeedQuery(isNewsFeedEnabled);
  const { t } = useTranslation();

  if (!isNewsFeedEnabled) {
    return null;
  }

  return (
    <S.Container>
      <header>
        <SectionHeader
          as="div"
          title={t('pages.lobby.newsfeed')}
          icon={FiTrello}
        />

        <Link to="/newsfeed">{t('pages.lobby.newsfeedComplete')}</Link>
      </header>

      <S.PostPreviewList>
        {newsfeedQuery.isLoading &&
          generateArray(3).map((key) => <PostPreviewLoading key={key} />)}

        {newsfeedQuery.isSuccess &&
          newsfeedQuery.data.pages[0].data
            .slice(0, 3)
            .map((post) => (
              <PostPreview key={`post-preview-${post.id}`} {...post} />
            ))}
      </S.PostPreviewList>
    </S.Container>
  );
};

export default memo(NewsFeedPreview);
