import { useEffect, useRef } from 'react';

import io, { Socket } from 'socket.io-client';

export const useWebSocket = () => {
  const socket = useRef<Socket>();

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_CHAT_BASE_URL);

    return () => {
      socket.current?.disconnect();
    };
  }, []);

  return socket.current;
};
