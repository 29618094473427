export const AUTH_ENDPOINT = <const>{
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  TOKEN: '/auth/token',
  FORGOTPASSWORD: '/auth/password/email',
  RESETPASSWORD: '/auth/password/reset',
};

export const USER_ENDPOINT = <const>{
  REGISTER: '/register',
  PROFILE: '/user/me',
  EDIT_PROFILE(userId: number | string) {
    return `/user/${userId}`;
  },
};

export const SETTINGS_ENDPOINT = '/settings';

export const ROOMS_ENDPOINT = '/rooms';

export const SPEAKERS_ENDPOINT = '/participants';

export const SCHEDULE_ENDPOINT = '/schedules';

export const SPONSORS_ENDPOINT = '/partners';

export const VIDEOS_ENDPOINT = '/videos';
