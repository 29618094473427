import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { TransientProps } from '@common/types';

type ContainerProps = TransientProps<{ open: boolean }>;

export const Container = styled.div<ContainerProps>`
  ${({ $open: isOpen }) => css`
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 1rem;

    button {
      padding: 0;
      background: none;
    }

    > button {
      display: flex;
      align-items: center;

      > span {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      svg {
        transition: 180ms ease-in-out;
        transform: rotate(${isOpen ? -180 : 0}deg);
      }
    }
  `}
`;

export const Dropdown = styled(motion.ul)`
  ${({ theme }) => css`
    position: absolute;
    top: 4.25rem;
    right: -0.5rem;
    width: 12rem;
    background: ${theme.colors.background[100]};
    border-radius: 0.25rem;

    box-shadow: 0 3.5px 2.3px rgba(0, 0, 0, 0.04),
      0 7.1px 5.6px rgba(0, 0, 0, 0.034), 0 11.1px 10.8px rgba(0, 0, 0, 0.029),
      0 15.8px 19.3px rgba(0, 0, 0, 0.025), 0 22px 31.9px rgba(0, 0, 0, 0.022),
      0 31.1px 40px rgba(0, 0, 0, 0.017), 0 44px 43px rgba(0, 0, 0, 0.012);

    > li {
      button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 0.875rem;
        transition: 180ms 80ms ease-in-out;

        :hover {
          background: ${theme.colors.brand.primary.default};
          color: ${theme.colors.base.text};
        }
      }

      :first-child button {
        border-radius: 0.25rem 0.25rem 0 0;
      }

      :last-child button {
        border-radius: 0 0 0.25rem 0.25rem;
      }

      :only-child button {
        border-radius: 0.25rem;
      }
    }

    ::before {
      content: '';
      border-style: solid;
      z-index: 2;
      pointer-events: none;
      display: block;
      position: absolute;
      transition: 250ms ease-in-out;
      top: -0.3rem;
      right: 6.5%;
      border-width: 0 0.315rem 0.315rem 0.315rem;
      border-color: transparent transparent ${theme.colors.background[100]}
        transparent;
    }
  `}
`;
