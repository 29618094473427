import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { api } from '@services';
import {
  EmojiNames,
  EmojiStatisticsData,
  EmojiStatisticsResponse,
} from '@common/types/api';

export const useEmojiMutation = (roomId?: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (emoji: EmojiNames) =>
      api.post('/rooms/emoji', {
        room_id: roomId,
        [emoji]: 1,
      }),
    {
      onMutate: async (emoji: EmojiNames) => {
        await queryClient.cancelQueries(['emoji', roomId]);

        const previousStatistics =
          queryClient.getQueryData<EmojiStatisticsData>(['emoji', roomId]);

        if (previousStatistics) {
          queryClient.setQueryData<EmojiStatisticsData>(['emoji', roomId], {
            ...previousStatistics,
            [emoji]: `${+previousStatistics[emoji] + 1}`,
          });
        }

        return { previousStatistics };
      },
      onSuccess: ({ data }: AxiosResponse<EmojiStatisticsResponse>) => {
        queryClient.setQueryData<EmojiStatisticsData>(
          ['emoji', roomId],
          data.data,
        );
      },
      onError: (_err, _variables, context) => {
        if (context?.previousStatistics) {
          queryClient.setQueryData<EmojiStatisticsData>(
            ['emoji', roomId],
            context.previousStatistics,
          );
        }
      },
    },
  );
};
