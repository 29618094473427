import { QueryFunctionContext, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { ONE_HOUR } from '@constants';
import { VideosData, VideoDetailResponse } from '@common/types/api';
import { api } from '@services';
import { PromiseWithCancel } from '@common/types';
import { cancelToken } from '@services/api';

const fetchVideoDetail = (ctx: QueryFunctionContext<string[]>) => {
  const [, videoId] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<VideoDetailResponse>(`videos/${videoId}`, {
      cancelToken: source.token,
    })
    .then(({ data }) => data.data) as PromiseWithCancel<VideosData>;

  promise.cancel = () => source.cancel('useVideoDetailQuery Cancelled');

  return promise;
};

export const useVideoDetailQuery = (id: string) =>
  useQuery<VideosData, AxiosError>(['video-detail', id], fetchVideoDetail, {
    staleTime: ONE_HOUR * 24,
  });
