import { Router } from 'react-router';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Toaster } from 'react-hot-toast';
import { useTheme } from 'styled-components';
import { AnimatePresence } from 'framer-motion';

import { history } from '@services';
import { useEventColorsEffect, useEventQuery } from '@hooks';
import { LoadingScreen, TawkTo, WhatsAppButton } from '@components';
import { DEVTOOLS_TOGGLE_BUTTON_PROPS } from '@services/query-client';
import { IntegrationLocation } from '@common/types/api';

import Routes from './routes';
import AppProviders from './contexts';
import { EventContext } from './contexts/event';
import { generateToastOptions } from './utils';

const App = () => {
  const theme = useTheme();
  const eventQuery = useEventQuery();

  const shouldDisplayLoading = !eventQuery.isSuccess || eventQuery.isFetching;

  useEventColorsEffect(eventQuery.data);

  return (
    <AnimatePresence initial={false}>
      {shouldDisplayLoading ? (
        <LoadingScreen key="loading-screen" />
      ) : (
        <EventContext.Provider value={eventQuery.data}>
          <Router history={history}>
            <AppProviders>
              <Routes />

              <TawkTo location={IntegrationLocation.EVERY_PAGE} />
              <WhatsAppButton location={IntegrationLocation.EVERY_PAGE} />

              <Toaster
                position="top-right"
                toastOptions={generateToastOptions(theme)}
              />
              <ReactQueryDevtools
                initialIsOpen
                toggleButtonProps={DEVTOOLS_TOGGLE_BUTTON_PROPS}
              />
            </AppProviders>
          </Router>
        </EventContext.Provider>
      )}
    </AnimatePresence>
  );
};

export default App;
