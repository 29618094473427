/* eslint-disable radar/cognitive-complexity */
import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { api } from '@services';
import { CommentData, TimelineResponse } from '@common/types/api';

import { useAuth } from '../useAuth';
import { selectUser } from '../useAuth/selectors';

export const useCreateCommentMutation = (postId: number) => {
  const queryClient = useQueryClient();

  const user = useAuth(selectUser);

  const queryKey = ['post-comments', postId] as const;
  const postsQueryKey = ['newsfeed'] as const;

  return useMutation(
    (comment: string) =>
      api.post('/timeline/comment', {
        post_id: postId,
        comment,
      }),
    {
      onMutate: (comment: string) => {
        const date = new Date().toISOString();

        const previousComments =
          queryClient.getQueryData<CommentData[]>(queryKey);

        const previousPosts =
          queryClient.getQueryData<InfiniteData<TimelineResponse>>(
            postsQueryKey,
          );

        if (previousComments) {
          queryClient.setQueryData<CommentData[]>(
            queryKey,
            previousComments.concat({
              id: Date.now(),
              post_id: postId,
              created_at: date,
              updated_at: date,
              comment,
              user_id: user.id,
              user: {
                data: {
                  id: user.id,
                  name: user.name,
                  avatar: user.avatar,
                },
              },
            } as CommentData),
          );
        }

        if (previousPosts) {
          queryClient.setQueryData<InfiniteData<TimelineResponse>>(
            postsQueryKey,
            {
              ...previousPosts,
              pages: previousPosts.pages.map((page) => {
                const postIndex = page.data.findIndex(
                  (post) => post.id === postId,
                );

                if (postIndex < 0) {
                  return page;
                }

                return {
                  ...page,
                  data: page.data.map((post, index) => {
                    if (postIndex !== index) {
                      return post;
                    }

                    return {
                      ...post,
                      comments_total: post.comments_total + 1,
                    };
                  }),
                };
              }),
            },
          );
        }

        return { previousComments, previousPosts };
      },
      onError: (_, __, ctx) => {
        if (ctx) {
          const { previousComments, previousPosts } = ctx;

          if (previousComments) {
            queryClient.setQueryData<CommentData[]>(queryKey, previousComments);
          }

          if (previousPosts) {
            queryClient.setQueryData<InfiniteData<TimelineResponse>>(
              postsQueryKey,
              previousPosts,
            );
          }
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    },
  );
};
