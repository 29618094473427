import styled, { css } from 'styled-components';

export const PlayerWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background[100]};
    border-radius: 2rem;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `};
`;
export const Glass = styled.div`
  ${() => css`
    height: 100px;
    width: 100%;
    z-index: 999;
    overflow: hidden;
    position: absolute;
  `};
`;
