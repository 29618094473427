import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';

export const Container = styled.article`
  > img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 1.75rem;
    margin-bottom: 3rem;
    ${down('md')} {
      height: 180px;
      margin-bottom: 2rem;
    }
  }
`;

export const Section = styled.section`
  min-width: 0;
  position: relative;
  margin-top: 4rem;

  > header {
    max-width: 100%;

    a {
      font-size: 0.875rem;
    }
  }

  & + &,
  & + div {
    margin-top: 4rem;
  }
`;

export const VideoCardsContainer = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.25rem;

  ${down('md')} {
    flex-direction: column;
  }
`;

export const VideoCard = styled.li`
  ${({ theme }) => css`
    width: calc(33% - 1.25rem);
    height: 14rem;
    border-radius: 1rem;
    overflow: hidden;
    background: ${theme.colors.background[100]};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);
    transition: 180ms ease-in-out;

    > a {
      color: ${theme.colors.foreground[100]};

      > img {
        width: 100%;
        height: 10.625rem;
        object-fit: cover;
        border-radius: 1rem;
      }
    }

    :hover {
      transform: translate3d(0, -0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023), 0 2px 2px rgba(0, 0, 0, 0.023),
        0 4px 4px rgba(0, 0, 0, 0.023), 0 8px 8px rgba(0, 0, 0, 0.023),
        0 16px 16px rgba(0, 0, 0, 0.023), 0 32px 32px rgba(0, 0, 0, 0.023);
    }

    :active {
      transform: translate3d(0, 0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047), 0 2px 2px rgba(0, 0, 0, 0.047),
        0 4px 4px rgba(0, 0, 0, 0.047);
    }

    ${down('md')} {
      flex-grow: 1;
      width: 100%;
    }
  `}
`;

export const VideoCardDataWrapper = styled.div`
  padding: 0.75rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const VideoCardDate = styled.div`
  ${({ theme }) => css`
    width: 3.125rem;
    height: 3.125rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background: ${theme.colors.background[80]};
    color: ${theme.colors.foreground[60]};

    > strong {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    > span {
      font-weight: 400;
      line-height: 1rem;
    }
  `}
`;

export const VideoCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > strong {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span {
    font-weight: 400;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    > svg {
      vertical-align: -1px;
      margin: 0 0.125rem 0 0;
    }
  }
`;
