import { useEffect } from 'react';
import { RefObject } from 'react';

type IntersectionObserverOptions = {
  root?: RefObject<Element>;
  target: RefObject<Element>;
  onIntersect: () => void;
  threshold?: number;
  rootMargin?: number;
  enabled?: boolean;
};

const useIntersectionObserver = (options: IntersectionObserverOptions) => {
  const {
    root,
    target,
    onIntersect,
    threshold = 1.0,
    rootMargin = 0,
    enabled = true,
  } = options;

  useEffect(() => {
    if (!enabled) {
      return () => null;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root: root?.current,
        rootMargin: `${rootMargin}px`,
        threshold,
      },
    );

    const element = target && target.current;

    if (!element) {
      return () => null;
    }

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.current, enabled]);
};

export { useIntersectionObserver };
