import { DispatchWithoutAction, ForwardedRef, forwardRef } from 'react';

import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

import { DEFAULT_MODAL_ANIMATION, DEFAULT_TRANSITION } from '@constants';
import { useAuth } from '@hooks';

import * as S from './styles';

type RewardModalProps = {
  handleClose: DispatchWithoutAction;
};

const RewardModal = (
  props: RewardModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const { handleClose, ...attrs } = props;
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      {...attrs}
    >
      <div>
        <button type="button" onClick={handleClose}>
          <MdClose />
        </button>
      </div>

      <strong>
        {user.name}, {t('modal.indication.congrats')}
      </strong>
      <p>{t('modal.indication.congratsMessage')}</p>
    </S.Container>
  );
};

export default forwardRef(RewardModal);
