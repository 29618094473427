import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { QueryFunctionContext } from 'react-query';

import {
  RoomPollsData,
  RoomPollsResponse,
  RoomPollsResult,
} from '@common/types/api';
import { api } from '@services';
import { cancelToken } from '@services/api';
import { PromiseWithCancel } from '@common/types';

const fetchPoll = (ctx: QueryFunctionContext<[string, number]>) => {
  const [, roomId] = ctx.queryKey;

  const source = cancelToken();

  const promise = api
    .get<RoomPollsResponse>(`/rooms/${roomId}/polls`, {
      cancelToken: source.token,
    })
    .then(({ data }) => {
      const activePolls = data.data.filter((quiz) => quiz.status);

      let totalAnsweredPolls = 0;
      const answeredPolls = [] as RoomPollsData[];
      const unansweredPolls = [] as RoomPollsData[];

      activePolls.forEach((quiz) => {
        if (quiz.user_replied) {
          totalAnsweredPolls += 1;

          if (quiz.show_result) {
            answeredPolls.push(quiz);
          }
        } else {
          unansweredPolls.push(quiz);
        }
      });

      return {
        totalAnsweredPolls,
        answeredPolls,
        polls: unansweredPolls,
      };
    }) as PromiseWithCancel<RoomPollsResult>;

  promise.cancel = () => source.cancel('usePollQuery Cancelled');

  return promise;
};

export const usePollQuery = (roomId?: number) =>
  useQuery<RoomPollsResult, AxiosError>(['poll', roomId], fetchPoll, {
    enabled: !!roomId,
  });
