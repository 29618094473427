import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import { UserProfileResponse } from '@common/types/api';
import { CHAT_CLIENT_ID } from '@constants';
import { USER_ENDPOINT } from '@constants/endpoints';
import { api, chatApi, eventfyStorage } from '@services';

type TokenParam = {
  access_token: string;
  token_type: 'bearer';
};

export type Token = {
  jwt: string;
  type: 'bearer';
};

export const handleTokenAndUserData = async (tokenData: TokenParam) => {
  const { token_type, access_token } = tokenData;

  api.defaults.headers.authorization = `${token_type} ${access_token}`;

  // const { data: chatResponse } = await chatApi.post(`/sessions/client`, {
  //   client_id: CHAT_CLIENT_ID,
  // });

  // chatApi.defaults.headers.authorization = `Bearer ${chatResponse.token}`;

  const { data: user } = await api.get<UserProfileResponse>(
    USER_ENDPOINT.PROFILE,
  );

  const token: Token = {
    jwt: access_token,
    type: token_type,
  };

  eventfyStorage.setCookie('token', token);
  // eventfyStorage.setCookie('chat-token', chatResponse.token);
  eventfyStorage.setItem('user', user.data);

  ReactGA.set({ userId: user.data.id });
  TagManager.dataLayer({ dataLayer: { userId: user.data.id } });

  return user.data;
};

export const handleTokenChat = async () => {
  const { data: chatResponse } = await chatApi.post(`/sessions/client`, {
    client_id: CHAT_CLIENT_ID,
  });

  chatApi.defaults.headers.authorization = `Bearer ${chatResponse.token}`;
  eventfyStorage.setCookie('chat-token', chatResponse.token);

  return chatResponse.token;
};
