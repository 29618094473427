import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { TransientProps } from '@common/types';

type withLoading = TransientProps<{ loading: boolean }>;

export const Container = styled.li<withLoading>`
  ${({ theme, $loading: isLoading }) => css`
    width: 100%;
    max-width: 13.25rem;
    height: 10rem;
    border-radius: 1rem;
    background: ${theme.colors.background[100]};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);
    transition: 180ms 80ms ease-in-out;

    ${isLoading &&
    css`
      pointer-events: none;
    `}

    :hover {
      transform: translate3d(0, -0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023), 0 2px 2px rgba(0, 0, 0, 0.023),
        0 4px 4px rgba(0, 0, 0, 0.023), 0 8px 8px rgba(0, 0, 0, 0.023),
        0 16px 16px rgba(0, 0, 0, 0.023), 0 32px 32px rgba(0, 0, 0, 0.023);
    }

    :active {
      transform: translate3d(0, 0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047), 0 2px 2px rgba(0, 0, 0, 0.047),
        0 4px 4px rgba(0, 0, 0, 0.047);
    }

    ${down('sm')} {
      max-width: 100%;
    }
  `}
`;

export const SponsorCardLink = styled(Link)`
  ${() => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;

    > img {
      width: 100%;
      height: 3.5rem;
      object-fit: contain;
    }
  `}
`;

export const SponsorCardThumbnailSkeleton = styled(Skeleton).attrs(() => ({
  width: '100%',
  height: '3.5rem',
  style: {
    display: 'block',
  },
}))``;

export const SponsorCardCTA = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding: 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background: ${theme.colors.brand.primary.default};
    color: ${theme.colors.brand.primary.text};
  `}
`;
