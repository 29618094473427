import { useCallback, useEffect, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { MetaTags, Pagination } from '@components';
import {
  useEventfy,
  usePagination,
  useSelectRoomsQuery,
  useSpeakersQuery,
} from '@hooks';
import { generateArray } from '@utils';
import { SpeakersIllustration } from '@assets';
import { ReactSelect } from '@components/Select/styles';
import { SelectRoomsData } from '@common/types/api';
import { selectHasMoreThanOneRoom } from '@hooks/useEventfy/selectors';

import * as S from './styles';
import SpeakerCard from './SpeakerCard';

type HandlePagination = (selectedItem: { selected: number }) => void;

const Speakers = () => {
  const history = useHistory();
  const { search } = useLocation();
  const eventHasManyRooms = useEventfy(selectHasMoreThanOneRoom);

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const currentPage = Number(queryParams.get('page') || '1');
  const roomId = queryParams.get('roomId');

  const selectRoomsQuery = useSelectRoomsQuery(eventHasManyRooms);
  const speakersQuery = useSpeakersQuery(
    currentPage,
    roomId ? +roomId : undefined,
  );
  const { t } = useTranslation();

  const pagination = usePagination(
    speakersQuery.data?.meta.pagination.total_pages,
  );

  const handleRoomFilter = useCallback(
    (e?: SelectRoomsData) => {
      if (!e) {
        queryParams.delete('roomId');
      } else {
        queryParams.set('roomId', String(e.value));
      }

      history.push({
        search: `?${queryParams.toString()}`,
      });
    },
    [queryParams, history],
  );

  const handlePageNavigation = useCallback<HandlePagination>(
    ({ selected }) => {
      queryParams.set('page', String(selected + 1));

      history.push({
        search: `?${queryParams.toString()}`,
      });
    },
    [history, queryParams],
  );

  useEffect(() => {
    handlePageNavigation({ selected: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  return (
    <S.Container>
      <MetaTags title={t('pages.speakers.title')} />

      <S.SpeakersHeader>
        <div>
          <span>{t('pages.speakers.subTitle')}</span>
          <h1>{t('pages.speakers.title')}</h1>
        </div>

        <SpeakersIllustration />
      </S.SpeakersHeader>

      {eventHasManyRooms && (
        <S.FilterContainer>
          <ReactSelect
            isClearable
            placeholder={t('pages.speakers.filter')}
            options={selectRoomsQuery.data}
            onChange={handleRoomFilter}
            value={
              roomId && selectRoomsQuery.data?.find((e) => e.value === +roomId)
            }
          />
        </S.FilterContainer>
      )}

      <S.ListWrapper>
        {speakersQuery.isLoading
          ? generateArray(5).map((key) => <SpeakerCard key={key} loading />)
          : speakersQuery.data?.speakers.map((speaker) => (
              <SpeakerCard key={`speaker-${speaker.id}`} data={speaker} />
            ))}
      </S.ListWrapper>

      <Pagination
        forcePage={currentPage - 1}
        pageCount={pagination}
        onPageChange={handlePageNavigation}
      />
    </S.Container>
  );
};

export default Speakers;
