import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(196, 196, 196, 0.27);
  padding-bottom: 0.625rem;
  > img {
    width: 3.375rem;
    height: 3.375rem;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
  }
  ${down('md')} {
    > div {
      > h2 {
        font-size: 16px;
      }
      > span {
        font-size: 12px;
      }
    }
  }
`;

export const RewardsList = styled(PerfectScrollbar)`
  max-width: 43.75rem;
  margin-right: -1.875rem;
  padding: 1.725rem 0 1.25rem 0;
  > ul {
    display: flex;
  }
  ${down('md')} {
    padding: 1.725rem 0 2rem 0;
    margin: 0;
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  font-size: 1.125rem;
  color: #616e84;
`;
