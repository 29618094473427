import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import Skeleton from 'react-loading-skeleton';

import { TransientProps } from '@common/types';

type SpeakerContainerProps = TransientProps<{ loading?: boolean }>;

export const Container = styled.li<SpeakerContainerProps>`
  ${({ theme, $loading: isLoading }) => css`
    padding: 0.5rem;
    width: 100%;
    max-width: 12.5rem;
    height: 100%;
    max-height: 19rem;
    border-radius: 0.5rem;
    background: ${theme.colors.background[100]};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.02),
      0 4px 4px rgba(0, 0, 0, 0.015), 0 8px 8px rgba(0, 0, 0, 0.01),
      0 16px 16px rgba(0, 0, 0, 0.005);
    transition: 180ms 80ms ease-in-out;

    ${isLoading &&
    css`
      pointer-events: none;
    `}

    :hover {
      transform: translate3d(0, -0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.023), 0 2px 2px rgba(0, 0, 0, 0.023),
        0 4px 4px rgba(0, 0, 0, 0.023), 0 8px 8px rgba(0, 0, 0, 0.023),
        0 16px 16px rgba(0, 0, 0, 0.023), 0 32px 32px rgba(0, 0, 0, 0.023);
    }

    :active {
      transform: translate3d(0, 0.125rem, 0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.047), 0 2px 2px rgba(0, 0, 0, 0.047),
        0 4px 4px rgba(0, 0, 0, 0.047);
    }

    ${down('sm')} {
      max-width: 100%;
      max-height: 25rem;
    }
  `}
`;

export const SpeakerCardLink = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
  font: inherit;
  padding: 0;
  width: 100%;
  background: none;

  > * {
    display: block;
  }

  > strong {
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span {
    font-weight: 400;
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SpeakerCardDate = styled.div`
  position: relative;
  margin-bottom: 1rem;

  > img {
    border-radius: 0.25rem;
    width: 100%;
    height: 10.5rem;
    object-fit: cover;
    object-position: center top;

    ${down('sm')} {
      height: 17.5rem;
    }
  }
`;

export const SpeakerAvatarSkeleton = styled(Skeleton).attrs(() => ({
  width: '100%',
  height: '10.5rem',
  style: {
    borderRadius: '0.25rem',
  },
}))``;

export const SpeakerCardCTA = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background: ${theme.colors.brand.primary.default};
    color: ${theme.colors.brand.primary.text};
  `}
`;
